import { useState, useEffect } from "react";
import { Chapter } from "@/types/course";
import { ChapterForm } from "./ChapterForm";
import { ChapterList } from "./ChapterList";
import { supabase } from "@/integrations/supabase/client";
import { toast } from "sonner";
import { generateSlug } from "@/utils/slugUtils";

interface ChapterManagerProps {
  courseId: string;
  onChaptersChange: (chapters: Chapter[]) => void;
}

export const ChapterManager = ({ courseId, onChaptersChange }: ChapterManagerProps) => {
  const [chapters, setChapters] = useState<Chapter[]>([]);
  const [isAddingChapter, setIsAddingChapter] = useState(false);
  const [editingChapter, setEditingChapter] = useState<Chapter | null>(null);

  useEffect(() => {
    if (courseId) {
      fetchChapters();
    }
  }, [courseId]);

  const fetchChapters = async () => {
    const { data, error } = await supabase
      .from("chapters")
      .select("*")
      .eq("course_id", courseId)
      .order("order_index");

    if (error) {
      console.error("Error fetching chapters:", error);
      toast.error("Erreur lors du chargement des chapitres");
      return;
    }

    const chaptersWithDuration = data.map(chapter => ({
      ...chapter,
      duration: chapter.duration || 15,
      slug: chapter.slug || generateSlug(chapter.title, chapter.order_index)
    })) as Chapter[];

    setChapters(chaptersWithDuration);
    onChaptersChange(chaptersWithDuration);
  };

  const handleChapterSuccess = () => {
    setIsAddingChapter(false);
    setEditingChapter(null);
    fetchChapters();
  };

  const handleChapterDelete = async (chapterId: string) => {
    try {
      const { error } = await supabase
        .from("chapters")
        .delete()
        .eq("id", chapterId);

      if (error) throw error;

      toast.success("Chapitre supprimé avec succès");
      fetchChapters();
    } catch (error) {
      console.error("Error deleting chapter:", error);
      toast.error("Erreur lors de la suppression du chapitre");
    }
  };

  const handleReorder = async (reorderedChapters: Chapter[]) => {
    try {
      const updates = reorderedChapters.map((chapter) => ({
        id: chapter.id,
        title: chapter.title,
        content: chapter.content,
        image_url: chapter.image_url,
        order_index: chapter.order_index,
        duration: chapter.duration,
        course_id: courseId,
        slug: chapter.slug || generateSlug(chapter.title, chapter.order_index),
        status: chapter.status
      }));

      const { error } = await supabase
        .from("chapters")
        .upsert(updates, { onConflict: "id" });

      if (error) throw error;

      setChapters(reorderedChapters);
      onChaptersChange(reorderedChapters);
      toast.success("Ordre des chapitres mis à jour");
    } catch (error) {
      console.error("Error reordering chapters:", error);
      toast.error("Erreur lors de la réorganisation des chapitres");
      fetchChapters(); // Revert to original order
    }
  };

  return (
    <div className="space-y-6">
      <ChapterList
        chapters={chapters}
        onEdit={setEditingChapter}
        onAdd={() => setIsAddingChapter(true)}
        onDelete={handleChapterDelete}
        onReorder={handleReorder}
      />

      {(isAddingChapter || editingChapter) && (
        <ChapterForm
          courseId={courseId}
          initialData={editingChapter || undefined}
          onSuccess={handleChapterSuccess}
          onCancel={() => {
            setIsAddingChapter(false);
            setEditingChapter(null);
          }}
        />
      )}
    </div>
  );
};