import { useEffect } from 'react';
import { useAdManager } from "@/hooks/useAdManager";

type AdSpaceProps = {
  type: 'sidebar-left' | 'sidebar-right' | 'content-start' | 'content-middle' | 'content-end';
};

export const AdSpace = ({ type }: AdSpaceProps) => {
  const { 
    adState, 
    isProtectedRoute, 
    checkAdBlocker,
    getAdSlot 
  } = useAdManager(type);

  // Ne pas afficher d'annonces sur les routes protégées
  if (isProtectedRoute) {
    return null;
  }

  useEffect(() => {
    // Skip initialization in development environment
    if (window.location.hostname.includes('lovableproject.com')) {
      console.log('Skipping AdSense initialization in development environment');
      return;
    }

    checkAdBlocker();

    // Initialiser l'annonce une fois que l'élément est monté
    const initializeAd = () => {
      const adElement = document.querySelector(`#ad-${type}`);
      
      // Vérifier si l'élément existe et n'a pas déjà été initialisé
      if (adElement && !adElement.hasAttribute('data-ad-status')) {
        try {
          console.log(`Initializing ad for slot: ${type}`);
          (window.adsbygoogle = window.adsbygoogle || []).push({});
        } catch (error) {
          console.error(`Error initializing ad for slot ${type}:`, error);
        }
      } else {
        console.log(`Ad slot ${type} already initialized or not found`);
      }
    };

    // Délai plus long pour s'assurer que AdSense est bien chargé
    const timer = setTimeout(initializeAd, 500);

    return () => clearTimeout(timer);
  }, [type, checkAdBlocker]);
  
  if (adState === 'blocked' || adState === 'error') {
    return (
      <div 
        className="flex items-center justify-center bg-gray-50 rounded-lg overflow-hidden text-gray-400 text-sm"
        style={{ 
          minHeight: type.startsWith('sidebar') ? '600px' : '250px',
          maxWidth: '100%',
          position: 'relative'
        }}
      >
        {adState === 'blocked' ? 'Contenu sponsorisé non disponible' : 'Erreur de chargement du contenu'}
      </div>
    );
  }

  return (
    <div 
      className="flex items-center justify-center bg-gray-50 rounded-lg overflow-hidden"
      style={{ 
        minHeight: type.startsWith('sidebar') ? '600px' : '250px',
        maxWidth: '100%',
        position: 'relative'
      }}
    >
      <ins
        id={`ad-${type}`}
        className="adsbygoogle"
        style={{ 
          display: 'block', 
          width: '100%', 
          height: '100%',
          maxWidth: '100%',
          overflow: 'hidden'
        }}
        data-ad-client="ca-pub-5422316091615187"
        data-ad-slot={getAdSlot()}
        data-ad-format="auto"
        data-full-width-responsive="true"
      />
    </div>
  );
};