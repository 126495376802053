import { FileText, CheckSquare, Clock } from "lucide-react";

export const getStatusIcon = (status: string) => {
  switch (status) {
    case "published":
      return <CheckSquare className="h-4 w-4" />;
    case "draft":
      return <FileText className="h-4 w-4" />;
    case "pending":
      return <Clock className="h-4 w-4" />;
    default:
      return <FileText className="h-4 w-4" />;
  }
};

export const getStatusColor = (status: string) => {
  switch (status) {
    case "published":
      return "bg-green-500";
    case "draft":
      return "bg-gray-500";
    case "pending":
      return "bg-yellow-500";
    default:
      return "bg-gray-500";
  }
};

export const getStatusLabel = (status: string) => {
  switch (status) {
    case "published":
      return "Publié";
    case "draft":
      return "Brouillon";
    case "pending":
      return "En attente";
    default:
      return status;
  }
};