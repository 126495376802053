import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { supabase } from "@/integrations/supabase/client";
import { Card } from "@/components/ui/card";
import { Header } from "@/components/layout/Header";
import { LoginHeader } from "@/components/auth/login/LoginHeader";
import { LoginForm } from "@/components/auth/login/LoginForm";
import { toast } from "sonner";
import { useQuery } from "@tanstack/react-query";

export default function Login() {
  const navigate = useNavigate();

  const { data: profile, isLoading } = useQuery({
    queryKey: ["profile"],
    queryFn: async () => {
      const { data: { session }, error: sessionError } = await supabase.auth.getSession();
      if (sessionError) throw sessionError;
      
      if (!session?.user?.id) return null;

      const { data, error } = await supabase
        .from("profiles")
        .select("*")
        .eq("id", session.user.id)
        .single();

      if (error) throw error;
      return data;
    },
    retry: false
  });

  useEffect(() => {
    const checkSession = async () => {
      try {
        const { data: { session }, error } = await supabase.auth.getSession();
        if (error) throw error;
        
        if (session) {
          if (profile?.role === 'admin') {
            navigate("/admin/dashboard");
          } else {
            navigate("/student/dashboard");
          }
          toast.success("Connexion réussie");
        }
      } catch (error) {
        console.error("Error checking session:", error);
      }
    };

    if (!isLoading) {
      checkSession();
    }

    const { data: { subscription } } = supabase.auth.onAuthStateChange((event, session) => {
      if (event === 'SIGNED_IN' && session) {
        checkSession();
      }
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [navigate, profile, isLoading]);

  return (
    <div className="min-h-screen flex flex-col bg-gray-50">
      <Header />
      
      <main className="flex-1 flex items-center justify-center p-4 sm:p-8">
        <Card className="w-full max-w-md p-8 bg-white shadow-lg">
          <LoginHeader />
          <LoginForm />
        </Card>
      </main>
    </div>
  );
}