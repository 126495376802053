import { Button } from "@/components/ui/button";
import { ChevronUp } from "lucide-react";

interface BackToTopProps {
  show: boolean;
  onClick: () => void;
}

export const BackToTop = ({ show, onClick }: BackToTopProps) => {
  if (!show) return null;

  return (
    <Button
      variant="secondary"
      size="icon"
      className="fixed bottom-8 right-8 w-12 h-12 rounded-full shadow-lg"
      onClick={onClick}
    >
      <ChevronUp className="h-6 w-6" />
    </Button>
  );
};