import { Card } from "@/components/ui/card";
import { Badge } from "@/components/ui/badge";
import { Checkbox } from "@/components/ui/checkbox";
import { Keyword } from "./types";

interface KeywordCardProps {
  keyword: Keyword;
  isSelected: boolean;
  onSelect: (keyword: string) => void;
}

export const KeywordCard = ({ keyword, isSelected, onSelect }: KeywordCardProps) => {
  return (
    <Card className="p-4">
      <div className="flex items-start gap-4">
        <Checkbox
          checked={isSelected}
          onCheckedChange={() => onSelect(keyword.keyword)}
        />
        <div className="flex-1">
          <div className="flex items-center gap-2">
            <h3 className="font-medium">{keyword.keyword}</h3>
            {keyword.recommended && (
              <Badge variant="secondary">Recommandé</Badge>
            )}
          </div>
          <div className="mt-2 space-y-1 text-sm text-muted-foreground">
            <p>Volume mensuel: {keyword.volume}</p>
            <p>Difficulté SEO: {keyword.difficulty}</p>
            <p>Intention: {keyword.intent}</p>
          </div>
        </div>
      </div>
    </Card>
  );
};