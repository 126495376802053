import { useState } from "react";
import { toast } from "sonner";
import { supabase } from "@/integrations/supabase/client";

interface UseAIGenerationProps {
  onSuccess?: (content: string) => void;
}

export const useAIGeneration = ({ onSuccess }: UseAIGenerationProps = {}) => {
  const [isLoading, setIsLoading] = useState(false);

  const generateContent = async (prompt: string) => {
    setIsLoading(true);
    try {
      console.log("🔄 Envoi de la requête à l'IA:", { prompt });

      const { data: response, error } = await supabase.functions.invoke('generate', {
        body: {
          prompt,
          model: "gemini-1.5-pro"
        }
      });

      if (error) throw error;

      console.log("✅ Réponse reçue de l'IA:", response);
      
      if (response && typeof response === 'object' && 'content' in response) {
        console.log("📝 Contenu généré:", response.content);
        toast.success("Contenu généré avec succès");
        onSuccess?.(response.content);
        return response.content;
      } else {
        console.error("Format de réponse inattendu:", response);
        toast.error("Format de réponse inattendu");
        return null;
      }
    } catch (error) {
      console.error("❌ Erreur lors de la génération:", error);
      toast.error("Erreur lors de la génération");
      return null;
    } finally {
      setIsLoading(false);
    }
  };

  return {
    generateContent,
    isLoading
  };
};