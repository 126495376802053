import { Button } from "@/components/ui/button";
import { Label } from "@/components/ui/label";
import { Plus } from "lucide-react";
import { AnswerList } from "../answers/AnswerList";
import { toast } from "sonner";

interface Answer {
  id?: string;
  answer: string;
  is_correct: boolean;
}

interface AnswerFormSectionProps {
  answers: Answer[];
  questionType: string;
  onAnswersUpdate: (answers: Answer[]) => void;
}

export const AnswerFormSection = ({
  answers,
  questionType,
  onAnswersUpdate,
}: AnswerFormSectionProps) => {
  const addAnswer = () => {
    if (answers.length < 6) {
      onAnswersUpdate([...answers, { answer: "", is_correct: false }]);
    } else {
      toast.error("Maximum 6 réponses possibles");
    }
  };

  if (questionType === "text_input") return null;

  return (
    <div className="space-y-4">
      <Label>Réponses</Label>
      <AnswerList
        answers={answers}
        questionType={questionType}
        onAnswerUpdate={onAnswersUpdate}
      />
      <Button
        type="button"
        variant="outline"
        onClick={addAnswer}
        className="w-full"
        disabled={answers.length >= 6}
      >
        <Plus className="h-4 w-4 mr-2" />
        Ajouter une réponse
      </Button>
    </div>
  );
};