import { useState } from "react";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { supabase } from "@/integrations/supabase/client";
import { toast } from "sonner";
import { Upload, Loader2, Image as ImageIcon } from "lucide-react";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { ImageGallery } from "./ImageGallery";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";

interface ImageUploadProps {
  currentImage?: string;
  onUploadComplete: (url: string) => void;
  folder: string;
}

export const ImageUpload = ({
  currentImage,
  onUploadComplete,
  folder,
}: ImageUploadProps) => {
  const [isUploading, setIsUploading] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file) return;

    if (!file.type.startsWith('image/')) {
      toast.error("Veuillez sélectionner un fichier image valide");
      return;
    }

    if (file.size > 5 * 1024 * 1024) {
      toast.error("L'image ne doit pas dépasser 5MB");
      return;
    }

    setIsUploading(true);
    try {
      const fileExt = file.name.split(".").pop();
      const fileName = `${Math.random()}.${fileExt}`;
      const filePath = `${folder}/${fileName}`;

      const { error: uploadError } = await supabase.storage
        .from("course-images")
        .upload(filePath, file, {
          cacheControl: "3600",
          upsert: false
        });

      if (uploadError) {
        console.error("Upload error:", uploadError);
        throw uploadError;
      }

      const { data: { publicUrl } } = supabase.storage
        .from("course-images")
        .getPublicUrl(filePath);

      onUploadComplete(publicUrl);
      setIsDialogOpen(false);
      toast.success("Image téléchargée avec succès");
    } catch (error) {
      console.error("Upload error:", error);
      toast.error("Erreur lors du téléchargement de l'image");
    } finally {
      setIsUploading(false);
    }
  };

  const handleGallerySelect = (url: string) => {
    onUploadComplete(url);
    setIsDialogOpen(false);
    toast.success("Image sélectionnée avec succès");
  };

  return (
    <div className="space-y-4">
      <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
        <DialogTrigger asChild>
          <Button variant="outline" className="w-[200px]">
            <ImageIcon className="h-4 w-4 mr-2" />
            Choisir une image
          </Button>
        </DialogTrigger>
        <DialogContent className="max-w-3xl">
          <DialogHeader>
            <DialogTitle>Sélectionner une image</DialogTitle>
          </DialogHeader>
          <Tabs defaultValue="upload">
            <TabsList className="grid w-full grid-cols-2">
              <TabsTrigger value="gallery">Galerie</TabsTrigger>
              <TabsTrigger value="upload">Télécharger</TabsTrigger>
            </TabsList>
            <TabsContent value="gallery" className="mt-4">
              <ImageGallery onSelect={handleGallerySelect} />
            </TabsContent>
            <TabsContent value="upload" className="mt-4">
              <div className="flex flex-col items-center justify-center">
                <label className="relative w-full cursor-pointer">
                  <div className={`flex flex-col items-center justify-center w-full h-[300px] border-2 border-dashed rounded-lg ${isUploading ? 'opacity-50' : 'hover:bg-muted/50'}`}>
                    {isUploading ? (
                      <div className="flex flex-col items-center gap-2">
                        <Loader2 className="h-8 w-8 animate-spin" />
                        <span>Téléchargement en cours...</span>
                      </div>
                    ) : (
                      <div className="flex flex-col items-center gap-2">
                        <Upload className="h-8 w-8" />
                        <span>Glissez une image ou cliquez pour en sélectionner une</span>
                        <span className="text-sm text-muted-foreground">
                          PNG, JPG ou GIF (max 5MB)
                        </span>
                      </div>
                    )}
                    <Input
                      type="file"
                      accept="image/*"
                      className="absolute inset-0 opacity-0 cursor-pointer"
                      onChange={handleUpload}
                      disabled={isUploading}
                    />
                  </div>
                </label>
              </div>
            </TabsContent>
          </Tabs>
        </DialogContent>
      </Dialog>

      {currentImage && (
        <div className="mt-4">
          <img
            src={currentImage}
            alt="Aperçu"
            className="max-w-xs rounded-lg shadow-sm"
            onError={(e) => {
              console.error("Preview image loading error:", currentImage);
              e.currentTarget.src = "/placeholder.svg";
            }}
          />
        </div>
      )}
    </div>
  );
};