import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { supabase } from "@/integrations/supabase/client";
import { toast } from "sonner";

interface CourseProgress {
  progress: number;
  completedAt: string | null;
  completedChapters: number;
  isCompleted: boolean;
}

const fetchUserSession = async () => {
  const { data: { session }, error } = await supabase.auth.getSession();
  if (error) {
    console.error("Auth error:", error);
    throw error;
  }
  if (!session) {
    throw new Error("No authenticated session");
  }
  return session;
};

const fetchCourseProgress = async (userId: string, courseId: string) => {
  console.log("Fetching progress for user:", userId, "course:", courseId);
  
  const { data: progressData, error: progressError } = await supabase
    .from("course_progress")
    .select("progress_percentage, completed_at")
    .eq("course_id", courseId)
    .eq("user_id", userId)
    .maybeSingle();

  if (progressError) {
    console.error("Error fetching progress:", progressError);
    throw progressError;
  }

  return progressData;
};

const fetchCompletedChaptersCount = async (userId: string, courseId: string) => {
  console.log("Fetching completed chapters for user:", userId, "course:", courseId);
  
  const { count, error: chaptersError } = await supabase
    .from("chapter_progress")
    .select("*", { count: 'exact', head: true })
    .eq("course_id", courseId)
    .eq("user_id", userId)
    .not("completed_at", "is", null);

  if (chaptersError) {
    console.error("Error counting completed chapters:", chaptersError);
    throw chaptersError;
  }

  return count ?? 0;
};

const fetchTotalChaptersCount = async (courseId: string) => {
  console.log("Fetching total chapters for course:", courseId);
  
  const { count, error: totalError } = await supabase
    .from("chapters")
    .select("*", { count: 'exact', head: true })
    .eq("course_id", courseId);

  if (totalError) {
    console.error("Error counting total chapters:", totalError);
    throw totalError;
  }

  return count ?? 0;
};

const createInitialProgress = async (userId: string, courseId: string, progress: number) => {
  console.log("Creating initial progress:", { userId, courseId, progress });
  
  const { error: createError } = await supabase
    .from("course_progress")
    .upsert({
      user_id: userId,
      course_id: courseId,
      progress_percentage: progress,
      completed_at: progress === 100 ? new Date().toISOString() : null
    });

  if (createError) {
    console.error("Error creating initial progress:", createError);
    throw createError;
  }
};

const calculateProgress = (completedChapters: number, totalChapters: number): number => {
  if (totalChapters === 0) return 0;
  return Math.round((completedChapters / totalChapters) * 100);
};

export const useCourseProgress = (courseId: string) => {
  const { data: progress, isLoading, error } = useQuery({
    queryKey: ["course-progress", courseId],
    queryFn: async () => {
      try {
        console.log("Starting progress calculation for course:", courseId);
        
        const session = await fetchUserSession();
        if (!session?.user?.id) {
          console.log("No authenticated session found");
          return null;
        }

        const userId = session.user.id;

        // Refresh the session token before making requests
        await supabase.auth.refreshSession();

        const progressData = await fetchCourseProgress(userId, courseId);
        const completedChapters = await fetchCompletedChaptersCount(userId, courseId);
        console.log("Completed chapters:", completedChapters);

        if (!progressData) {
          const totalChapters = await fetchTotalChaptersCount(courseId);
          console.log("Total chapters:", totalChapters);
          
          if (totalChapters === 0) {
            return {
              progress: 0,
              completedAt: null,
              completedChapters: 0,
              isCompleted: false
            };
          }

          const initialProgress = calculateProgress(completedChapters, totalChapters);
          console.log("Calculated initial progress:", initialProgress);
          
          await createInitialProgress(userId, courseId, initialProgress);

          return {
            progress: initialProgress,
            completedAt: initialProgress === 100 ? new Date().toISOString() : null,
            completedChapters,
            isCompleted: initialProgress === 100
          };
        }

        console.log("Existing progress data:", progressData);
        return {
          progress: progressData.progress_percentage ?? 0,
          completedAt: progressData.completed_at,
          completedChapters,
          isCompleted: progressData.completed_at !== null
        };
      } catch (error) {
        console.error("Error in course progress calculation:", error);
        toast.error("Erreur lors du calcul de la progression");
        throw error;
      }
    },
    retry: 3,
    retryDelay: 1000,
    staleTime: 1000,
    gcTime: 5 * 60 * 1000,
  });

  return {
    progress: progress?.progress ?? 0,
    completedAt: progress?.completedAt,
    completedChapters: progress?.completedChapters ?? 0,
    isCompleted: progress?.isCompleted ?? false,
    isLoading,
    error
  };
};

export const useResetCourseProgress = (courseId: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async () => {
      const { data: { session } } = await supabase.auth.getSession();
      if (!session?.user?.id) {
        throw new Error("User not authenticated");
      }

      // Réinitialiser la progression du cours
      const { error: courseError } = await supabase
        .from("course_progress")
        .upsert({
          user_id: session.user.id,
          course_id: courseId,
          progress_percentage: 0,
          completed_at: null
        });

      if (courseError) throw courseError;

      // Réinitialiser la progression des chapitres
      const { error: chapterError } = await supabase
        .from("chapter_progress")
        .delete()
        .eq("user_id", session.user.id)
        .eq("course_id", courseId);

      if (chapterError) throw chapterError;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["course-progress"] });
      toast.success("Progression réinitialisée");
    },
    onError: (error) => {
      console.error("Error resetting progress:", error);
      toast.error("Erreur lors de la réinitialisation");
    }
  });
};
