import { Input } from "@/components/ui/input";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { FormData } from "../types";

interface BlogArticleFormProps {
  formData: FormData;
  setFormData: (data: FormData) => void;
}

export const BlogArticleForm = ({ formData, setFormData }: BlogArticleFormProps) => {
  return (
    <div className="space-y-4">
      <div>
        <label className="text-sm font-medium mb-1 block">
          Sujet principal de l'article
        </label>
        <Input
          value={formData.subject}
          onChange={(e) =>
            setFormData({ ...formData, subject: e.target.value })
          }
          placeholder="Ex: Réussir son permis moto en 2024"
        />
      </div>
      <div>
        <label className="text-sm font-medium mb-1 block">
          Mots-clés importants
        </label>
        <Input
          value={formData.keywords}
          onChange={(e) =>
            setFormData({ ...formData, keywords: e.target.value })
          }
          placeholder="Ex: permis moto, formation moto, réussir le permis moto"
        />
      </div>
      <div>
        <label className="text-sm font-medium mb-1 block">
          Longueur souhaitée
        </label>
        <Select
          value={formData.length}
          onValueChange={(value) =>
            setFormData({ ...formData, length: value })
          }
        >
          <SelectTrigger>
            <SelectValue />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="short">Court (~800 mots)</SelectItem>
            <SelectItem value="medium">Moyen (~1500 mots)</SelectItem>
            <SelectItem value="long">Long (~2500 mots)</SelectItem>
          </SelectContent>
        </Select>
      </div>
      <div>
        <label className="text-sm font-medium mb-1 block">
          Public cible
        </label>
        <Select
          value={formData.target}
          onValueChange={(value) =>
            setFormData({ ...formData, target: value })
          }
        >
          <SelectTrigger>
            <SelectValue placeholder="Choisir le public cible" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="beginners">Débutants</SelectItem>
            <SelectItem value="enthusiasts">Passionnés de moto</SelectItem>
            <SelectItem value="candidates">Futurs candidats au permis</SelectItem>
            <SelectItem value="experienced">Motards expérimentés</SelectItem>
          </SelectContent>
        </Select>
      </div>
      <div>
        <label className="text-sm font-medium mb-1 block">
          Angle d'approche
        </label>
        <Select
          value={formData.angle}
          onValueChange={(value) =>
            setFormData({ ...formData, angle: value })
          }
        >
          <SelectTrigger>
            <SelectValue placeholder="Choisir un angle" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="informative">Article informatif</SelectItem>
            <SelectItem value="tutorial">Guide pratique / Tutoriel</SelectItem>
            <SelectItem value="comparison">Comparatif</SelectItem>
            <SelectItem value="analysis">Analyse approfondie</SelectItem>
            <SelectItem value="tips">Conseils et astuces</SelectItem>
          </SelectContent>
        </Select>
      </div>
    </div>
  );
};