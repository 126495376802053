import { useState } from "react";
import { ChevronLeft, ChevronRight } from "lucide-react";
import { Button } from "@/components/ui/button";
import { CourseNavigation } from "./CourseNavigation";
import { cn } from "@/lib/utils";

interface ChapterNavigationSidebarProps {
  chapters: any[];
  currentChapterId: string;
  onChapterChange: (chapterId: string) => void;
  courseId: string;
}

export const ChapterNavigationSidebar = ({
  chapters,
  currentChapterId,
  onChapterChange,
  courseId,
}: ChapterNavigationSidebarProps) => {
  const [isCollapsed, setIsCollapsed] = useState(false);

  return (
    <div className="relative h-full">
      <Button
        variant="ghost"
        size="icon"
        onClick={() => setIsCollapsed(!isCollapsed)}
        className="absolute -right-4 top-2 z-50 rounded-full bg-background border shadow-sm hover:bg-secondary"
      >
        {isCollapsed ? (
          <ChevronRight className="h-4 w-4" />
        ) : (
          <ChevronLeft className="h-4 w-4" />
        )}
      </Button>
      
      <div
        className={cn(
          "h-full transition-all duration-300 ease-in-out overflow-hidden",
          isCollapsed ? "w-0" : "w-80"
        )}
      >
        <nav className="h-full bg-background border-r shadow-sm">
          <div className="flex-1 px-2 py-4 overflow-y-auto">
            <CourseNavigation
              chapters={chapters}
              currentChapterId={currentChapterId}
              onChapterChange={onChapterChange}
              courseId={courseId}
            />
          </div>
        </nav>
      </div>
    </div>
  );
};