import { Button } from "@/components/ui/button";
import { Course, CourseFormData } from "@/types/course";
import { CourseForm } from "./CourseForm";
import { useCourseMutation } from "@/hooks/useCourseMutation";

interface CourseFormContainerProps {
  editingCourse: Course | null;
  onCancel: () => void;
  onSuccess: () => void;
  refetchCourses: () => void;
}

export const CourseFormContainer = ({
  editingCourse,
  onCancel,
  onSuccess,
  refetchCourses,
}: CourseFormContainerProps) => {
  const { handleSubmit, isSubmitting } = useCourseMutation(onSuccess);

  return (
    <div className="space-y-6">
      <div className="flex items-center justify-between">
        <h1 className="text-3xl font-bold">
          {editingCourse ? "Modifier le cours" : "Nouveau cours"}
        </h1>
        <Button variant="outline" onClick={onCancel}>
          Retour
        </Button>
      </div>

      <CourseForm
        initialData={editingCourse}
        onSubmit={(data: CourseFormData) => handleSubmit(data, editingCourse)}
        onDelete={onCancel}
        refetchCourses={refetchCourses}
      />
    </div>
  );
};