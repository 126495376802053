import { useEffect, useState } from "react";
import { supabase } from "@/integrations/supabase/client";
import { toast } from "sonner";
import { QuestionCard } from "./questions/QuestionCard";
import { EmptyState } from "./questions/EmptyState";
import { QuestionHeader } from "./questions/QuestionHeader";
import { QuizQuestion } from "@/types/quiz";
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { SortableQuestionCard } from "./questions/SortableQuestionCard";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/components/ui/tooltip";

interface QuestionListProps {
  chapterId: string;
  showQuestionForm: boolean;
  onShowQuestionForm: (show: boolean) => void;
}

export const QuestionList = ({
  chapterId,
  showQuestionForm,
  onShowQuestionForm,
}: QuestionListProps) => {
  const [questions, setQuestions] = useState<QuizQuestion[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  useEffect(() => {
    fetchQuestions();
  }, [chapterId]);

  const fetchQuestions = async () => {
    try {
      const { data: questionsData, error: questionsError } = await supabase
        .from("chapter_quiz_questions")
        .select(`
          *,
          answers:chapter_quiz_answers(*)
        `)
        .eq("chapter_id", chapterId)
        .order("order_index");

      if (questionsError) throw questionsError;

      setQuestions(questionsData);
    } catch (error) {
      console.error("Error fetching questions:", error);
      toast.error("Erreur lors du chargement des questions");
    } finally {
      setIsLoading(false);
    }
  };

  const handleDelete = async (questionId: string) => {
    try {
      const { error: answersError } = await supabase
        .from("chapter_quiz_answers")
        .delete()
        .eq("question_id", questionId);

      if (answersError) throw answersError;

      const { error: questionError } = await supabase
        .from("chapter_quiz_questions")
        .delete()
        .eq("id", questionId);

      if (questionError) throw questionError;

      toast.success("Question supprimée avec succès");
      fetchQuestions();
    } catch (error) {
      console.error("Error deleting question:", error);
      toast.error("Erreur lors de la suppression de la question");
    }
  };

  const handleDragEnd = async (event: any) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      const oldIndex = questions.findIndex((q) => q.id === active.id);
      const newIndex = questions.findIndex((q) => q.id === over.id);

      const newQuestions = arrayMove(questions, oldIndex, newIndex);
      setQuestions(newQuestions);

      // Mettre à jour l'ordre dans la base de données
      try {
        const updates = newQuestions.map((question, index) => ({
          id: question.id,
          question: question.question,
          explanation: question.explanation,
          order_index: index,
          chapter_id: chapterId,
        }));

        const { error } = await supabase
          .from("chapter_quiz_questions")
          .upsert(updates);

        if (error) throw error;
      } catch (error) {
        console.error("Error updating question order:", error);
        toast.error("Erreur lors de la mise à jour de l'ordre des questions");
      }
    }
  };

  if (isLoading) {
    return <div>Chargement des questions...</div>;
  }

  return (
    <div className="space-y-4">
      <QuestionHeader onAddQuestion={() => onShowQuestionForm(true)} />

      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
      >
        <SortableContext
          items={questions.map((q) => q.id)}
          strategy={verticalListSortingStrategy}
        >
          <div className="grid gap-4">
            {questions.map((question, index) => (
              <TooltipProvider key={question.id}>
                <Tooltip>
                  <TooltipTrigger asChild>
                    <div>
                      <SortableQuestionCard
                        id={question.id}
                        index={index + 1}
                        question={question.question}
                        answers={question.answers}
                        onDelete={handleDelete}
                      />
                    </div>
                  </TooltipTrigger>
                  <TooltipContent>
                    <p>Glisser-déposer pour réorganiser</p>
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
            ))}
          </div>
        </SortableContext>
      </DndContext>

      {questions.length === 0 && <EmptyState />}
    </div>
  );
};