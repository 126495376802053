import { Editor } from "@tiptap/react";
import { Link } from "lucide-react";
import { Button } from "@/components/ui/button";
import { MediaManager } from "../media/MediaManager";

interface MediaButtonsProps {
  editor: Editor;
  folder?: string;
}

export const MediaButtons = ({ editor, folder }: MediaButtonsProps) => {
  return (
    <div className="flex items-center gap-1">
      <MediaManager editor={editor} folder={folder} />
      
      <Button
        variant="ghost"
        size="sm"
        className="h-8"
        onClick={() => {
          const url = window.prompt("URL du lien :");
          if (url) {
            editor.chain().focus().setLink({ href: url }).run();
          }
        }}
      >
        <Link className="h-4 w-4" />
      </Button>
    </div>
  );
};