import { useState } from "react";
import { Button } from "@/components/ui/button";
import { Card } from "@/components/ui/card";
import { ArticleData } from "../types";
import { supabase } from "@/integrations/supabase/client";

interface ArticleTypeProps {
  data: ArticleData;
  onComplete: (data: Partial<ArticleData>) => void;
}

const articleTypes = [
  { id: "news", label: "Articles de news" },
  { id: "blog", label: "Articles de blog" },
  { id: "guide", label: "Guides pratiques" },
  { id: "comparison", label: "Comparaisons" },
  { id: "review", label: "Revues de produits" },
  { id: "tips", label: "Conseils et astuces" }
];

export const ArticleType = ({ data, onComplete }: ArticleTypeProps) => {
  const [selectedType, setSelectedType] = useState(data.type);
  const [isLoading, setIsLoading] = useState(false);

  const handleTypeSelection = async (type: string) => {
    setSelectedType(type);
    setIsLoading(true);
    
    try {
      const { data: response, error } = await supabase.functions.invoke('generate', {
        body: {
          prompt: `Pour le sujet "${data.subject}", quel type d'article recommandez-vous parmi les suivants : articles de news, articles de blog, guides pratiques, comparaisons, revues de produits, conseils et astuces ? Expliquez votre choix en quelques phrases.`,
          model: "gemini-1.5-pro"
        }
      });

      if (error) throw error;
      
      // L'IA a fait sa recommandation, mais on garde le choix de l'utilisateur
      onComplete({ type });
    } catch (error) {
      console.error("Error getting AI recommendation:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="space-y-6">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        {articleTypes.map((type) => (
          <Card
            key={type.id}
            className={`p-4 cursor-pointer transition-colors ${
              selectedType === type.id
                ? "border-primary bg-primary/5"
                : "hover:border-primary/50"
            }`}
            onClick={() => handleTypeSelection(type.id)}
          >
            <h3 className="font-medium">{type.label}</h3>
          </Card>
        ))}
      </div>

      <Button
        onClick={() => onComplete({ type: selectedType })}
        disabled={!selectedType || isLoading}
        className="w-full"
      >
        {isLoading ? "Analyse en cours..." : "Continuer"}
      </Button>
    </div>
  );
};
