import { Chapter } from "@/types/course";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Accordion } from "@/components/ui/accordion";
import { BookOpen } from "lucide-react";
import { ChapterContent } from "./ChapterContent";
import { useQuery } from "@tanstack/react-query";
import { supabase } from "@/integrations/supabase/client";

interface ChaptersListProps {
  chapters: Chapter[];
  courseId: string;
}

export const ChaptersList = ({ chapters, courseId }: ChaptersListProps) => {
  const { data: questions } = useQuery({
    queryKey: ["chapter-questions", courseId],
    queryFn: async () => {
      const { data, error } = await supabase
        .from("chapter_quiz_questions")
        .select("*")
        .eq("chapter_id", courseId)
        .order("order_index");

      if (error) {
        console.error("Error fetching questions:", error);
        return [];
      }

      return data;
    },
  });

  // Filtrer les chapitres pour n'afficher que ceux qui sont publiés
  const publishedChapters = chapters.filter(chapter => chapter.status === 'published');

  // Combine chapters and questions into a single sorted array
  const sortedContent = [
    ...publishedChapters.map(chapter => ({
      ...chapter,
      type: 'chapter' as const,
      order_index: chapter.order_index
    })),
    ...(questions?.map(question => ({
      id: question.id,
      title: question.question,
      type: 'quiz' as const,
      order_index: question.order_index,
      description: question.explanation
    })) || [])
  ].sort((a, b) => a.order_index - b.order_index);

  return (
    <div className="mt-2">
      <div className="flex items-center gap-2 mb-3">
        <BookOpen className="h-4 w-4 text-primary" />
        <span className="text-sm font-medium">Programme du cours</span>
      </div>
      <ScrollArea className="h-[280px] pr-4">
        <Accordion type="single" collapsible className="space-y-2">
          {sortedContent.map((item, index) => (
            item.type === 'chapter' ? (
              <ChapterContent 
                key={item.id} 
                chapter={item} 
                index={index} 
              />
            ) : (
              <div
                key={item.id}
                className="p-4 rounded-lg bg-secondary/50 hover:bg-secondary/80 transition-colors"
              >
                <div className="flex items-center gap-2">
                  <span className="text-sm font-medium">Question {index + 1}:</span>
                  <span className="text-sm">{item.title}</span>
                </div>
                {item.description && (
                  <p className="text-sm text-muted-foreground mt-1">
                    {item.description}
                  </p>
                )}
              </div>
            )
          ))}
        </Accordion>
      </ScrollArea>
    </div>
  );
};