export const getKeywordAnalysisPrompt = (subject: string, sources: string) => `Pour le sujet "${subject}", analysez les mots-clés stratégiques en vous basant sur les sources sélectionnées.

Sources de référence:
${sources}

Instructions d'analyse détaillées :
1. Identifiez 15-20 mots-clés pertinents en considérant :
   - Le sujet principal et ses variations
   - Les intentions de recherche des utilisateurs
   - Les questions fréquemment posées
   - Les termes techniques et leurs équivalents vulgarisés
   - Les sujets connexes et complémentaires

2. Pour chaque mot-clé, évaluez :
   - Volume de recherche (1-100) basé sur la popularité estimée
   - Difficulté de positionnement (1-100) basée sur la concurrence
   - Intention de recherche (informatif/commercial/transactionnel)
   - Pertinence commerciale et valeur pour le lecteur
   - Potentiel de conversion

3. Critères d'évaluation :
   - Pertinence par rapport au sujet principal
   - Potentiel de trafic qualifié
   - Difficulté de positionnement réaliste
   - Adéquation avec l'intention de l'utilisateur
   - Potentiel de conversion

Format de réponse pour chaque mot-clé :
Mot-clé : [terme exact]
Volume : [1-100]
Difficulté : [1-100]
Intention : [informatif/commercial/transactionnel]
Recommandé : [oui/non]
Contexte : [utilisation recommandée dans l'article]`;