import { Chapter } from "@/types/course";
import { ChapterContent } from "./ChapterContent";
import { ChapterNavigation } from "./ChapterNavigation";
import { useChapterProgress } from "@/hooks/useChapterProgress";
import { AdSpace } from "./AdSpace";

interface ChapterViewProps {
  chapter: Chapter;
  courseId: string;
  currentIndex: number;
  totalChapters: number;
  onChapterComplete: (nextChapterId: string) => void;
  chapters: Chapter[];
}

export const ChapterView = ({
  chapter,
  courseId,
  currentIndex,
  totalChapters,
  onChapterComplete,
  chapters,
}: ChapterViewProps) => {
  const nextChapter = chapters[currentIndex + 1];
  const nextChapterId = nextChapter?.id || "";

  const { handleChapterComplete, isCompleting } = useChapterProgress({
    chapterId: chapter.id,
    courseId,
    currentIndex,
    totalChapters,
    onChapterComplete,
    onCourseComplete: () => {}, // Implement if needed
    nextChapterId,
  });

  return (
    <div className="flex flex-col lg:flex-row gap-8 max-w-[1400px] mx-auto">
      {/* Publicité gauche */}
      <div className="hidden lg:block w-[300px] shrink-0">
        <div className="sticky top-8">
          <AdSpace type="sidebar-left" />
        </div>
      </div>

      {/* Contenu principal */}
      <div className="flex-1 min-w-0">
        <ChapterContent 
          chapter={chapter}
          courseId={courseId}
          currentIndex={currentIndex}
          totalChapters={totalChapters}
          onChapterComplete={onChapterComplete}
          chapters={chapters}
        />
        
        <div className="mt-8">
          <ChapterNavigation
            courseId={courseId}
            currentIndex={currentIndex}
            totalChapters={totalChapters}
            onChapterChange={onChapterComplete}
            chapters={chapters}
          />
        </div>
      </div>

      {/* Publicité droite */}
      <div className="hidden lg:block w-[300px] shrink-0">
        <div className="sticky top-8">
          <AdSpace type="sidebar-right" />
        </div>
      </div>
    </div>
  );
};