import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { ImageUpload } from "../../ImageUpload";
import { CourseStatusSelect } from "../../CourseStatusSelect";
import { CourseStatus } from "@/types/course";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";

interface GeneralInfoSectionProps {
  title: string;
  setTitle: (title: string) => void;
  slug: string;
  setSlug: (slug: string) => void;
  imageUrl: string;
  setImageUrl: (url: string) => void;
  status: CourseStatus;
  setStatus: (status: CourseStatus) => void;
  courseId?: string;
}

export const GeneralInfoSection = ({
  title,
  setTitle,
  slug,
  setSlug,
  imageUrl,
  setImageUrl,
  status,
  setStatus,
  courseId,
}: GeneralInfoSectionProps) => {
  const generateSlug = (title: string) => {
    return title
      .toLowerCase()
      .replace(/[^a-z0-9]+/g, '-')
      .replace(/(^-|-$)/g, '');
  };

  return (
    <Card>
      <CardHeader>
        <CardTitle>Informations générales</CardTitle>
      </CardHeader>
      <CardContent className="space-y-4">
        <div className="space-y-2">
          <Label htmlFor="title">Titre du cours</Label>
          <Input
            id="title"
            value={title}
            onChange={(e) => {
              setTitle(e.target.value);
              if (!slug) {
                setSlug(generateSlug(e.target.value));
              }
            }}
            placeholder="Entrez le titre du cours"
            required
          />
        </div>

        <div className="space-y-2">
          <Label htmlFor="slug">URL du cours</Label>
          <Input
            id="slug"
            value={slug}
            onChange={(e) => setSlug(generateSlug(e.target.value))}
            placeholder="url-du-cours"
            required
          />
        </div>

        <div className="space-y-2">
          <Label>Image du cours</Label>
          <ImageUpload
            currentImage={imageUrl}
            onUploadComplete={setImageUrl}
            folder={`courses/${courseId || "new"}`}
          />
        </div>

        <div className="space-y-2">
          <Label>Statut du cours</Label>
          <CourseStatusSelect status={status} onStatusChange={setStatus} />
        </div>
      </CardContent>
    </Card>
  );
};