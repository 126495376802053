import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { supabase } from "@/integrations/supabase/client";
import { Chapter } from "@/types/course";
import { useCourseNavigation } from "./useCourseNavigation";

type ContentItem = {
  id: string;
  title: string;
  order_index: number;
  type: 'chapter' | 'quiz';
};

interface UseCourseContentNavigationProps {
  chapters: Chapter[];
  currentChapterId: string;
  courseId: string;
  onChapterChange: (chapterId: string) => void;
}

export const useCourseContentNavigation = ({
  chapters,
  currentChapterId,
  courseId,
  onChapterChange,
}: UseCourseContentNavigationProps) => {
  const navigate = useNavigate();
  const { handleNextCourse } = useCourseNavigation(courseId);

  const { data: questions } = useQuery({
    queryKey: ["chapter-questions", courseId],
    queryFn: async () => {
      const { data, error } = await supabase
        .from("chapter_quiz_questions")
        .select("*")
        .eq("chapter_id", courseId)
        .order("order_index");

      if (error) {
        console.error("Error fetching questions:", error);
        return [];
      }

      return data;
    },
  });

  const sortedContent: ContentItem[] = [
    ...chapters.map(chapter => ({
      id: chapter.id,
      title: chapter.title,
      order_index: chapter.order_index,
      type: 'chapter' as const
    })),
    ...(questions?.map(question => ({
      id: question.id,
      title: question.question,
      order_index: question.order_index,
      type: 'quiz' as const
    })) || [])
  ].sort((a, b) => a.order_index - b.order_index);

  const currentIndex = sortedContent.findIndex(
    item => item.type === 'chapter' && item.id === currentChapterId
  );

  const isLastChapter = currentIndex === chapters.length - 1;

  const handlePrevious = () => {
    if (currentIndex > 0) {
      const prevItem = sortedContent
        .slice(0, currentIndex)
        .reverse()
        .find(item => item.type === 'chapter');
      if (prevItem) {
        onChapterChange(prevItem.id);
      }
    }
  };

  const handleNext = () => {
    if (isLastChapter) {
      const nextQuestion = sortedContent
        .slice(currentIndex + 1)
        .find(item => item.type === 'quiz');
      
      if (nextQuestion) {
        navigate(`/student/quiz/${nextQuestion.id}`);
      } else {
        handleNextCourse();
      }
    } else {
      const nextItem = sortedContent
        .slice(currentIndex + 1)
        .find(item => item.type === 'chapter');
      if (nextItem) {
        onChapterChange(nextItem.id);
      }
    }
  };

  const handleItemClick = (item: ContentItem) => {
    if (item.type === 'chapter') {
      onChapterChange(item.id);
    } else {
      navigate(`/student/quiz/${item.id}`);
    }
  };

  return {
    sortedContent,
    currentIndex,
    isLastChapter,
    handlePrevious,
    handleNext,
    handleItemClick,
  };
};