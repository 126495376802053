import { supabase } from "@/integrations/supabase/client";
import { toast } from "sonner";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { QuestionFormProps } from "@/types/quiz";
import { QuizGenerator } from "./QuizGenerator";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { QuestionFormContent } from "./form/QuestionFormContent";
import { useQuestionForm } from "./hooks/useQuestionForm";

export const QuestionForm = ({ 
  chapterId, 
  open, 
  onOpenChange, 
  onSuccess,
  initialData,
  chapterContent 
}: QuestionFormProps & { chapterContent?: string }) => {
  const {
    question,
    richText,
    answers,
    questionType,
    fontSize,
    textAlign,
    backgroundColor,
    isSubmitting,
    setQuestion,
    setRichText,
    setAnswers,
    setQuestionType,
    setFontSize,
    setTextAlign,
    setBackgroundColor,
    handleSubmit,
  } = useQuestionForm(chapterId, initialData, () => {
    onSuccess();
    onOpenChange(false);
  });

  const handleAIQuizGenerated = (questions: any[]) => {
    questions.forEach(async (q, index) => {
      try {
        const { data: questionData, error: questionError } = await supabase
          .from("chapter_quiz_questions")
          .insert({
            chapter_id: chapterId,
            question: q.question,
            explanation: q.explanation,
            order_index: index
          })
          .select()
          .single();

        if (questionError) throw questionError;

        const answersToInsert = q.answers.map((answer: any) => ({
          question_id: questionData.id,
          answer: answer.text,
          is_correct: answer.isCorrect,
          explanation: answer.explanation,
        }));

        const { error: answersError } = await supabase
          .from("chapter_quiz_answers")
          .insert(answersToInsert);

        if (answersError) throw answersError;
      } catch (error: any) {
        console.error("Error saving AI generated question:", error);
        toast.error("Erreur lors de la sauvegarde de la question");
      }
    });

    toast.success("Questions générées ajoutées avec succès");
    onSuccess();
    onOpenChange(false);
  };

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="max-w-3xl max-h-[90vh] overflow-y-auto">
        <DialogHeader>
          <DialogTitle>
            {initialData ? "Modifier la question" : "Ajouter une question"}
          </DialogTitle>
        </DialogHeader>
        
        <Tabs defaultValue="manual">
          <TabsList>
            <TabsTrigger value="manual">Manuel</TabsTrigger>
            <TabsTrigger value="ai" disabled={!chapterContent}>
              Générer avec l'IA
            </TabsTrigger>
          </TabsList>

          <TabsContent value="manual">
            <QuestionFormContent
              question={question}
              richText={richText}
              answers={answers}
              questionType={questionType}
              fontSize={fontSize}
              textAlign={textAlign}
              backgroundColor={backgroundColor}
              isSubmitting={isSubmitting}
              onQuestionChange={setQuestion}
              onRichTextChange={setRichText}
              onAnswersUpdate={setAnswers}
              onQuestionTypeChange={setQuestionType}
              onFontSizeChange={setFontSize}
              onTextAlignChange={setTextAlign}
              onBackgroundColorChange={setBackgroundColor}
              onCancel={() => onOpenChange(false)}
              onSubmit={handleSubmit}
            />
          </TabsContent>

          <TabsContent value="ai">
            {chapterContent ? (
              <QuizGenerator
                chapterContent={chapterContent}
                onQuizGenerated={handleAIQuizGenerated}
              />
            ) : (
              <div className="text-center p-4 text-muted-foreground">
                Le contenu du chapitre est requis pour générer un quiz avec l'IA
              </div>
            )}
          </TabsContent>
        </Tabs>
      </DialogContent>
    </Dialog>
  );
};