import { SelectableCard } from "../../common/SelectableCard";
import { PlanContent } from "./PlanContent";

interface PlanDisplayProps {
  plan: string;
  index: number;
  isSelected: boolean;
  onSelect: () => void;
}

export const PlanDisplay = ({ plan, index, isSelected, onSelect }: PlanDisplayProps) => {
  return (
    <SelectableCard isSelected={isSelected} onSelect={onSelect}>
      <div className="space-y-4">
        <h3 className="font-semibold text-lg">Plan {index + 1}</h3>
        <PlanContent content={plan} />
      </div>
    </SelectableCard>
  );
};