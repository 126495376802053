import { Link } from "react-router-dom";

export const NavigationLinks = ({ className = "" }: { className?: string }) => {
  return (
    <div className={className}>
      <Link to="/" className="text-gray-700 hover:text-primary transition-colors">
        Accueil
      </Link>
      <Link to="/about" className="text-gray-700 hover:text-primary transition-colors">
        À propos
      </Link>
      <Link to="/tarifs" className="text-gray-700 hover:text-primary transition-colors">
        Tarifs
      </Link>
      <Link to="/code" className="text-gray-700 hover:text-primary transition-colors">
        Code
      </Link>
      <Link to="/blog" className="text-gray-700 hover:text-primary transition-colors">
        Blog
      </Link>
      <Link to="/contact" className="text-gray-700 hover:text-primary transition-colors">
        Contact
      </Link>
    </div>
  );
};