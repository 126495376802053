import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { StatusBadge } from "./StatusBadge";
import { SubscriptionDetails } from "./SubscriptionDetails";
import { SubscriptionActions } from "./SubscriptionActions";
import { Loader2 } from "lucide-react";

interface SubscriptionInfoProps {
  subscription: {
    plan_type: string;
    status: string;
    end_date?: string;
    start_date?: string;
  } | null;
  onSubscribe: (planType: string) => void;
  onCancel: () => void;
  isLoading: boolean;
}

export const SubscriptionInfo = ({ subscription, onSubscribe, onCancel, isLoading }: SubscriptionInfoProps) => {
  if (isLoading) {
    return (
      <Card>
        <CardContent className="flex items-center justify-center py-6">
          <Loader2 className="h-6 w-6 animate-spin" />
        </CardContent>
      </Card>
    );
  }

  return (
    <Card>
      <CardHeader>
        <CardTitle>Abonnement</CardTitle>
      </CardHeader>
      <CardContent>
        <div className="space-y-6">
          <SubscriptionDetails subscription={subscription} />
          
          <div className="grid gap-2">
            <div className="font-medium">Statut</div>
            <div>
              {subscription?.status ? (
                <StatusBadge status={subscription.status} />
              ) : (
                <StatusBadge status="inactive" />
              )}
            </div>
          </div>

          <SubscriptionActions 
            onSubscribe={onSubscribe}
            onCancel={onCancel}
            subscription={subscription}
          />
        </div>
      </CardContent>
    </Card>
  );
};