import { Link } from "react-router-dom";
import { Button } from "@/components/ui/button";
import { ArrowRight } from "lucide-react";

export const HeroSection = () => {
  return (
    <section className="relative bg-white overflow-hidden pt-16 md:pt-24 lg:pt-20">
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-16">
          {/* Left Column - Text Content */}
          <div className="flex flex-col justify-center">
            <div className="animate-fadeIn">
              <h1 className="text-4xl font-bold tracking-tight text-[#1a2542] sm:text-5xl md:text-6xl">
                Passe ton <span className="text-primary">ETM</span>
                <br />
                en toute
                <br />
                <span className="text-primary">simplicité</span>
              </h1>
              <p className="mt-6 text-lg text-gray-600">
                Obtenez votre accès à nos cours exclusifs pour décrocher votre code moto du premier coup.
              </p>
              <div className="mt-8 flex flex-col sm:flex-row gap-4">
                <Link to="/tarifs">
                  <Button size="lg" className="w-full sm:w-auto bg-[#3CA740] hover:bg-[#2E8031] text-lg">
                    Commencer
                  </Button>
                </Link>
                <Link to="/code">
                  <Button 
                    size="lg" 
                    variant="outline"
                    className="w-full sm:w-auto bg-[#8E31F6] hover:bg-[#7526D9] text-white border-0 text-lg"
                  >
                    Version Gratuite
                  </Button>
                </Link>
              </div>
            </div>
          </div>
          
          {/* Right Column - Image */}
          <div className="relative flex items-center justify-center lg:justify-end">
            <div className="w-full max-w-[600px] animate-fadeIn">
              <img
                src="/lovable-uploads/bb7c6b30-fd53-4312-a648-21b0625ab489.png"
                alt="Moto Royal Enfield bleue avec casque"
                className="w-full h-auto object-cover rounded-lg"
              />
            </div>
          </div>
        </div>

        {/* Stats Section */}
        <div className="mt-16 grid grid-cols-1 gap-8 sm:grid-cols-3 border-t border-gray-200 pt-8">
          <div className="text-center">
            <div className="text-4xl font-bold text-primary">3</div>
            <div className="mt-2 text-sm text-gray-600">JOURS GRATUITS</div>
          </div>
          <div className="text-center">
            <div className="text-4xl font-bold text-primary">95%</div>
            <div className="mt-2 text-sm text-gray-600">TAUX DE RÉUSSITE</div>
          </div>
          <div className="text-center">
            <div className="text-4xl font-bold text-primary">24/7</div>
            <div className="mt-2 text-sm text-gray-600">DE SUPPORT</div>
          </div>
        </div>
      </div>
    </section>
  );
};