import { ReactNode } from "react";
import { DashboardLayout } from "@/components/layout/DashboardLayout";
import { useIsMobile } from "@/hooks/use-mobile";
import { MobileNavigation } from "../view/MobileNavigation";
import { ChapterNavigationSidebar } from "../ChapterNavigationSidebar";
import { Chapter } from "@/types/course";

interface CourseViewLayoutProps {
  children: ReactNode;
  isNavOpen: boolean;
  onNavOpenChange: (open: boolean) => void;
  chapters: Chapter[];
  currentChapterId: string;
  onChapterChange: (chapterId: string) => void;
  courseId: string;
}

export const CourseViewLayout = ({
  children,
  isNavOpen,
  onNavOpenChange,
  chapters,
  currentChapterId,
  onChapterChange,
  courseId,
}: CourseViewLayoutProps) => {
  const isMobile = useIsMobile();

  return (
    <DashboardLayout type="student">
      <div className="min-h-screen flex relative">
        {isMobile ? (
          <MobileNavigation
            isOpen={isNavOpen}
            onOpenChange={onNavOpenChange}
            chapters={chapters}
            currentChapterId={currentChapterId}
            onChapterChange={onChapterChange}
            courseId={courseId}
          />
        ) : (
          <div className="hidden lg:block">
            <ChapterNavigationSidebar
              chapters={chapters}
              currentChapterId={currentChapterId}
              onChapterChange={onChapterChange}
              courseId={courseId}
            />
          </div>
        )}
        <div className="flex-1 overflow-y-auto bg-white">
          {children}
        </div>
      </div>
    </DashboardLayout>
  );
};