import { QuizManager } from "@/components/admin/courses/quiz/QuizManager";
import { Card } from "@/components/ui/card";
import { QuizSectionProps } from "./types";

export const QuizSection = ({ chapterId }: QuizSectionProps) => {
  if (!chapterId) {
    return (
      <Card className="p-8 text-center text-muted-foreground">
        Sauvegardez d'abord le chapitre pour pouvoir ajouter un quiz
      </Card>
    );
  }

  return <QuizManager chapterId={chapterId} />;
};