import { DashboardLayout } from "@/components/layout/DashboardLayout";
import { ArticleGeneratorContainer } from "@/components/admin/ai-articles/ArticleGeneratorContainer";

const AIArticles = () => {
  return (
    <DashboardLayout type="admin">
      <div className="space-y-8">
        <div>
          <h1 className="text-3xl font-bold">Générateur d'Articles IA</h1>
          <p className="text-muted-foreground mt-2">
            Générez des articles de qualité avec l'aide de l'IA
          </p>
        </div>
        <ArticleGeneratorContainer />
      </div>
    </DashboardLayout>
  );
};

export default AIArticles;