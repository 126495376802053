const stats = [
  {
    value: "10K+",
    label: "Élèves formés",
  },
  {
    value: "95%",
    label: "Taux de réussite",
  },
  {
    value: "500+",
    label: "Cours disponibles",
  },
  {
    value: "24/7",
    label: "Support disponible",
  },
];

export const StatsSection = () => {
  return (
    <section className="py-16 md:py-24 bg-gray-50">
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-2 gap-8 lg:grid-cols-4">
          {stats.map((stat, index) => (
            <div
              key={stat.label}
              className="text-center animate-fadeIn"
              style={{ animationDelay: `${index * 100}ms` }}
            >
              <div className="text-4xl font-bold text-primary">{stat.value}</div>
              <div className="mt-2 text-sm text-black">
                {stat.label}
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};