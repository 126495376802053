import { Link } from "react-router-dom";
import { Button } from "@/components/ui/button";

export const CTASection = () => {
  return (
    <section className="relative overflow-hidden bg-primary py-16 md:py-24">
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div className="relative z-10 text-center">
          <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">
            Prêt à commencer votre formation ?
          </h2>
          <p className="mx-auto mt-4 max-w-2xl text-lg text-primary-foreground/90">
            Rejoignez des milliers d'élèves qui ont déjà obtenu leur permis grâce à notre plateforme.
          </p>
          <div className="mt-8">
            <Link to="/register">
              <Button
                size="lg"
                variant="secondary"
                className="animate-fadeIn bg-white text-primary hover:bg-white/90"
              >
                S'inscrire maintenant
              </Button>
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};