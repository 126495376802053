import { createClient } from '@supabase/supabase-js';
import type { Database } from './types';

const SUPABASE_URL = "https://ekhyyaxtamgevelchtqn.supabase.co";
const SUPABASE_ANON_KEY = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImVraHl5YXh0YW1nZXZlbGNodHFuIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MzU5MTE2ODYsImV4cCI6MjA1MTQ4NzY4Nn0.Ag-aWjPyUauhM2j5_1ui8i9cZTBWLy_f8M9TxAi_uYE";

export const supabase = createClient<Database>(
  SUPABASE_URL,
  SUPABASE_ANON_KEY,
  {
    auth: {
      persistSession: true,
      autoRefreshToken: true,
      detectSessionInUrl: true,
      flowType: 'pkce',
      storage: window.localStorage,
      storageKey: 'supabase.auth.token',
    },
    global: {
      headers: {
        'X-Client-Info': 'supabase-js-web'
      },
    },
  }
);