import { Label } from "@/components/ui/label";
import { Input } from "@/components/ui/input";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";

interface QuestionFormattingProps {
  fontSize: string;
  textAlign: string;
  backgroundColor: string;
  onFontSizeChange: (value: string) => void;
  onTextAlignChange: (value: string) => void;
  onBackgroundColorChange: (value: string) => void;
}

export const QuestionFormatting = ({
  fontSize,
  textAlign,
  backgroundColor,
  onFontSizeChange,
  onTextAlignChange,
  onBackgroundColorChange,
}: QuestionFormattingProps) => {
  return (
    <div className="space-y-4">
      <div className="space-y-2">
        <Label htmlFor="fontSize">Taille de police</Label>
        <Select value={fontSize} onValueChange={onFontSizeChange}>
          <SelectTrigger id="fontSize">
            <SelectValue placeholder="Choisir la taille" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="sm">Petit</SelectItem>
            <SelectItem value="base">Normal</SelectItem>
            <SelectItem value="lg">Grand</SelectItem>
            <SelectItem value="xl">Très grand</SelectItem>
          </SelectContent>
        </Select>
      </div>

      <div className="space-y-2">
        <Label htmlFor="textAlign">Alignement</Label>
        <Select value={textAlign} onValueChange={onTextAlignChange}>
          <SelectTrigger id="textAlign">
            <SelectValue placeholder="Choisir l'alignement" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="left">Gauche</SelectItem>
            <SelectItem value="center">Centre</SelectItem>
            <SelectItem value="right">Droite</SelectItem>
          </SelectContent>
        </Select>
      </div>

      <div className="space-y-2">
        <Label htmlFor="backgroundColor">Couleur de fond</Label>
        <Input
          id="backgroundColor"
          type="color"
          value={backgroundColor}
          onChange={(e) => onBackgroundColorChange(e.target.value)}
        />
      </div>
    </div>
  );
};