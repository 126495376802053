import { Course } from "@/types/course";
import { Button } from "@/components/ui/button";
import { ArrowUp, ArrowDown } from "lucide-react";
import { Card } from "@/components/ui/card";
import { CourseStatusBadge } from "@/components/courses/CourseStatusBadge";

interface CourseOrderItemProps {
  course: Course;
  index: number;
  totalCourses: number;
  onMoveUp: (index: number) => void;
  onMoveDown: (index: number) => void;
}

export const CourseOrderItem = ({
  course,
  index,
  totalCourses,
  onMoveUp,
  onMoveDown,
}: CourseOrderItemProps) => {
  return (
    <Card className="p-4">
      <div className="flex items-center justify-between gap-4">
        <div className="flex items-center gap-4 min-w-0 flex-1">
          {course.image_url && (
            <img
              src={course.image_url}
              alt={course.title}
              className="h-12 w-12 object-cover rounded"
            />
          )}
          <div className="min-w-0 flex-1">
            <h3 className="font-semibold truncate">{course.title}</h3>
            <div className="flex items-center gap-2">
              <CourseStatusBadge status={course.status} />
              <span className="text-sm text-muted-foreground">
                Ordre : {course.order_index}
              </span>
            </div>
          </div>
        </div>
        <div className="flex items-center gap-2">
          <Button
            variant="outline"
            size="icon"
            onClick={() => onMoveUp(index)}
            disabled={index === 0}
            title="Monter"
          >
            <ArrowUp className="h-4 w-4" />
          </Button>
          <Button
            variant="outline"
            size="icon"
            onClick={() => onMoveDown(index)}
            disabled={index === totalCourses - 1}
            title="Descendre"
          >
            <ArrowDown className="h-4 w-4" />
          </Button>
        </div>
      </div>
    </Card>
  );
};