import { DashboardLayout } from "@/components/layout/DashboardLayout";
import { useQuery } from "@tanstack/react-query";
import { supabase } from "@/integrations/supabase/client";
import { Button } from "@/components/ui/button";
import { Plus } from "lucide-react";
import { useState } from "react";
import { AIToolDialog } from "@/components/admin/ai/AIToolDialog";
import { AIToolCard } from "@/components/admin/ai/AIToolCard";
import { AIToolRunner } from "@/components/admin/ai/AIToolRunner";
import { AITool } from "@/integrations/supabase/types/ai";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { Helmet } from "react-helmet";

const AdminAITools = () => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedTool, setSelectedTool] = useState<AITool | undefined>(undefined);

  const { data: tools, isLoading } = useQuery({
    queryKey: ["ai-tools"],
    queryFn: async () => {
      const { data, error } = await supabase
        .from("ai_tools")
        .select("*")
        .order("created_at", { ascending: false });

      if (error) throw error;
      return data as AITool[];
    },
  });

  const handleEdit = (tool: AITool) => {
    setSelectedTool(tool);
    setIsDialogOpen(true);
  };

  return (
    <DashboardLayout type="admin">
      <Helmet>
        <title>Outils IA | Administration Bekane</title>
        <meta name="robots" content="noindex,nofollow" />
        {/* Google Tag Manager */}
        <script>
          {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-WNTHDK2Z');`}
        </script>
      </Helmet>
      <div className="space-y-8">
        {/* Google Tag Manager (noscript) */}
        <noscript>
          <iframe 
            src="https://www.googletagmanager.com/ns.html?id=GTM-WNTHDK2Z"
            height="0" 
            width="0" 
            style={{ display: 'none', visibility: 'hidden' }}
          />
        </noscript>
        <div className="flex items-center justify-between">
          <div>
            <h1 className="text-3xl font-bold">Outils IA</h1>
            <p className="text-muted-foreground mt-2">
              Gérez et utilisez vos outils d'intelligence artificielle
            </p>
          </div>
          <Button onClick={() => setIsDialogOpen(true)}>
            <Plus className="w-4 h-4 mr-2" />
            Nouvel outil
          </Button>
        </div>

        <Tabs defaultValue="use">
          <TabsList>
            <TabsTrigger value="use">Utiliser</TabsTrigger>
            <TabsTrigger value="manage">Gérer</TabsTrigger>
          </TabsList>
          
          <TabsContent value="use" className="space-y-6">
            {isLoading ? (
              <div>Chargement...</div>
            ) : (
              <div className="grid gap-6">
                {tools?.map((tool) => (
                  <AIToolRunner key={tool.id} tool={tool} />
                ))}
              </div>
            )}
          </TabsContent>

          <TabsContent value="manage">
            {isLoading ? (
              <div>Chargement...</div>
            ) : (
              <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-3">
                {tools?.map((tool) => (
                  <AIToolCard key={tool.id} tool={tool} onEdit={() => handleEdit(tool)} />
                ))}
              </div>
            )}
          </TabsContent>
        </Tabs>

        <AIToolDialog
          open={isDialogOpen}
          onOpenChange={setIsDialogOpen}
          tool={selectedTool}
          onClose={() => {
            setSelectedTool(undefined);
            setIsDialogOpen(false);
          }}
        />
      </div>
    </DashboardLayout>
  );
};

export default AdminAITools;
