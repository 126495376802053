import { useQuery } from "@tanstack/react-query";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { Progress } from "@/components/ui/progress";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Clock, ArrowRight } from "lucide-react";
import { supabase } from "@/integrations/supabase/client";
import { useNavigate } from "react-router-dom";

interface CourseDetailViewProps {
  onClose: () => void;
}

export const CourseDetailView = ({ onClose }: CourseDetailViewProps) => {
  const navigate = useNavigate();

  const { data: courses, isLoading } = useQuery({
    queryKey: ["student-courses-detail"],
    queryFn: async () => {
      const { data: { session } } = await supabase.auth.getSession();
      if (!session?.user?.id) throw new Error("Non authentifié");

      const { data, error } = await supabase
        .from("course_progress")
        .select(`
          course_id,
          progress_percentage,
          total_time_spent,
          last_activity_at,
          courses:courses(
            title,
            slug
          )
        `)
        .eq("user_id", session.user.id)
        .order("last_activity_at", { ascending: false });

      if (error) throw error;
      return data;
    },
  });

  const handleCourseClick = (slug: string) => {
    navigate(`/student/courses/${slug}`);
  };

  if (isLoading) {
    return <div>Chargement...</div>;
  }

  return (
    <Card className="animate-fadeIn">
      <CardHeader>
        <CardTitle>Vos cours en cours</CardTitle>
      </CardHeader>
      <CardContent>
        <ScrollArea className="h-[400px] pr-4">
          <div className="space-y-4">
            {courses?.map((course) => (
              <Card key={course.course_id} className="hover:shadow-md transition-shadow">
                <CardContent className="p-4">
                  <div className="flex items-center justify-between mb-2">
                    <h3 className="font-semibold">{course.courses?.title}</h3>
                    <Button
                      variant="ghost"
                      size="sm"
                      onClick={() => course.courses?.slug && handleCourseClick(course.courses.slug)}
                    >
                      Continuer
                      <ArrowRight className="ml-2 h-4 w-4" />
                    </Button>
                  </div>
                  <Progress value={course.progress_percentage || 0} className="mb-2" />
                  <div className="flex items-center text-sm text-muted-foreground">
                    <Clock className="mr-2 h-4 w-4" />
                    {Math.round((course.total_time_spent || 0) / 60)}h passées sur ce cours
                  </div>
                </CardContent>
              </Card>
            ))}
          </div>
        </ScrollArea>
      </CardContent>
    </Card>
  );
};