import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";

type CourseStatus = "draft" | "pending_validation" | "published";

const statusOptions = [
  { value: "draft", label: "Brouillon" },
  { value: "pending_validation", label: "En attente de validation" },
  { value: "published", label: "Publié" },
];

interface CourseStatusSelectProps {
  status: CourseStatus;
  onStatusChange: (status: CourseStatus) => void;
  disabled?: boolean;
}

export const CourseStatusSelect = ({
  status,
  onStatusChange,
  disabled,
}: CourseStatusSelectProps) => {
  return (
    <Select
      value={status}
      onValueChange={(value) => onStatusChange(value as CourseStatus)}
      disabled={disabled}
    >
      <SelectTrigger className="w-[200px]">
        <SelectValue placeholder="Sélectionner un statut" />
      </SelectTrigger>
      <SelectContent>
        {statusOptions.map((option) => (
          <SelectItem key={option.value} value={option.value}>
            {option.label}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  );
};