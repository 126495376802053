import { DashboardLayout } from "@/components/layout/DashboardLayout";
import { PersonalInfoSection } from "@/components/settings/PersonalInfoSection";
import { PasswordSection } from "@/components/settings/PasswordSection";
import { SubscriptionSection } from "@/components/settings/SubscriptionSection";

const StudentSettings = () => {
  return (
    <DashboardLayout type="student">
      <div className="space-y-8">
        <div>
          <h1 className="text-3xl font-bold">Paramètres</h1>
          <p className="text-muted-foreground">
            Gérez vos informations personnelles et vos préférences
          </p>
        </div>

        <div className="grid gap-6">
          <PersonalInfoSection />
          <PasswordSection />
          <SubscriptionSection />
        </div>
      </div>
    </DashboardLayout>
  );
};

export default StudentSettings;