import { Header } from "@/components/layout/Header";
import { Footer } from "@/components/layout/Footer";
import { useQuery } from "@tanstack/react-query";
import { supabase } from "@/integrations/supabase/client";
import { Link } from "react-router-dom";
import { Button } from "@/components/ui/button";
import { ArrowRight } from "lucide-react";
import { Card, CardContent, CardFooter, CardHeader, CardTitle } from "@/components/ui/card";
import { Helmet } from "react-helmet";

const Code = () => {
  const { data: courses, isLoading } = useQuery({
    queryKey: ["public-courses"],
    queryFn: async () => {
      const { data, error } = await supabase
        .from("courses")
        .select("*")
        .eq("status", "published")
        .order("created_at", { ascending: false });

      if (error) throw error;
      return data;
    },
  });

  return (
    <div className="min-h-screen flex flex-col">
      <Helmet>
        <title>Code moto gratuit</title>
        <meta name="description" content="Passer le code moto gratuitement avec Bekane" />
        <meta name="keywords" content="code moto, codemoto, etm moto, code de la route moto, code moto en ligne gratuit, permis moto code, code moto gratuit en ligne" />
        <meta name="author" content="Bekane" />
      </Helmet>
      <Header />
      <main className="flex-grow container mx-auto px-4 py-8">
        <div className="max-w-3xl mx-auto text-center mb-12 animate-fadeIn">
          <h1 className="text-4xl font-bold mb-6">Code de la route moto</h1>
          <p className="text-lg text-gray-700 mb-4">
            Bienvenue dans votre espace d'apprentissage du code de la route spécialisé pour les motards. Notre plateforme vous propose une formation complète et interactive pour maîtriser les règles de circulation spécifiques aux deux-roues.
          </p>
          <p className="text-lg text-gray-700 mb-8">
            Chaque cours est conçu pour vous aider à comprendre les subtilités du code de la route moto, de la signalisation aux règles de priorité, en passant par les techniques de conduite sécuritaire. Préparez-vous efficacement à l'examen du permis moto avec nos contenus pédagogiques adaptés.
          </p>
        </div>

        {isLoading ? (
          <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
            {[1, 2, 3].map((n) => (
              <Card key={n} className="animate-pulse">
                <div className="w-full h-48 bg-gray-200" />
                <CardContent className="p-6">
                  <div className="h-6 bg-gray-200 rounded w-3/4 mb-4" />
                  <div className="h-4 bg-gray-200 rounded w-full mb-2" />
                  <div className="h-4 bg-gray-200 rounded w-2/3" />
                </CardContent>
              </Card>
            ))}
          </div>
        ) : (
          <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
            {courses?.map((course) => (
              <Card 
                key={course.id} 
                className="overflow-hidden hover:shadow-lg transition-shadow duration-300 animate-fadeIn"
              >
                {course.image_url && (
                  <img 
                    src={course.image_url} 
                    alt={course.title} 
                    className="w-full h-48 object-cover"
                  />
                )}
                <CardHeader>
                  <CardTitle className="line-clamp-2">{course.title}</CardTitle>
                </CardHeader>
                <CardContent>
                  <p className="text-gray-600 mb-4 line-clamp-3">
                    {course.content?.replace(/<[^>]*>/g, '').substring(0, 150)}...
                  </p>
                </CardContent>
                <CardFooter>
                  <Link to={`/code/${course.slug}`} className="w-full">
                    <Button variant="outline" className="w-full">
                      Accéder au cours
                      <ArrowRight className="ml-2 h-4 w-4" />
                    </Button>
                  </Link>
                </CardFooter>
              </Card>
            ))}
          </div>
        )}
      </main>
      <Footer />
    </div>
  );
};

export default Code;