import { Source } from "../../types";

export const parseSourceFromText = (text: string): Source[] => {
  const sources: Source[] = [];
  let currentSource: Partial<Source> = {};
  
  const lines = text.split('\n').filter(line => line.trim());
  
  for (const line of lines) {
    const trimmedLine = line.trim();
    
    // Si on trouve un titre de section (commence par ###)
    if (trimmedLine.startsWith('### ')) {
      // Si on a déjà une source en cours, on l'ajoute à la liste
      if (isValidSource(currentSource)) {
        sources.push(currentSource as Source);
      }
      // On commence une nouvelle source
      currentSource = {
        title: trimmedLine.replace('### ', '').replace(/^\d+\.\s/, '').trim()
      };
      continue;
    }

    // On cherche les lignes qui contiennent des informations (format: - **Clé** : Valeur)
    if (trimmedLine.startsWith('- **')) {
      const match = trimmedLine.match(/- \*\*(.*?)\*\* : (.*)/);
      if (match) {
        const [, key, value] = match;
        updateSourceField(currentSource, key.toLowerCase(), value.trim());
      }
    }
  }

  // On n'oublie pas d'ajouter la dernière source
  if (isValidSource(currentSource)) {
    sources.push(currentSource as Source);
  }

  console.log("Parsed sources:", sources);
  return sources;
};

const isValidSource = (source: Partial<Source>): boolean => {
  return !!(source.title && source.source && typeof source.relevance === 'number');
};

const updateSourceField = (source: Partial<Source>, key: string, value: string) => {
  switch (key) {
    case 'source':
      source.source = value;
      break;
    case 'pertinence':
      const relevanceStr = value.split('/')[0];
      const relevanceNum = parseInt(relevanceStr);
      source.relevance = isNaN(relevanceNum) ? 0 : relevanceNum;
      break;
    case 'recommandée':
      source.recommended = value.toLowerCase().includes('oui');
      break;
  }
};