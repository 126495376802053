import { Button } from "@/components/ui/button";
import { ArrowRight, BookOpen } from "lucide-react";
import { cn } from "@/lib/utils";

interface CourseActionButtonProps {
  isCompleted: boolean;
  progress: number;
  onClick: () => void;
}

export const CourseActionButton = ({ 
  isCompleted, 
  progress, 
  onClick 
}: CourseActionButtonProps) => {
  return (
    <Button 
      className={cn(
        "w-full mt-auto group relative overflow-hidden",
        isCompleted && "bg-green-500 hover:bg-green-600",
        progress > 0 && !isCompleted && "bg-blue-500 hover:bg-blue-600"
      )}
      onClick={onClick}
    >
      <span className="relative z-10 flex items-center justify-center gap-2">
        {isCompleted ? (
          <>
            Réviser le cours
            <BookOpen className="w-4 h-4" />
          </>
        ) : progress > 0 ? (
          <>
            Continuer
            <ArrowRight className="w-4 h-4 transition-transform group-hover:translate-x-1" />
          </>
        ) : (
          <>
            Commencer
            <ArrowRight className="w-4 h-4 transition-transform group-hover:translate-x-1" />
          </>
        )}
      </span>
    </Button>
  );
};