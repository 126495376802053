import { Editor } from '@tiptap/react';
import { HeadingButtons } from './toolbar/HeadingButtons';
import { TextFormatButtons } from './toolbar/TextFormatButtons';
import { ListButtons } from './toolbar/ListButtons';
import { AlignmentButtons } from './toolbar/AlignmentButtons';
import { MediaButtons } from './toolbar/MediaButtons';
import { TableButtons } from './toolbar/TableButtons';
import { FontFamilySelect } from './toolbar/FontFamilySelect';
import { ColorPicker } from './toolbar/ColorPicker';
import { HistoryButtons } from './toolbar/HistoryButtons';

interface EditorToolbarProps {
  editor: Editor;
  folder?: string;
}

export const EditorToolbar = ({ editor, folder }: EditorToolbarProps) => {
  return (
    <div className="border-b p-2 flex flex-wrap gap-2 bg-muted/10">
      <HeadingButtons editor={editor} />
      <div className="w-px h-6 bg-border" />
      <TextFormatButtons editor={editor} />
      <div className="w-px h-6 bg-border" />
      <ListButtons editor={editor} />
      <div className="w-px h-6 bg-border" />
      <AlignmentButtons editor={editor} />
      <div className="w-px h-6 bg-border" />
      <FontFamilySelect editor={editor} />
      <ColorPicker editor={editor} />
      <div className="w-px h-6 bg-border" />
      <MediaButtons editor={editor} folder={folder} />
      <div className="w-px h-6 bg-border" />
      <TableButtons editor={editor} />
      <div className="w-px h-6 bg-border" />
      <HistoryButtons editor={editor} />
    </div>
  );
};