import { Card, CardHeader, CardTitle, CardDescription, CardContent, CardFooter } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { Pencil, Trash } from "lucide-react";
import { useToast } from "@/hooks/use-toast";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { supabase } from "@/integrations/supabase/client";
import { AITool } from "@/integrations/supabase/types/ai";

interface AIToolCardProps {
  tool: AITool;
  onEdit: () => void;
}

export const AIToolCard = ({ tool, onEdit }: AIToolCardProps) => {
  const { toast } = useToast();
  const queryClient = useQueryClient();

  const deleteMutation = useMutation({
    mutationFn: async () => {
      const { error } = await supabase
        .from("ai_tools")
        .delete()
        .eq("id", tool.id);
      
      if (error) throw error;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["ai-tools"] });
      toast({
        title: "Outil supprimé",
        description: "L'outil a été supprimé avec succès",
      });
    },
    onError: (error) => {
      toast({
        title: "Erreur",
        description: "Une erreur est survenue lors de la suppression",
        variant: "destructive",
      });
    },
  });

  return (
    <Card>
      <CardHeader>
        <CardTitle>{tool.name}</CardTitle>
        <CardDescription>{tool.description}</CardDescription>
      </CardHeader>
      <CardContent>
        <div className="text-sm text-muted-foreground">
          <p>Modèle : {tool.model}</p>
        </div>
      </CardContent>
      <CardFooter className="flex justify-end gap-2">
        <Button variant="outline" size="sm" onClick={onEdit}>
          <Pencil className="w-4 h-4 mr-2" />
          Modifier
        </Button>
        <Button 
          variant="destructive" 
          size="sm"
          onClick={() => deleteMutation.mutate()}
        >
          <Trash className="w-4 h-4 mr-2" />
          Supprimer
        </Button>
      </CardFooter>
    </Card>
  );
};