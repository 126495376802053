import { cn } from "@/lib/utils";
import { CheckCircle2, Circle, PlayCircle } from "lucide-react";
import { Button } from "@/components/ui/button";

interface NavigationItemProps {
  id: string;
  title: string;
  type: 'chapter' | 'quiz';
  index: number;
  isActive: boolean;
  isCompleted: boolean;
  onClick: () => void;
}

export const NavigationItem = ({
  id,
  title,
  type,
  index,
  isActive,
  isCompleted,
  onClick,
}: NavigationItemProps) => {
  return (
    <Button
      onClick={onClick}
      variant="ghost"
      className={cn(
        "flex items-center gap-2 w-full font-normal",
        isActive && "bg-secondary",
        type === 'quiz' && "text-muted"
      )}
    >
      <div className="flex items-center gap-2 flex-1">
        {isCompleted ? (
          <CheckCircle2 className="h-4 w-4 text-muted" />
        ) : isActive ? (
          <PlayCircle className="h-4 w-4 text-muted" />
        ) : (
          <Circle className="h-4 w-4" />
        )}
        <span className="truncate">
          {index + 1}. {type === 'quiz' ? `Quiz: ${title}` : title}
        </span>
      </div>
    </Button>
  );
};