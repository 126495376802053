import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { Label } from "@/components/ui/label";

interface ModelSelectorProps {
  selectedModel: string;
  onModelChange: (model: string) => void;
}

export const ModelSelector = ({ selectedModel, onModelChange }: ModelSelectorProps) => {
  return (
    <RadioGroup value={selectedModel} onValueChange={onModelChange}>
      <div className="space-y-2">
        <div className="flex items-center space-x-2">
          <RadioGroupItem value="gpt-4o" id="gpt4" />
          <Label htmlFor="gpt4">GPT-4 Optimisé (Meilleure qualité)</Label>
        </div>
        <div className="flex items-center space-x-2">
          <RadioGroupItem value="gpt-4o-mini" id="gpt4mini" />
          <Label htmlFor="gpt4mini">GPT-4 Mini (Rapide)</Label>
        </div>
        <div className="flex items-center space-x-2">
          <RadioGroupItem value="gemini-pro" id="gemini" />
          <Label htmlFor="gemini">Gemini Pro</Label>
        </div>
      </div>
    </RadioGroup>
  );
};