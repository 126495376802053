import { useCourseOrder } from "@/hooks/useCourseOrder";
import { CourseOrderItem } from "./order/CourseOrderItem";
import { Skeleton } from "@/components/ui/skeleton";
import { Card } from "@/components/ui/card";

export const CourseOrderManager = () => {
  const { courses, isLoading, moveUp, moveDown } = useCourseOrder();

  if (isLoading) {
    return (
      <div className="space-y-4">
        {[1, 2, 3].map((i) => (
          <Card key={i} className="p-4">
            <Skeleton className="h-6 w-48" />
          </Card>
        ))}
      </div>
    );
  }

  if (!courses?.length) {
    return (
      <Card className="p-4">
        <p className="text-center text-muted-foreground">Aucun cours à afficher</p>
      </Card>
    );
  }

  return (
    <div className="space-y-4">
      {courses.map((course, index) => (
        <CourseOrderItem
          key={course.id}
          course={course}
          index={index}
          totalCourses={courses.length}
          onMoveUp={moveUp}
          onMoveDown={moveDown}
        />
      ))}
    </div>
  );
};