import { Sheet, SheetContent, SheetTrigger } from "@/components/ui/sheet";
import { Button } from "@/components/ui/button";
import { AlertCircle } from "lucide-react";
import { ChapterNavigationSidebar } from "../ChapterNavigationSidebar";
import { Chapter } from "@/types/course";

interface MobileNavigationProps {
  isOpen: boolean;
  onOpenChange: (open: boolean) => void;
  chapters: Chapter[];
  currentChapterId: string;
  onChapterChange: (chapterId: string) => void;
  courseId: string;
}

export const MobileNavigation = ({
  isOpen,
  onOpenChange,
  chapters,
  currentChapterId,
  onChapterChange,
  courseId,
}: MobileNavigationProps) => {
  return (
    <Sheet open={isOpen} onOpenChange={onOpenChange}>
      <SheetTrigger asChild>
        <Button
          variant="outline"
          size="icon"
          className="fixed bottom-4 right-4 z-50 rounded-full shadow-lg"
        >
          <AlertCircle className="h-5 w-5" />
        </Button>
      </SheetTrigger>
      <SheetContent side="right" className="w-[85%] p-0">
        <ChapterNavigationSidebar
          chapters={chapters}
          currentChapterId={currentChapterId}
          onChapterChange={onChapterChange}
          courseId={courseId}
        />
      </SheetContent>
    </Sheet>
  );
};