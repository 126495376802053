import { Route, Navigate } from "react-router-dom";
import Index from "@/pages/Index";
import About from "@/pages/About";
import Contact from "@/pages/Contact";
import Blog from "@/pages/Blog";
import BlogPost from "@/pages/BlogPost";
import Code from "@/pages/Code";
import CodeView from "@/pages/CodeView";
import ChapterView from "@/pages/ChapterView";
import Tarifs from "@/pages/Tarifs";
import Login from "@/pages/auth/Login";
import Register from "@/pages/auth/Register";

export const publicRoutes = [
  <Route key="index" path="/" element={<Index />} />,
  <Route key="about" path="/about" element={<About />} />,
  <Route key="contact" path="/contact" element={<Contact />} />,
  <Route key="blog" path="/blog" element={<Blog />} />,
  <Route key="blog-post" path="/blog/:slug" element={<BlogPost />} />,
  <Route key="code" path="/code" element={<Code />} />,
  <Route key="code-view" path="/code/:slug" element={<CodeView />} />,
  <Route key="chapter-view" path="/code/:courseSlug/:chapterSlug" element={<ChapterView />} />,
  <Route key="tarifs" path="/tarifs" element={<Tarifs />} />,
  <Route key="login" path="/login" element={<Navigate to="/auth/login" replace />} />,
  <Route key="auth-login" path="/auth/login" element={<Login />} />,
  <Route key="register" path="/register" element={<Navigate to="/auth/register" replace />} />,
  <Route key="auth-register" path="/auth/register" element={<Register />} />,
];