import { useState } from "react";
import { Editor } from "@tiptap/react";
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger } from "@/components/ui/dialog";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { ImageGallery } from "@/components/courses/ImageGallery";
import { VideoGallery } from "@/components/courses/VideoGallery";
import { Button } from "@/components/ui/button";
import { Image as ImageIcon, Video, Upload } from "lucide-react";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { toast } from "sonner";
import { supabase } from "@/integrations/supabase/client";

interface MediaManagerProps {
  editor: Editor;
  folder?: string;
}

export const MediaManager = ({ editor, folder }: MediaManagerProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [videoUrl, setVideoUrl] = useState("");
  const [isUploading, setIsUploading] = useState(false);

  const handleImageSelect = (url: string) => {
    editor.chain().focus().setImage({ src: url }).run();
    setIsOpen(false);
    toast.success("Image ajoutée avec succès");
  };

  const handleVideoSelect = (url: string) => {
    editor.chain().focus().setVideo({ src: url }).run();
    setIsOpen(false);
    toast.success("Vidéo ajoutée avec succès");
  };

  const handleYoutubeAdd = () => {
    const youtubeId = extractYoutubeId(videoUrl);
    if (youtubeId) {
      editor.chain().focus().setYoutubeVideo({
        src: youtubeId,
        width: 640,
        height: 480,
      }).run();
      setVideoUrl("");
      setIsOpen(false);
      toast.success("Vidéo YouTube ajoutée avec succès");
    } else {
      toast.error("URL YouTube invalide");
    }
  };

  const handleUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file) return;

    if (!file.type.startsWith('image/')) {
      toast.error("Veuillez sélectionner un fichier image valide");
      return;
    }

    if (file.size > 5 * 1024 * 1024) {
      toast.error("L'image ne doit pas dépasser 5MB");
      return;
    }

    setIsUploading(true);
    try {
      const fileExt = file.name.split(".").pop();
      const fileName = `${Math.random()}.${fileExt}`;
      const filePath = folder ? `${folder}/${fileName}` : fileName;

      const { error: uploadError } = await supabase.storage
        .from("course-images")
        .upload(filePath, file, {
          cacheControl: "3600",
          upsert: false
        });

      if (uploadError) {
        throw uploadError;
      }

      const { data: { publicUrl } } = supabase.storage
        .from("course-images")
        .getPublicUrl(filePath);

      handleImageSelect(publicUrl);
      toast.success("Image téléchargée avec succès");
    } catch (error) {
      console.error("Upload error:", error);
      toast.error("Erreur lors du téléchargement de l'image");
    } finally {
      setIsUploading(false);
    }
  };

  const extractYoutubeId = (url: string): string | null => {
    const patterns = [
      /(?:youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?\/\s]{11})/i,
      /^[a-zA-Z0-9_-]{11}$/
    ];

    for (const pattern of patterns) {
      const match = url.match(pattern);
      if (match) return match[1];
    }

    return null;
  };

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogTrigger asChild>
        <Button variant="ghost" size="sm" className="h-8">
          <ImageIcon className="h-4 w-4" />
        </Button>
      </DialogTrigger>
      <DialogContent className="max-w-3xl">
        <DialogHeader>
          <DialogTitle>Ajouter un média</DialogTitle>
        </DialogHeader>
        <Tabs defaultValue="images">
          <TabsList className="grid w-full grid-cols-3">
            <TabsTrigger value="images">Images</TabsTrigger>
            <TabsTrigger value="videos">Vidéos</TabsTrigger>
            <TabsTrigger value="youtube">YouTube</TabsTrigger>
          </TabsList>
          
          <TabsContent value="images" className="space-y-4">
            <div className="flex justify-end">
              <Button 
                variant="outline" 
                disabled={isUploading}
                className="relative"
              >
                {isUploading ? (
                  <div className="flex items-center gap-2">
                    <Upload className="h-4 w-4 animate-spin" />
                    Téléchargement...
                  </div>
                ) : (
                  <div className="flex items-center gap-2">
                    <Upload className="h-4 w-4" />
                    Télécharger une image
                  </div>
                )}
                <input
                  type="file"
                  accept="image/*"
                  className="absolute inset-0 opacity-0 cursor-pointer"
                  onChange={handleUpload}
                  disabled={isUploading}
                />
              </Button>
            </div>
            <ImageGallery onSelect={handleImageSelect} />
          </TabsContent>
          
          <TabsContent value="videos">
            <VideoGallery onSelect={handleVideoSelect} folder={folder} />
          </TabsContent>
          
          <TabsContent value="youtube" className="space-y-4">
            <div className="space-y-2">
              <Label>URL YouTube</Label>
              <Input
                placeholder="https://youtube.com/watch?v=... ou ID de la vidéo"
                value={videoUrl}
                onChange={(e) => setVideoUrl(e.target.value)}
              />
              <Button onClick={handleYoutubeAdd} className="w-full">
                Ajouter la vidéo YouTube
              </Button>
            </div>
          </TabsContent>
        </Tabs>
      </DialogContent>
    </Dialog>
  );
};