import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogDescription } from "@/components/ui/dialog";
import { useForm } from "react-hook-form";
import { useToast } from "@/hooks/use-toast";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { supabase } from "@/integrations/supabase/client";
import { useEffect } from "react";
import { AITool } from "@/integrations/supabase/types/ai";
import { PredefinedTools } from "./PredefinedTools";
import { AIToolForm } from "./AIToolForm";

interface AIToolDialogProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  tool?: AITool;
  onClose: () => void;
}

export const AIToolDialog = ({ open, onOpenChange, tool, onClose }: AIToolDialogProps) => {
  const { toast } = useToast();
  const queryClient = useQueryClient();
  
  const form = useForm<AITool>({
    defaultValues: {
      id: "",
      name: "",
      description: "",
      prompt: "",
      model: "gpt-4o-mini",
      created_at: "",
      updated_at: "",
    },
  });

  useEffect(() => {
    if (tool) {
      form.reset(tool);
    } else {
      form.reset({
        id: "",
        name: "",
        description: "",
        prompt: "",
        model: "gpt-4o-mini",
        created_at: "",
        updated_at: "",
      });
    }
  }, [tool, form]);

  const mutation = useMutation({
    mutationFn: async (values: AITool) => {
      const { id, created_at, updated_at, ...insertData } = values;
      
      if (tool) {
        const { error } = await supabase
          .from("ai_tools")
          .update(insertData)
          .eq("id", tool.id);
        
        if (error) throw error;
      } else {
        const { error } = await supabase
          .from("ai_tools")
          .insert([insertData]);
        
        if (error) throw error;
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["ai-tools"] });
      toast({
        title: tool ? "Outil mis à jour" : "Outil créé",
        description: tool ? "L'outil a été mis à jour avec succès" : "L'outil a été créé avec succès",
      });
      onClose();
    },
    onError: (error) => {
      console.error("Error in mutation:", error);
      toast({
        title: "Erreur",
        description: "Une erreur est survenue lors de l'enregistrement",
        variant: "destructive",
      });
    },
  });

  const handlePredefinedToolSelect = (selectedTool: AITool) => {
    form.setValue("name", selectedTool.name);
    form.setValue("description", selectedTool.description);
    form.setValue("prompt", selectedTool.prompt);
    form.setValue("model", selectedTool.model);
  };

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="sm:max-w-[600px] max-h-[90vh] overflow-y-auto">
        <DialogHeader className="space-y-2">
          <DialogTitle className="text-2xl">
            {tool ? "Modifier l'outil" : "Nouvel outil"}
          </DialogTitle>
          {!tool && (
            <DialogDescription className="text-base">
              Créez un nouvel outil d'IA ou sélectionnez un modèle prédéfini pour commencer
            </DialogDescription>
          )}
        </DialogHeader>
        <div className="space-y-6 py-4">
          {!tool && <PredefinedTools onSelect={handlePredefinedToolSelect} />}
          <AIToolForm 
            form={form} 
            onSubmit={(values) => mutation.mutate(values)}
            onClose={onClose}
            isEditing={!!tool}
          />
        </div>
      </DialogContent>
    </Dialog>
  );
};