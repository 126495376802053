import { ArticleData } from "../types";
import { ArticleConfigurationForm } from "./article-configuration/ArticleConfigurationForm";

interface ArticleConfigurationProps {
  data: ArticleData;
  onComplete: (data: Partial<ArticleData>) => void;
}

export const ArticleConfiguration = ({ data, onComplete }: ArticleConfigurationProps) => {
  return (
    <div className="space-y-6">
      <ArticleConfigurationForm data={data} onComplete={onComplete} />
    </div>
  );
};