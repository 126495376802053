import { Header } from "@/components/layout/Header";
import { Footer } from "@/components/layout/Footer";
import { PricingSection } from "@/components/home/PricingSection";
import { Helmet } from "react-helmet";

const Tarifs = () => {
  return (
    <div className="min-h-screen flex flex-col">
      <Helmet>
        <title>Tarifs</title>
        <meta name="description" content="Découvrez les tarifs du code moto sur Bekane.fr" />
        <meta name="keywords" content="tarifs, abonnement" />
        <meta name="author" content="Bekane" />
        <meta name="robots" content="noindex,nofollow" />
      </Helmet>
      <Header />
      <main className="flex-grow">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
          <div className="text-center mb-12">
            <h1 className="text-4xl font-bold tracking-tight sm:text-5xl mb-4">
              Nos tarifs
            </h1>
            <p className="text-xl text-black max-w-2xl mx-auto">
              Choisissez la formule qui correspond le mieux à vos besoins et commencez votre formation dès aujourd'hui.
            </p>
          </div>
        </div>
        
        <PricingSection />

        <section className="py-16 bg-gray-50">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="grid md:grid-cols-2 gap-12 items-center">
              <div className="space-y-4">
                <h2 className="text-3xl font-bold tracking-tight">
                  Des questions sur nos formules ?
                </h2>
                <p className="text-lg text-black">
                  Notre équipe est là pour vous aider à choisir la formule la plus adaptée à vos besoins et répondre à toutes vos questions.
                </p>
              </div>
              <div className="bg-white p-8 rounded-lg shadow-sm">
                <h3 className="text-xl font-semibold mb-4">Contactez-nous</h3>
                <div className="space-y-3">
                  <p className="text-black">Email: contact@bekane.fr</p>
                  <p className="text-black">Du lundi au vendredi, de 9h à 18h</p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </div>
  );
};

export default Tarifs;