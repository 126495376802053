import { Helmet } from "react-helmet";

export const StudentCoursesHeader = () => {
  return (
    <>
      <Helmet>
        <title>Mes cours | Bekane</title>
        <meta name="description" content="Accédez à tous vos cours de code moto et suivez votre progression." />
        <meta name="author" content="Bekane" />
        <meta name="robots" content="noindex,nofollow" />
      </Helmet>
      <div className="flex flex-col items-start gap-4 mb-8">
        <h1 className="text-3xl font-bold">Mes cours</h1>
        <p className="text-muted-foreground">
          Retrouvez tous vos cours et suivez votre progression
        </p>
      </div>
    </>
  );
};