import { BaseQuestionForm } from "./BaseQuestionForm";
import { AnswerFormSection } from "./AnswerFormSection";
import { Answer } from "@/types/quiz";

interface QuestionFormContentProps {
  question: string;
  richText: string;
  answers: Answer[];
  questionType: string;
  fontSize: string;
  textAlign: string;
  backgroundColor: string;
  isSubmitting: boolean;
  onQuestionChange: (value: string) => void;
  onRichTextChange: (value: string) => void;
  onAnswersUpdate: (answers: Answer[]) => void;
  onQuestionTypeChange: (value: string) => void;
  onFontSizeChange: (value: string) => void;
  onTextAlignChange: (value: string) => void;
  onBackgroundColorChange: (value: string) => void;
  onCancel: () => void;
  onSubmit: (e: React.FormEvent) => void;
}

export const QuestionFormContent = ({
  question,
  richText,
  answers,
  questionType,
  fontSize,
  textAlign,
  backgroundColor,
  isSubmitting,
  onQuestionChange,
  onRichTextChange,
  onAnswersUpdate,
  onQuestionTypeChange,
  onFontSizeChange,
  onTextAlignChange,
  onBackgroundColorChange,
  onCancel,
  onSubmit,
}: QuestionFormContentProps) => {
  return (
    <form onSubmit={onSubmit} className="space-y-6">
      <BaseQuestionForm
        question={question}
        richText={richText}
        questionType={questionType}
        fontSize={fontSize}
        textAlign={textAlign}
        backgroundColor={backgroundColor}
        isSubmitting={isSubmitting}
        onQuestionChange={onQuestionChange}
        onRichTextChange={onRichTextChange}
        onQuestionTypeChange={onQuestionTypeChange}
        onFontSizeChange={onFontSizeChange}
        onTextAlignChange={onTextAlignChange}
        onBackgroundColorChange={onBackgroundColorChange}
        onCancel={onCancel}
        onSubmit={onSubmit}
      />

      <AnswerFormSection
        answers={answers}
        questionType={questionType}
        onAnswersUpdate={onAnswersUpdate}
      />
    </form>
  );
};