import { Card, CardHeader, CardTitle, CardContent } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { Badge } from "@/components/ui/badge";
import { Edit, FileText, CheckSquare, Clock, Trash2 } from "lucide-react";
import { format } from "date-fns";
import { fr } from "date-fns/locale";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { BlogPost } from "./types";
import { getStatusIcon, getStatusColor, getStatusLabel } from "./utils";

interface BlogCardProps {
  post: BlogPost;
  viewMode: "list" | "grid";
  onEdit: (post: BlogPost) => void;
  onStatusChange: (postId: string, newStatus: string) => void;
  onDeleteClick: (postId: string) => void;
}

export const BlogCard = ({
  post,
  viewMode,
  onEdit,
  onStatusChange,
  onDeleteClick,
}: BlogCardProps) => {
  return (
    <Card key={post.id} className="hover:shadow-md transition-shadow">
      <CardHeader>
        <div className="flex justify-between items-start">
          <div className="space-y-2">
            <CardTitle className="text-lg">{post.title}</CardTitle>
            <div className="flex items-center gap-2 text-sm text-muted-foreground">
              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <Button
                    variant="ghost"
                    size="sm"
                    className="p-0 h-auto hover:bg-transparent"
                  >
                    <Badge 
                      variant="secondary"
                      className={`flex items-center gap-1 ${getStatusColor(post.status)} text-white cursor-pointer`}
                    >
                      {getStatusIcon(post.status)}
                      {getStatusLabel(post.status)}
                    </Badge>
                  </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent align="start">
                  <DropdownMenuItem onClick={() => onStatusChange(post.id, "draft")}>
                    <FileText className="h-4 w-4 mr-2" />
                    Brouillon
                  </DropdownMenuItem>
                  <DropdownMenuItem onClick={() => onStatusChange(post.id, "pending")}>
                    <Clock className="h-4 w-4 mr-2" />
                    En attente
                  </DropdownMenuItem>
                  <DropdownMenuItem onClick={() => onStatusChange(post.id, "published")}>
                    <CheckSquare className="h-4 w-4 mr-2" />
                    Publié
                  </DropdownMenuItem>
                </DropdownMenuContent>
              </DropdownMenu>
              <span>•</span>
              <span>
                {format(new Date(post.created_at), "d MMMM yyyy", { locale: fr })}
              </span>
            </div>
          </div>
          <div className="flex items-center gap-2">
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button variant="outline" size="sm">
                  <Edit className="h-4 w-4 mr-2" />
                  Modifier
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent align="end">
                <DropdownMenuItem onClick={() => onEdit(post)}>
                  <Edit className="h-4 w-4 mr-2" />
                  Éditer l'article
                </DropdownMenuItem>
                <DropdownMenuItem onClick={() => onStatusChange(post.id, "draft")}>
                  <FileText className="h-4 w-4 mr-2" />
                  Marquer comme brouillon
                </DropdownMenuItem>
                <DropdownMenuItem onClick={() => onStatusChange(post.id, "pending")}>
                  <Clock className="h-4 w-4 mr-2" />
                  Marquer en attente
                </DropdownMenuItem>
                <DropdownMenuItem onClick={() => onStatusChange(post.id, "published")}>
                  <CheckSquare className="h-4 w-4 mr-2" />
                  Publier
                </DropdownMenuItem>
                <DropdownMenuItem 
                  className="text-red-600"
                  onClick={() => onDeleteClick(post.id)}
                >
                  <Trash2 className="h-4 w-4 mr-2" />
                  Supprimer
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        </div>
      </CardHeader>
      {viewMode === "grid" && post.image_url && (
        <CardContent>
          <img
            src={post.image_url}
            alt={post.title}
            className="w-full h-48 object-cover rounded-md"
          />
        </CardContent>
      )}
    </Card>
  );
};