import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Checkbox } from "@/components/ui/checkbox";
import { ArrowUpCircle, ArrowDownCircle, Trash2 } from "lucide-react";

interface Answer {
  id?: string;
  answer: string;
  is_correct: boolean;
}

interface AnswerListProps {
  answers: Answer[];
  questionType: string;
  onAnswerUpdate: (answers: Answer[]) => void;
}

export const AnswerList = ({ answers, questionType, onAnswerUpdate }: AnswerListProps) => {
  const updateAnswer = (index: number, field: keyof Answer, value: any) => {
    const newAnswers = [...answers];
    newAnswers[index] = { ...newAnswers[index], [field]: value };

    if (field === "is_correct" && value === true && questionType === "single_choice") {
      newAnswers.forEach((_, i) => {
        if (i !== index) {
          newAnswers[i].is_correct = false;
        }
      });
    }

    onAnswerUpdate(newAnswers);
  };

  const moveAnswer = (index: number, direction: "up" | "down") => {
    if (
      (direction === "up" && index === 0) ||
      (direction === "down" && index === answers.length - 1)
    ) {
      return;
    }

    const newAnswers = [...answers];
    const targetIndex = direction === "up" ? index - 1 : index + 1;
    [newAnswers[index], newAnswers[targetIndex]] = [
      newAnswers[targetIndex],
      newAnswers[index],
    ];
    onAnswerUpdate(newAnswers);
  };

  const removeAnswer = (index: number) => {
    if (answers.length > 1) {
      const newAnswers = answers.filter((_, i) => i !== index);
      onAnswerUpdate(newAnswers);
    }
  };

  return (
    <div className="space-y-4">
      {answers.map((answer, index) => (
        <div key={index} className="flex items-center gap-4">
          <div className="flex-1">
            <Input
              value={answer.answer}
              onChange={(e) => updateAnswer(index, "answer", e.target.value)}
              placeholder={`Réponse ${index + 1}`}
              required
            />
          </div>
          <div className="flex items-center gap-2">
            <Checkbox
              id={`correct-${index}`}
              checked={answer.is_correct}
              onCheckedChange={(checked) =>
                updateAnswer(index, "is_correct", checked)
              }
            />
            <Label htmlFor={`correct-${index}`}>Correcte</Label>
          </div>
          <div className="flex items-center gap-1">
            <Button
              type="button"
              variant="ghost"
              size="icon"
              onClick={() => moveAnswer(index, "up")}
              disabled={index === 0}
            >
              <ArrowUpCircle className="h-4 w-4" />
            </Button>
            <Button
              type="button"
              variant="ghost"
              size="icon"
              onClick={() => moveAnswer(index, "down")}
              disabled={index === answers.length - 1}
            >
              <ArrowDownCircle className="h-4 w-4" />
            </Button>
            {answers.length > 1 && (
              <Button
                type="button"
                variant="ghost"
                size="icon"
                onClick={() => removeAnswer(index)}
              >
                <Trash2 className="h-4 w-4" />
              </Button>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};