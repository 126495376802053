import { Label } from "@/components/ui/label";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";

interface QuestionTypeSelectProps {
  value: string;
  onChange: (value: string) => void;
}

export const QuestionTypeSelect = ({ value, onChange }: QuestionTypeSelectProps) => {
  return (
    <div className="space-y-2">
      <Label htmlFor="questionType">Type de question</Label>
      <Select value={value} onValueChange={onChange}>
        <SelectTrigger id="questionType">
          <SelectValue placeholder="Choisir le type de question" />
        </SelectTrigger>
        <SelectContent>
          <SelectItem value="single_choice">QCM (choix unique)</SelectItem>
          <SelectItem value="multiple_choice">QCM (choix multiples)</SelectItem>
          <SelectItem value="true_false">Vrai/Faux</SelectItem>
          <SelectItem value="text_input">Réponse libre</SelectItem>
          <SelectItem value="matching">Appariement</SelectItem>
        </SelectContent>
      </Select>
    </div>
  );
};