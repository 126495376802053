import { Card } from "@/components/ui/card";
import { Checkbox } from "@/components/ui/checkbox";

interface SelectableCardProps {
  isSelected: boolean;
  onSelect: () => void;
  children: React.ReactNode;
  className?: string;
}

export const SelectableCard = ({ 
  isSelected, 
  onSelect, 
  children,
  className = ""
}: SelectableCardProps) => {
  return (
    <Card className={`p-6 transition-all ${isSelected ? 'ring-2 ring-primary' : ''} ${className}`}>
      <div className="flex items-start gap-4">
        <Checkbox
          checked={isSelected}
          onCheckedChange={onSelect}
          className="mt-1"
        />
        <div className="flex-1">
          {children}
        </div>
      </div>
    </Card>
  );
};