import { useState } from "react";
import { supabase } from "@/integrations/supabase/client";
import { toast } from "sonner";
import { useQueryClient } from "@tanstack/react-query";

interface UseChapterProgressProps {
  chapterId: string;
  courseId: string;
  currentIndex: number;
  totalChapters: number;
  onChapterComplete: (nextChapterId: string) => void;
  onCourseComplete: () => void;
  nextChapterId: string;
}

export const useChapterProgress = ({
  courseId,
  currentIndex,
  totalChapters,
  onChapterComplete,
  onCourseComplete,
  nextChapterId,
  chapterId,
}: UseChapterProgressProps) => {
  const [isCompleting, setIsCompleting] = useState(false);
  const queryClient = useQueryClient();

  const handleChapterComplete = async () => {
    try {
      setIsCompleting(true);
      const isLastChapter = currentIndex === totalChapters - 1;

      const { data: { session }, error: sessionError } = await supabase.auth.getSession();
      if (sessionError) {
        console.error("Session error:", sessionError);
        toast.error("Erreur d'authentification");
        return;
      }
      
      if (!session?.user?.id) {
        toast.error("Vous devez être connecté pour suivre votre progression");
        return;
      }

      // Mise à jour ou création de la progression du chapitre actuel avec upsert
      const { error: chapterError } = await supabase
        .from('chapter_progress')
        .upsert({
          course_id: courseId,
          chapter_id: chapterId,
          user_id: session.user.id,
          completed_at: new Date().toISOString(),
          last_visited_at: new Date().toISOString(),
        }, {
          onConflict: 'user_id,chapter_id,course_id'
        });

      if (chapterError) {
        console.error("Error updating chapter progress:", chapterError);
        toast.error("Erreur lors de la sauvegarde de votre progression");
        return;
      }

      // Si ce n'est pas le dernier chapitre, mettre à jour le prochain chapitre comme visité
      if (!isLastChapter && nextChapterId) {
        const { error: nextChapterError } = await supabase
          .from('chapter_progress')
          .upsert({
            course_id: courseId,
            chapter_id: nextChapterId,
            user_id: session.user.id,
            last_visited_at: new Date().toISOString(),
          }, {
            onConflict: 'user_id,chapter_id,course_id'
          });

        if (nextChapterError) {
          console.error("Error updating next chapter:", nextChapterError);
        }
      }

      // Calculer le nombre de chapitres complétés
      const { count: completedChaptersCount, error: countError } = await supabase
        .from('chapter_progress')
        .select('*', { count: 'exact', head: true })
        .eq('course_id', courseId)
        .eq('user_id', session.user.id)
        .not('completed_at', 'is', null);

      if (countError) {
        console.error("Error counting completed chapters:", countError);
        toast.error("Erreur lors du calcul de votre progression");
        return;
      }

      if (completedChaptersCount === null) {
        console.error("Unexpected null count for completed chapters");
        toast.error("Erreur lors du calcul de votre progression");
        return;
      }

      // Mettre à jour la progression globale du cours
      const progressPercentage = Math.round(((completedChaptersCount) / totalChapters) * 100);
      const { error: progressError } = await supabase
        .from('course_progress')
        .upsert({
          course_id: courseId,
          user_id: session.user.id,
          progress_percentage: progressPercentage,
          completed_at: progressPercentage === 100 ? new Date().toISOString() : null,
          last_activity_at: new Date().toISOString()
        }, {
          onConflict: 'user_id,course_id'
        });

      if (progressError) {
        console.error("Error updating course progress:", progressError);
        toast.error("Erreur lors de la mise à jour de votre progression");
        return;
      }

      // Créer une entrée dans user_activities
      const { error: activityError } = await supabase
        .from('user_activities')
        .insert({
          user_id: session.user.id,
          course_id: courseId,
          chapter_id: chapterId,
          activity_type: isLastChapter ? 'course_completed' : 'chapter_completed',
        });

      if (activityError) {
        console.error("Error creating activity:", activityError);
      }

      // Invalider les caches pour forcer le rafraîchissement des données
      queryClient.invalidateQueries({ queryKey: ['courses-stats'] });
      queryClient.invalidateQueries({ queryKey: ['student-activities'] });
      queryClient.invalidateQueries({ queryKey: ['student-courses-detail'] });

      if (isLastChapter) {
        toast.success("Félicitations ! Vous avez terminé ce cours !", {
          description: "Vous pouvez maintenant passer au cours suivant",
          duration: 5000,
        });
        onCourseComplete();
      } else {
        const remainingChapters = totalChapters - completedChaptersCount;
        toast.success(`Chapitre terminé ! Plus que ${remainingChapters} chapitre${remainingChapters > 1 ? 's' : ''} !`, {
          description: "Continuez sur votre lancée !",
          duration: 3000,
        });
        onChapterComplete(nextChapterId);
      }
    } catch (error) {
      console.error("Error in handleChapterComplete:", error);
      toast.error("Une erreur est survenue", {
        description: "Veuillez réessayer plus tard",
      });
    } finally {
      setIsCompleting(false);
    }
  };

  return {
    isCompleting,
    handleChapterComplete,
  };
};