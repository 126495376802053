import { Button } from "@/components/ui/button";
import { AITool } from "@/integrations/supabase/types/ai";

interface ToolSelectorProps {
  tools: Omit<AITool, "id" | "created_at" | "updated_at">[];
  selectedTool: Omit<AITool, "id" | "created_at" | "updated_at"> | null;
  onSelect: (tool: Omit<AITool, "id" | "created_at" | "updated_at">) => void;
}

export const ToolSelector = ({ tools, selectedTool, onSelect }: ToolSelectorProps) => {
  return (
    <div className="grid gap-4">
      {tools.map((tool) => (
        <Button
          key={tool.name}
          variant={selectedTool?.name === tool.name ? "default" : "outline"}
          className="h-auto flex flex-col items-start p-4 space-y-2 w-full"
          onClick={() => onSelect(tool)}
        >
          <span className="font-semibold">{tool.name}</span>
          <span className="text-sm text-muted-foreground text-left">
            {tool.description}
          </span>
        </Button>
      ))}
    </div>
  );
};