import { Chapter } from "@/types/course";
import { AccordionContent, AccordionItem, AccordionTrigger } from "@/components/ui/accordion";
import { Clock } from "lucide-react";
import { Badge } from "@/components/ui/badge";

interface ChapterContentProps {
  chapter: Chapter;
  index: number;
}

export const ChapterContent = ({ chapter, index }: ChapterContentProps) => {
  return (
    <AccordionItem value={chapter.id} className="border rounded-lg">
      <AccordionTrigger className="px-4 hover:no-underline [&[data-state=open]]:bg-secondary/50">
        <div className="flex items-center gap-4">
          <Badge variant="outline" className="w-6 h-6 rounded-full flex items-center justify-center flex-shrink-0">
            {index + 1}
          </Badge>
          <div className="flex-1 text-left">
            <h3 className="font-medium">{chapter.title}</h3>
            {chapter.meta_description && (
              <p className="text-sm text-muted-foreground mt-1">
                {chapter.meta_description}
              </p>
            )}
          </div>
          <Badge variant="secondary" className="ml-auto flex items-center gap-1">
            <Clock className="w-3 h-3" />
            {chapter.duration} min
          </Badge>
        </div>
      </AccordionTrigger>
      <AccordionContent className="px-4 pt-2 pb-3">
        <div className="prose prose-sm max-w-none">
          <div dangerouslySetInnerHTML={{ __html: chapter.content || "" }} />
        </div>
      </AccordionContent>
    </AccordionItem>
  );
};