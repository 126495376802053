import { Button } from "@/components/ui/button";
import { toast } from "sonner";
import { useNavigate } from "react-router-dom";
import { supabase } from "@/integrations/supabase/client";

interface SimpleResultProps {
  content: string;
}

export const SimpleResult = ({ content }: SimpleResultProps) => {
  const navigate = useNavigate();

  const formatContentForBlog = (content: string) => {
    // Séparer le contenu en sections basées sur les titres
    const sections = content.split(/(?=\n[A-Z][\w\s-]+:)/);
    
    let formattedContent = '';
    
    sections.forEach(section => {
      // Traiter chaque section
      const lines = section.trim().split('\n');
      
      lines.forEach(line => {
        // Détecter et formater les titres
        if (line.match(/^[A-Z][\w\s-]+:/)) {
          formattedContent += `\n## ${line.replace(':', '')}\n\n`;
        }
        // Détecter et formater les sous-titres
        else if (line.match(/^\d+\.\d+\./)) {
          formattedContent += `\n### ${line.split('.').slice(2).join('.').trim()}\n\n`;
        }
        // Détecter et formater les listes
        else if (line.match(/^[-•*]/)) {
          formattedContent += `${line}\n`;
        }
        // Paragraphes normaux
        else if (line.trim()) {
          formattedContent += `${line}\n\n`;
        }
      });
    });

    return formattedContent.trim();
  };

  const handleCreateBlogPost = async () => {
    try {
      // Extraire le titre H1 du contenu
      const titleMatch = content.match(/^(.+?)(?=\n|$)/);
      const title = titleMatch ? titleMatch[1].replace(/^#\s*/, '') : "Nouvel article généré par IA";
      
      // Formater le contenu pour le blog
      const formattedContent = formatContentForBlog(content);
      
      // Générer un slug unique basé sur le titre
      const slug = title
        .toLowerCase()
        .replace(/[^a-z0-9]+/g, '-')
        .replace(/^-+|-+$/g, '') + 
        '-' + Date.now().toString().slice(-4);

      const { data, error } = await supabase.from("blog_posts").insert([
        {
          title,
          content: formattedContent,
          status: "draft",
          slug,
        },
      ]).select().single();

      if (error) throw error;

      toast.success("Article créé avec succès !");
      navigate(`/admin/blog?edit=${data.id}`);
    } catch (error) {
      console.error("Error creating blog post:", error);
      toast.error("Erreur lors de la création de l'article");
    }
  };

  if (!content) return null;

  return (
    <div className="mt-4 space-y-4">
      <div className="prose max-w-none border rounded-lg p-4 whitespace-pre-wrap">
        {content}
      </div>
      <div className="flex gap-2">
        <Button
          variant="outline"
          onClick={() => {
            navigator.clipboard.writeText(content);
            toast.success("Contenu copié dans le presse-papier !");
          }}
        >
          Copier le contenu
        </Button>
        <Button onClick={handleCreateBlogPost}>
          Créer un article de blog
        </Button>
      </div>
    </div>
  );
};