import { useState } from "react";
import { toast } from "sonner";
import { useNavigate } from "react-router-dom";
import { useSession } from "@supabase/auth-helpers-react";
import { supabase } from "@/integrations/supabase/client";

export const useSubscription = () => {
  const navigate = useNavigate();
  const session = useSession();

  const handleSubscribe = async (planType: string) => {
    try {
      if (!session?.user?.id) {
        navigate("/auth/login");
        return;
      }

      console.log('Creating checkout session for plan:', planType);
      
      const { data, error } = await supabase.functions.invoke('create-checkout', {
        body: { planType },
      });

      if (error) {
        console.error('Checkout error:', error);
        // Parse the error message from the response body if it exists
        let errorMessage = "Une erreur est survenue lors de la création de la session de paiement.";
        try {
          const errorBody = JSON.parse(error.message);
          if (errorBody?.error) {
            errorMessage = errorBody.error;
          }
        } catch (e) {
          // If parsing fails, use the original error message
          errorMessage = error.message || errorMessage;
        }
        
        toast.error("Erreur", {
          description: errorMessage,
        });
        return;
      }

      if (data?.url) {
        window.location.href = data.url;
      } else {
        toast.error("Erreur", {
          description: "URL de paiement invalide.",
        });
      }
    } catch (error) {
      console.error('Subscription error:', error);
      toast.error("Erreur", {
        description: "Une erreur est survenue lors de la création de la session de paiement.",
      });
    }
  };

  return { handleSubscribe };
};