import { useState } from "react";
import { Button } from "@/components/ui/button";
import { toast } from "sonner";
import { ArticleData } from "../types";
import { PlanDisplay } from "./plan-selection/PlanDisplay";
import { useAIGeneration } from "@/hooks/useAIGeneration";
import { parsePlansFromText } from "./plan-selection/planParser";
import { getPlanGenerationPrompt } from "../prompts/planGenerationPrompt";

interface PlanSelectionProps {
  data: ArticleData;
  onComplete: (data: Partial<ArticleData>) => void;
}

export const PlanSelection = ({ data, onComplete }: PlanSelectionProps) => {
  const [plans, setPlans] = useState<string[]>([]);
  const [selectedPlan, setSelectedPlan] = useState<string>("");
  const { generateContent, isLoading } = useAIGeneration();

  const generatePlans = async () => {
    if (!data.selectedTitle || !data.selectedKeywords) {
      toast.error("Le titre et les mots-clés sont requis pour générer des plans");
      return;
    }

    const keywords = data.selectedKeywords.map(kw => kw.keyword).join(", ");
    
    console.log("📝 Configuration pour la génération de plans:", {
      title: data.selectedTitle,
      keywords,
      configuration: data.configuration
    });

    const prompt = getPlanGenerationPrompt(
      data.selectedTitle,
      keywords,
      data.configuration || {
        titleCount: "3",
        writingStyle: "professionnel",
        perspective: "expert",
        wordCount: "1500"
      }
    );

    console.log("🔄 Envoi du prompt pour la génération de plans:", {
      type: "PLAN_GENERATION",
      prompt
    });

    const content = await generateContent(prompt);
    
    if (content) {
      console.log("✅ Contenu brut reçu:", content);
      const parsedPlans = parsePlansFromText(content);
      console.log("✅ Plans parsés:", parsedPlans);
      setPlans(parsedPlans);
    }
  };

  const handleContinue = () => {
    if (!selectedPlan) {
      toast.error("Veuillez sélectionner un plan");
      return;
    }
    console.log("✅ Plan sélectionné:", selectedPlan);
    onComplete({ selectedPlan });
  };

  return (
    <div className="space-y-6">
      <Button
        onClick={generatePlans}
        disabled={isLoading}
        className="w-full"
      >
        {isLoading ? "Génération des plans en cours..." : "Générer des propositions de plans"}
      </Button>

      {plans.length > 0 && (
        <div className="space-y-6">
          <div className="grid gap-6">
            {plans.map((plan, index) => (
              <PlanDisplay
                key={index}
                plan={plan}
                index={index}
                isSelected={selectedPlan === plan}
                onSelect={() => setSelectedPlan(plan)}
              />
            ))}
          </div>

          <Button
            onClick={handleContinue}
            disabled={!selectedPlan}
            className="w-full"
          >
            Continuer avec le plan sélectionné
          </Button>
        </div>
      )}
    </div>
  );
};