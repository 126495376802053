import { Route } from "react-router-dom";
import { ProtectedRoute } from "@/components/auth/ProtectedRoute";
import { AdminRoute } from "@/components/auth/AdminRoute";
import AdminDashboard from "@/pages/admin/Dashboard";
import AdminCourses from "@/pages/admin/Courses";
import AdminBlog from "@/pages/admin/Blog";
import AdminSettings from "@/pages/admin/Settings";
import AdminSubscriptions from "@/pages/admin/Subscriptions";
import AdminAITools from "@/pages/admin/AITools";
import AIArticles from "@/pages/admin/AIArticles";

export const adminRoutes = [
  <Route
    key="admin-dashboard"
    path="/admin/dashboard"
    element={
      <ProtectedRoute>
        <AdminRoute>
          <AdminDashboard />
        </AdminRoute>
      </ProtectedRoute>
    }
  />,
  <Route
    key="admin-courses"
    path="/admin/courses"
    element={
      <ProtectedRoute>
        <AdminRoute>
          <AdminCourses />
        </AdminRoute>
      </ProtectedRoute>
    }
  />,
  <Route
    key="admin-blog"
    path="/admin/blog"
    element={
      <ProtectedRoute>
        <AdminRoute>
          <AdminBlog />
        </AdminRoute>
      </ProtectedRoute>
    }
  />,
  <Route
    key="admin-ai-articles"
    path="/admin/ai-articles"
    element={
      <ProtectedRoute>
        <AdminRoute>
          <AIArticles />
        </AdminRoute>
      </ProtectedRoute>
    }
  />,
  <Route
    key="admin-settings"
    path="/admin/settings"
    element={
      <ProtectedRoute>
        <AdminRoute>
          <AdminSettings />
        </AdminRoute>
      </ProtectedRoute>
    }
  />,
  <Route
    key="admin-subscriptions"
    path="/admin/subscriptions"
    element={
      <ProtectedRoute>
        <AdminRoute>
          <AdminSubscriptions />
        </AdminRoute>
      </ProtectedRoute>
    }
  />,
  <Route
    key="admin-ai-tools"
    path="/admin/ai-tools"
    element={
      <ProtectedRoute>
        <AdminRoute>
          <AdminAITools />
        </AdminRoute>
      </ProtectedRoute>
    }
  />,
];