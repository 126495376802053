import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { PricingPlan } from "./PricingPlan";
import { pricingPlans } from "./pricingPlans";

interface PricingDialogProps {
  onSubscribe: (planType: string) => void;
  currentPlan?: string;
}

export const PricingDialog = ({ onSubscribe, currentPlan }: PricingDialogProps) => {
  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button variant="outline">Gérer l'abonnement</Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[900px]">
        <DialogHeader>
          <DialogTitle>Nos formules d'apprentissage</DialogTitle>
          <DialogDescription>
            Choisissez la formule qui correspond le mieux à vos besoins
          </DialogDescription>
        </DialogHeader>
        <div className="grid grid-cols-1 gap-8 md:grid-cols-3 p-4">
          {pricingPlans.map((plan) => (
            <PricingPlan
              key={plan.name}
              {...plan}
              onSubscribe={onSubscribe}
              isCurrentPlan={currentPlan === plan.planType}
            />
          ))}
        </div>
      </DialogContent>
    </Dialog>
  );
};