import { GeneratedTitle } from "./types";

export const parseTitlesFromText = (text: string): GeneratedTitle[] => {
  const titles: GeneratedTitle[] = [];
  const sections = text.split(/(?=### Titre \d+)/);

  for (const section of sections) {
    if (!section.trim()) continue;

    const lines = section.split('\n');
    let currentTitle: Partial<GeneratedTitle> = {};
    let description = '';

    for (const line of lines) {
      const trimmedLine = line.trim();
      
      if (trimmedLine.startsWith('**"') || trimmedLine.startsWith('"')) {
        currentTitle.title = trimmedLine.replace(/[\*"]/g, '').trim();
      } else if (trimmedLine.startsWith('**Type :**')) {
        description += `Type: ${trimmedLine.replace('**Type :**', '').trim()}\n`;
      } else if (trimmedLine.startsWith('**Mot-clé ciblé :**')) {
        description += `Mot-clé: ${trimmedLine.replace('**Mot-clé ciblé :**', '').trim()}\n`;
      } else if (trimmedLine.startsWith('**Force :**')) {
        description += `Force: ${trimmedLine.replace('**Force :**', '').trim()}`;
      }
    }

    if (currentTitle.title && description) {
      titles.push({
        title: currentTitle.title,
        description: description.trim()
      });
    }
  }

  console.log("Parsed titles:", titles);
  return titles;
};