import { useEffect, useState } from "react";
import { supabase } from "@/integrations/supabase/client";
import { Logo } from "./header/Logo";
import { NavigationLinks } from "./header/NavigationLinks";
import { AuthButtons } from "./header/AuthButtons";
import { Sheet, SheetContent, SheetTrigger } from "@/components/ui/sheet";
import { Menu } from "lucide-react";
import { Button } from "../ui/button";

export const Header = () => {
  const handleLogout = () => {
    // Cette fonction reste pour maintenir la compatibilité avec les composants enfants
  };

  return (
    <header className="bg-white border-b">
      <nav className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-4">
        <div className="flex justify-between items-center">
          <Logo />

          {/* Desktop Navigation */}
          <NavigationLinks className="hidden md:flex items-center space-x-8" />
          
          {/* Desktop Auth Buttons */}
          <AuthButtons 
            onLogout={handleLogout}
            className="hidden md:flex items-center space-x-4" 
          />

          {/* Mobile Menu */}
          <Sheet>
            <SheetTrigger asChild>
              <Button variant="ghost" size="icon" className="md:hidden">
                <Menu className="h-6 w-6" />
                <span className="sr-only">Menu</span>
              </Button>
            </SheetTrigger>
            <SheetContent side="right" className="w-[300px] sm:w-[400px]">
              <div className="flex flex-col h-full">
                <NavigationLinks className="flex flex-col space-y-4 mt-8" />
                <div className="mt-8 pt-8 border-t">
                  <AuthButtons 
                    onLogout={handleLogout}
                    className="flex flex-col space-y-4" 
                  />
                </div>
              </div>
            </SheetContent>
          </Sheet>
        </div>
      </nav>
    </header>
  );
};