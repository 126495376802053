import { useState } from "react";
import { Button } from "@/components/ui/button";
import { toast } from "sonner";
import { supabase } from "@/integrations/supabase/client";
import { parseKeywordsFromText } from "./parseKeywords";
import { Keyword } from "./types";
import { getKeywordAnalysisPrompt } from "../../prompts/articlePrompts";

interface KeywordAnalyzerProps {
  subject: string;
  selectedSources: Array<{ title: string; source: string }>;
  onAnalysisComplete: (keywords: Keyword[]) => void;
}

export const KeywordAnalyzer = ({
  subject,
  selectedSources,
  onAnalysisComplete
}: KeywordAnalyzerProps) => {
  const [isLoading, setIsLoading] = useState(false);

  const analyzeKeywords = async () => {
    setIsLoading(true);
    try {
      const sourcesText = selectedSources
        ?.map(source => `- ${source.title} (${source.source})`)
        .join('\n') || '';

      const prompt = getKeywordAnalysisPrompt(subject, sourcesText);

      console.log("🔄 Envoi de la requête d'analyse des mots-clés:", {
        subject,
        sourcesText,
        prompt
      });

      const { data: response, error } = await supabase.functions.invoke('generate', {
        body: {
          prompt,
          model: "gemini-1.5-pro"
        }
      });

      if (error) throw error;
      
      if (response && typeof response === 'object' && 'content' in response) {
        console.log("✅ Réponse reçue de l'IA:", response);
        const parsedKeywords = parseKeywordsFromText(response.content);
        console.log("📝 Mots-clés analysés:", parsedKeywords);
        onAnalysisComplete(parsedKeywords);
        toast.success("Mots-clés analysés avec succès");
      } else {
        throw new Error("Format de réponse inattendu");
      }
    } catch (error) {
      console.error("❌ Erreur lors de l'analyse des mots-clés:", error);
      toast.error("Erreur lors de l'analyse des mots-clés");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Button
      onClick={analyzeKeywords}
      disabled={isLoading}
      className="w-full"
    >
      {isLoading ? "Analyse des mots-clés en cours..." : "Analyser les mots-clés"}
    </Button>
  );
};