import { useSession } from "@supabase/auth-helpers-react";
import { useQuery } from "@tanstack/react-query";
import { supabase } from "@/integrations/supabase/client";
import { toast } from "sonner";
import { useNavigate } from "react-router-dom";
import { SubscriptionInfo } from "./subscription/SubscriptionInfo";
import { useState } from "react";

export const SubscriptionSection = () => {
  const session = useSession();
  const navigate = useNavigate();
  const [isProcessing, setIsProcessing] = useState(false);

  const { data: subscription, isLoading } = useQuery({
    queryKey: ["subscription"],
    queryFn: async () => {
      console.log("Fetching subscription for user:", session?.user.id);
      const { data, error } = await supabase
        .from("subscriptions")
        .select("*")
        .eq("user_id", session?.user.id)
        .maybeSingle();

      if (error) {
        console.error("Error fetching subscription:", error);
        toast.error("Erreur lors du chargement de l'abonnement");
        throw error;
      }
      
      console.log("Subscription data:", data);
      return data;
    },
    enabled: !!session?.user.id,
  });

  const handleSubscribe = async (planType: string) => {
    try {
      if (isProcessing) return;
      setIsProcessing(true);

      if (!session?.user?.id) {
        navigate("/auth/login");
        return;
      }

      // Check if user is already subscribed to this plan
      if (subscription?.plan_type === planType && subscription?.status === 'active') {
        toast.error("Vous êtes déjà abonné à cette formule");
        return;
      }

      console.log('Creating checkout session for plan:', planType);
      
      const { data: { session: currentSession }, error: sessionError } = await supabase.auth.getSession();
      
      if (sessionError || !currentSession?.access_token) {
        console.error('Session error:', sessionError);
        toast.error("Erreur d'authentification");
        return;
      }

      const { data, error } = await supabase.functions.invoke('create-checkout', {
        body: { planType },
        headers: {
          Authorization: `Bearer ${currentSession.access_token}`,
        }
      });

      if (error) {
        console.error('Checkout error:', error);
        let errorMessage = "Une erreur est survenue lors de la création de la session de paiement.";
        
        try {
          if (error.message) {
            const parsedError = JSON.parse(error.message);
            if (parsedError?.error) {
              errorMessage = parsedError.error;
            }
          }
          else if (error.body) {
            const parsedBody = JSON.parse(error.body);
            if (parsedBody?.error) {
              errorMessage = parsedBody.error;
            }
          }
        } catch (e) {
          console.error('Error parsing error message:', e);
          if (typeof error.message === 'string') {
            errorMessage = error.message;
          }
        }
        
        toast.error(errorMessage);
        return;
      }

      if (data?.url) {
        window.location.href = data.url;
      } else {
        toast.error("URL de paiement invalide");
      }
    } catch (error) {
      console.error('Subscription error:', error);
      toast.error("Une erreur est survenue lors de la création de la session de paiement");
    } finally {
      setIsProcessing(false);
    }
  };

  const handleCancelSubscription = async () => {
    try {
      if (isProcessing) return;
      setIsProcessing(true);

      const { data: { session: currentSession }, error: sessionError } = await supabase.auth.getSession();
      
      if (sessionError || !currentSession?.access_token) {
        console.error('Session error:', sessionError);
        toast.error("Erreur d'authentification");
        return;
      }

      const { error } = await supabase.functions.invoke('cancel-subscription', {
        headers: {
          Authorization: `Bearer ${currentSession.access_token}`,
        }
      });
      
      if (error) {
        console.error('Cancel subscription error:', error);
        let errorMessage = "Une erreur est survenue lors de l'annulation de l'abonnement.";
        try {
          if (error.message) {
            const parsedError = JSON.parse(error.message);
            if (parsedError?.error) {
              errorMessage = parsedError.error;
            }
          }
        } catch (e) {
          errorMessage = error.message || errorMessage;
        }
        
        toast.error(errorMessage);
        return;
      }
      
      toast.success("Abonnement annulé", {
        description: "Votre abonnement sera actif jusqu'à la fin de la période en cours."
      });
    } catch (error) {
      console.error('Error canceling subscription:', error);
      toast.error("Une erreur est survenue lors de l'annulation de l'abonnement");
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <SubscriptionInfo 
      subscription={subscription} 
      onSubscribe={handleSubscribe} 
      onCancel={handleCancelSubscription}
      isLoading={isLoading || isProcessing}
    />
  );
};