import { ScrollArea } from "@/components/ui/scroll-area";
import { Chapter } from "@/types/course";
import { NavigationItem } from "./navigation/NavigationItem";
import { NavigationButtons } from "./navigation/NavigationButtons";
import { useCourseContentNavigation } from "@/hooks/useCourseContentNavigation";
import { useQuery } from "@tanstack/react-query";
import { supabase } from "@/integrations/supabase/client";

interface CourseNavigationProps {
  chapters: Chapter[];
  currentChapterId: string;
  onChapterChange: (chapterId: string) => void;
  courseId: string;
}

export const CourseNavigation = ({
  chapters,
  currentChapterId,
  onChapterChange,
  courseId,
}: CourseNavigationProps) => {
  const {
    sortedContent,
    currentIndex,
    isLastChapter,
    handlePrevious,
    handleNext,
    handleItemClick,
  } = useCourseContentNavigation({
    chapters,
    currentChapterId,
    courseId,
    onChapterChange,
  });

  // Récupérer les chapitres complétés
  const { data: completedChapters } = useQuery({
    queryKey: ["completed-chapters", courseId],
    queryFn: async () => {
      const { data: { session } } = await supabase.auth.getSession();
      if (!session?.user?.id) return [];

      const { data, error } = await supabase
        .from("chapter_progress")
        .select("chapter_id")
        .eq("course_id", courseId)
        .eq("user_id", session.user.id)
        .not("completed_at", "is", null);

      if (error) {
        console.error("Error fetching completed chapters:", error);
        return [];
      }

      return data.map(progress => progress.chapter_id);
    },
    staleTime: 1000, // Rafraîchir toutes les secondes
  });

  return (
    <div className="flex flex-col h-full">
      <ScrollArea className="flex-1">
        <div className="space-y-1 p-4">
          {sortedContent.map((item, index) => (
            <NavigationItem
              key={item.id}
              id={item.id}
              title={item.title}
              type={item.type}
              index={index}
              isActive={item.type === 'chapter' && item.id === currentChapterId}
              isCompleted={item.type === 'chapter' && (completedChapters?.includes(item.id) || index < currentIndex)}
              onClick={() => handleItemClick(item)}
            />
          ))}
        </div>
      </ScrollArea>

      <NavigationButtons
        onPrevious={handlePrevious}
        onNext={handleNext}
        isFirstChapter={currentIndex === 0}
        isLastChapter={isLastChapter}
      />
    </div>
  );
};