import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { supabase } from "@/integrations/supabase/client";
import { Course } from "@/types/course";
import { toast } from "sonner";

export const useCourseOrder = () => {
  const queryClient = useQueryClient();

  const { data: courses, isLoading } = useQuery({
    queryKey: ["courses-order"],
    queryFn: async () => {
      console.log("Fetching courses for ordering...");
      const { data, error } = await supabase
        .from("courses")
        .select("id, title, content, image_url, status, slug, order_index")
        .order("order_index", { ascending: true });

      if (error) {
        console.error("Error fetching courses:", error);
        toast.error("Erreur lors du chargement des cours");
        throw error;
      }

      console.log("Fetched courses:", data);
      return data.map(course => ({
        ...course,
        chapters: [], // Non nécessaire pour l'ordre
        status: course.status as Course["status"],
        order_index: course.order_index || 0
      })) as Course[];
    },
  });

  const updateOrderMutation = useMutation({
    mutationFn: async ({ courseId, newOrder }: { courseId: string; newOrder: number }) => {
      console.log("Updating course order:", { courseId, newOrder });
      const { error } = await supabase
        .from("courses")
        .update({ order_index: newOrder })
        .eq("id", courseId);

      if (error) throw error;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["courses-order"] });
      toast.success("Ordre des cours mis à jour");
    },
    onError: (error) => {
      console.error("Error updating course order:", error);
      toast.error("Erreur lors de la mise à jour de l'ordre");
    },
  });

  const moveUp = async (currentIndex: number) => {
    if (!courses || currentIndex <= 0) return;
    
    const currentCourse = courses[currentIndex];
    const previousCourse = courses[currentIndex - 1];
    
    console.log("Moving course up:", {
      current: { id: currentCourse.id, order: currentCourse.order_index },
      previous: { id: previousCourse.id, order: previousCourse.order_index }
    });

    await Promise.all([
      updateOrderMutation.mutateAsync({
        courseId: currentCourse.id,
        newOrder: previousCourse.order_index
      }),
      updateOrderMutation.mutateAsync({
        courseId: previousCourse.id,
        newOrder: currentCourse.order_index
      })
    ]);
  };

  const moveDown = async (currentIndex: number) => {
    if (!courses || currentIndex >= courses.length - 1) return;
    
    const currentCourse = courses[currentIndex];
    const nextCourse = courses[currentIndex + 1];
    
    console.log("Moving course down:", {
      current: { id: currentCourse.id, order: currentCourse.order_index },
      next: { id: nextCourse.id, order: nextCourse.order_index }
    });

    await Promise.all([
      updateOrderMutation.mutateAsync({
        courseId: currentCourse.id,
        newOrder: nextCourse.order_index
      }),
      updateOrderMutation.mutateAsync({
        courseId: nextCourse.id,
        newOrder: currentCourse.order_index
      })
    ]);
  };

  return {
    courses,
    isLoading,
    moveUp,
    moveDown
  };
};