import { Header } from "@/components/layout/Header";
import { Footer } from "@/components/layout/Footer";
import { HeroSection } from "@/components/home/HeroSection";
import { DashboardParallax } from "@/components/home/DashboardParallax";
import { FeaturesSection } from "@/components/home/FeaturesSection";
import { StatsSection } from "@/components/home/StatsSection";
import { PricingSection } from "@/components/home/PricingSection";
import { BlogSection } from "@/components/home/BlogSection";
import { CTASection } from "@/components/home/CTASection";
import { useQuery } from "@tanstack/react-query";
import { supabase } from "@/integrations/supabase/client";
import { Helmet } from "react-helmet";

const Index = () => {
  const { data: posts } = useQuery({
    queryKey: ["recent-blog-posts"],
    queryFn: async () => {
      const { data, error } = await supabase
        .from("blog_posts")
        .select("*")
        .eq("status", "published")
        .order("created_at", { ascending: false })
        .limit(3);

      if (error) {
        console.error("Error fetching blog posts:", error);
        throw error;
      }

      return data;
    },
  });

  return (
    <div className="min-h-screen flex flex-col">
      <Helmet>
        <title>Bekane | Code moto gratuit en ligne</title>
        <meta name="description" content="Préparer votre ETM en ligne grâce à des cours exclusifs et des examens blancs corrigés." />
        <meta name="keywords" content="code moto, codemoto, etm moto, code de la route moto, code moto en ligne gratuit, permis moto code, code moto gratuit en ligne" />
        <meta name="author" content="Bekane" />
        <link rel="canonical" href="https://www.bekane.fr" />
      </Helmet>
      <Header />
      <main>
        <HeroSection />
        <DashboardParallax />
        <FeaturesSection />
        <StatsSection />
        <PricingSection />
        <BlogSection posts={posts} />
        <CTASection />
      </main>
      <Footer />
    </div>
  );
};

export default Index;