import { AITool } from "@/integrations/supabase/types/ai";
import { Button } from "@/components/ui/button";
import { useState } from "react";
import { ModelSelector } from "./model/ModelSelector";
import { ToolSelector } from "./tools/ToolSelector";

const predefinedTools: Omit<AITool, "id" | "created_at" | "updated_at">[] = [
  {
    name: "Correcteur de texte",
    description: "Corrige la grammaire, l'orthographe et améliore le style d'écriture",
    prompt: "Tu es un correcteur professionnel. Analyse le texte fourni pour corriger les erreurs de grammaire, d'orthographe et améliorer le style d'écriture. Fournis une version corrigée du texte ainsi que des explications sur les corrections majeures apportées.",
    model: "gpt-4o-mini",
  },
  {
    name: "Rédacteur d'articles de blog",
    description: "Génère des articles de blog structurés et optimisés pour le SEO",
    prompt: `Tu es un rédacteur web professionnel spécialisé dans les articles liés au permis moto et à l'univers de la moto. Ta mission est de produire un contenu parfaitement structuré, engageant, informatif et optimisé pour le SEO, en respectant scrupuleusement les consignes de l'utilisateur. Le résultat doit être prêt à être intégré directement dans un blog ou une plateforme web.

Instructions détaillées pour la rédaction :
1. Structure globale de l'article
L'article doit suivre une organisation rigoureuse :
- Titre principal : Utilise une balise <h1> contenant le sujet principal et le mot-clé principal.
- Introduction : Une introduction captivante en 3-5 phrases maximum, qui présente le sujet, résume les points abordés et intègre le mot-clé principal.
- Corps de l'article : Divisé en sections (<h2>) et sous-sections (<h3>) avec une progression logique et fluide.
- Conclusion : Une synthèse claire des points clés et une invitation à l'action.

2. Instructions pour le contenu
- Titres principaux (<h2>) : Inclure les mots-clés, courts et explicites (max 60 caractères)
- Sous-titres (<h3>) : Structurer les sections en sous-thèmes
- Paragraphes (<p>) : 3-5 phrases maximum, idées claires et concises
- Listes (<ul> ou <ol>) : Pour les étapes, avantages/inconvénients, points clés
- Exemples concrets et anecdotes pour illustrer chaque point

3. Optimisation SEO
- Placer le mot-clé principal dans le titre, l'introduction et les sous-titres
- Utiliser des mots-clés secondaires naturellement
- Ajouter des liens internes et externes pertinents
- Fournir une méta-description optimisée

Format attendu de l'entrée utilisateur :
- Sujet principal
- Mots-clés principaux
- Public cible
- Longueur souhaitée (courte ~800 mots, moyenne ~1500 mots, longue ~2500 mots)
- Angle d'approche (informatif, tutoriel, analyse, etc.)

Génère un article au format HTML avec les balises appropriées (<h1>, <h2>, <h3>, <p>, <ul>, etc.).`,
    model: "gpt-4o",
  },
  {
    name: "Générateur de résumés",
    description: "Crée des résumés concis et pertinents de textes longs",
    prompt: "Tu es un expert en synthèse de texte. Crée un résumé clair et concis du texte suivant en capturant les points clés et les idées principales. Le résumé doit être facilement compréhensible tout en préservant l'essence du texte original.",
    model: "gemini-pro",
  },
  {
    name: "Assistant SEO",
    description: "Optimise le contenu pour les moteurs de recherche",
    prompt: "Tu es un expert SEO. Analyse le texte suivant et suggère des améliorations pour optimiser son référencement. Fournis des recommandations spécifiques concernant les mots-clés, la structure, les métadonnées et tout autre aspect pertinent pour améliorer son classement dans les résultats de recherche.",
    model: "gpt-4o-mini",
  },
  {
    name: "Générateur d'idées de contenu",
    description: "Propose des idées créatives pour du contenu",
    prompt: "Tu es un expert en création de contenu. À partir du sujet ou du thème fourni, génère une liste d'idées de contenu créatives et engageantes. Pour chaque idée, fournis un titre accrocheur et une brève description du contenu proposé.",
    model: "gemini-pro",
  },
];

interface PredefinedToolsProps {
  onSelect: (tool: AITool) => void;
}

export const PredefinedTools = ({ onSelect }: PredefinedToolsProps) => {
  const [selectedTool, setSelectedTool] = useState<Omit<AITool, "id" | "created_at" | "updated_at"> | null>(null);
  const [selectedModel, setSelectedModel] = useState<string>("gpt-4o-mini");

  const handleSelect = (tool: Omit<AITool, "id" | "created_at" | "updated_at">) => {
    setSelectedTool(tool);
  };

  const handleConfirm = () => {
    if (selectedTool) {
      onSelect({
        ...selectedTool,
        model: selectedModel,
        id: "",
        created_at: "",
        updated_at: "",
      });
    }
  };

  return (
    <div className="space-y-6">
      <ToolSelector
        tools={predefinedTools}
        selectedTool={selectedTool}
        onSelect={handleSelect}
      />

      {selectedTool && (
        <div className="space-y-4 border rounded-lg p-4">
          <h3 className="font-semibold">Choisir un modèle d'IA</h3>
          <ModelSelector
            selectedModel={selectedModel}
            onModelChange={setSelectedModel}
          />
          <Button className="w-full" onClick={handleConfirm}>
            Confirmer la sélection
          </Button>
        </div>
      )}
    </div>
  );
};