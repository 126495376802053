import { Badge } from "@/components/ui/badge";
import { cn } from "@/lib/utils";

type CourseStatus = "draft" | "pending_validation" | "published";

const statusConfig = {
  draft: {
    label: "Brouillon",
    variant: "secondary" as const,
  },
  pending_validation: {
    label: "En attente",
    variant: "outline" as const,
  },
  published: {
    label: "Publié",
    variant: "default" as const,
  },
};

interface CourseStatusBadgeProps {
  status: CourseStatus;
  className?: string;
}

export const CourseStatusBadge = ({ status, className }: CourseStatusBadgeProps) => {
  const config = statusConfig[status];
  
  return (
    <Badge variant={config.variant} className={cn(className)}>
      {config.label}
    </Badge>
  );
};