import StarterKit from '@tiptap/starter-kit';
import Color from '@tiptap/extension-color';
import TextStyle from '@tiptap/extension-text-style';
import Highlight from '@tiptap/extension-highlight';
import Image from '@tiptap/extension-image';
import Link from '@tiptap/extension-link';
import Underline from '@tiptap/extension-underline';
import TextAlign from '@tiptap/extension-text-align';
import Table from '@tiptap/extension-table';
import TableRow from '@tiptap/extension-table-row';
import TableCell from '@tiptap/extension-table-cell';
import TableHeader from '@tiptap/extension-table-header';
import Youtube from '@tiptap/extension-youtube';
import Heading from '@tiptap/extension-heading';
import FontFamily from '@tiptap/extension-font-family';
import { Video } from '../extensions/Video';
import History from '@tiptap/extension-history';

export const getExtensions = () => [
  StarterKit.configure({
    heading: false,
    history: false,
    bulletList: {
      HTMLAttributes: {
        class: 'list-disc list-inside ml-4 space-y-1',
      },
    },
    orderedList: {
      HTMLAttributes: {
        class: 'list-decimal list-inside ml-4 space-y-1',
      },
    },
  }),
  History.configure({
    depth: 100,
    newGroupDelay: 500,
  }),
  TextStyle,
  Color,
  Highlight.configure({
    multicolor: true,
  }),
  FontFamily.configure({
    types: ['textStyle'],
  }),
  Image.configure({
    HTMLAttributes: {
      class: 'rounded-lg max-w-full h-auto',
    },
    allowBase64: true,
  }),
  Link.configure({
    openOnClick: false,
    HTMLAttributes: {
      class: 'text-primary hover:text-primary/80 underline',
    },
  }),
  Underline,
  TextAlign.configure({
    types: ['heading', 'paragraph'],
  }),
  Table.configure({
    resizable: true,
    HTMLAttributes: {
      class: 'border-collapse table-auto w-full border border-gray-300',
    },
  }),
  TableRow.configure({
    HTMLAttributes: {
      class: 'border-b border-gray-300',
    },
  }),
  TableCell.configure({
    HTMLAttributes: {
      class: 'border border-gray-300 p-2',
    },
  }),
  TableHeader.configure({
    HTMLAttributes: {
      class: 'border border-gray-300 p-2 bg-gray-100 font-bold',
    },
  }),
  Youtube.configure({
    HTMLAttributes: {
      class: 'w-full aspect-video rounded-lg my-4',
    },
    width: 640,
    height: 480,
    nocookie: true,
    modestBranding: true,
  }),
  Video,
  Heading.configure({
    levels: [1, 2, 3, 4, 5],
    HTMLAttributes: {
      class: (level) => {
        const classes = {
          1: 'text-4xl font-bold mb-6 text-primary',
          2: 'text-3xl font-semibold mb-4 text-secondary',
          3: 'text-2xl font-medium mb-3',
          4: 'text-xl font-medium mb-2',
          5: 'text-lg font-medium mb-2 text-muted',
        };
        return classes[level as keyof typeof classes] || '';
      }
    },
  }),
];