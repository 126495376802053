import { Course, CourseStatus } from "@/types/course";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table";
import { CourseStatusBadge } from "./CourseStatusBadge";
import { Button } from "@/components/ui/button";
import { Edit, Eye } from "lucide-react";
import { CourseStatusSelect } from "./CourseStatusSelect";
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "@/components/ui/sheet";
import { CoursePreview } from "./CoursePreview";

interface CourseListViewProps {
  courses: Course[];
  onEdit: (course: Course) => void;
  onStatusChange: (courseId: string, status: CourseStatus) => void;
}

export const CourseListView = ({ courses, onEdit, onStatusChange }: CourseListViewProps) => {
  return (
    <div className="rounded-md border">
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead>Titre</TableHead>
            <TableHead>Chapitres</TableHead>
            <TableHead>Statut</TableHead>
            <TableHead>Actions</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {courses.map((course) => (
            <TableRow key={course.id}>
              <TableCell className="font-medium">
                <div className="flex items-center gap-4">
                  {course.image_url && (
                    <img
                      src={course.image_url}
                      alt={course.title}
                      className="h-12 w-12 object-cover rounded"
                    />
                  )}
                  <div>
                    <p className="font-semibold">{course.title}</p>
                    <p className="text-sm text-muted-foreground line-clamp-2 max-w-[500px]">
                      {course.content?.replace(/<[^>]*>/g, '').substring(0, 150)}
                      {course.content && course.content.length > 150 ? '...' : ''}
                    </p>
                  </div>
                </div>
              </TableCell>
              <TableCell>
                {course.chapters?.length || 0} chapitre{course.chapters?.length !== 1 ? 's' : ''}
              </TableCell>
              <TableCell>
                <div className="space-y-2">
                  <CourseStatusBadge status={course.status} />
                  <CourseStatusSelect
                    status={course.status}
                    onStatusChange={(newStatus) => onStatusChange(course.id, newStatus)}
                  />
                </div>
              </TableCell>
              <TableCell>
                <div className="flex items-center gap-2">
                  <Button
                    variant="outline"
                    size="sm"
                    onClick={() => onEdit(course)}
                  >
                    <Edit className="w-4 h-4 mr-2" />
                    Modifier
                  </Button>
                  <Sheet>
                    <SheetTrigger asChild>
                      <Button variant="outline" size="sm">
                        <Eye className="w-4 h-4 mr-2" />
                        Aperçu
                      </Button>
                    </SheetTrigger>
                    <SheetContent side="right" className="w-[90vw] sm:w-[45vw]">
                      <SheetHeader>
                        <SheetTitle>Aperçu du cours</SheetTitle>
                        <SheetDescription>
                          Visualisez le cours et ses chapitres
                        </SheetDescription>
                      </SheetHeader>
                      <div className="mt-4">
                        <CoursePreview course={course} />
                      </div>
                    </SheetContent>
                  </Sheet>
                </div>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};