import { ReactNode } from "react";
import { Sidebar } from "./Sidebar";

interface DashboardLayoutProps {
  children: ReactNode;
  type: "admin" | "student";
}

export const DashboardLayout = ({ children, type }: DashboardLayoutProps) => {
  return (
    <div className="min-h-screen flex">
      <Sidebar type={type} />
      <main className="flex-1 p-8 bg-white">
        <div className="max-w-7xl mx-auto animate-in">
          {children}
        </div>
      </main>
    </div>
  );
};