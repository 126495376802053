import { Button } from "@/components/ui/button";
import { Plus } from "lucide-react";

interface CoursesHeaderProps {
  onCreateCourse: () => void;
}

export const CoursesHeader = ({ onCreateCourse }: CoursesHeaderProps) => {
  return (
    <div className="flex justify-between items-center">
      <div>
        <h1 className="text-3xl font-bold">Gestion des cours</h1>
        <p className="text-muted-foreground mt-2">
          Créez et gérez vos cours et leurs sous-thèmes
        </p>
      </div>
      <Button onClick={onCreateCourse}>
        <Plus className="w-4 h-4 mr-2" />
        Nouveau cours
      </Button>
    </div>
  );
};