import { Check } from "lucide-react";
import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";

interface PricingPlanProps {
  name: string;
  description: string;
  price: string;
  period: string;
  features: string[];
  planType: string;
  featured: boolean;
  onSubscribe: (planType: string) => void;
}

export const PricingPlan = ({
  name,
  description,
  price,
  period,
  features,
  planType,
  featured,
  onSubscribe,
}: PricingPlanProps) => {
  return (
    <Card 
      className={`animate-fadeIn ${featured ? 'border-primary shadow-lg' : ''}`}
    >
      <CardHeader>
        <CardTitle className="text-black">{name}</CardTitle>
        <CardDescription className="text-black">{description}</CardDescription>
      </CardHeader>
      <CardContent>
        <div className="mb-4 text-black">
          <span className="text-4xl font-bold">{price}€</span>
          <span className="text-black">/{period}</span>
        </div>
        <ul className="space-y-3">
          {features.map((feature, i) => (
            <li key={i} className="flex items-center gap-2 text-black">
              <Check className="h-4 w-4 text-primary" />
              <span>{feature}</span>
            </li>
          ))}
        </ul>
      </CardContent>
      <CardFooter>
        <Button 
          className="w-full" 
          variant={featured ? "default" : "outline"}
          onClick={() => onSubscribe(planType)}
        >
          Choisir cette formule
        </Button>
      </CardFooter>
    </Card>
  );
};