import { Link } from "react-router-dom";
import { Button } from "@/components/ui/button";
import { LogIn, LogOut } from "lucide-react";
import { supabase } from "@/integrations/supabase/client";
import { toast } from "sonner";
import { useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { useSession } from "@supabase/auth-helpers-react";

interface AuthButtonsProps {
  className?: string;
  onLogout?: () => void;
}

export const AuthButtons = ({ className = "", onLogout }: AuthButtonsProps) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const session = useSession();

  const handleLogout = async () => {
    try {
      const { error } = await supabase.auth.signOut();
      if (error) throw error;
      
      queryClient.clear();
      onLogout?.();
      toast.success("Déconnexion réussie");
      navigate("/auth/login");
    } catch (error) {
      console.error("Erreur lors de la déconnexion:", error);
      toast.error("Erreur lors de la déconnexion");
    }
  };

  return (
    <div className={className}>
      {session ? (
        <>
          <Link to="/student/dashboard">
            <Button variant="outline" className="w-full justify-center">
              Mon espace
            </Button>
          </Link>
          <Button 
            variant="ghost" 
            onClick={handleLogout}
            className="w-full justify-center flex items-center gap-2"
          >
            <LogOut className="h-4 w-4" />
            Déconnexion
          </Button>
        </>
      ) : (
        <Link to="/auth/login" className="w-full">
          <Button className="w-full justify-center flex items-center gap-2">
            <LogIn className="h-4 w-4" />
            Connexion
          </Button>
        </Link>
      )}
    </div>
  );
};