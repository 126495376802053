import { Header } from "@/components/layout/Header";
import { Footer } from "@/components/layout/Footer";
import { Award, BookOpen, Users, Heart, Building, Globe } from "lucide-react";
import { Card, CardContent } from "@/components/ui/card";
import { Helmet } from "react-helmet";

const About = () => {
  return (
    <div className="min-h-screen flex flex-col">
      <Helmet>
        <title>À propos | Bekane - Formation code moto en ligne</title>
        <meta name="description" content="Découvrez Bekane, votre plateforme de formation au code de la route moto. Notre mission est de vous accompagner vers la réussite de votre ETM avec des cours adaptés et un support personnalisé." />
        <meta name="keywords" content="bekane, formation moto, code moto en ligne, etm moto, à propos bekane" />
        <meta name="author" content="Bekane" />
        <meta property="og:title" content="À propos | Bekane - Formation code moto en ligne" />
        <meta property="og:description" content="Découvrez Bekane, votre plateforme de formation au code de la route moto. Notre mission est de vous accompagner vers la réussite." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.bekane.fr/about" />
        <link rel="canonical" href="https://www.bekane.fr/about" />
        {/* Google Tag Manager */}
        <script>
          {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-WNTHDK2Z');`}
        </script>
      </Helmet>
      <Header />
      <main className="flex-grow">
        {/* Google Tag Manager (noscript) */}
        <noscript>
          <iframe 
            src="https://www.googletagmanager.com/ns.html?id=GTM-WNTHDK2Z"
            height="0" 
            width="0" 
            style={{ display: 'none', visibility: 'hidden' }}
          />
        </noscript>
        {/* Hero Section */}
        <section className="bg-gradient-to-b from-gray-50 to-white py-20">
          <div className="container mx-auto px-4">
            <div className="max-w-3xl mx-auto text-center animate-fadeIn">
              <h1 className="text-4xl font-bold mb-6">Notre Histoire et Notre Mission</h1>
              <p className="text-xl text-gray-600 leading-relaxed">
                Depuis notre création, Bekane s'engage à transformer l'apprentissage du code de la route moto 
                en une expérience accessible, interactive et efficace pour tous les futurs motards.
              </p>
            </div>
          </div>
        </section>

        {/* Values Section */}
        <section className="py-16">
          <div className="container mx-auto px-4">
            <h2 className="text-3xl font-bold text-center mb-12">Nos Valeurs</h2>
            <div className="grid md:grid-cols-3 gap-8">
              <Card className="hover:shadow-lg transition-shadow animate-fadeIn">
                <CardContent className="p-6 text-center">
                  <Heart className="w-12 h-12 mx-auto mb-4 text-primary" />
                  <h3 className="text-xl font-semibold mb-3">Passion</h3>
                  <p className="text-gray-600">
                    Notre passion pour la moto guide chacune de nos actions pour vous offrir la meilleure formation possible.
                  </p>
                </CardContent>
              </Card>
              <Card className="hover:shadow-lg transition-shadow animate-fadeIn [animation-delay:200ms]">
                <CardContent className="p-6 text-center">
                  <BookOpen className="w-12 h-12 mx-auto mb-4 text-primary" />
                  <h3 className="text-xl font-semibold mb-3">Excellence</h3>
                  <p className="text-gray-600">
                    Nous visons l'excellence dans nos contenus pédagogiques pour garantir votre réussite.
                  </p>
                </CardContent>
              </Card>
              <Card className="hover:shadow-lg transition-shadow animate-fadeIn [animation-delay:400ms]">
                <CardContent className="p-6 text-center">
                  <Users className="w-12 h-12 mx-auto mb-4 text-primary" />
                  <h3 className="text-xl font-semibold mb-3">Communauté</h3>
                  <p className="text-gray-600">
                    Nous créons une communauté solidaire d'apprenants et d'experts passionnés.
                  </p>
                </CardContent>
              </Card>
            </div>
          </div>
        </section>

        {/* Features Section */}
        <section className="bg-gray-50 py-16">
          <div className="container mx-auto px-4">
            <h2 className="text-3xl font-bold text-center mb-12">Ce Qui Nous Distingue</h2>
            <div className="grid md:grid-cols-2 gap-12">
              <div className="space-y-8">
                <div className="flex items-start space-x-4 animate-slideIn">
                  <Award className="w-8 h-8 text-primary flex-shrink-0" />
                  <div>
                    <h3 className="text-xl font-semibold mb-2">Formation Conforme</h3>
                    <p className="text-gray-600">
                      Nos programmes sont alignés sur le contenu officiel de l'examen du code moto.
                    </p>
                  </div>
                </div>
                <div className="flex items-start space-x-4 animate-slideIn [animation-delay:200ms]">
                  <Building className="w-8 h-8 text-primary flex-shrink-0" />
                  <div>
                    <h3 className="text-xl font-semibold mb-2">Infrastructure Moderne</h3>
                    <p className="text-gray-600">
                      Une plateforme d'apprentissage innovante et des outils pédagogiques de pointe.
                    </p>
                  </div>
                </div>
                <div className="flex items-start space-x-4 animate-slideIn [animation-delay:400ms]">
                  <Globe className="w-8 h-8 text-primary flex-shrink-0" />
                  <div>
                    <h3 className="text-xl font-semibold mb-2">Accessibilité</h3>
                    <p className="text-gray-600">
                      Une formation disponible partout et à tout moment, adaptée à votre rythme.
                    </p>
                  </div>
                </div>
              </div>
              <div className="relative">
                <img 
                  src="https://images.unsplash.com/photo-1486312338219-ce68d2c6f44d"
                  alt="Personne étudiant sur un ordinateur portable" 
                  className="rounded-lg shadow-xl w-full h-full object-cover"
                />
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </div>
  );
};

export default About;
