import { Card } from "@/components/ui/card";
import { Checkbox } from "@/components/ui/checkbox";
import { Badge } from "@/components/ui/badge";

interface SourceItemProps {
  title: string;
  source: string;
  relevance: number;
  recommended: boolean;
  isSelected: boolean;
  onSelect: (title: string) => void;
}

export const SourceItem = ({
  title,
  source,
  relevance,
  recommended,
  isSelected,
  onSelect,
}: SourceItemProps) => {
  return (
    <Card className="p-4">
      <div className="flex items-start gap-4">
        <Checkbox
          checked={isSelected}
          onCheckedChange={() => onSelect(title)}
        />
        <div className="flex-1">
          <div className="flex items-center gap-2">
            <h3 className="font-medium">{title}</h3>
            {recommended && (
              <Badge variant="secondary">Recommandé</Badge>
            )}
          </div>
          <p className="text-sm text-muted-foreground">{source}</p>
          <p className="text-sm">Pertinence: {relevance}%</p>
        </div>
      </div>
    </Card>
  );
};