import { useEditor, EditorContent } from '@tiptap/react';
import { EditorToolbar } from './EditorToolbar';
import { useEffect, useCallback, useState } from 'react';
import { getExtensions } from './config/extensions';
import { toast } from 'sonner';
import { Button } from '../ui/button';
import { Maximize2, Minimize2 } from 'lucide-react';

interface TiptapProps {
  content: string;
  onChange: (content: string) => void;
  folder?: string;
}

export const Tiptap = ({ content, onChange, folder }: TiptapProps) => {
  const [isFullscreen, setIsFullscreen] = useState(false);
  const editor = useEditor({
    extensions: getExtensions(),
    content,
    onUpdate: ({ editor }) => {
      onChange(editor.getHTML());
    },
    editorProps: {
      handleDrop: (view, event, slice, moved) => {
        if (!moved && event.dataTransfer?.files.length) {
          const files = Array.from(event.dataTransfer.files);
          const images = files.filter(file => file.type.startsWith('image/'));
          const videos = files.filter(file => file.type.startsWith('video/'));
          
          if (images.length || videos.length) {
            event.preventDefault();
            [...images, ...videos].forEach(file => {
              const reader = new FileReader();
              reader.onload = (readerEvent) => {
                if (readerEvent.target?.result) {
                  if (file.type.startsWith('image/')) {
                    view.dispatch(view.state.tr.replaceSelectionWith(
                      view.state.schema.nodes.image.create({
                        src: readerEvent.target.result
                      })
                    ));
                  } else if (file.type.startsWith('video/')) {
                    view.dispatch(view.state.tr.replaceSelectionWith(
                      view.state.schema.nodes.video.create({
                        src: readerEvent.target.result
                      })
                    ));
                  }
                }
              };
              reader.readAsDataURL(file);
            });
            return true;
          }
        }
        return false;
      },
      handleKeyDown: (view, event) => {
        // Raccourcis clavier personnalisés
        if (event.ctrlKey || event.metaKey) {
          switch(event.key) {
            case 'b':
              event.preventDefault();
              editor?.chain().focus().toggleBold().run();
              return true;
            case 'i':
              event.preventDefault();
              editor?.chain().focus().toggleItalic().run();
              return true;
            case 'u':
              event.preventDefault();
              editor?.chain().focus().toggleUnderline().run();
              return true;
            case '1':
              event.preventDefault();
              editor?.chain().focus().toggleHeading({ level: 1 }).run();
              return true;
            case '2':
              event.preventDefault();
              editor?.chain().focus().toggleHeading({ level: 2 }).run();
              return true;
            case '3':
              event.preventDefault();
              editor?.chain().focus().toggleHeading({ level: 3 }).run();
              return true;
          }
        }
        return false;
      },
    },
  });

  // Sauvegarde automatique
  const autoSave = useCallback(() => {
    if (editor) {
      localStorage.setItem('editor-content', editor.getHTML());
      toast.success('Contenu sauvegardé automatiquement');
    }
  }, [editor]);

  useEffect(() => {
    const interval = setInterval(autoSave, 60000); // Sauvegarde toutes les minutes
    return () => clearInterval(interval);
  }, [autoSave]);

  useEffect(() => {
    if (editor && content !== editor.getHTML()) {
      editor.commands.setContent(content);
    }
  }, [content, editor]);

  useEffect(() => {
    const handleEsc = (event: KeyboardEvent) => {
      if (event.key === 'Escape' && isFullscreen) {
        setIsFullscreen(false);
      }
    };
    window.addEventListener('keydown', handleEsc);
    return () => window.removeEventListener('keydown', handleEsc);
  }, [isFullscreen]);

  if (!editor) {
    return null;
  }

  return (
    <div className={`border rounded-lg overflow-hidden relative ${isFullscreen ? 'fixed inset-0 z-50 bg-background' : ''}`}>
      <div className="sticky top-0 z-10 bg-background">
        <EditorToolbar editor={editor} folder={folder} />
      </div>
      <div className={`prose prose-sm max-w-none overflow-y-auto ${isFullscreen ? 'h-[calc(100vh-4rem)] p-8' : 'min-h-[600px] p-4'}`}>
        <EditorContent editor={editor} />
      </div>
      <Button
        variant="ghost"
        size="sm"
        className="absolute top-2 right-2"
        onClick={() => setIsFullscreen(!isFullscreen)}
      >
        {isFullscreen ? <Minimize2 className="h-4 w-4" /> : <Maximize2 className="h-4 w-4" />}
      </Button>
    </div>
  );
};