import { useState } from "react";
import { cn } from "@/lib/utils";
import { SidebarHeader } from "./sidebar/SidebarHeader";
import { SidebarNav } from "./sidebar/SidebarNav";
import { SidebarFooter } from "./sidebar/SidebarFooter";
import { MobileMenuButton } from "./sidebar/MobileMenuButton";

interface SidebarProps {
  type: "admin" | "student";
}

export const Sidebar = ({ type }: SidebarProps) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <MobileMenuButton isOpen={isOpen} onClick={() => setIsOpen(!isOpen)} />

      <div
        className={cn(
          "fixed inset-y-0 left-0 w-64 bg-background border-r transform transition-transform duration-200 ease-in-out lg:translate-x-0 lg:static lg:z-0",
          {
            "translate-x-0 z-40": isOpen,
            "-translate-x-full": !isOpen,
          }
        )}
      >
        <div className="flex flex-col h-full">
          <SidebarHeader />
          <SidebarNav type={type} />
          <SidebarFooter />
        </div>
      </div>
    </>
  );
};