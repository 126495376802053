import { Link } from "react-router-dom";

export const SidebarHeader = () => {
  return (
    <div className="flex items-center justify-between h-16 px-4 border-b">
      <Link to="/" className="flex items-center">
        <img 
          src="/lovable-uploads/cf83c9da-55bd-4749-b59b-22399ff0630f.png" 
          alt="Bekane" 
          className="h-12 w-auto" // Augmenté de h-8 à h-12
        />
      </Link>
    </div>
  );
};