import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Label } from "@/components/ui/label";
import { Input } from "@/components/ui/input";
import { Tiptap } from "@/components/editor/Tiptap";
import { ImageUpload } from "../../ImageUpload";
import { CourseStatusSelect } from "../../CourseStatusSelect";
import { ContentSectionProps } from "./types";

export const ContentSection = ({
  title,
  content,
  imageUrl,
  orderIndex,
  duration,
  status,
  slug,
  onTitleChange,
  onContentChange,
  onImageUrlChange,
  onOrderIndexChange,
  onDurationChange,
  onStatusChange,
  onSlugChange,
}: ContentSectionProps) => {
  return (
    <Card>
      <CardHeader>
        <CardTitle>Contenu</CardTitle>
      </CardHeader>
      <CardContent className="space-y-6">
        {title !== undefined && (
          <div className="space-y-2">
            <Label htmlFor="title">Titre</Label>
            <Input
              id="title"
              value={title}
              onChange={onTitleChange}
              placeholder="Titre du chapitre"
            />
          </div>
        )}

        {slug !== undefined && (
          <div className="space-y-2">
            <Label htmlFor="slug">Slug</Label>
            <Input
              id="slug"
              value={slug}
              onChange={(e) => onSlugChange?.(e.target.value)}
              placeholder="slug-du-chapitre"
            />
          </div>
        )}

        {imageUrl !== undefined && (
          <div className="space-y-2">
            <Label>Image</Label>
            <ImageUpload
              currentImage={imageUrl}
              onUploadComplete={onImageUrlChange || (() => {})}
              folder="course-images"
            />
          </div>
        )}

        {orderIndex !== undefined && duration !== undefined && status !== undefined && (
          <div className="grid grid-cols-3 gap-4">
            <div className="space-y-2">
              <Label htmlFor="orderIndex">Ordre</Label>
              <Input
                id="orderIndex"
                type="number"
                value={orderIndex}
                onChange={onOrderIndexChange}
                min={0}
              />
            </div>

            <div className="space-y-2">
              <Label htmlFor="duration">Durée (min)</Label>
              <Input
                id="duration"
                type="number"
                value={duration}
                onChange={onDurationChange}
                min={1}
              />
            </div>

            <div className="space-y-2">
              <Label htmlFor="status">Statut</Label>
              <CourseStatusSelect
                status={status}
                onStatusChange={onStatusChange || (() => {})}
              />
            </div>
          </div>
        )}

        <div className="space-y-2">
          <Label>Contenu</Label>
          <Tiptap content={content} onChange={onContentChange} />
        </div>
      </CardContent>
    </Card>
  );
};