import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { supabase } from "@/integrations/supabase/client";
import { Header } from "@/components/layout/Header";
import { Footer } from "@/components/layout/Footer";
import { Loader2 } from "lucide-react";
import { Helmet } from "react-helmet";
import { format } from "date-fns";
import { fr } from "date-fns/locale";
import { AdSpace } from "@/components/blog/AdSpace";

const BlogPost = () => {
  const { slug } = useParams();

  const { data: post, isLoading } = useQuery({
    queryKey: ["blog-post", slug],
    queryFn: async () => {
      const { data: post, error } = await supabase
        .from("blog_posts")
        .select(`
          *,
          author:profiles(
            first_name,
            last_name
          )
        `)
        .eq("slug", slug)
        .maybeSingle();

      if (error) throw error;
      return post;
    },
  });

  if (isLoading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <Loader2 className="h-8 w-8 animate-spin" />
      </div>
    );
  }

  if (!post) {
    return (
      <div className="min-h-screen">
        <Header />
        <div className="container mx-auto px-4 py-16">
          <div className="text-center">
            <h1 className="text-2xl font-bold mb-4">Article non trouvé</h1>
            <p className="text-muted-foreground">
              L'article que vous recherchez n'existe pas ou a été supprimé.
            </p>
          </div>
        </div>
        <Footer />
      </div>
    );
  }

  const authorName = post.author 
    ? `${post.author.first_name || ''} ${post.author.last_name || ''}`.trim() 
    : 'Auteur inconnu';

  return (
    <div className="min-h-screen flex flex-col">
      <Helmet>
        <title>{post.seo_title || post.title} | Bekane</title>
        <meta name="description" content={post.meta_description || post.content?.substring(0, 155)} />
        {post.meta_keywords && <meta name="keywords" content={post.meta_keywords} />}
        <link rel="canonical" href={`https://www.bekane.fr/blog/${post.slug}`} />
      </Helmet>

      <Header />
      
      <main className="flex-grow bg-gray-50">
        <div className="container mx-auto px-4 py-8">
          <div className="flex flex-col lg:flex-row gap-8 max-w-[1400px] mx-auto">
            {/* Publicité gauche */}
            <div className="hidden lg:block w-[300px]">
              <AdSpace type="sidebar-left" />
            </div>

            {/* Article principal */}
            <article className="flex-1 min-w-0 bg-white rounded-lg shadow-lg">
              {/* Image hero */}
              {post.image_url && (
                <div className="relative w-full h-[400px]">
                  <img 
                    src={post.image_url} 
                    alt={post.title}
                    className="w-full h-full object-cover rounded-t-lg"
                  />
                </div>
              )}
              
              {/* Contenu */}
              <div className="p-8 md:p-12">
                <div className="max-w-3xl mx-auto">
                  <h1 className="text-4xl font-bold mb-6">{post.title}</h1>
                  <div className="flex items-center gap-4 text-sm text-muted-foreground mb-8">
                    <time dateTime={post.created_at}>
                      {format(new Date(post.created_at), "d MMMM yyyy", { locale: fr })}
                    </time>
                    {post.reading_time && (
                      <span>• {post.reading_time} min de lecture</span>
                    )}
                    <span>• Par {authorName}</span>
                  </div>
                  {/* Contenu HTML */}
                  <div 
                    className="prose prose-lg max-w-none"
                    dangerouslySetInnerHTML={{ __html: post.content || "" }} 
                  />
                </div>
              </div>
            </article>

            {/* Publicité droite */}
            <div className="hidden lg:block w-[300px]">
              <AdSpace type="sidebar-right" />
            </div>
          </div>
        </div>
      </main>

      <Footer />
    </div>
  );
};

export default BlogPost;

