import { useState } from "react";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { toast } from "sonner";
import { supabase } from "@/integrations/supabase/client";
import { Loader2 } from "lucide-react";

interface ImportArticleDialogProps {
  isOpen: boolean;
  onOpenChange: (open: boolean) => void;
  onImport: (content: {
    title: string;
    content: string;
    meta_description?: string;
    meta_keywords?: string;
  }) => void;
}

export const ImportArticleDialog = ({
  isOpen,
  onOpenChange,
  onImport,
}: ImportArticleDialogProps) => {
  const [url, setUrl] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleImport = async () => {
    if (!url) {
      toast.error("Veuillez entrer une URL");
      return;
    }

    setIsLoading(true);
    try {
      const { data, error } = await supabase.functions.invoke('import-article', {
        body: { url }
      });

      if (error) throw error;

      onImport(data);
      onOpenChange(false);
      toast.success("Article importé avec succès");
    } catch (error) {
      console.error("Error importing article:", error);
      toast.error("Erreur lors de l'importation de l'article");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Dialog open={isOpen} onOpenChange={onOpenChange}>
      <DialogContent className="sm:max-w-[600px]">
        <DialogHeader>
          <DialogTitle>Importer un article</DialogTitle>
        </DialogHeader>
        <div className="space-y-4 py-4">
          <Input
            value={url}
            onChange={(e) => setUrl(e.target.value)}
            placeholder="Entrez l'URL de l'article à importer..."
            className="w-full"
          />
          <div className="flex justify-end">
            <Button
              onClick={handleImport}
              disabled={isLoading || !url}
            >
              {isLoading ? (
                <>
                  <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                  Importation...
                </>
              ) : (
                "Importer"
              )}
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};