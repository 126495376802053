import { useState } from "react";
import { Button } from "@/components/ui/button";
import { toast } from "sonner";
import { supabase } from "@/integrations/supabase/client";
import { KeywordCard } from "./keyword-selection/KeywordCard";
import { parseSecondaryKeywords } from "./keyword-selection/secondaryKeywordParser";
import { Keyword } from "./keyword-selection/types";
import { ArticleData } from "../types";

interface SecondaryKeywordsProps {
  data: ArticleData;
  onComplete: (data: Partial<ArticleData>) => void;
}

export const SecondaryKeywords = ({ data, onComplete }: SecondaryKeywordsProps) => {
  const [keywords, setKeywords] = useState<Keyword[]>([]);
  const [selectedKeywords, setSelectedKeywords] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const analyzeKeywords = async () => {
    setIsLoading(true);
    try {
      const prompt = `Pour le titre "${data.selectedTitle}" et en utilisant les mots-clés principaux comme référence, analysez et suggérez une liste de 10 à 20 mots-clés secondaires complémentaires.

Mots-clés principaux sélectionnés:
${data.selectedKeywords?.map(kw => `- ${kw.keyword}`).join('\n')}

Pour chaque mot-clé secondaire, fournissez les informations suivantes au format suivant:

1. **Mot-clé** : [mot-clé]
   - **Volume** : [1-100]
   - **Difficulté** : [1-100]
   - **Intention** : [informatif/commercial/transactionnel]`;

      console.log("🔄 Envoi de la requête d'analyse des mots-clés secondaires:", {
        selectedTitle: data.selectedTitle,
        selectedKeywords: data.selectedKeywords,
        prompt
      });

      const { data: response, error } = await supabase.functions.invoke('generate', {
        body: {
          prompt,
          model: "gemini-1.5-pro"
        }
      });

      if (error) throw error;
      
      if (response && typeof response === 'object' && 'content' in response) {
        console.log("✅ Réponse reçue de l'IA:", response);
        const parsedKeywords = parseSecondaryKeywords(response.content);
        console.log("📝 Mots-clés secondaires analysés:", parsedKeywords);
        
        if (parsedKeywords.length === 0) {
          throw new Error("Aucun mot-clé n'a pu être extrait de la réponse");
        }
        
        setKeywords(parsedKeywords);
        toast.success("Mots-clés secondaires analysés avec succès");
      } else {
        throw new Error("Format de réponse inattendu");
      }
    } catch (error) {
      console.error("❌ Erreur lors de l'analyse des mots-clés secondaires:", error);
      toast.error("Erreur lors de l'analyse des mots-clés secondaires");
    } finally {
      setIsLoading(false);
    }
  };

  const handleKeywordSelection = (keyword: string) => {
    setSelectedKeywords(prev => 
      prev.includes(keyword)
        ? prev.filter(k => k !== keyword)
        : [...prev, keyword]
    );
  };

  const handleContinue = () => {
    const secondaryKeywords = keywords
      .filter(kw => selectedKeywords.includes(kw.keyword))
      .map(kw => ({
        keyword: kw.keyword,
        volume: kw.volume,
        difficulty: kw.difficulty,
        intent: kw.intent,
        recommended: kw.recommended
      }));
    console.log("✅ Mots-clés secondaires sélectionnés:", secondaryKeywords);
    onComplete({ secondaryKeywords });
  };

  return (
    <div className="space-y-6">
      <Button
        onClick={analyzeKeywords}
        disabled={isLoading}
        className="w-full"
      >
        {isLoading ? "Analyse des mots-clés secondaires en cours..." : "Analyser les mots-clés secondaires"}
      </Button>

      {keywords.length > 0 && (
        <div className="space-y-4">
          <div className="grid gap-4 grid-cols-1 md:grid-cols-2">
            {keywords.map((kw) => (
              <KeywordCard
                key={kw.keyword}
                keyword={kw}
                isSelected={selectedKeywords.includes(kw.keyword)}
                onSelect={() => handleKeywordSelection(kw.keyword)}
              />
            ))}
          </div>

          <Button
            onClick={handleContinue}
            disabled={selectedKeywords.length === 0}
            className="w-full"
          >
            Continuer avec les mots-clés secondaires ({selectedKeywords.length})
          </Button>
        </div>
      )}
    </div>
  );
};