import { TabsList, TabsTrigger } from "@/components/ui/tabs";

export const StepsNavigation = () => {
  return (
    <TabsList className="mb-4">
      <TabsTrigger value="1">Paramètres de base</TabsTrigger>
      <TabsTrigger value="2">Type d'article</TabsTrigger>
      <TabsTrigger value="3">Analyse des sources</TabsTrigger>
      <TabsTrigger value="4">Mots-clés</TabsTrigger>
      <TabsTrigger value="5">Titre</TabsTrigger>
      <TabsTrigger value="6">Mots-clés secondaires</TabsTrigger>
      <TabsTrigger value="7">Configuration</TabsTrigger>
      <TabsTrigger value="8">Plan</TabsTrigger>
      <TabsTrigger value="9">Finalisation</TabsTrigger>
    </TabsList>
  );
};