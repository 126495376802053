import { Button } from "@/components/ui/button";
import { Eye } from "lucide-react";
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "@/components/ui/sheet";
import { CoursePreview } from "./CoursePreview";
import { Course } from "@/types/course";

interface CourseFormHeaderProps {
  isEditing: boolean;
  previewData: Course;
}

export const CourseFormHeader = ({ isEditing, previewData }: CourseFormHeaderProps) => {
  return (
    <div className="flex justify-between items-center">
      <h1 className="text-2xl font-bold">
        {isEditing ? "Modifier le cours" : "Créer un nouveau cours"}
      </h1>
      <Sheet>
        <SheetTrigger asChild>
          <Button variant="outline" size="sm">
            <Eye className="h-4 w-4 mr-2" />
            Aperçu
          </Button>
        </SheetTrigger>
        <SheetContent side="right" className="w-[90vw] sm:w-[45vw]">
          <SheetHeader>
            <SheetTitle>Aperçu du cours</SheetTitle>
            <SheetDescription>
              Visualisez votre cours tel qu'il apparaîtra aux étudiants
            </SheetDescription>
          </SheetHeader>
          <div className="mt-4">
            <CoursePreview course={previewData} />
          </div>
        </SheetContent>
      </Sheet>
    </div>
  );
};