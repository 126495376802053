export const getSourceAnalysisPrompt = (subject: string) => `Pour le sujet "${subject}", analysez et suggérez 5 à 10 sources pertinentes et fiables.

Instructions détaillées :
1. Privilégiez les sources récentes (moins de 2 ans)
2. Incluez un mélange de types de sources :
   - Articles académiques
   - Publications spécialisées
   - Sites d'autorité dans le domaine
   - Études et statistiques
3. Évaluez chaque source selon :
   - La crédibilité (30%) : réputation, expertise, références
   - La pertinence (40%) : adéquation avec le sujet, profondeur
   - L'actualité (30%) : date de publication, mises à jour

Format de réponse :
- Titre : [titre complet]
- Source : [nom du site/publication]
- Pertinence : [note/100]
- Recommandée : [oui/non]
- Justification : [brève explication]`;