import { ImageOff } from "lucide-react";

interface EmptyStateProps {
  message: string;
}

export const EmptyState = ({ message }: EmptyStateProps) => {
  return (
    <div className="flex flex-col items-center justify-center p-8 text-muted-foreground">
      <ImageOff className="h-8 w-8 mb-2" />
      <p>{message}</p>
    </div>
  );
};