import { DashboardLayout } from "@/components/layout/DashboardLayout";
import { Helmet } from "react-helmet";
import { Users, BookOpen, CheckSquare, TrendingUp } from "lucide-react";
import { useAdminStats } from "@/hooks/useAdminStats";
import { StatCard } from "@/components/admin/stats/StatCard";
import { ProgressStats } from "@/components/admin/stats/ProgressStats";
import { EnrollmentTrends } from "@/components/admin/stats/EnrollmentTrends";

const AdminDashboard = () => {
  const { data: stats, isLoading } = useAdminStats();

  const statCards = [
    {
      title: "Utilisateurs",
      value: stats?.usersCount || 0,
      description: `${stats?.activeUsersCount || 0} utilisateurs actifs`,
      icon: Users,
    },
    {
      title: "Cours publiés",
      value: stats?.publishedCoursesCount || 0,
      description: `${stats?.chaptersCount || 0} chapitres au total`,
      icon: BookOpen,
    },
    {
      title: "Articles de blog",
      value: stats?.blogPostsCount || 0,
      description: "Articles publiés",
      icon: TrendingUp,
    },
  ];

  return (
    <DashboardLayout type="admin">
      <Helmet>
        <title>Administration | Bekane</title>
        <meta name="description" content="Tableau de bord d'administration Bekane" />
        <meta name="author" content="Bekane" />
        <meta name="robots" content="noindex,nofollow" />
      </Helmet>

      <div className="space-y-8">
        <h1 className="text-3xl font-bold">Tableau de bord</h1>

        <div className="grid gap-4 md:grid-cols-3">
          {statCards.map((stat) => (
            <StatCard
              key={stat.title}
              title={stat.title}
              value={stat.value}
              description={stat.description}
              icon={stat.icon}
              isLoading={isLoading}
            />
          ))}
        </div>

        <div className="grid gap-4 md:grid-cols-2">
          <ProgressStats
            isLoading={isLoading}
            progressStats={stats?.progressStats || {
              totalEnrollments: 0,
              completedCourses: 0,
              inProgressCourses: 0,
            }}
            completionRate={stats?.completionRate || 0}
          />
          <EnrollmentTrends
            isLoading={isLoading}
            trends={stats?.enrollmentTrends || []}
          />
        </div>
      </div>
    </DashboardLayout>
  );
};

export default AdminDashboard;