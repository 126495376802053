import { Course } from "@/types/course";
import { Card, CardContent, CardFooter, CardHeader } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { Badge } from "@/components/ui/badge";
import { BookOpen, Clock, CheckCircle2, RefreshCw } from "lucide-react";
import { Link } from "react-router-dom";
import { useCourseProgress, useResetCourseProgress } from "@/hooks/useCourseProgress";
import { cn } from "@/lib/utils";

interface StudentCourseCardProps {
  course: Course;
  onPreview: () => void;
  onClick: () => void;
}

export const StudentCourseCard = ({ course, onPreview, onClick }: StudentCourseCardProps) => {
  const { progress, isCompleted } = useCourseProgress(course.id);
  const resetProgress = useResetCourseProgress(course.id);
  const totalDuration = course.chapters?.reduce((acc, chapter) => acc + (chapter.duration || 15), 0) || 0;

  const handleReset = (e: React.MouseEvent) => {
    e.stopPropagation();
    resetProgress.mutate();
  };

  return (
    <Card 
      className={cn(
        "flex flex-col h-full hover:shadow-lg transition-shadow duration-300 cursor-pointer group relative",
        isCompleted && "border-green-500"
      )}
      onClick={onClick}
    >
      {course.image_url && (
        <div className="relative aspect-video">
          <img
            src={course.image_url}
            alt={course.title}
            className="object-cover w-full h-full rounded-t-lg"
          />
          {progress > 0 && (
            <div className="absolute bottom-0 left-0 w-full h-1 bg-gray-200">
              <div
                className={cn(
                  "h-full transition-all duration-300",
                  isCompleted ? "bg-green-500" : "bg-primary"
                )}
                style={{ width: `${progress}%` }}
              />
            </div>
          )}
          {isCompleted && (
            <div className="absolute top-2 right-2 bg-green-500 text-white p-2 rounded-full">
              <CheckCircle2 className="w-5 h-5" />
            </div>
          )}
        </div>
      )}
      
      <CardHeader>
        <div className="space-y-2">
          <h3 className="text-xl font-semibold line-clamp-2">{course.title}</h3>
          <div className="flex flex-wrap gap-2">
            <Badge variant="secondary" className="flex items-center gap-1">
              <BookOpen className="w-3 h-3" />
              {course.chapters?.length || 0} chapitre{course.chapters?.length !== 1 ? 's' : ''}
            </Badge>
            <Badge variant="outline" className="flex items-center gap-1">
              <Clock className="w-3 h-3" />
              {totalDuration} min
            </Badge>
          </div>
        </div>
      </CardHeader>

      <CardContent className="flex-grow">
        <p className="text-muted-foreground line-clamp-3">
          {course.content?.replace(/<[^>]*>/g, '')}
        </p>
      </CardContent>

      <CardFooter className="flex gap-2">
        <Link 
          to={`/student/courses/${course.slug}`} 
          className="flex-1"
          onClick={(e) => e.stopPropagation()}
        >
          <Button className="w-full" variant={isCompleted ? "outline" : "default"}>
            {isCompleted ? "Revoir le cours" : progress > 0 ? "Continuer" : "Commencer"}
          </Button>
        </Link>
        {isCompleted && (
          <Button
            variant="outline"
            size="icon"
            className="flex-shrink-0"
            onClick={handleReset}
            title="Recommencer le cours"
          >
            <RefreshCw className="w-4 h-4" />
          </Button>
        )}
      </CardFooter>
    </Card>
  );
};