import { useState } from 'react';
import { useLocation } from 'react-router-dom';

type AdState = 'loading' | 'blocked' | 'error' | 'loaded';
type AdType = 'sidebar-left' | 'sidebar-right' | 'content-start' | 'content-middle' | 'content-end';

export const useAdManager = (type: AdType) => {
  const [adState, setAdState] = useState<AdState>('loading');
  const location = useLocation();

  const isProtectedRoute = location.pathname.startsWith('/student/') || 
                          location.pathname.startsWith('/admin/');

  const getAdSlot = () => {
    switch(type) {
      case 'sidebar-left':
        return '2969771120';
      case 'sidebar-right':
        return '4522789274';
      case 'content-start':
        return '6119776124';
      case 'content-middle':
        return '2011912679';
      case 'content-end':
        return '3493612783';
      default:
        return '';
    }
  };

  const checkAdBlocker = () => {
    try {
      // Skip initialization in development environment
      if (window.location.hostname.includes('lovableproject.com')) {
        console.log('Skipping AdSense checks in development environment');
        setAdState('loaded');
        return false;
      }

      // Vérifier si AdSense est disponible
      if (typeof window.adsbygoogle === 'undefined') {
        console.log('AdSense not available');
        setAdState('blocked');
        return true;
      }

      // Par défaut, on autorise les annonces
      setAdState('loaded');
      return false;
    } catch (err) {
      console.warn('Error checking AdSense:', err);
      setAdState('error');
      return true;
    }
  };

  return {
    adState,
    setAdState,
    isProtectedRoute,
    checkAdBlocker,
    getAdSlot
  };
};