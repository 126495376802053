interface ParsedQuestion {
  question: string;
  answers: {
    text: string;
    isCorrect: boolean;
    explanation?: string;
  }[];
  explanation: string;
}

export const parseQuizFromText = (text: string): ParsedQuestion[] => {
  const questions: ParsedQuestion[] = [];
  const sections = text.split(/(?=### Question \d+)/);

  for (const section of sections) {
    if (!section.trim()) continue;

    const questionMatch = section.match(/\*\*Question :\*\* (.*?)(?=\*\*|$)/s);
    const answersMatch = section.match(/\*\*Réponses :\*\*\n((?:[\d]+\. .*\n?)*)/s);
    const correctAnswerMatch = section.match(/\*\*Réponse correcte :\*\* (\d+)/);
    const explanationMatch = section.match(/\*\*Explication :\*\* (.*?)(?=\n\n|$)/s);

    if (questionMatch && answersMatch && correctAnswerMatch && explanationMatch) {
      const question = questionMatch[1].trim();
      const answersText = answersMatch[1].trim();
      const correctAnswer = parseInt(correctAnswerMatch[1]);
      const explanation = explanationMatch[1].trim();

      const answers = answersText.split('\n').map((answer, index) => ({
        text: answer.replace(/^\d+\.\s*/, '').trim(),
        isCorrect: index + 1 === correctAnswer,
        explanation: index + 1 === correctAnswer ? explanation : undefined
      }));

      questions.push({
        question,
        answers,
        explanation
      });
    }
  }

  return questions;
};