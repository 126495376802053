import { Table, Plus, Minus, Trash2 } from 'lucide-react';
import { Editor } from '@tiptap/react';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Button } from '@/components/ui/button';

interface TableButtonsProps {
  editor: Editor;
}

export const TableButtons = ({ editor }: TableButtonsProps) => {
  const insertTable = (rows: number, cols: number) => {
    editor.chain().focus().insertTable({ rows, cols }).run();
  };

  const isTableSelected = editor.isActive('table');

  return (
    <div className="flex items-center gap-1">
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button 
            variant="ghost" 
            size="sm" 
            className="h-8 w-8 p-0"
            aria-label="Insérer un tableau"
          >
            <Table className="h-4 w-4" />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent>
          {!isTableSelected ? (
            <DropdownMenuSub>
              <DropdownMenuSubTrigger>
                <Plus className="h-4 w-4 mr-2" />
                Insérer un tableau
              </DropdownMenuSubTrigger>
              <DropdownMenuSubContent>
                {[2, 3, 4, 5].map((rows) => (
                  <DropdownMenuSub key={rows}>
                    <DropdownMenuSubTrigger>{rows} lignes</DropdownMenuSubTrigger>
                    <DropdownMenuSubContent>
                      {[2, 3, 4, 5].map((cols) => (
                        <DropdownMenuItem
                          key={cols}
                          onClick={() => insertTable(rows, cols)}
                        >
                          {cols} colonnes
                        </DropdownMenuItem>
                      ))}
                    </DropdownMenuSubContent>
                  </DropdownMenuSub>
                ))}
              </DropdownMenuSubContent>
            </DropdownMenuSub>
          ) : (
            <>
              <DropdownMenuItem onClick={() => editor.chain().focus().addColumnBefore().run()}>
                <Plus className="h-4 w-4 mr-2" />
                Ajouter une colonne avant
              </DropdownMenuItem>
              <DropdownMenuItem onClick={() => editor.chain().focus().addColumnAfter().run()}>
                <Plus className="h-4 w-4 mr-2" />
                Ajouter une colonne après
              </DropdownMenuItem>
              <DropdownMenuItem onClick={() => editor.chain().focus().deleteColumn().run()}>
                <Minus className="h-4 w-4 mr-2" />
                Supprimer la colonne
              </DropdownMenuItem>
              <DropdownMenuSeparator />
              <DropdownMenuItem onClick={() => editor.chain().focus().addRowBefore().run()}>
                <Plus className="h-4 w-4 mr-2" />
                Ajouter une ligne avant
              </DropdownMenuItem>
              <DropdownMenuItem onClick={() => editor.chain().focus().addRowAfter().run()}>
                <Plus className="h-4 w-4 mr-2" />
                Ajouter une ligne après
              </DropdownMenuItem>
              <DropdownMenuItem onClick={() => editor.chain().focus().deleteRow().run()}>
                <Minus className="h-4 w-4 mr-2" />
                Supprimer la ligne
              </DropdownMenuItem>
              <DropdownMenuSeparator />
              <DropdownMenuItem onClick={() => editor.chain().focus().deleteTable().run()}>
                <Trash2 className="h-4 w-4 mr-2" />
                Supprimer le tableau
              </DropdownMenuItem>
            </>
          )}
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  );
};