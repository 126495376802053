import { Link } from "react-router-dom";
import { Mail, Phone, MapPin, Facebook, Twitter, Instagram, Youtube } from "lucide-react";

export const Footer = () => {
  return (
    <footer className="bg-white">
      <div className="px-4 sm:px-6 lg:px-8 py-12">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
          <div className="space-y-4">
            <Link to="/" className="flex items-center space-x-2">
              <img 
                src="/lovable-uploads/cf83c9da-55bd-4749-b59b-22399ff0630f.png" 
                alt="Bekane" 
                className="h-16 w-auto"
              />
            </Link>
            <p className="text-black">
              La meilleure plateforme pour apprendre et réussir votre code moto.
            </p>
          </div>

          <div>
            <h3 className="text-lg font-semibold mb-4 text-black">Navigation</h3>
            <ul className="space-y-2">
              <li>
                <Link to="/" className="text-black hover:text-primary transition-colors">
                  Accueil
                </Link>
              </li>
              <li>
                <Link to="/about" className="text-black hover:text-primary transition-colors">
                  À propos
                </Link>
              </li>
              <li>
                <Link to="/tarifs" className="text-black hover:text-primary transition-colors">
                  Tarifs
                </Link>
              </li>
              <li>
                <Link to="/blog" className="text-black hover:text-primary transition-colors">
                  Blog
                </Link>
              </li>
              <li>
                <Link to="/contact" className="text-black hover:text-primary transition-colors">
                  Contact
                </Link>
              </li>
            </ul>
          </div>

          <div>
            <h3 className="text-lg font-semibold mb-4 text-black">Contact</h3>
            <ul className="space-y-2">
              <li>
                <a href="mailto:contact@bekane.fr" className="flex items-center space-x-2 text-black hover:text-primary transition-colors">
                  <Mail className="h-5 w-5 text-primary" />
                  <span>contact@bekane.fr</span>
                </a>
              </li>
              <li>
                <a href="tel:0123456789" className="flex items-center space-x-2 text-black hover:text-primary transition-colors">
                  <Phone className="h-5 w-5 text-primary" />
                  <span>01 23 45 67 89</span>
                </a>
              </li>
              <li>
                <a href="https://maps.google.com/?q=Paris,France" target="_blank" rel="noopener noreferrer" className="flex items-center space-x-2 text-black hover:text-primary transition-colors">
                  <MapPin className="h-5 w-5 text-primary" />
                  <span>Paris, France</span>
                </a>
              </li>
            </ul>
          </div>

          <div>
            <h3 className="text-lg font-semibold mb-4 text-black">Suivez-nous</h3>
            <div className="flex space-x-4">
              <a href="https://facebook.com" target="_blank" rel="noopener noreferrer" className="text-primary hover:text-primary-hover transition-colors">
                <Facebook className="h-6 w-6" />
              </a>
              <a href="https://twitter.com" target="_blank" rel="noopener noreferrer" className="text-primary hover:text-primary-hover transition-colors">
                <Twitter className="h-6 w-6" />
              </a>
              <a href="https://instagram.com" target="_blank" rel="noopener noreferrer" className="text-primary hover:text-primary-hover transition-colors">
                <Instagram className="h-6 w-6" />
              </a>
              <a href="https://youtube.com" target="_blank" rel="noopener noreferrer" className="text-primary hover:text-primary-hover transition-colors">
                <Youtube className="h-6 w-6" />
              </a>
            </div>
          </div>
        </div>

        <div className="mt-12 pt-8 border-t border-gray-200 text-center">
          <p className="text-black">&copy; {new Date().getFullYear()} Bekane. Tous droits réservés.</p>
        </div>
      </div>
    </footer>
  );
};