import { useParams } from "react-router-dom";
import { Header } from "@/components/layout/Header";
import { Footer } from "@/components/layout/Footer";
import { useQuery } from "@tanstack/react-query";
import { supabase } from "@/integrations/supabase/client";
import { PublicCourseHeader } from "@/components/courses/public/PublicCourseHeader";
import { PublicCourseContent } from "@/components/courses/public/PublicCourseContent";
import { PublicChaptersList } from "@/components/courses/public/PublicChaptersList";
import { Chapter } from "@/types/course";
import { toast } from "sonner";

const CodeView = () => {
  const { slug } = useParams();

  const { data: course, isLoading, error } = useQuery({
    queryKey: ["course-detail", slug],
    queryFn: async () => {
      try {
        console.log("Fetching course details for slug:", slug);
        const { data, error } = await supabase
          .from("courses")
          .select(`
            *,
            chapters (
              id,
              title,
              content,
              image_url,
              order_index,
              duration,
              course_id,
              status,
              slug
            )
          `)
          .eq("slug", slug)
          .eq("status", "published")
          .maybeSingle();

        if (error) {
          console.error("Supabase error fetching course:", error);
          toast.error("Erreur lors du chargement du cours");
          throw error;
        }

        if (!data) {
          console.log("No course found with slug:", slug);
          return null;
        }

        console.log("Course data fetched successfully:", data);

        const chaptersWithCourseId = (data.chapters || []).map((chapter) => ({
          ...chapter,
          course_id: data.id,
          image_url: chapter.image_url || "",
          content: chapter.content || "",
          status: chapter.status || "published",
          slug: chapter.slug || `${data.slug}-${chapter.order_index}`
        })) as Chapter[];

        return {
          ...data,
          chapters: chaptersWithCourseId,
        };
      } catch (error) {
        console.error("Error in course fetch:", error);
        toast.error("Erreur lors du chargement du cours");
        throw error;
      }
    },
    retry: 1,
    staleTime: 1000 * 60 * 5, // Cache for 5 minutes
    refetchOnWindowFocus: false,
  });

  if (isLoading) {
    return (
      <div className="min-h-screen flex flex-col">
        <Header />
        <main className="flex-grow">
          <div className="container mx-auto px-4 py-8">
            <div className="animate-pulse space-y-4">
              <div className="h-8 bg-gray-200 rounded w-3/4" />
              <div className="h-64 bg-gray-200 rounded" />
              <div className="space-y-2">
                <div className="h-4 bg-gray-200 rounded w-full" />
                <div className="h-4 bg-gray-200 rounded w-5/6" />
              </div>
            </div>
          </div>
        </main>
        <Footer />
      </div>
    );
  }

  if (!course) {
    return (
      <div className="min-h-screen flex flex-col">
        <Header />
        <main className="flex-grow">
          <div className="container mx-auto px-4 py-8">
            <div className="text-center">
              <h1 className="text-2xl font-bold mb-4">Cours introuvable</h1>
              <p className="text-muted-foreground">
                Le cours que vous recherchez n'existe pas ou n'est pas accessible.
              </p>
            </div>
          </div>
        </main>
        <Footer />
      </div>
    );
  }

  return (
    <div className="min-h-screen flex flex-col">
      <Header />
      <main className="flex-grow bg-gray-50">
        <div className="container mx-auto px-4 py-8">
          <div className="max-w-4xl mx-auto">
            <PublicCourseHeader title={course.title} />
            <PublicCourseContent 
              content={course.content}
              chapters={course.chapters}
              imageUrl={course.image_url}
            />
            <div className="mt-8">
              <PublicChaptersList 
                chapters={course.chapters} 
                courseId={course.id}
                courseSlug={course.slug}
              />
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default CodeView;