import { useState, useEffect } from "react";
import { supabase } from "@/integrations/supabase/client";
import { Loader2, ChevronLeft, Trash2, Folder } from "lucide-react";
import { Button } from "@/components/ui/button";
import { EmptyState } from "./gallery/EmptyState";
import { toast } from "sonner";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog";
import { ScrollArea } from "@/components/ui/scroll-area";

interface ImageGalleryProps {
  onSelect: (url: string) => void;
}

export const ImageGallery = ({ onSelect }: ImageGalleryProps) => {
  const [currentPath, setCurrentPath] = useState<string>("");
  const [items, setItems] = useState<Array<{ name: string; type: "folder" | "image" }>>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [breadcrumbs, setBreadcrumbs] = useState<string[]>([]);

  const fetchItems = async (path: string) => {
    try {
      setIsLoading(true);
      setError(null);

      const { data: files, error: listError } = await supabase.storage
        .from("course-images")
        .list(path);

      if (listError) throw listError;

      if (!files) {
        setItems([]);
        return;
      }

      const formattedItems = files.map(file => ({
        name: file.name,
        type: file.metadata?.mimetype?.startsWith('image/') ? 'image' as const : 'folder' as const
      }));

      setItems(formattedItems);
    } catch (err) {
      console.error("Error in fetchItems:", err);
      setError("Erreur lors du chargement des fichiers");
      toast.error("Erreur lors du chargement des fichiers");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchItems(currentPath);
    const newBreadcrumbs = currentPath ? currentPath.split('/').filter(Boolean) : [];
    setBreadcrumbs(newBreadcrumbs);
  }, [currentPath]);

  const handleFolderClick = (folderName: string) => {
    const newPath = currentPath ? `${currentPath}/${folderName}` : folderName;
    setCurrentPath(newPath);
  };

  const handleBackClick = () => {
    const newPath = currentPath.split('/').slice(0, -1).join('/');
    setCurrentPath(newPath);
  };

  const getPublicUrl = (filename: string) => {
    const path = currentPath ? `${currentPath}/${filename}` : filename;
    const { data } = supabase.storage
      .from("course-images")
      .getPublicUrl(path);
    return data.publicUrl;
  };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center p-8">
        <Loader2 className="h-8 w-8 animate-spin text-primary" />
      </div>
    );
  }

  if (error) {
    return <EmptyState message={error} />;
  }

  return (
    <div className="space-y-4">
      <div className="flex items-center gap-2 p-2 bg-muted/50 rounded-lg">
        <Button 
          variant="ghost" 
          onClick={() => setCurrentPath("")}
          size="sm"
          className={!currentPath ? "bg-primary/10" : ""}
        >
          Racine
        </Button>
        {breadcrumbs.map((crumb, index) => (
          <div key={index} className="flex items-center">
            <ChevronLeft className="h-4 w-4 mx-1 text-muted-foreground" />
            <Button
              variant="ghost"
              size="sm"
              onClick={() => setCurrentPath(breadcrumbs.slice(0, index + 1).join('/'))}
              className={index === breadcrumbs.length - 1 ? "bg-primary/10" : ""}
            >
              {crumb}
            </Button>
          </div>
        ))}
      </div>

      <ScrollArea className="h-[400px] rounded-md border">
        <div className="grid grid-cols-3 gap-4 p-4">
          {items.length === 0 ? (
            <div className="col-span-3">
              <EmptyState message="Aucun fichier disponible" />
            </div>
          ) : (
            items.map((item) => (
              <div key={item.name} className="relative group">
                {item.type === 'folder' ? (
                  <button
                    onClick={() => handleFolderClick(item.name)}
                    className="w-full aspect-square rounded-lg border-2 border-dashed hover:border-primary flex items-center justify-center flex-col gap-2 transition-colors"
                  >
                    <Folder className="h-8 w-8 text-muted-foreground" />
                    <div className="text-muted-foreground text-sm">{item.name}</div>
                  </button>
                ) : (
                  <div className="relative group">
                    <img
                      src={getPublicUrl(item.name)}
                      alt={item.name}
                      className="w-full aspect-square object-cover rounded-lg cursor-pointer hover:opacity-75 transition-opacity"
                      onClick={() => onSelect(getPublicUrl(item.name))}
                    />
                    <div className="absolute bottom-2 left-2 right-2 text-xs text-white bg-black/50 p-1 rounded truncate">
                      {item.name}
                    </div>
                  </div>
                )}
              </div>
            ))
          )}
        </div>
      </ScrollArea>
    </div>
  );
};