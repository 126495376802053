import { Chapter } from "@/types/course";
import { Button } from "@/components/ui/button";
import { Card } from "@/components/ui/card";
import { Badge } from "@/components/ui/badge";
import { Pencil, Trash2, GripVertical, AlertCircle } from "lucide-react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

interface SortableChapterItemProps {
  chapter: Chapter;
  onEdit: (chapter: Chapter) => void;
  onDelete: (chapterId: string) => void;
}

export const SortableChapterItem = ({
  chapter,
  onEdit,
  onDelete,
}: SortableChapterItemProps) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: chapter.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: isDragging ? 0.5 : 1,
  };

  const getStatusBadge = () => {
    switch (chapter.status) {
      case "published":
        return (
          <Badge variant="success" className="ml-2">
            Publié
          </Badge>
        );
      case "pending_validation":
        return (
          <Badge variant="secondary" className="ml-2">
            <AlertCircle className="w-3 h-3 mr-1" />
            En attente
          </Badge>
        );
      case "draft":
        return (
          <Badge variant="secondary" className="ml-2">
            Brouillon
          </Badge>
        );
    }
  };

  return (
    <Card
      ref={setNodeRef}
      style={style}
      className="p-4"
    >
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-4">
          <Button
            variant="ghost"
            size="icon"
            className="cursor-grab"
            {...attributes}
            {...listeners}
          >
            <GripVertical className="h-4 w-4" />
          </Button>
          <div>
            <div className="flex items-center">
              <h3 className="font-semibold">{chapter.title}</h3>
              {getStatusBadge()}
            </div>
            <p className="text-sm text-muted-foreground">
              {chapter.duration} minutes
            </p>
          </div>
        </div>
        <div className="space-x-2">
          <Button
            variant="ghost"
            size="icon"
            onClick={() => onEdit(chapter)}
          >
            <Pencil className="h-4 w-4" />
          </Button>
          <Button
            variant="ghost"
            size="icon"
            onClick={() => onDelete(chapter.id)}
          >
            <Trash2 className="h-4 w-4" />
          </Button>
        </div>
      </div>
    </Card>
  );
};