import { Badge } from "@/components/ui/badge";
import { BookOpen, Clock } from "lucide-react";
import { CardTitle } from "@/components/ui/card";
import { Chapter } from "@/types/course";

interface CourseHeaderProps {
  title: string;
  chapters: Chapter[];
}

export const CourseHeader = ({ title, chapters }: CourseHeaderProps) => {
  const totalDuration = chapters.reduce((acc, chapter) => acc + (chapter.duration || 15), 0);

  return (
    <div className="space-y-3">
      <CardTitle className="text-2xl font-bold">{title}</CardTitle>
      <div className="flex flex-wrap gap-3">
        <Badge variant="secondary" className="px-3 py-1">
          <BookOpen className="w-3.5 h-3.5 mr-1.5" />
          {chapters.length} chapitre{chapters.length > 1 ? "s" : ""}
        </Badge>
        <Badge variant="outline" className="px-3 py-1">
          <Clock className="w-3.5 h-3.5 mr-1.5" />
          {totalDuration} min
        </Badge>
      </div>
    </div>
  );
};