import { Route, Navigate } from "react-router-dom";
import { ProtectedRoute } from "@/components/auth/ProtectedRoute";
import StudentDashboard from "@/pages/student/Dashboard";
import StudentCourses from "@/pages/student/Courses";
import StudentSettings from "@/pages/student/Settings";
import CourseView from "@/pages/student/CourseView";
import Quiz from "@/pages/student/Quiz";

export const studentRoutes = [
  <Route
    key="student-dashboard"
    path="/student/dashboard"
    element={
      <ProtectedRoute>
        <StudentDashboard />
      </ProtectedRoute>
    }
  />,
  <Route
    key="student-courses"
    path="/student/courses"
    element={
      <ProtectedRoute>
        <StudentCourses />
      </ProtectedRoute>
    }
  />,
  <Route
    key="student-course-view"
    path="/student/courses/:slug"
    element={
      <ProtectedRoute>
        <CourseView />
      </ProtectedRoute>
    }
  />,
  <Route
    key="student-quiz"
    path="/student/quiz/:id"
    element={
      <ProtectedRoute>
        <Quiz />
      </ProtectedRoute>
    }
  />,
  <Route
    key="student-settings"
    path="/student/settings"
    element={
      <ProtectedRoute>
        <StudentSettings />
      </ProtectedRoute>
    }
  />,
  <Route
    key="student-not-found"
    path="/student/*"
    element={
      <ProtectedRoute>
        <Navigate to="/student/courses" replace />
      </ProtectedRoute>
    }
  />,
];
