import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { supabase } from "@/integrations/supabase/client";
import { toast } from "sonner";
import { CourseViewSkeleton } from "@/components/courses/view/CourseViewSkeleton";
import { CourseViewError } from "@/components/courses/view/CourseViewError";
import { CourseViewLayout } from "@/components/courses/view/CourseViewLayout";
import { CourseViewContent } from "@/components/courses/view/CourseViewContent";
import { Chapter, ChapterStatus } from "@/types/course";
import { generateSlug } from "@/utils/slugUtils";

const CourseView = () => {
  const { slug } = useParams<{ slug: string }>();
  const [currentChapterId, setCurrentChapterId] = useState<string>("");
  const [isNavOpen, setIsNavOpen] = useState(false);

  const { data: course, isLoading, error } = useQuery({
    queryKey: ["course", slug],
    queryFn: async () => {
      const { data, error } = await supabase
        .from("courses")
        .select(`
          *,
          chapters (
            id,
            title,
            content,
            image_url,
            order_index,
            duration,
            course_id,
            status,
            slug
          )
        `)
        .eq("slug", slug)
        .eq("status", "published")
        .maybeSingle();

      if (error) throw error;
      if (!data) return null;

      return {
        ...data,
        chapters: data.chapters?.map(chapter => ({
          ...chapter,
          course_id: data.id,
          status: chapter.status as ChapterStatus || 'published',
          slug: chapter.slug || generateSlug(chapter.title, chapter.order_index)
        })) || []
      };
    },
  });

  const { data: lastVisitedChapter } = useQuery({
    queryKey: ["last-visited-chapter", course?.id],
    queryFn: async () => {
      if (!course?.id) return null;

      const { data: { session } } = await supabase.auth.getSession();
      if (!session?.user?.id) return null;

      const { data, error } = await supabase
        .from("chapter_progress")
        .select("chapter_id")
        .eq("course_id", course.id)
        .eq("user_id", session.user.id)
        .order("last_visited_at", { ascending: false })
        .limit(1)
        .maybeSingle();

      if (error) throw error;
      return data?.chapter_id;
    },
    enabled: !!course?.id,
  });

  useEffect(() => {
    if (course?.chapters && course.chapters.length > 0) {
      const sortedChapters = [...course.chapters].sort(
        (a, b) => a.order_index - b.order_index
      );
      setCurrentChapterId(lastVisitedChapter || sortedChapters[0].id);
    }
  }, [course?.chapters, lastVisitedChapter]);

  const handleChapterChange = async (chapterId: string) => {
    try {
      const { data: { session } } = await supabase.auth.getSession();
      if (!session?.user?.id || !course?.id) return;

      const { error } = await supabase
        .from("chapter_progress")
        .upsert({
          course_id: course.id,
          chapter_id: chapterId,
          user_id: session.user.id,
          last_visited_at: new Date().toISOString(),
        }, {
          onConflict: 'user_id,chapter_id,course_id'
        });

      if (error) {
        console.error("Error updating last visited chapter:", error);
        toast.error("Erreur lors de la mise à jour de votre progression");
        return;
      }

      setCurrentChapterId(chapterId);
      setIsNavOpen(false);
    } catch (error) {
      console.error("Error in handleChapterChange:", error);
      toast.error("Une erreur est survenue");
    }
  };

  if (isLoading) return <CourseViewSkeleton />;
  if (error) {
    return (
      <CourseViewError
        message="Erreur"
        description="Une erreur est survenue lors du chargement du cours. Veuillez réessayer plus tard."
      />
    );
  }
  if (!course) {
    return (
      <CourseViewError
        message="Cours non trouvé"
        description="Le cours que vous recherchez n'existe pas ou n'est pas accessible."
      />
    );
  }

  const currentChapter = course.chapters?.find(
    (chapter) => chapter.id === currentChapterId
  );
  const currentChapterIndex = course.chapters?.findIndex(
    (chapter) => chapter.id === currentChapterId
  ) ?? 0;

  return (
    <CourseViewLayout
      isNavOpen={isNavOpen}
      onNavOpenChange={setIsNavOpen}
      chapters={course.chapters}
      currentChapterId={currentChapterId}
      onChapterChange={handleChapterChange}
      courseId={course.id}
    >
      {currentChapter && (
        <CourseViewContent
          currentChapter={currentChapter}
          courseId={course.id}
          currentIndex={currentChapterIndex}
          totalChapters={course.chapters.length}
          onChapterComplete={handleChapterChange}
          chapters={course.chapters}
        />
      )}
    </CourseViewLayout>
  );
};

export default CourseView;