import { DashboardLayout } from "@/components/layout/DashboardLayout";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";

const AdminSettings = () => {
  return (
    <DashboardLayout type="admin">
      <div className="space-y-8">
        <div>
          <h1 className="text-3xl font-bold">Paramètres</h1>
          <p className="text-muted-foreground mt-2">
            Gérez les paramètres de votre plateforme
          </p>
        </div>

        <div className="bg-white p-6 rounded-lg shadow-sm space-y-6">
          <h2 className="text-xl font-semibold">Paramètres généraux</h2>
          
          <div className="space-y-4">
            <div className="grid gap-2">
              <Label htmlFor="platform-name">Nom de la plateforme</Label>
              <Input id="platform-name" defaultValue="CodeMoto" />
            </div>

            <div className="grid gap-2">
              <Label htmlFor="contact-email">Email de contact</Label>
              <Input id="contact-email" type="email" defaultValue="contact@codemoto.fr" />
            </div>

            <div className="grid gap-2">
              <Label htmlFor="description">Description</Label>
              <Input id="description" defaultValue="Formation en ligne pour le permis moto" />
            </div>
          </div>

          <div className="pt-4">
            <Button>Enregistrer les modifications</Button>
          </div>
        </div>

        <div className="bg-white p-6 rounded-lg shadow-sm space-y-6">
          <h2 className="text-xl font-semibold">Notifications</h2>
          
          <div className="space-y-4">
            <div className="flex items-center justify-between">
              <div>
                <p className="font-medium">Notifications par email</p>
                <p className="text-sm text-muted-foreground">
                  Recevoir des notifications pour les nouvelles inscriptions
                </p>
              </div>
              <Button variant="outline">Configurer</Button>
            </div>

            <div className="flex items-center justify-between">
              <div>
                <p className="font-medium">Rapports hebdomadaires</p>
                <p className="text-sm text-muted-foreground">
                  Recevoir des rapports d'activité chaque semaine
                </p>
              </div>
              <Button variant="outline">Configurer</Button>
            </div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default AdminSettings;