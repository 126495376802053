import { useState } from "react";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { supabase } from "@/integrations/supabase/client";
import { Course, CourseStatus } from "@/types/course";
import { toast } from "sonner";

export const useCourseManagement = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [statusFilter, setStatusFilter] = useState<string>("all");
  const [view, setView] = useState<"grid" | "list">("grid");
  const queryClient = useQueryClient();

  const { data: courses, isLoading } = useQuery({
    queryKey: ["courses"],
    queryFn: async () => {
      const { data, error } = await supabase
        .from("courses")
        .select(`
          *,
          chapters (
            id,
            title,
            content,
            image_url,
            duration,
            order_index,
            status,
            slug,
            seo_title,
            meta_description,
            meta_keywords
          )
        `)
        .order("order_index", { ascending: true });

      if (error) throw error;
      return data as Course[];
    },
  });

  const updateStatusMutation = useMutation({
    mutationFn: async ({ courseId, newStatus }: { courseId: string; newStatus: CourseStatus }) => {
      const { error } = await supabase
        .from("courses")
        .update({ status: newStatus })
        .eq("id", courseId);

      if (error) throw error;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["courses"] });
      toast.success("Statut mis à jour avec succès");
    },
    onError: () => {
      toast.error("Erreur lors de la mise à jour du statut");
    },
  });

  const filteredCourses = courses?.filter((course) => {
    const matchesSearch = course.title
      .toLowerCase()
      .includes(searchQuery.toLowerCase());
    const matchesStatus = statusFilter === "all" || course.status === statusFilter;
    return matchesSearch && matchesStatus;
  });

  const refreshCourses = () => {
    queryClient.invalidateQueries({ queryKey: ["courses"] });
  };

  return {
    courses: filteredCourses,
    isLoading,
    searchQuery,
    setSearchQuery,
    statusFilter,
    setStatusFilter,
    view,
    setView,
    updateStatus: (courseId: string, newStatus: CourseStatus) => 
      updateStatusMutation.mutate({ courseId, newStatus }),
    refreshCourses,
  };
};