export const DashboardParallax = () => {
  return (
    <section className="relative py-20 bg-gradient-to-b from-white to-gray-100">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="relative w-full max-w-4xl mx-auto">
          <img
            src="/lovable-uploads/0db3852e-436c-4566-aec5-5d1ba29d9931.png"
            alt="Interface du tableau de bord avec statistiques"
            className="w-full h-auto object-cover rounded-lg shadow-xl"
          />
        </div>
      </div>
    </section>
  );
};