import { useQuery } from "@tanstack/react-query";
import { supabase } from "@/integrations/supabase/client";
import { useNavigate, useLocation } from "react-router-dom";
import { toast } from "sonner";

export const useCourseNavigation = (currentCourseId: string) => {
  const navigate = useNavigate();
  const location = useLocation();
  const isStudentDashboard = location.pathname.startsWith('/student');

  const { data: nextCourse } = useQuery({
    queryKey: ["next-course", currentCourseId],
    queryFn: async () => {
      try {
        console.log("Fetching next course for courseId:", currentCourseId);
        
        // Récupérer l'order_index du cours actuel
        const { data: currentCourse, error: currentError } = await supabase
          .from("courses")
          .select("order_index")
          .eq("id", currentCourseId)
          .maybeSingle();

        if (currentError) {
          console.error("Error fetching current course:", currentError);
          return null;
        }

        if (!currentCourse) {
          console.error("Current course not found");
          return null;
        }

        // Chercher le prochain cours avec un order_index supérieur
        const { data, error } = await supabase
          .from("courses")
          .select("id, slug, order_index")
          .eq("status", "published")
          .gt("order_index", currentCourse.order_index)
          .order("order_index", { ascending: true })
          .limit(1)
          .maybeSingle();

        if (error && error.code !== 'PGRST116') {
          console.error("Error fetching next course:", error);
          return null;
        }

        console.log("Next course found:", data);
        return data;
      } catch (error) {
        console.error("Navigation error:", error);
        return null;
      }
    },
    enabled: !!currentCourseId,
  });

  const handleNextCourse = () => {
    if (nextCourse?.slug) {
      // Si on vient du dashboard étudiant, on navigue vers la vue étudiant
      const basePath = isStudentDashboard ? '/student/courses' : '/code';
      navigate(`${basePath}/${nextCourse.slug}`);
    } else {
      // Si pas de cours suivant, rediriger vers la liste des cours appropriée
      const fallbackPath = isStudentDashboard ? '/student/courses' : '/code';
      navigate(fallbackPath);
      toast.info("C'était le dernier cours de la liste !");
    }
  };

  return {
    nextCourse,
    handleNextCourse,
  };
};