import { Button } from "@/components/ui/button";
import { Trash2 } from "lucide-react";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog";

interface CourseFormActionsProps {
  isSubmitting: boolean;
  isDeleting: boolean;
  isEditing: boolean;
  onDelete: () => void;
}

export const CourseFormActions = ({
  isSubmitting,
  isDeleting,
  isEditing,
  onDelete,
}: CourseFormActionsProps) => {
  return (
    <div className="flex justify-end gap-2">
      {isEditing && (
        <AlertDialog>
          <AlertDialogTrigger asChild>
            <Button type="button" variant="destructive" disabled={isDeleting}>
              <Trash2 className="h-4 w-4 mr-2" />
              {isDeleting ? "Suppression..." : "Supprimer"}
            </Button>
          </AlertDialogTrigger>
          <AlertDialogContent>
            <AlertDialogHeader>
              <AlertDialogTitle>Êtes-vous sûr ?</AlertDialogTitle>
              <AlertDialogDescription>
                Cette action est irréversible. Le cours et tous ses chapitres
                seront définitivement supprimés.
              </AlertDialogDescription>
            </AlertDialogHeader>
            <AlertDialogFooter>
              <AlertDialogCancel>Annuler</AlertDialogCancel>
              <AlertDialogAction onClick={onDelete}>Supprimer</AlertDialogAction>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialog>
      )}
      <Button type="submit" disabled={isSubmitting}>
        {isSubmitting
          ? "Enregistrement..."
          : isEditing
          ? "Mettre à jour"
          : "Créer"}
      </Button>
    </div>
  );
};