import { Editor } from '@tiptap/react';
import { Palette } from 'lucide-react';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { Button } from '@/components/ui/button';

const colors = [
  '#000000',
  '#343A40',
  '#495057',
  '#868E96',
  '#ADB5BD',
  '#E9ECEF',
  '#1864AB',
  '#2B8A3E',
  '#D9480F',
  '#E03131',
  '#9C36B5',
];

interface ColorPickerProps {
  editor: Editor;
}

export const ColorPicker = ({ editor }: ColorPickerProps) => {
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="ghost" size="sm" className="h-8">
          <Palette className="h-4 w-4" />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        <div className="grid grid-cols-6 gap-1 p-2">
          {colors.map((color) => (
            <button
              key={color}
              className="w-6 h-6 rounded-md border border-gray-200 hover:scale-110 transition-transform"
              style={{ backgroundColor: color }}
              onClick={() => editor.chain().focus().setColor(color).run()}
            />
          ))}
        </div>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};