import { Badge } from "@/components/ui/badge";

interface StatusBadgeProps {
  status: string;
}

export const StatusBadge = ({ status }: StatusBadgeProps) => {
  const statusColors = {
    active: 'bg-green-500',
    inactive: 'bg-gray-500',
    canceled: 'bg-red-500',
    pending: 'bg-yellow-500'
  };

  const statusLabels = {
    active: 'Actif',
    inactive: 'Inactif',
    canceled: 'Annulé',
    pending: 'En attente'
  };

  return (
    <Badge className={statusColors[status as keyof typeof statusColors] || 'bg-gray-500'}>
      {statusLabels[status as keyof typeof statusLabels] || status}
    </Badge>
  );
};