import { useState } from "react";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { Card } from "@/components/ui/card";
import { ArticleData } from "../types";

interface BasicParametersProps {
  data: ArticleData;
  onComplete: (data: Partial<ArticleData>) => void;
}

export const BasicParameters = ({ data, onComplete }: BasicParametersProps) => {
  const [formData, setFormData] = useState({
    subject: data.subject || "",
    language: data.language || "fr",
    model: data.model || "gpt-4o"
  });

  const handleSubmit = () => {
    if (!formData.subject.trim()) {
      return;
    }
    onComplete(formData);
  };

  return (
    <Card className="p-6">
      <div className="space-y-6">
        <div className="space-y-2">
          <Label htmlFor="subject">Sujet de l'article</Label>
          <Input
            id="subject"
            placeholder="Entrez le sujet de votre article"
            value={formData.subject}
            onChange={(e) => setFormData(prev => ({ ...prev, subject: e.target.value }))}
          />
        </div>

        <div className="space-y-2">
          <Label htmlFor="language">Langue</Label>
          <Select
            value={formData.language}
            onValueChange={(value) => setFormData(prev => ({ ...prev, language: value }))}
          >
            <SelectTrigger id="language">
              <SelectValue placeholder="Sélectionnez une langue" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="fr">Français</SelectItem>
              <SelectItem value="en">English</SelectItem>
            </SelectContent>
          </Select>
        </div>

        <div className="space-y-2">
          <Label htmlFor="model">Modèle d'IA</Label>
          <Select
            value={formData.model}
            onValueChange={(value) => setFormData(prev => ({ ...prev, model: value }))}
          >
            <SelectTrigger id="model">
              <SelectValue placeholder="Sélectionnez un modèle" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="gpt-4o">GPT-4 Optimisé (Meilleure qualité)</SelectItem>
              <SelectItem value="gpt-4o-mini">GPT-4 Mini (Rapide)</SelectItem>
              <SelectItem value="gemini-pro">Gemini Pro</SelectItem>
            </SelectContent>
          </Select>
          <p className="text-sm text-muted-foreground">
            Choisissez le modèle d'IA qui générera votre article. GPT-4 Optimisé offre la meilleure qualité mais est plus lent, GPT-4 Mini est plus rapide mais moins détaillé.
          </p>
        </div>

        <Button onClick={handleSubmit} className="w-full">
          Continuer
        </Button>
      </div>
    </Card>
  );
};