import { Calendar, Clock, User } from "lucide-react";
import { formatDistanceToNow } from "date-fns";
import { fr } from "date-fns/locale";

interface ArticleHeaderProps {
  title?: string;
  author?: { name: string; avatar?: string };
  publishedAt?: Date;
  readingTime?: number;
  tags?: string[];
  summary?: string;
  imageUrl?: string;
}

export const ArticleHeader = ({
  title,
  author,
  publishedAt,
  readingTime = 5,
  tags = [],
  summary,
  imageUrl,
}: ArticleHeaderProps) => {
  return (
    <header className="mb-12">
      {/* Hero Image */}
      {imageUrl && (
        <div className="relative w-full h-[400px] mb-8 rounded-xl overflow-hidden">
          <img
            src={imageUrl}
            alt={title}
            className="w-full h-full object-cover"
          />
        </div>
      )}

      {/* Title */}
      {title && (
        <h1 className="text-4xl md:text-5xl font-bold mb-6 text-gray-900">
          {title}
        </h1>
      )}

      {/* Metadata */}
      <div className="flex flex-wrap items-center gap-6 text-gray-600 mb-6">
        {publishedAt && (
          <div className="flex items-center">
            <Calendar className="h-5 w-5 mr-2" />
            <span>
              {formatDistanceToNow(publishedAt, {
                addSuffix: true,
                locale: fr,
              })}
            </span>
          </div>
        )}
        <div className="flex items-center">
          <Clock className="h-5 w-5 mr-2" />
          <span>{readingTime} min de lecture</span>
        </div>
        {author && (
          <div className="flex items-center">
            <User className="h-5 w-5 mr-2" />
            <span>{author.name}</span>
          </div>
        )}
      </div>

      {/* Tags */}
      {tags && tags.length > 0 && (
        <div className="flex flex-wrap gap-2 mb-6">
          {tags.map((tag, index) => (
            <span
              key={index}
              className="bg-primary/10 text-primary px-3 py-1 rounded-full text-sm"
            >
              {tag}
            </span>
          ))}
        </div>
      )}

      {/* Summary */}
      {summary && (
        <div className="max-w-3xl">
          <p className="text-xl text-gray-600 leading-relaxed">{summary}</p>
        </div>
      )}
    </header>
  );
};