import { useState } from "react";
import { supabase } from "@/integrations/supabase/client";
import { Course, CourseFormData } from "@/types/course";
import { toast } from "sonner";
import { generateSlug } from "@/utils/slugUtils";

export const useCourseMutation = (onSuccess: () => void) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (data: CourseFormData, editingCourse?: Course | null) => {
    setIsSubmitting(true);
    console.log("Submitting course data:", data);
    const courseId = editingCourse?.id;

    try {
      if (courseId) {
        // Update existing course
        const { error: courseError } = await supabase
          .from("courses")
          .update({
            title: data.title,
            content: data.content,
            image_url: data.image_url,
            status: data.status,
            slug: data.slug,
          })
          .eq("id", courseId);

        if (courseError) throw courseError;

        // Handle chapters
        const existingChapterIds = editingCourse.chapters.map(ch => ch.id);
        const newChapterIds = data.chapters.filter(ch => ch.id).map(ch => ch.id as string);
        const chaptersToDelete = existingChapterIds.filter(id => !newChapterIds.includes(id));

        console.log("Chapters to delete:", chaptersToDelete);
        console.log("New/Updated chapters:", data.chapters);

        // Delete removed chapters
        if (chaptersToDelete.length > 0) {
          const { error: deleteError } = await supabase
            .from("chapters")
            .delete()
            .in("id", chaptersToDelete);

          if (deleteError) throw deleteError;
        }

        // Update existing chapters and create new ones
        for (const chapter of data.chapters) {
          const chapterData = {
            title: chapter.title,
            content: chapter.content,
            image_url: chapter.image_url || null,
            order_index: chapter.order_index,
            duration: chapter.duration,
            course_id: courseId,
            status: chapter.status,
            slug: chapter.slug || generateSlug(chapter.title, chapter.order_index)
          };

          if (chapter.id) {
            // Update existing chapter
            const { error: updateError } = await supabase
              .from("chapters")
              .update(chapterData)
              .eq("id", chapter.id);

            if (updateError) throw updateError;
          } else {
            // Create new chapter
            const { error: createError } = await supabase
              .from("chapters")
              .insert(chapterData);

            if (createError) throw createError;
          }
        }
      } else {
        // Create new course
        const { data: newCourse, error: courseError } = await supabase
          .from("courses")
          .insert({
            title: data.title,
            content: data.content,
            image_url: data.image_url,
            status: data.status,
            slug: data.slug,
          })
          .select()
          .single();

        if (courseError) throw courseError;

        console.log("Created new course:", newCourse);

        // Create chapters for new course
        if (data.chapters.length > 0) {
          const chaptersToCreate = data.chapters.map(chapter => ({
            course_id: newCourse.id,
            title: chapter.title,
            content: chapter.content,
            image_url: chapter.image_url || null,
            order_index: chapter.order_index,
            duration: chapter.duration,
            status: chapter.status,
            slug: chapter.slug || generateSlug(chapter.title, chapter.order_index)
          }));

          console.log("Creating chapters:", chaptersToCreate);

          const { error: chaptersError } = await supabase
            .from("chapters")
            .insert(chaptersToCreate);

          if (chaptersError) throw chaptersError;
        }
      }

      toast.success(
        courseId ? "Cours mis à jour avec succès" : "Cours créé avec succès"
      );
      onSuccess();
    } catch (error) {
      console.error("Error submitting course:", error);
      toast.error(
        "Une erreur est survenue lors de l'enregistrement du cours"
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  return {
    handleSubmit,
    isSubmitting,
  };
};