import { useState } from "react";
import { Button } from "@/components/ui/button";
import { Textarea } from "@/components/ui/textarea";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { toast } from "sonner";

interface HTMLImportDialogProps {
  isOpen: boolean;
  onOpenChange: (open: boolean) => void;
  onImport: (content: string) => void;
}

export const HTMLImportDialog = ({
  isOpen,
  onOpenChange,
  onImport,
}: HTMLImportDialogProps) => {
  const [htmlContent, setHtmlContent] = useState("");

  const cleanHtml = (html: string) => {
    // Créer un élément div temporaire pour parser le HTML
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = html;

    // Supprimer les scripts et styles
    const scripts = tempDiv.getElementsByTagName("script");
    const styles = tempDiv.getElementsByTagName("style");

    for (let i = scripts.length - 1; i >= 0; i--) {
      scripts[i].remove();
    }

    for (let i = styles.length - 1; i >= 0; i--) {
      styles[i].remove();
    }

    // Nettoyer les attributs inutiles
    const allElements = tempDiv.getElementsByTagName("*");
    for (let i = 0; i < allElements.length; i++) {
      const element = allElements[i];
      // Conserver uniquement les attributs essentiels
      const attributes = element.attributes;
      for (let j = attributes.length - 1; j >= 0; j--) {
        const attrName = attributes[j].name;
        if (!["href", "src", "alt"].includes(attrName)) {
          element.removeAttribute(attrName);
        }
      }
    }

    // Convertir les balises spécifiques et nettoyer les espaces
    let cleanedHtml = tempDiv.innerHTML
      .replace(/<div/g, "<p")
      .replace(/<\/div>/g, "</p>")
      // Supprimer les espaces multiples
      .replace(/\s+/g, " ")
      // Supprimer les espaces entre les balises
      .replace(/>\s+</g, "><")
      // Supprimer les paragraphes vides
      .replace(/<p>\s*<\/p>/g, "")
      // Supprimer les sauts de ligne excessifs entre différents types d'éléments
      .replace(/(<\/(?:p|ul|ol|h[1-6])>)\s*(<(?:p|ul|ol|h[1-6])>)/g, "$1$2")
      .trim();

    return cleanedHtml;
  };

  const handleImport = () => {
    try {
      const cleanContent = cleanHtml(htmlContent);
      onImport(cleanContent);
      setHtmlContent("");
      onOpenChange(false);
      toast.success("Contenu HTML importé avec succès");
    } catch (error) {
      console.error("Erreur lors de l'import du HTML:", error);
      toast.error("Erreur lors de l'import du HTML");
    }
  };

  return (
    <Dialog open={isOpen} onOpenChange={onOpenChange}>
      <DialogContent className="sm:max-w-[600px]">
        <DialogHeader>
          <DialogTitle>Importer du contenu HTML</DialogTitle>
        </DialogHeader>
        <div className="space-y-4 py-4">
          <Textarea
            value={htmlContent}
            onChange={(e) => setHtmlContent(e.target.value)}
            placeholder="Collez votre code HTML ici..."
            className="min-h-[200px]"
          />
          <div className="flex justify-end">
            <Button
              type="button"
              onClick={handleImport}
              disabled={!htmlContent}
            >
              Importer
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};