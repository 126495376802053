import { Check } from "lucide-react";
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { DialogDescription } from "@/components/ui/dialog";
import { Badge } from "@/components/ui/badge";

interface PricingPlanProps {
  name: string;
  description: string;
  price: string;
  features: string[];
  planType: string;
  featured: boolean;
  isCurrentPlan?: boolean;
  onSubscribe: (planType: string) => void;
}

export const PricingPlan = ({
  name,
  description,
  price,
  features,
  planType,
  featured,
  isCurrentPlan,
  onSubscribe,
}: PricingPlanProps) => {
  return (
    <Card className={`relative ${featured ? 'border-primary shadow-lg' : ''}`}>
      {isCurrentPlan && (
        <Badge className="absolute -top-2 right-2 bg-green-500">
          Plan actuel
        </Badge>
      )}
      <CardHeader>
        <CardTitle>{name}</CardTitle>
        <DialogDescription>{description}</DialogDescription>
      </CardHeader>
      <CardContent>
        <div className="mb-4">
          <span className="text-4xl font-bold">{price}€</span>
          <span className="text-muted-foreground">/mois</span>
        </div>
        <ul className="space-y-3">
          {features.map((feature, i) => (
            <li key={i} className="flex items-center gap-2">
              <Check className="h-4 w-4 text-primary" />
              <span>{feature}</span>
            </li>
          ))}
        </ul>
        <Button 
          className="w-full mt-4" 
          variant={featured ? "default" : "outline"}
          onClick={() => onSubscribe(planType)}
          disabled={isCurrentPlan}
        >
          {isCurrentPlan ? 'Plan actuel' : 'Choisir cette formule'}
        </Button>
      </CardContent>
    </Card>
  );
};