import { Progress } from "@/components/ui/progress";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Skeleton } from "@/components/ui/skeleton";
import { BookOpenCheck } from "lucide-react";

interface ProgressStatsProps {
  isLoading: boolean;
  progressStats: {
    totalEnrollments: number;
    completedCourses: number;
    inProgressCourses: number;
  };
  completionRate: number;
}

export const ProgressStats = ({ isLoading, progressStats, completionRate }: ProgressStatsProps) => (
  <Card className="md:col-span-2">
    <CardHeader>
      <CardTitle className="flex items-center gap-2">
        <BookOpenCheck className="h-4 w-4" />
        Progression des cours
      </CardTitle>
    </CardHeader>
    <CardContent>
      {isLoading ? (
        <Skeleton className="h-[120px] w-full" />
      ) : (
        <div className="space-y-4">
          <div className="grid grid-cols-3 gap-4 text-sm">
            <div>
              <p className="text-muted-foreground">Total inscriptions</p>
              <p className="text-xl font-bold">{progressStats.totalEnrollments}</p>
            </div>
            <div>
              <p className="text-muted-foreground">Cours terminés</p>
              <p className="text-xl font-bold">{progressStats.completedCourses}</p>
            </div>
            <div>
              <p className="text-muted-foreground">En cours</p>
              <p className="text-xl font-bold">{progressStats.inProgressCourses}</p>
            </div>
          </div>
          <div className="space-y-2">
            <div className="flex justify-between text-sm text-muted-foreground">
              <span>Progression globale</span>
              <span>{completionRate}%</span>
            </div>
            <Progress 
              value={completionRate} 
              className="h-2"
            />
          </div>
        </div>
      )}
    </CardContent>
  </Card>
);