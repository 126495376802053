import { useState, useEffect, useRef, useCallback } from "react";
import { ArticleHeader } from "@/components/blog/article/ArticleHeader";
import { TableOfContents } from "@/components/blog/article/TableOfContents";
import { ArticleBody } from "@/components/blog/article/ArticleBody";
import { SharingButtons } from "@/components/blog/article/SharingButtons";
import { BackToTop } from "@/components/blog/article/BackToTop";

interface ArticleContentProps {
  content: string;
  title?: string;
  author?: { name: string; avatar?: string };
  publishedAt?: Date;
  readingTime?: number;
  tags?: string[];
  summary?: string;
  imageUrl?: string;
}

export const ArticleContent = ({
  content,
  title,
  author,
  publishedAt,
  readingTime = 5,
  tags = [],
  summary,
  imageUrl,
}: ArticleContentProps) => {
  const [showBackToTop, setShowBackToTop] = useState(false);
  const [activeSection, setActiveSection] = useState("");
  const [tableOfContents, setTableOfContents] = useState<Array<{id: string, title: string, level: number}>>([]);
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleScroll = () => {
      setShowBackToTop(window.scrollY > 300);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    if (!contentRef.current) return;

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setActiveSection(entry.target.id);
          }
        });
      },
      { threshold: 0.5 }
    );

    const headings = contentRef.current.querySelectorAll('h1, h2, h3');
    headings.forEach((heading) => observer.observe(heading));

    return () => {
      headings.forEach((heading) => observer.unobserve(heading));
    };
  }, [content]);

  const scrollToTop = useCallback(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  const handleHeadingsGenerated = useCallback((headings: Array<{id: string, title: string, level: number}>) => {
    setTableOfContents(headings);
  }, []);

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative">
      <ArticleHeader
        title={title}
        author={author}
        publishedAt={publishedAt}
        readingTime={readingTime}
        tags={tags}
        summary={summary}
        imageUrl={imageUrl}
      />

      <div className="flex gap-8">
        <TableOfContents items={tableOfContents} activeSection={activeSection} />
        
        <div ref={contentRef}>
          <ArticleBody content={content} onHeadingsGenerated={handleHeadingsGenerated} />
        </div>

        <SharingButtons />
      </div>

      <BackToTop show={showBackToTop} onClick={scrollToTop} />
    </div>
  );
};