import { useQuery } from "@tanstack/react-query";
import { supabase } from "@/integrations/supabase/client";
import { Avatar, AvatarFallback } from "@/components/ui/avatar";
import { User } from "lucide-react";
import { Skeleton } from "@/components/ui/skeleton";
import { toast } from "sonner";
import { useLocation } from "react-router-dom";

export const ProfileSection = () => {
  const location = useLocation();
  const isAdminDashboard = location.pathname.startsWith("/admin");

  const { data: session, isLoading: isSessionLoading } = useQuery({
    queryKey: ["session"],
    queryFn: async () => {
      const { data: { session }, error } = await supabase.auth.getSession();
      if (error) {
        toast.error("Erreur lors de la récupération de la session");
        throw error;
      }
      return session;
    },
  });

  const { data: profile, isLoading: isProfileLoading } = useQuery({
    queryKey: ["profile", session?.user?.id],
    enabled: !!session?.user?.id,
    queryFn: async () => {
      const { data, error } = await supabase
        .from("profiles")
        .select("*")
        .eq("id", session?.user?.id)
        .maybeSingle();

      if (error) {
        toast.error("Erreur lors de la récupération du profil");
        throw error;
      }
      return data;
    },
  });

  if (isSessionLoading || isProfileLoading) {
    return (
      <div className="flex items-center gap-3 px-4 py-3">
        <Skeleton className="h-10 w-10 rounded-full" />
        <div className="space-y-2">
          <Skeleton className="h-4 w-24" />
          <Skeleton className="h-3 w-16" />
        </div>
      </div>
    );
  }

  if (!session?.user) {
    return null;
  }

  return (
    <div className="flex items-center gap-3 px-4 py-3">
      <Avatar>
        <AvatarFallback>
          <User className="h-5 w-5" />
        </AvatarFallback>
      </Avatar>
      <div>
        <p className="text-sm font-medium">{session.user.email}</p>
        {isAdminDashboard && (
          <p className="text-xs text-muted-foreground capitalize">
            Rôle : {profile?.role || "Utilisateur"}
          </p>
        )}
      </div>
    </div>
  );
};