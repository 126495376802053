import { useState } from "react";
import { Button } from "@/components/ui/button";
import { toast } from "sonner";
import { supabase } from "@/integrations/supabase/client";
import { GeneratedTitle } from "./types";
import { parseTitlesFromText } from "./titleParser";

interface TitleGeneratorProps {
  selectedKeywords: Array<{ keyword: string }>;
  onTitlesGenerated: (titles: GeneratedTitle[]) => void;
}

export const TitleGenerator = ({
  selectedKeywords,
  onTitlesGenerated
}: TitleGeneratorProps) => {
  const [isLoading, setIsLoading] = useState(false);

  const generateTitles = async () => {
    setIsLoading(true);
    try {
      const keywordsText = selectedKeywords
        ?.map(kw => `- ${kw.keyword}`)
        .join('\n') || '';

      const prompt = `Générez des propositions de titres optimisés SEO en utilisant les mots-clés sélectionnés.

Mots-clés à intégrer :
${keywordsText}

Instructions de création :
1. Créez 5 variantes de titres uniques
2. Chaque titre doit :
   - Inclure naturellement un mot-clé principal
   - Être accrocheur et engageant
   - Contenir 50-60 caractères
   - Répondre à une intention de recherche
   - Promettre une valeur claire
3. Variez les approches :
   - Questions
   - Chiffres et listes
   - Comment/Pourquoi
   - Solutions à un problème
   - Guides étape par étape

Format de réponse :
### Titre 1 :
**"[Titre proposé]"**
**Type :** [informatif/guide/liste/question]
**Mot-clé ciblé :** [mot-clé principal]
**Force :** [explication de l'efficacité]`;

      console.log("🔄 Envoi de la requête de génération des titres:", {
        keywordsText,
        prompt
      });

      const { data: response, error } = await supabase.functions.invoke('generate', {
        body: {
          prompt,
          model: "gemini-1.5-pro"
        }
      });

      if (error) throw error;

      if (response && typeof response === 'object' && 'content' in response) {
        console.log("✅ Réponse reçue de l'IA:", response);
        const parsedTitles = parseTitlesFromText(response.content);
        console.log("📝 Titres générés:", parsedTitles);
        onTitlesGenerated(parsedTitles);
        toast.success("Titres générés avec succès");
      } else {
        throw new Error("Format de réponse inattendu");
      }
    } catch (error) {
      console.error("❌ Erreur lors de la génération des titres:", error);
      toast.error("Erreur lors de la génération des titres");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Button
      onClick={generateTitles}
      disabled={isLoading}
      className="w-full"
    >
      {isLoading ? "Génération des titres en cours..." : "Générer des propositions de titres"}
    </Button>
  );
};