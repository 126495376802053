import { useState } from "react";
import { Button } from "@/components/ui/button";
import { ArticleData, Source } from "../types";
import { supabase } from "@/integrations/supabase/client";
import { toast } from "sonner";
import { SourceList } from "./source-analysis/SourceList";
import { getSourceAnalysisPrompt } from "../prompts/sourceAnalysisPrompt";
import { parseSourceFromText } from "./source-analysis/sourceParser";

interface SourceAnalysisProps {
  data: ArticleData;
  onComplete: (data: Partial<ArticleData>) => void;
}

export const SourceAnalysis = ({ data, onComplete }: SourceAnalysisProps) => {
  const [sources, setSources] = useState<Source[]>([]);
  const [selectedSources, setSelectedSources] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const analyzeSources = async () => {
    setIsLoading(true);
    try {
      const prompt = getSourceAnalysisPrompt(data.subject);
      console.log("Sending prompt:", prompt);

      const { data: response, error } = await supabase.functions.invoke('generate', {
        body: {
          prompt,
          model: "gemini-1.5-pro"
        }
      });

      if (error) throw error;

      console.log("API Response:", response);

      if (response && typeof response === 'object' && 'content' in response) {
        const parsedSources = parseSourceFromText(response.content);
        console.log("Setting sources:", parsedSources);
        setSources(parsedSources);
        toast.success("Sources analysées avec succès");
      } else {
        console.error("Format de réponse inattendu:", response);
        toast.error("Format de réponse inattendu");
      }
    } catch (error) {
      console.error("Erreur lors de l'analyse des sources:", error);
      toast.error("Erreur lors de l'analyse des sources");
    } finally {
      setIsLoading(false);
    }
  };

  const handleSourceSelection = (title: string) => {
    setSelectedSources(prev => 
      prev.includes(title)
        ? prev.filter(t => t !== title)
        : [...prev, title]
    );
  };

  const handleContinue = () => {
    const selectedSourcesData = sources
      .filter(source => selectedSources.includes(source.title));
    onComplete({ selectedSources: selectedSourcesData });
  };

  return (
    <div className="space-y-6">
      <Button
        onClick={analyzeSources}
        disabled={isLoading}
        className="w-full"
      >
        {isLoading ? "Analyse en cours..." : "Analyser les sources"}
      </Button>

      {sources.length > 0 && (
        <SourceList
          sources={sources}
          selectedSources={selectedSources}
          onSourceSelect={handleSourceSelection}
        />
      )}

      <Button
        onClick={handleContinue}
        disabled={selectedSources.length === 0}
        className="w-full"
      >
        Continuer avec les sources sélectionnées
      </Button>
    </div>
  );
};