import { useParams, useNavigate } from "react-router-dom";
import { Header } from "@/components/layout/Header";
import { Footer } from "@/components/layout/Footer";
import { useQuery } from "@tanstack/react-query";
import { supabase } from "@/integrations/supabase/client";
import { Helmet } from "react-helmet";
import { toast } from "sonner";
import { ChapterHeader } from "@/components/courses/chapter/ChapterHeader";
import { ChapterView as ChapterViewComponent } from "@/components/courses/chapter/ChapterView";
import { AdSpace } from "@/components/courses/chapter/AdSpace";
import { Chapter } from "@/types/course";

const ChapterView = () => {
  const { courseSlug, chapterSlug } = useParams();
  const navigate = useNavigate();

  const { data: chapter, isLoading } = useQuery({
    queryKey: ["chapter-detail", chapterSlug],
    queryFn: async () => {
      const { data: chapterData, error } = await supabase
        .from("chapters")
        .select(`
          *,
          courses (
            id,
            title,
            slug
          )
        `)
        .eq("slug", chapterSlug)
        .eq("status", "published")
        .maybeSingle();

      if (error) {
        console.error("Error fetching chapter:", error);
        toast.error("Erreur lors du chargement du chapitre");
        throw error;
      }

      if (chapterData) {
        return {
          ...chapterData,
          status: chapterData.status as Chapter["status"]
        };
      }
      return null;
    },
  });

  const { data: chapters } = useQuery({
    queryKey: ["course-chapters", chapter?.course_id],
    queryFn: async () => {
      if (!chapter?.course_id) return [];

      const { data, error } = await supabase
        .from("chapters")
        .select("*")
        .eq("course_id", chapter.course_id)
        .eq("status", "published")
        .order("order_index");

      if (error) {
        console.error("Error fetching chapters:", error);
        return [];
      }

      return data as Chapter[];
    },
    enabled: !!chapter?.course_id,
  });

  const currentIndex = chapters?.findIndex(c => c.id === chapter?.id) ?? 0;
  const totalChapters = chapters?.length ?? 0;

  const handleChapterComplete = (nextChapterId: string) => {
    const nextChapter = chapters?.find(c => c.id === nextChapterId);
    if (nextChapter) {
      navigate(`/code/${courseSlug}/${nextChapter.slug}`);
    }
  };

  if (isLoading) {
    return (
      <div className="min-h-screen flex flex-col">
        <Header />
        <main className="flex-grow">
          <div className="container mx-auto px-4 py-8">
            <div className="animate-pulse space-y-4">
              <div className="h-8 bg-gray-200 rounded w-3/4" />
              <div className="h-64 bg-gray-200 rounded" />
            </div>
          </div>
        </main>
        <Footer />
      </div>
    );
  }

  if (!chapter || !chapters) {
    return (
      <div className="min-h-screen flex flex-col">
        <Header />
        <main className="flex-grow">
          <div className="container mx-auto px-4 py-8">
            <div className="text-center">
              <h1 className="text-2xl font-bold mb-4">Chapitre introuvable</h1>
              <p className="text-muted-foreground">
                Le chapitre que vous recherchez n'existe pas ou n'est pas accessible.
              </p>
            </div>
          </div>
        </main>
        <Footer />
      </div>
    );
  }

  return (
    <div className="min-h-screen flex flex-col">
      <Helmet>
        <title>{chapter.seo_title || chapter.title}</title>
        <meta name="description" content={chapter.meta_description || ""} />
        <meta name="keywords" content={chapter.meta_keywords || ""} />
      </Helmet>

      <Header />
      <main className="flex-grow bg-gray-50">
        <div className="container mx-auto px-4 py-8">
          <ChapterHeader 
            courseSlug={courseSlug || ""}
            onNextChapter={() => {}}
            hasNextChapter={currentIndex < totalChapters - 1}
            hasNextCourse={false}
          />
          <ChapterViewComponent 
            chapter={chapter}
            courseId={chapter.course_id}
            currentIndex={currentIndex}
            totalChapters={totalChapters}
            onChapterComplete={handleChapterComplete}
            chapters={chapters}
          />
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default ChapterView;