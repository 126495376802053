import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import { Clock, ArrowRight } from "lucide-react";
import { Chapter } from "@/types/course";
import { Link, useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { supabase } from "@/integrations/supabase/client";
import { AdSpace } from "../chapter/AdSpace";

interface PublicChaptersListProps {
  chapters: Chapter[];
  courseId: string;
  courseSlug: string;
}

export const PublicChaptersList = ({ chapters, courseId, courseSlug }: PublicChaptersListProps) => {
  const navigate = useNavigate();

  const { data: nextCourse } = useQuery({
    queryKey: ["next-course", courseId],
    queryFn: async () => {
      const { data: currentCourse } = await supabase
        .from("courses")
        .select("order_index")
        .eq("id", courseId)
        .single();

      if (!currentCourse) return null;

      const { data, error } = await supabase
        .from("courses")
        .select("slug")
        .eq("status", "published")
        .gt("order_index", currentCourse.order_index)
        .order("order_index", { ascending: true })
        .limit(1)
        .single();

      if (error && error.code !== 'PGRST116') return null;
      return data;
    },
  });

  const handleNextCourse = () => {
    if (nextCourse?.slug) {
      navigate(`/code/${nextCourse.slug}`);
    } else {
      navigate("/code");
    }
  };

  if (!chapters || chapters.length === 0) return null;

  return (
    <div className="mt-12 space-y-8">
      <h2 className="text-2xl font-bold">Chapitres du cours</h2>
      <div className="space-y-4">
        {chapters
          .sort((a, b) => a.order_index - b.order_index)
          .map((chapter, index) => (
            <Link 
              key={chapter.id} 
              to={`/code/${courseSlug}/${chapter.slug}`}
              className="block"
            >
              <div className="bg-gray-50 rounded-lg p-6 hover:bg-gray-100 transition-colors">
                <div className="flex items-center gap-4">
                  <Badge variant="outline" className="w-8 h-8 rounded-full flex items-center justify-center flex-shrink-0">
                    {index + 1}
                  </Badge>
                  <div className="min-w-0 flex-1">
                    <h3 className="font-semibold text-lg mb-1">{chapter.title}</h3>
                    <Badge variant="secondary" className="flex items-center w-fit">
                      <Clock className="w-3 h-3 mr-1" />
                      {chapter.duration} min
                    </Badge>
                  </div>
                </div>
                {chapter.meta_description && (
                  <div className="mt-4 text-sm text-gray-600">
                    {chapter.meta_description}
                  </div>
                )}
              </div>
            </Link>
          ))}
      </div>

      <div className="flex justify-end pt-6 border-t">
        <Button
          onClick={handleNextCourse}
          className="group"
        >
          {nextCourse ? "Cours suivant" : "Retour aux cours"}
          <ArrowRight className="w-4 h-4 ml-2 transition-transform group-hover:translate-x-1" />
        </Button>
      </div>

      {/* Annonce en bas de page */}
      <div className="mt-8">
        <AdSpace type="content-end" />
      </div>
    </div>
  );
};