import { Link } from "react-router-dom";

export const LoginHeader = () => {
  return (
    <div className="flex flex-col items-center space-y-4">
      <Link to="/" className="flex items-center mb-2">
        <img 
          src="/lovable-uploads/cf83c9da-55bd-4749-b59b-22399ff0630f.png" 
          alt="Bekane" 
          className="h-16 w-auto"
        />
      </Link>
      <h2 className="text-3xl font-bold tracking-tight text-black">
        Connexion à votre compte
      </h2>
      <p className="text-base text-black/80">
        Entrez vos identifiants pour accéder à votre espace
      </p>
    </div>
  );
};