import { Link } from "react-router-dom";

export const Logo = () => {
  return (
    <div className="flex items-center space-x-2">
      <Link to="/" className="flex items-center">
        <img 
          src="/lovable-uploads/cf83c9da-55bd-4749-b59b-22399ff0630f.png" 
          alt="Bekane" 
          className="h-14 w-auto" // Augmenté de h-10 à h-14
        />
      </Link>
    </div>
  );
};