import { useState } from "react";
import { Button } from "@/components/ui/button";
import { Keyword } from "./keyword-selection/types";
import { KeywordAnalyzer } from "./keyword-selection/KeywordAnalyzer";
import { KeywordList } from "./keyword-selection/KeywordList";

interface KeywordSelectionProps {
  data: {
    subject: string;
    selectedSources?: Array<{
      title: string;
      source: string;
      relevance: number;
    }>;
  };
  onComplete: (data: { selectedKeywords: Keyword[] }) => void;
}

export const KeywordSelection = ({ data, onComplete }: KeywordSelectionProps) => {
  const [keywords, setKeywords] = useState<Keyword[]>([]);
  const [selectedKeywords, setSelectedKeywords] = useState<string[]>([]);

  const handleKeywordSelection = (keyword: string) => {
    setSelectedKeywords(prev => 
      prev.includes(keyword)
        ? prev.filter(k => k !== keyword)
        : [...prev, keyword]
    );
  };

  const handleContinue = () => {
    const selectedKeywordsData = keywords
      .filter(kw => selectedKeywords.includes(kw.keyword));
    onComplete({ selectedKeywords: selectedKeywordsData });
  };

  return (
    <div className="space-y-6">
      <KeywordAnalyzer
        subject={data.subject}
        selectedSources={data.selectedSources || []}
        onAnalysisComplete={setKeywords}
      />

      {keywords.length > 0 && (
        <div className="space-y-4">
          <KeywordList
            keywords={keywords}
            selectedKeywords={selectedKeywords}
            onKeywordSelect={handleKeywordSelection}
          />

          <Button
            onClick={handleContinue}
            disabled={selectedKeywords.length === 0}
            className="w-full"
          >
            Continuer avec les mots-clés sélectionnés ({selectedKeywords.length})
          </Button>
        </div>
      )}
    </div>
  );
};