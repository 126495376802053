import { LogOut } from "lucide-react";
import { Button } from "@/components/ui/button";
import { supabase } from "@/integrations/supabase/client";
import { toast } from "sonner";
import { useQueryClient } from "@tanstack/react-query";
import { ProfileSection } from "@/components/profile/ProfileSection";
import { useNavigate } from "react-router-dom";

export const SidebarFooter = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      await supabase.auth.signOut();
      queryClient.clear();
      toast.success("Vous avez été déconnecté avec succès");
      navigate("/auth/login");
    } catch (error) {
      console.error("Erreur lors de la déconnexion:", error);
      toast.error("Une erreur est survenue lors de la déconnexion");
    }
  };

  return (
    <div className="p-4 space-y-4 border-t border-border">
      <ProfileSection />
      <Button 
        variant="ghost" 
        className="w-full justify-start text-red-500 hover:text-red-600 hover:bg-red-50"
        onClick={handleLogout}
      >
        <LogOut className="w-5 h-5 mr-3" />
        Déconnexion
      </Button>
    </div>
  );
};