import { useState } from "react";
import { Button } from "@/components/ui/button";
import { toast } from "sonner";
import { supabase } from "@/integrations/supabase/client";
import { ArticleData } from "../types";
import { Card } from "@/components/ui/card";
import { Loader2 } from "lucide-react";
import { ArticleContent } from "../components/ArticleContent";

interface ArticleFinalizationProps {
  data: ArticleData;
  onComplete: (data: Partial<ArticleData>) => void;
}

export const ArticleFinalization = ({ data, onComplete }: ArticleFinalizationProps) => {
  const [content, setContent] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const generateArticle = async () => {
    setIsLoading(true);
    try {
      const configuration = `Style d'écriture: ${data.configuration?.writingStyle}, Perspective: ${data.configuration?.perspective}, Longueur: ~${data.configuration?.wordCount} mots`;
      
      const prompt = `# ${data.selectedTitle || ""}

${data.selectedPlan || ""}

Instructions de style:
- Style d'écriture: ${data.configuration?.writingStyle}
- Perspective: ${data.configuration?.perspective}
- Longueur visée: ${data.configuration?.wordCount} mots

Mots-clés principaux: ${data.selectedKeywords?.map(kw => kw.keyword).join(", ") || ""}
Mots-clés secondaires: ${data.secondaryKeywords?.map(kw => kw.keyword).join(", ") || ""}

Format requis:
- Utiliser des titres avec # pour H1, ## pour H2, ### pour H3
- Structurer le contenu en sections claires
- Ajouter des listes à puces quand pertinent
- Créer des paragraphes courts et bien espacés
- Inclure une introduction et une conclusion`;

      console.log("🔄 Envoi de la requête de génération d'article:", {
        title: data.selectedTitle,
        keywords: data.selectedKeywords,
        secondaryKeywords: data.secondaryKeywords,
        configuration: data.configuration,
        plan: data.selectedPlan,
        model: data.model,
        prompt
      });

      const { data: response, error } = await supabase.functions.invoke('generate', {
        body: {
          prompt,
          model: data.model
        }
      });

      if (error) throw error;

      console.log("✅ Réponse reçue de l'IA:", response);
      
      if (response && typeof response === 'object' && 'content' in response) {
        console.log("📝 Article généré:", response.content);
        setContent(response.content);
        onComplete({ finalContent: response.content });
        toast.success("Article généré avec succès");
      } else {
        console.error("Format de réponse inattendu:", response);
        toast.error("Format de réponse inattendu");
      }
    } catch (error) {
      console.error("❌ Erreur lors de la génération de l'article:", error);
      toast.error("Erreur lors de la génération de l'article");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="space-y-6">
      <Card className="p-6">
        <Button
          onClick={generateArticle}
          disabled={isLoading}
          className="w-full"
          size="lg"
        >
          {isLoading ? (
            <>
              <Loader2 className="w-4 h-4 mr-2 animate-spin" />
              Génération de l'article en cours...
            </>
          ) : (
            "Générer l'article final"
          )}
        </Button>

        {content && (
          <div className="mt-6">
            <ArticleContent content={content} />
          </div>
        )}
      </Card>
    </div>
  );
};