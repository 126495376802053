import { useQuery } from "@tanstack/react-query";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { ScrollArea } from "@/components/ui/scroll-area";
import { BookOpen, CheckCircle2, Trophy, Clock } from "lucide-react";
import { supabase } from "@/integrations/supabase/client";
import { format } from "date-fns";
import { fr } from "date-fns/locale";

const activityIcons = {
  course_started: BookOpen,
  chapter_completed: CheckCircle2,
  course_completed: Trophy,
  progress_updated: Clock,
};

const activityMessages = {
  course_started: "a commencé",
  chapter_completed: "a terminé le chapitre",
  course_completed: "a terminé",
  progress_updated: "a progressé sur",
};

export const ActivityTimeline = () => {
  const { data: activities, isLoading } = useQuery({
    queryKey: ["student-activities"],
    queryFn: async () => {
      const { data: { session } } = await supabase.auth.getSession();
      if (!session?.user?.id) throw new Error("Non authentifié");

      const { data, error } = await supabase
        .from("user_activities")
        .select(`
          *,
          courses:courses(title),
          chapters:chapters(title)
        `)
        .eq("user_id", session.user.id)
        .order("created_at", { ascending: false })
        .limit(10);

      if (error) throw error;
      return data;
    },
  });

  if (isLoading) {
    return <div>Chargement...</div>;
  }

  return (
    <Card>
      <CardHeader>
        <CardTitle>Activités récentes</CardTitle>
      </CardHeader>
      <CardContent>
        <ScrollArea className="h-[400px] pr-4">
          <div className="space-y-4">
            {activities?.map((activity) => {
              const Icon = activityIcons[activity.activity_type as keyof typeof activityIcons] || Clock;
              const message = activityMessages[activity.activity_type as keyof typeof activityMessages] || "a interagi avec";
              
              return (
                <div
                  key={activity.id}
                  className="flex items-start space-x-4 animate-fadeIn"
                >
                  <div className="flex-shrink-0 mt-1">
                    <div className="p-2 bg-primary/10 rounded-full">
                      <Icon className="h-4 w-4 text-primary" />
                    </div>
                  </div>
                  <div className="flex-1 min-w-0">
                    <p className="text-sm">
                      Vous {message}{" "}
                      <span className="font-medium">
                        {activity.chapters?.title || activity.courses?.title}
                      </span>
                    </p>
                    <p className="text-xs text-muted-foreground">
                      {format(new Date(activity.created_at), "d MMMM yyyy 'à' HH:mm", { locale: fr })}
                    </p>
                  </div>
                </div>
              );
            })}
          </div>
        </ScrollArea>
      </CardContent>
    </Card>
  );
};