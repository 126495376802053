import { useState } from "react";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { AITool } from "@/integrations/supabase/types/ai";
import { Loader2 } from "lucide-react";
import { toast } from "sonner";
import { supabase } from "@/integrations/supabase/client";
import { BlogArticleForm } from "./forms/BlogArticleForm";
import { SimplePromptForm } from "./forms/SimplePromptForm";
import { AIToolResult } from "./AIToolResult";
import { FormData, GenerateResponse } from "./types";
import { Alert, AlertDescription } from "@/components/ui/alert";

interface AIToolRunnerProps {
  tool: AITool;
}

export const AIToolRunner = ({ tool }: AIToolRunnerProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [result, setResult] = useState("");
  const [error, setError] = useState<string | null>(null);
  const [formData, setFormData] = useState<FormData>({
    subject: "",
    keywords: "",
    length: "medium",
    target: "",
    tone: "",
    angle: "",
  });

  const generatePrompt = (): string => {
    if (tool.name === "Rédacteur d'articles de blog") {
      return `
        Sujet : ${formData.subject}
        Mots-clés : ${formData.keywords}
        Longueur : ${formData.length}
        Public cible : ${formData.target}
        Ton : ${formData.tone}
        Angle : ${formData.angle}

        ${tool.prompt}
      `;
    }
    return formData.subject;
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const { data, error: functionError } = await supabase.functions.invoke<GenerateResponse>('generate', {
        body: {
          prompt: generatePrompt(),
          toolId: tool.id,
        },
      });

      if (functionError) {
        console.error("Function error:", functionError);
        throw new Error(functionError.message);
      }

      if (!data) {
        throw new Error("Aucune donnée n'a été reçue");
      }

      if (data.error) {
        setError(data.error);
        toast.error(data.error);
        return;
      }

      setResult(data.content);
      toast.success("Contenu généré avec succès !");
    } catch (error: any) {
      console.error("Error:", error);
      const errorMessage = error.message || "Une erreur est survenue lors de la génération";
      setError(errorMessage);
      toast.error(errorMessage);
    } finally {
      setIsLoading(false);
    }
  };

  const isArticleGenerator = tool.name === "Rédacteur d'articles de blog";

  return (
    <Card>
      <CardHeader>
        <CardTitle>{tool.name}</CardTitle>
      </CardHeader>
      <CardContent className="space-y-4">
        {error && (
          <Alert variant="destructive">
            <AlertDescription>{error}</AlertDescription>
          </Alert>
        )}

        {isArticleGenerator ? (
          <BlogArticleForm formData={formData} setFormData={setFormData} />
        ) : (
          <SimplePromptForm formData={formData} setFormData={setFormData} />
        )}

        <Button
          onClick={handleSubmit}
          disabled={isLoading}
          className="w-full"
        >
          {isLoading ? (
            <>
              <Loader2 className="mr-2 h-4 w-4 animate-spin" />
              Génération en cours...
            </>
          ) : (
            "Générer"
          )}
        </Button>

        <AIToolResult result={result} />
      </CardContent>
    </Card>
  );
};