import { Keyword } from "./types";

export const parseKeywordsFromText = (text: string): Keyword[] => {
  const keywords: Keyword[] = [];
  const lines = text.split('\n');
  let currentKeyword: Partial<Keyword> = {};

  lines.forEach(line => {
    const trimmedLine = line.trim();
    
    // Détecte un nouveau mot-clé (format "**Mot-clé** :" ou "Mot-clé :")
    if (trimmedLine.includes('**Mot-clé**') || trimmedLine.toLowerCase().includes('mot-clé')) {
      // Si on a un mot-clé en cours, on l'ajoute à la liste
      if (Object.keys(currentKeyword).length > 0 && currentKeyword.keyword) {
        keywords.push({
          ...currentKeyword,
          recommended: currentKeyword.recommended || false
        } as Keyword);
      }
      
      // Initialise un nouveau mot-clé
      currentKeyword = {};
      const keywordMatch = trimmedLine.match(/:\s*(.+)$/);
      if (keywordMatch) {
        currentKeyword.keyword = keywordMatch[1].replace(/\*\*/g, '').trim();
      }
    }
    // Parse le volume
    else if (trimmedLine.toLowerCase().includes('volume')) {
      const volumeMatch = trimmedLine.match(/:\s*(\d+)/);
      if (volumeMatch) {
        currentKeyword.volume = parseInt(volumeMatch[1]);
      }
    }
    // Parse la difficulté
    else if (trimmedLine.toLowerCase().includes('difficulté')) {
      const difficultyMatch = trimmedLine.match(/:\s*(\d+)/);
      if (difficultyMatch) {
        currentKeyword.difficulty = parseInt(difficultyMatch[1]);
      }
    }
    // Parse l'intention
    else if (trimmedLine.toLowerCase().includes('intention')) {
      const intentMatch = trimmedLine.match(/:\s*(.+)$/);
      if (intentMatch) {
        currentKeyword.intent = intentMatch[1].trim();
      }
    }
    // Parse la recommandation
    else if (trimmedLine.toLowerCase().includes('recommandé')) {
      currentKeyword.recommended = trimmedLine.toLowerCase().includes('oui');
    }
  });

  // N'oublie pas d'ajouter le dernier mot-clé
  if (Object.keys(currentKeyword).length > 0 && currentKeyword.keyword) {
    keywords.push({
      ...currentKeyword,
      recommended: currentKeyword.recommended || false
    } as Keyword);
  }

  console.log("Mots-clés parsés:", keywords);
  return keywords;
};