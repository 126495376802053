import { useQuery, useQueryClient } from '@tanstack/react-query';
import { supabase } from '@/integrations/supabase/client';

interface CourseProgress {
  course_id: string;
  progress_percentage: number;
  completed_at: string | null;
  total_time_spent: number;
  last_activity_at: string;
}

interface ChapterProgress {
  chapter_id: string;
  course_id: string;
  completed_at: string | null;
  last_visited_at: string;
}

interface CourseStats {
  totalStartedCourses: number;
  completionRate: number;
  averageProgress: number;
  activeCourses: number;
}

interface UseCoursesStatsProps {
  minimumTimeThreshold?: number; // en minutes
  minimumProgressThreshold?: number; // en pourcentage
}

const calculateStats = (
  courseProgress: CourseProgress[],
  chapterProgress: ChapterProgress[],
  minimumTimeThreshold: number,
  minimumProgressThreshold: number
): CourseStats => {
  // Regrouper les chapitres par cours
  const chaptersByCourse = chapterProgress.reduce((acc, chapter) => {
    if (!acc[chapter.course_id]) {
      acc[chapter.course_id] = [];
    }
    acc[chapter.course_id].push(chapter);
    return acc;
  }, {} as Record<string, ChapterProgress[]>);

  // Un cours est considéré comme commencé si :
  // 1. Il a au moins un chapitre visité
  // 2. OU il a une progression > 0
  // 3. OU il a du temps passé dessus
  const startedCourses = courseProgress.filter(course => {
    const hasVisitedChapters = chaptersByCourse[course.course_id]?.length > 0;
    const hasProgress = course.progress_percentage > 0;
    const hasTimeSpent = course.total_time_spent > 0;

    return hasVisitedChapters || hasProgress || hasTimeSpent;
  });

  // Calculer le taux de complétion moyen
  const totalProgress = startedCourses.reduce((sum, course) => {
    return sum + (course.progress_percentage || 0);
  }, 0);

  const completionRate = startedCourses.length > 0
    ? Math.round(totalProgress / startedCourses.length)
    : 0;

  // Calculer les cours actifs (avec activité récente)
  const thirtyDaysAgo = new Date();
  thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);

  const activeCourses = startedCourses.filter(course => 
    course.last_activity_at && new Date(course.last_activity_at) > thirtyDaysAgo
  ).length;

  // Calculer la progression moyenne
  const averageProgress = startedCourses.length > 0
    ? Math.round(totalProgress / startedCourses.length)
    : 0;

  return {
    totalStartedCourses: startedCourses.length,
    completionRate,
    averageProgress,
    activeCourses
  };
};

export const useCoursesStats = ({ 
  minimumTimeThreshold = 5,
  minimumProgressThreshold = 5 
}: UseCoursesStatsProps = {}) => {
  const queryClient = useQueryClient();

  return useQuery({
    queryKey: ['courses-stats'],
    queryFn: async () => {
      const { data: { session } } = await supabase.auth.getSession();
      if (!session?.user?.id) throw new Error("Non authentifié");

      // Récupérer la progression des cours
      const { data: courseProgress, error: courseError } = await supabase
        .from('course_progress')
        .select(`
          course_id,
          progress_percentage,
          completed_at,
          total_time_spent,
          last_activity_at
        `)
        .eq('user_id', session.user.id);

      if (courseError) {
        console.error("Erreur lors de la récupération de la progression des cours:", courseError);
        throw courseError;
      }

      // Récupérer la progression des chapitres
      const { data: chapterProgress, error: chapterError } = await supabase
        .from('chapter_progress')
        .select(`
          chapter_id,
          course_id,
          completed_at,
          last_visited_at
        `)
        .eq('user_id', session.user.id);

      if (chapterError) {
        console.error("Erreur lors de la récupération de la progression des chapitres:", chapterError);
        throw chapterError;
      }

      return calculateStats(
        courseProgress || [], 
        chapterProgress || [],
        minimumTimeThreshold,
        minimumProgressThreshold
      );
    },
    staleTime: 1000 * 60, // Rafraîchir toutes les minutes
  });
};