interface Answer {
  answer: string;
  is_correct: boolean;
}

export const validateQuestion = (
  question: string,
  questionType: string,
  answers: Answer[]
) => {
  if (!question.trim()) {
    throw new Error("La question est requise");
  }

  if (questionType !== "text_input") {
    if (answers.some((a) => !a.answer.trim())) {
      throw new Error("Toutes les réponses doivent être remplies");
    }
    if (!answers.some((a) => a.is_correct)) {
      throw new Error("Il faut au moins une bonne réponse");
    }
  }
};