import { BookOpen, CheckCircle2, GraduationCap } from "lucide-react";

const features = [
  {
    title: "Cours interactifs",
    description: "Des leçons engageantes avec des exemples concrets et des cas pratiques.",
    icon: <BookOpen className="h-6 w-6" />,
  },
  {
    title: "Suivi personnalisé",
    description: "Un tableau de bord pour suivre votre progression et identifier vos points d'amélioration.",
    icon: <CheckCircle2 className="h-6 w-6" />,
  },
  {
    title: "Formateurs qualifiés",
    description: "Une équipe d'instructeurs expérimentés pour vous accompagner.",
    icon: <GraduationCap className="h-6 w-6" />,
  },
];

export const FeaturesSection = () => {
  return (
    <section className="py-16 md:py-24">
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div className="text-center">
          <h2 className="text-3xl font-bold tracking-tight sm:text-4xl">
            Pourquoi choisir Bekane ?
          </h2>
          <p className="mt-4 text-lg text-black">
            Une formation complète et adaptée à vos besoins
          </p>
        </div>

        <div className="mt-16 grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
          {features.map((feature, index) => (
            <div
              key={feature.title}
              className="group relative rounded-2xl border p-6 hover:border-primary transition-colors animate-fadeIn"
              style={{ animationDelay: `${index * 100}ms` }}
            >
              <div className="flex h-12 w-12 items-center justify-center rounded-full bg-primary/10 text-primary">
                {feature.icon}
              </div>
              <h3 className="mt-4 text-xl font-semibold">{feature.title}</h3>
              <p className="mt-2 text-black">{feature.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};