import { Header } from "@/components/layout/Header";
import { Footer } from "@/components/layout/Footer";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import { Mail, MapPin } from "lucide-react";
import { Helmet } from "react-helmet";

const Contact = () => {
  return (
    <div className="min-h-screen flex flex-col">
      <Helmet>
        <title>Contact | Bekane - Formation code moto en ligne</title>
        <meta name="description" content="Contactez l'équipe Bekane pour toute question sur notre formation au code de la route moto en ligne. Support réactif et accompagnement personnalisé." />
        <meta name="keywords" content="contact bekane, formation moto, code moto en ligne, support formation moto" />
        <meta name="author" content="Bekane" />
        <meta property="og:title" content="Contact | Bekane - Formation code moto en ligne" />
        <meta property="og:description" content="Contactez l'équipe Bekane pour toute question sur notre formation au code de la route moto en ligne." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.bekane.fr/contact" />
        <link rel="canonical" href="https://www.bekane.fr/contact" />
        {/* Google Tag Manager */}
        <script>
          {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-WNTHDK2Z');`}
        </script>
      </Helmet>
      <Header />
      <main className="flex-grow container mx-auto px-4 py-8">
        {/* Google Tag Manager (noscript) */}
        <noscript>
          <iframe 
            src="https://www.googletagmanager.com/ns.html?id=GTM-WNTHDK2Z"
            height="0" 
            width="0" 
            style={{ display: 'none', visibility: 'hidden' }}
          />
        </noscript>
        <h1 className="text-4xl font-bold mb-8">Contactez-nous</h1>
        
        <div className="grid md:grid-cols-2 gap-12">
          <div>
            <form className="space-y-6">
              <div>
                <label htmlFor="name" className="block text-sm font-medium mb-2">Nom</label>
                <Input id="name" placeholder="Votre nom" />
              </div>
              
              <div>
                <label htmlFor="email" className="block text-sm font-medium mb-2">Email</label>
                <Input id="email" type="email" placeholder="votre@email.com" />
              </div>
              
              <div>
                <label htmlFor="message" className="block text-sm font-medium mb-2">Message</label>
                <Textarea id="message" placeholder="Votre message" className="h-32" />
              </div>
              
              <Button type="submit" className="w-full">Envoyer</Button>
            </form>
          </div>
          
          <div className="space-y-8">
            <div>
              <h2 className="text-2xl font-semibold mb-4">Informations de contact</h2>
              <div className="space-y-4">
                <div className="flex items-center space-x-3">
                  <Mail className="h-5 w-5 text-primary" />
                  <span>contact@bekane.fr</span>
                </div>
                <div className="flex items-center space-x-3">
                  <MapPin className="h-5 w-5 text-primary" />
                  <span>Paris, France</span>
                </div>
              </div>
            </div>
            
            <div>
              <h2 className="text-2xl font-semibold mb-4">FAQ</h2>
              <div className="space-y-4">
                <div>
                  <h3 className="font-medium mb-2">Combien de temps pour passer le code ?</h3>
                  <p className="text-gray-600">La durée moyenne de préparation est de 2 à 3 semaines avec une pratique régulière.</p>
                </div>
                <div>
                  <h3 className="font-medium mb-2">Comment se déroule l'examen ?</h3>
                  <p className="text-gray-600">L'examen dure 30 minutes et comporte 40 questions à choix multiples.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default Contact;
