import { DashboardLayout } from "@/components/layout/DashboardLayout";
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";
import { AlertCircle } from "lucide-react";

interface CourseViewErrorProps {
  message: string;
  description: string;
}

export const CourseViewError = ({ message, description }: CourseViewErrorProps) => {
  return (
    <DashboardLayout type="student">
      <div className="p-4 lg:p-6">
        <Alert variant="destructive">
          <AlertCircle className="h-4 w-4" />
          <AlertTitle>{message}</AlertTitle>
          <AlertDescription>{description}</AlertDescription>
        </Alert>
      </div>
    </DashboardLayout>
  );
};