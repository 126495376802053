import { Button } from "@/components/ui/button";
import { ChevronLeft, ChevronRight, ArrowRight } from "lucide-react";
import { useCourseNavigation } from "@/hooks/useCourseNavigation";

interface ChapterNavigationProps {
  courseId: string;
  currentIndex: number;
  totalChapters: number;
  onChapterChange: (chapterId: string) => void;
  chapters: any[];
}

export const ChapterNavigation = ({
  courseId,
  currentIndex,
  totalChapters,
  onChapterChange,
  chapters,
}: ChapterNavigationProps) => {
  const { handleNextCourse } = useCourseNavigation(courseId);
  const isLastChapter = currentIndex === totalChapters - 1;

  const handlePrevious = () => {
    if (currentIndex > 0) {
      onChapterChange(chapters[currentIndex - 1].id);
    }
  };

  const handleNext = () => {
    if (isLastChapter) {
      handleNextCourse();
    } else {
      onChapterChange(chapters[currentIndex + 1].id);
    }
  };

  return (
    <div className="flex justify-between gap-2 mt-8 mb-4 px-4">
      <Button
        variant="outline"
        onClick={handlePrevious}
        disabled={currentIndex === 0}
        className="w-full"
      >
        <ChevronLeft className="w-4 h-4 mr-2" />
        Précédent
      </Button>
      <Button onClick={handleNext} className="w-full group">
        {isLastChapter ? (
          <>
            Cours suivant
            <ArrowRight className="w-4 h-4 ml-2 transition-transform group-hover:translate-x-1" />
          </>
        ) : (
          <>
            Suivant
            <ChevronRight className="w-4 h-4 ml-2" />
          </>
        )}
      </Button>
    </div>
  );
};