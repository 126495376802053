import { useState } from "react";
import { Button } from "@/components/ui/button";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Video, Folder } from "lucide-react";
import { supabase } from "@/integrations/supabase/client";
import { toast } from "sonner";

interface VideoGalleryProps {
  onSelect: (url: string) => void;
  folder?: string;
}

interface VideoFile {
  id: string;
  title: string;
  file_path: string;
  content_type: string;
}

export const VideoGallery = ({ onSelect, folder }: VideoGalleryProps) => {
  const [videos, setVideos] = useState<VideoFile[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const loadVideos = async () => {
    setIsLoading(true);
    try {
      const { data, error } = await supabase
        .from("video_files")
        .select("*")
        .eq("folder_path", folder || "videos")
        .order("created_at", { ascending: false });

      if (error) throw error;
      setVideos(data || []);
    } catch (error) {
      console.error("Error loading videos:", error);
      toast.error("Erreur lors du chargement des vidéos");
    } finally {
      setIsLoading(false);
    }
  };

  const handleSelect = async (video: VideoFile) => {
    try {
      const { data } = supabase.storage
        .from("course-videos")
        .getPublicUrl(video.file_path);
      
      onSelect(data.publicUrl);
    } catch (error) {
      console.error("Error getting video URL:", error);
      toast.error("Erreur lors de la sélection de la vidéo");
    }
  };

  return (
    <div className="space-y-4">
      <div className="flex items-center gap-2">
        <Folder className="h-4 w-4" />
        <span className="text-sm text-muted-foreground">
          {folder || "Vidéos"}
        </span>
      </div>

      <ScrollArea className="h-[300px] w-full rounded-md border">
        {isLoading ? (
          <div className="flex items-center justify-center h-full">
            <span className="text-sm text-muted-foreground">
              Chargement...
            </span>
          </div>
        ) : videos.length === 0 ? (
          <div className="flex flex-col items-center justify-center h-full gap-2 p-4">
            <Video className="h-8 w-8 text-muted-foreground" />
            <span className="text-sm text-muted-foreground">
              Aucune vidéo disponible
            </span>
            <Button
              variant="outline"
              size="sm"
              onClick={loadVideos}
            >
              Rafraîchir
            </Button>
          </div>
        ) : (
          <div className="grid grid-cols-2 gap-4 p-4">
            {videos.map((video) => (
              <div
                key={video.id}
                className="relative group cursor-pointer"
                onClick={() => handleSelect(video)}
              >
                <div className="aspect-video bg-muted rounded-lg flex items-center justify-center">
                  <Video className="h-8 w-8 text-muted-foreground" />
                </div>
                <div className="mt-2">
                  <p className="text-sm font-medium truncate">{video.title}</p>
                </div>
              </div>
            ))}
          </div>
        )}
      </ScrollArea>
    </div>
  );
};