import { useEffect, useState } from "react";
import { Card, CardHeader, CardTitle, CardContent } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { Plus, Edit, Trash2 } from "lucide-react";
import { supabase } from "@/integrations/supabase/client";
import { toast } from "sonner";
import { QuizQuestion } from "@/types/quiz";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog";

interface QuizListProps {
  chapterId: string;
  onEdit: (question: QuizQuestion) => void;
  onAdd: () => void;
}

export const QuizList = ({ chapterId, onEdit, onAdd }: QuizListProps) => {
  const [questions, setQuestions] = useState<QuizQuestion[]>([]);

  useEffect(() => {
    fetchQuestions();
  }, [chapterId]);

  const fetchQuestions = async () => {
    try {
      const { data, error } = await supabase
        .from("chapter_quiz_questions")
        .select(`
          *,
          answers:chapter_quiz_answers(*)
        `)
        .eq("chapter_id", chapterId)
        .order("order_index");

      if (error) throw error;
      setQuestions(data || []);
    } catch (error) {
      console.error("Error fetching questions:", error);
      toast.error("Erreur lors du chargement des questions");
    }
  };

  const handleDelete = async (questionId: string) => {
    try {
      const { error: answersError } = await supabase
        .from("chapter_quiz_answers")
        .delete()
        .eq("question_id", questionId);

      if (answersError) throw answersError;

      const { error: questionError } = await supabase
        .from("chapter_quiz_questions")
        .delete()
        .eq("id", questionId);

      if (questionError) throw questionError;

      toast.success("Question supprimée avec succès");
      fetchQuestions();
    } catch (error) {
      console.error("Error deleting question:", error);
      toast.error("Erreur lors de la suppression de la question");
    }
  };

  return (
    <div className="space-y-4">
      <div className="flex justify-between items-center">
        <h3 className="text-lg font-semibold">Questions</h3>
        <Button onClick={onAdd} size="sm">
          <Plus className="h-4 w-4 mr-2" />
          Ajouter une question
        </Button>
      </div>

      {questions.length > 0 ? (
        <div className="grid gap-4">
          {questions.map((question) => (
            <Card key={question.id}>
              <CardHeader className="pb-4">
                <div className="flex justify-between items-start">
                  <div className="space-y-2">
                    <CardTitle className="text-lg">{question.question}</CardTitle>
                    {question.explanation && (
                      <p className="text-sm text-muted-foreground">
                        {question.explanation}
                      </p>
                    )}
                  </div>
                  <div className="flex gap-2">
                    <Button
                      variant="outline"
                      size="sm"
                      onClick={() => onEdit(question)}
                    >
                      <Edit className="h-4 w-4" />
                    </Button>
                    <AlertDialog>
                      <AlertDialogTrigger asChild>
                        <Button variant="destructive" size="sm">
                          <Trash2 className="h-4 w-4" />
                        </Button>
                      </AlertDialogTrigger>
                      <AlertDialogContent>
                        <AlertDialogHeader>
                          <AlertDialogTitle>
                            Supprimer la question ?
                          </AlertDialogTitle>
                          <AlertDialogDescription>
                            Cette action est irréversible. La question et toutes ses
                            réponses seront définitivement supprimées.
                          </AlertDialogDescription>
                        </AlertDialogHeader>
                        <AlertDialogFooter>
                          <AlertDialogCancel>Annuler</AlertDialogCancel>
                          <AlertDialogAction
                            onClick={() => handleDelete(question.id)}
                            className="bg-destructive text-destructive-foreground hover:bg-destructive/90"
                          >
                            Supprimer
                          </AlertDialogAction>
                        </AlertDialogFooter>
                      </AlertDialogContent>
                    </AlertDialog>
                  </div>
                </div>
              </CardHeader>
              <CardContent>
                <div className="space-y-2">
                  {question.answers.map((answer, index) => (
                    <div
                      key={answer.id}
                      className={`p-2 rounded-md ${
                        answer.is_correct
                          ? "bg-green-100 dark:bg-green-900/20"
                          : "bg-gray-100 dark:bg-gray-800"
                      }`}
                    >
                      <span className="mr-2">{index + 1}.</span>
                      {answer.answer}
                      {answer.is_correct && (
                        <span className="ml-2 text-green-600 dark:text-green-400">
                          (Correcte)
                        </span>
                      )}
                    </div>
                  ))}
                </div>
              </CardContent>
            </Card>
          ))}
        </div>
      ) : (
        <Card className="p-8 text-center text-muted-foreground">
          Aucune question n'a été créée pour ce chapitre.
        </Card>
      )}
    </div>
  );
};