import { DashboardLayout } from "@/components/layout/DashboardLayout";
import { useQuery } from "@tanstack/react-query";
import { supabase } from "@/integrations/supabase/client";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Users, CheckCircle, XCircle, DollarSign } from "lucide-react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { Badge } from "@/components/ui/badge";
import { format } from "date-fns";
import { fr } from "date-fns/locale";

const AdminSubscriptions = () => {
  const { data: stats } = useQuery({
    queryKey: ["subscription-stats"],
    queryFn: async () => {
      const { count: activeCount } = await supabase
        .from("subscriptions")
        .select("*", { count: "exact", head: true })
        .eq("status", "active");

      const { count: totalCount } = await supabase
        .from("subscriptions")
        .select("*", { count: "exact", head: true });

      const { data: planStats } = await supabase
        .from("subscriptions")
        .select("plan_type, status")
        .eq("status", "active");

      const planCounts = planStats?.reduce((acc: Record<string, number>, curr) => {
        acc[curr.plan_type] = (acc[curr.plan_type] || 0) + 1;
        return acc;
      }, {});

      return {
        activeSubscriptions: activeCount || 0,
        totalSubscriptions: totalCount || 0,
        planStats: planCounts || {},
      };
    },
  });

  const { data: subscriptions } = useQuery({
    queryKey: ["subscriptions"],
    queryFn: async () => {
      const { data, error } = await supabase
        .from("subscriptions")
        .select(`
          *,
          profiles:user_id (
            first_name,
            last_name
          )
        `)
        .order("created_at", { ascending: false });

      if (error) throw error;
      return data;
    },
  });

  return (
    <DashboardLayout type="admin">
      <div className="space-y-8">
        <div className="flex items-center justify-between">
          <h1 className="text-3xl font-bold">Abonnements</h1>
        </div>

        <div className="grid gap-4 md:grid-cols-4">
          <Card>
            <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
              <CardTitle className="text-sm font-medium">
                Total Abonnés
              </CardTitle>
              <Users className="h-4 w-4 text-muted-foreground" />
            </CardHeader>
            <CardContent>
              <div className="text-2xl font-bold">{stats?.totalSubscriptions}</div>
            </CardContent>
          </Card>

          <Card>
            <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
              <CardTitle className="text-sm font-medium">
                Abonnements Actifs
              </CardTitle>
              <CheckCircle className="h-4 w-4 text-muted-foreground" />
            </CardHeader>
            <CardContent>
              <div className="text-2xl font-bold">{stats?.activeSubscriptions}</div>
            </CardContent>
          </Card>

          <Card>
            <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
              <CardTitle className="text-sm font-medium">
                Abonnements Inactifs
              </CardTitle>
              <XCircle className="h-4 w-4 text-muted-foreground" />
            </CardHeader>
            <CardContent>
              <div className="text-2xl font-bold">
                {(stats?.totalSubscriptions || 0) - (stats?.activeSubscriptions || 0)}
              </div>
            </CardContent>
          </Card>

          <Card>
            <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
              <CardTitle className="text-sm font-medium">
                Revenus Mensuels
              </CardTitle>
              <DollarSign className="h-4 w-4 text-muted-foreground" />
            </CardHeader>
            <CardContent>
              <div className="text-2xl font-bold">
                {stats?.planStats?.premium || 0} Premium
              </div>
              <p className="text-xs text-muted-foreground">
                {stats?.planStats?.essentiel || 0} Essentiel ·{" "}
                {stats?.planStats?.pro || 0} Pro
              </p>
            </CardContent>
          </Card>
        </div>

        <Card>
          <CardHeader>
            <CardTitle>Liste des abonnements</CardTitle>
          </CardHeader>
          <CardContent>
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead>Utilisateur</TableHead>
                  <TableHead>Plan</TableHead>
                  <TableHead>Statut</TableHead>
                  <TableHead>Date de début</TableHead>
                  <TableHead>Date de fin</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {subscriptions?.map((subscription) => (
                  <TableRow key={subscription.id}>
                    <TableCell>
                      {subscription.profiles?.first_name}{" "}
                      {subscription.profiles?.last_name}
                    </TableCell>
                    <TableCell className="capitalize">{subscription.plan_type}</TableCell>
                    <TableCell>
                      <Badge
                        variant={
                          subscription.status === "active" ? "success" : "destructive"
                        }
                      >
                        {subscription.status === "active"
                          ? "Actif"
                          : subscription.status === "cancelled"
                          ? "Annulé"
                          : "Expiré"}
                      </Badge>
                    </TableCell>
                    <TableCell>
                      {format(new Date(subscription.start_date), "dd MMMM yyyy", {
                        locale: fr,
                      })}
                    </TableCell>
                    <TableCell>
                      {subscription.end_date
                        ? format(new Date(subscription.end_date), "dd MMMM yyyy", {
                            locale: fr,
                          })
                        : "-"}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </CardContent>
        </Card>
      </div>
    </DashboardLayout>
  );
};

export default AdminSubscriptions;