import { useState } from "react";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Textarea } from "@/components/ui/textarea";
import { Tiptap } from "../editor/Tiptap";
import { toast } from "sonner";
import { ImageUpload } from "../courses/ImageUpload";
import { HTMLImportDialog } from "../courses/import/HTMLImportDialog";
import { ImportArticleDialog } from "./ImportArticleDialog";
import { FileText, Link } from "lucide-react";

interface BlogPost {
  id: string;
  title: string;
  content: string;
  image_url?: string;
  status: string;
  slug: string;
  meta_description?: string;
  meta_keywords?: string;
  seo_title?: string;
}

interface BlogFormProps {
  initialData?: BlogPost;
  onSubmit: (data: Omit<BlogPost, "id"> & { id?: string }) => void;
  onCancel: () => void;
}

export const BlogForm = ({
  initialData,
  onSubmit,
  onCancel,
}: BlogFormProps) => {
  const [title, setTitle] = useState(initialData?.title || "");
  const [content, setContent] = useState(initialData?.content || "");
  const [imageUrl, setImageUrl] = useState(initialData?.image_url || "");
  const [slug, setSlug] = useState(initialData?.slug || "");
  const [seoTitle, setSeoTitle] = useState(initialData?.seo_title || "");
  const [metaDescription, setMetaDescription] = useState(
    initialData?.meta_description || ""
  );
  const [metaKeywords, setMetaKeywords] = useState(
    initialData?.meta_keywords || ""
  );
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isImportDialogOpen, setIsImportDialogOpen] = useState(false);
  const [isArticleImportDialogOpen, setIsArticleImportDialogOpen] = useState(false);

  const generateSlug = (title: string) => {
    return title
      .toLowerCase()
      .replace(/[^a-z0-9]+/g, "-")
      .replace(/(^-|-$)/g, "");
  };

  const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newTitle = e.target.value;
    setTitle(newTitle);
    if (!initialData) {
      setSlug(generateSlug(newTitle));
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      await onSubmit({
        title,
        content,
        image_url: imageUrl,
        status: "draft",
        slug,
        seo_title: seoTitle,
        meta_description: metaDescription,
        meta_keywords: metaKeywords,
      });
      toast.success(
        initialData ? "Article modifié" : "Article créé avec succès!"
      );
    } catch (error) {
      console.error("Error submitting blog post:", error);
      toast.error(
        "Une erreur est survenue lors de l'enregistrement de l'article"
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleImport = (importedContent: string) => {
    setContent(importedContent);
    toast.success("Contenu importé avec succès");
  };

  const handleArticleImport = (data: {
    title: string;
    content: string;
    meta_description?: string;
    meta_keywords?: string;
  }) => {
    setTitle(data.title);
    setContent(data.content);
    setSlug(generateSlug(data.title));
    if (data.meta_description) setMetaDescription(data.meta_description);
    if (data.meta_keywords) setMetaKeywords(data.meta_keywords);
    toast.success("Article importé avec succès");
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-8">
      <Card>
        <CardHeader>
          <div className="flex items-center justify-between">
            <CardTitle>
              {initialData ? "Modifier l'article" : "Nouvel article"}
            </CardTitle>
            <div className="flex gap-2">
              <Button
                type="button"
                variant="outline"
                size="sm"
                onClick={() => setIsImportDialogOpen(true)}
              >
                <FileText className="w-4 h-4 mr-2" />
                Importer du HTML
              </Button>
              <Button
                type="button"
                variant="outline"
                size="sm"
                onClick={() => setIsArticleImportDialogOpen(true)}
              >
                <Link className="w-4 h-4 mr-2" />
                Importer depuis une URL
              </Button>
            </div>
          </div>
        </CardHeader>
        <CardContent className="space-y-6">
          <div className="space-y-2">
            <Label htmlFor="title">Titre de l'article</Label>
            <Input
              id="title"
              value={title}
              onChange={handleTitleChange}
              placeholder="Entrez le titre de l'article"
              required
            />
          </div>

          <div className="space-y-2">
            <Label htmlFor="slug">URL de l'article</Label>
            <Input
              id="slug"
              value={slug}
              onChange={(e) => setSlug(e.target.value)}
              placeholder="url-de-l-article"
              required
            />
          </div>

          <div className="space-y-2">
            <Label>Image de couverture</Label>
            <ImageUpload
              currentImage={imageUrl}
              onUploadComplete={(url) => setImageUrl(url)}
              folder="blog"
            />
          </div>

          <div className="space-y-2">
            <div className="flex items-center justify-between mb-2">
              <Label>Contenu de l'article</Label>
              <Button
                type="button"
                variant="outline"
                size="sm"
                onClick={() => setIsImportDialogOpen(true)}
              >
                <FileText className="w-4 h-4 mr-2" />
                Importer du HTML
              </Button>
            </div>
            <Tiptap content={content} onChange={setContent} />
          </div>

          <Card>
            <CardHeader>
              <CardTitle>Optimisation SEO</CardTitle>
            </CardHeader>
            <CardContent className="space-y-4">
              <div className="space-y-2">
                <Label htmlFor="seoTitle">Titre SEO</Label>
                <Input
                  id="seoTitle"
                  value={seoTitle}
                  onChange={(e) => setSeoTitle(e.target.value)}
                  placeholder="Titre optimisé pour les moteurs de recherche"
                />
              </div>

              <div className="space-y-2">
                <Label htmlFor="metaDescription">Description Meta</Label>
                <Textarea
                  id="metaDescription"
                  value={metaDescription}
                  onChange={(e) => setMetaDescription(e.target.value)}
                  placeholder="Description courte qui apparaîtra dans les résultats de recherche"
                  className="h-20"
                />
              </div>

              <div className="space-y-2">
                <Label htmlFor="metaKeywords">Mots-clés Meta</Label>
                <Input
                  id="metaKeywords"
                  value={metaKeywords}
                  onChange={(e) => setMetaKeywords(e.target.value)}
                  placeholder="Mots-clés séparés par des virgules"
                />
              </div>
            </CardContent>
          </Card>

          <div className="flex justify-end space-x-2">
            <Button type="button" variant="outline" onClick={onCancel}>
              Annuler
            </Button>
            <Button type="submit" disabled={isSubmitting}>
              {isSubmitting
                ? "Enregistrement..."
                : initialData
                ? "Mettre à jour"
                : "Créer"}
            </Button>
          </div>
        </CardContent>
      </Card>

      <HTMLImportDialog
        isOpen={isImportDialogOpen}
        onOpenChange={setIsImportDialogOpen}
        onImport={handleImport}
      />

      <ImportArticleDialog
        isOpen={isArticleImportDialogOpen}
        onOpenChange={setIsArticleImportDialogOpen}
        onImport={handleArticleImport}
      />
    </form>
  );
};