import { List, ListOrdered } from 'lucide-react';
import { Toggle } from '@/components/ui/toggle';
import { Editor } from '@tiptap/react';

interface ListButtonsProps {
  editor: Editor;
}

export const ListButtons = ({ editor }: ListButtonsProps) => {
  return (
    <div className="flex items-center gap-1">
      <Toggle
        size="sm"
        pressed={editor.isActive('bulletList')}
        onPressedChange={() => editor.chain().focus().toggleBulletList().run()}
        aria-label="Liste à puces"
      >
        <List className="h-4 w-4" />
      </Toggle>
      <Toggle
        size="sm"
        pressed={editor.isActive('orderedList')}
        onPressedChange={() => editor.chain().focus().toggleOrderedList().run()}
        aria-label="Liste numérotée"
      >
        <ListOrdered className="h-4 w-4" />
      </Toggle>
    </div>
  );
};