import { Chapter } from "@/types/course";
import { ChapterContent } from "@/components/courses/chapter/ChapterContent";

interface CourseViewContentProps {
  currentChapter: Chapter;
  courseId: string;
  currentIndex: number;
  totalChapters: number;
  onChapterComplete: (chapterId: string) => void;
  chapters: Chapter[];
}

export const CourseViewContent = ({
  currentChapter,
  courseId,
  currentIndex,
  totalChapters,
  onChapterComplete,
  chapters,
}: CourseViewContentProps) => {
  if (!currentChapter) return null;

  return (
    <div className="container mx-auto px-4 sm:px-6 pb-20 lg:pb-6">
      <ChapterContent
        chapter={currentChapter}
        courseId={courseId}
        currentIndex={currentIndex}
        totalChapters={totalChapters}
        onChapterComplete={onChapterComplete}
        chapters={chapters}
      />
    </div>
  );
};