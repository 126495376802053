import { Link } from "react-router-dom";
import { Button } from "@/components/ui/button";
import { ArrowLeft, ArrowRight } from "lucide-react";

interface ChapterHeaderProps {
  courseSlug: string;
  onNextChapter: () => void;
  hasNextChapter: boolean;
  hasNextCourse: boolean;
}

export const ChapterHeader = ({ 
  courseSlug, 
  onNextChapter, 
  hasNextChapter,
  hasNextCourse 
}: ChapterHeaderProps) => {
  return (
    <div className="mb-6 flex justify-between items-center">
      <Link to={`/code/${courseSlug}`}>
        <Button variant="ghost" className="gap-2">
          <ArrowLeft className="w-4 h-4" />
          Retour au cours
        </Button>
      </Link>
      <Button onClick={onNextChapter} className="gap-2">
        {hasNextChapter ? "Chapitre suivant" : hasNextCourse ? "Cours suivant" : "Retour aux cours"}
        <ArrowRight className="w-4 h-4" />
      </Button>
    </div>
  );
};