import { Editor } from '@tiptap/react';
import { Type } from 'lucide-react';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { Button } from '@/components/ui/button';

const fonts = [
  { name: 'Default', value: 'Inter' },
  { name: 'Serif', value: 'serif' },
  { name: 'Monospace', value: 'monospace' },
  { name: 'Cursive', value: 'cursive' },
];

interface FontFamilySelectProps {
  editor: Editor;
}

export const FontFamilySelect = ({ editor }: FontFamilySelectProps) => {
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="ghost" size="sm" className="h-8">
          <Type className="h-4 w-4" />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        {fonts.map((font) => (
          <DropdownMenuItem
            key={font.value}
            onClick={() => editor.chain().focus().setFontFamily(font.value).run()}
            className={`font-${font.value}`}
          >
            {font.name}
          </DropdownMenuItem>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};