import { useState, useEffect } from "react";
import { DashboardLayout } from "@/components/layout/DashboardLayout";
import { BlogList } from "@/components/blog/BlogList";
import { BlogForm } from "@/components/blog/BlogForm";
import { CourseFilters } from "@/components/courses/CourseFilters";
import { supabase } from "@/integrations/supabase/client";
import { toast } from "sonner";

const AdminBlog = () => {
  const [posts, setPosts] = useState([]);
  const [isCreating, setIsCreating] = useState(false);
  const [editingPost, setEditingPost] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [statusFilter, setStatusFilter] = useState("all");
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetchPosts();
  }, [searchQuery, statusFilter]);

  const fetchPosts = async () => {
    try {
      setIsLoading(true);
      let query = supabase
        .from("blog_posts")
        .select("*")
        .order("created_at", { ascending: false });

      if (searchQuery) {
        query = query.ilike("title", `%${searchQuery}%`);
      }

      if (statusFilter !== "all") {
        query = query.eq("status", statusFilter);
      }

      const { data, error } = await query;

      if (error) throw error;
      setPosts(data || []);
    } catch (error) {
      console.error("Error fetching posts:", error);
      toast.error("Erreur lors du chargement des articles");
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = async (postData) => {
    try {
      if (editingPost) {
        const { error } = await supabase
          .from("blog_posts")
          .update(postData)
          .eq("id", editingPost.id);

        if (error) throw error;
        toast.success("Article mis à jour avec succès");
      } else {
        const { error } = await supabase.from("blog_posts").insert([postData]);
        if (error) throw error;
        toast.success("Article créé avec succès");
      }

      await fetchPosts();
      setIsCreating(false);
      setEditingPost(null);
    } catch (error) {
      console.error("Error submitting post:", error);
      toast.error("Erreur lors de l'enregistrement de l'article");
    }
  };

  const handleStatusChange = async (postId: string, newStatus: string) => {
    try {
      const { error } = await supabase
        .from("blog_posts")
        .update({ status: newStatus })
        .eq("id", postId);

      if (error) throw error;
      
      toast.success(`Statut mis à jour : ${newStatus}`);
      await fetchPosts();
    } catch (error) {
      console.error("Error updating status:", error);
      toast.error("Erreur lors de la mise à jour du statut");
    }
  };

  const handleDelete = async (postId: string) => {
    try {
      const { error } = await supabase
        .from("blog_posts")
        .delete()
        .eq("id", postId);

      if (error) throw error;
      
      toast.success("Article supprimé avec succès");
      await fetchPosts();
    } catch (error) {
      console.error("Error deleting post:", error);
      toast.error("Erreur lors de la suppression de l'article");
    }
  };

  if (isCreating || editingPost) {
    return (
      <DashboardLayout type="admin">
        <BlogForm
          initialData={editingPost}
          onSubmit={handleSubmit}
          onCancel={() => {
            setIsCreating(false);
            setEditingPost(null);
          }}
        />
      </DashboardLayout>
    );
  }

  return (
    <DashboardLayout type="admin">
      <div className="space-y-8">
        <h1 className="text-3xl font-bold">Gestion du blog</h1>
        <CourseFilters
          searchQuery={searchQuery}
          onSearchChange={setSearchQuery}
          statusFilter={statusFilter}
          onStatusFilterChange={setStatusFilter}
        />
        <BlogList
          posts={posts}
          onEdit={setEditingPost}
          onAdd={() => setIsCreating(true)}
          onStatusChange={handleStatusChange}
          onDelete={handleDelete}
        />
      </div>
    </DashboardLayout>
  );
};

export default AdminBlog;