import { Course, CourseStatus } from "@/types/course";
import { CourseCard } from "./CourseCard";

interface CourseListProps {
  courses: Course[];
  onEdit: (course: Course) => void;
  onStatusChange: (courseId: string, status: CourseStatus) => void;
}

export const CourseList = ({ courses, onEdit, onStatusChange }: CourseListProps) => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
      {courses?.map((course) => (
        <CourseCard
          key={course.id}
          course={course}
          onEdit={onEdit}
          onStatusChange={onStatusChange}
        />
      ))}
    </div>
  );
};