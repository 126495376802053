import { DashboardLayout } from "@/components/layout/DashboardLayout";
import { DashboardStats } from "@/components/student/dashboard/DashboardStats";
import { Helmet } from "react-helmet";

const StudentDashboard = () => {
  return (
    <DashboardLayout type="student">
      <Helmet>
        <title>Tableau de bord | Bekane</title>
        <meta 
          name="description" 
          content="Suivez votre progression et accédez à vos statistiques d'apprentissage." 
        />
      </Helmet>

      <div className="space-y-8">
        <section>
          <h1 className="text-3xl font-bold mb-4">
            Tableau de bord
          </h1>
          <p className="text-muted-foreground">
            Suivez votre progression et visualisez vos statistiques d'apprentissage
          </p>
        </section>

        <DashboardStats />
      </div>
    </DashboardLayout>
  );
};

export default StudentDashboard;