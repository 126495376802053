import { Textarea } from "@/components/ui/textarea";
import { FormData } from "../types";

interface SimplePromptFormProps {
  formData: FormData;
  setFormData: (data: FormData) => void;
}

export const SimplePromptForm = ({ formData, setFormData }: SimplePromptFormProps) => {
  return (
    <Textarea
      value={formData.subject}
      onChange={(e) =>
        setFormData({ ...formData, subject: e.target.value })
      }
      placeholder="Entrez votre prompt ici..."
      className="min-h-[100px]"
    />
  );
};