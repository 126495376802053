import { useState } from "react";
import { DashboardLayout } from "@/components/layout/DashboardLayout";
import { Course } from "@/types/course";
import { CourseList } from "@/components/courses/CourseList";
import { CourseListView } from "@/components/courses/CourseListView";
import { CoursesHeader } from "@/components/courses/CoursesHeader";
import { CourseFilters } from "@/components/courses/CourseFilters";
import { ViewToggle } from "@/components/courses/ViewToggle";
import { CourseFormContainer } from "@/components/courses/CourseFormContainer";
import { CourseAuthCheck } from "@/components/courses/auth/CourseAuthCheck";
import { useCourseManagement } from "@/hooks/useCourseManagement";

export const AdminCourses = () => {
  const [isCreating, setIsCreating] = useState(false);
  const [editingCourse, setEditingCourse] = useState<Course | null>(null);
  
  const {
    courses,
    isLoading,
    searchQuery,
    setSearchQuery,
    statusFilter,
    setStatusFilter,
    view,
    setView,
    updateStatus,
    refreshCourses
  } = useCourseManagement();

  const handleFormSuccess = () => {
    setIsCreating(false);
    setEditingCourse(null);
    refreshCourses();
  };

  if (isCreating || editingCourse) {
    return (
      <DashboardLayout type="admin">
        <CourseFormContainer
          editingCourse={editingCourse}
          onCancel={() => {
            setIsCreating(false);
            setEditingCourse(null);
          }}
          onSuccess={handleFormSuccess}
          refetchCourses={refreshCourses}
        />
      </DashboardLayout>
    );
  }

  return (
    <DashboardLayout type="admin">
      <CourseAuthCheck />
      
      <div className="space-y-8">
        <CoursesHeader onCreateCourse={() => setIsCreating(true)} />

        <div className="flex flex-col sm:flex-row gap-4 items-start sm:items-center justify-between">
          <div className="flex-1 w-full">
            <CourseFilters
              searchQuery={searchQuery}
              onSearchChange={setSearchQuery}
              statusFilter={statusFilter}
              onStatusFilterChange={setStatusFilter}
            />
          </div>
          <ViewToggle view={view} onViewChange={setView} />
        </div>

        {view === "grid" ? (
          <CourseList
            courses={courses || []}
            onEdit={setEditingCourse}
            onStatusChange={updateStatus}
          />
        ) : (
          <CourseListView
            courses={courses || []}
            onEdit={setEditingCourse}
            onStatusChange={updateStatus}
          />
        )}
      </div>
    </DashboardLayout>
  );
};

export default AdminCourses;