import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { BookOpen, CheckCircle2, Activity, TrendingUp } from "lucide-react";
import { StatCard } from "@/components/admin/stats/StatCard";
import { CourseDetailView } from "./CourseDetailView";
import { ActivityTimeline } from "./ActivityTimeline";
import { useCoursesStats } from "@/hooks/useCoursesStats";

export const DashboardStats = () => {
  const [selectedView, setSelectedView] = useState<"courses" | "activities" | null>(null);
  const { data: stats, isLoading } = useCoursesStats();

  const handleStatClick = (view: "courses" | "activities") => {
    setSelectedView(view);
  };

  return (
    <div className="space-y-8">
      <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-3">
        <div onClick={() => handleStatClick("courses")} className="cursor-pointer">
          <StatCard
            title="Cours commencés"
            value={stats?.totalStartedCourses || 0}
            description={`Dont ${stats?.activeCourses || 0} actifs ce mois-ci`}
            icon={BookOpen}
            isLoading={isLoading}
          />
        </div>
        <StatCard
          title="Taux de complétion"
          value={`${stats?.completionRate || 0}%`}
          description="Moyenne de progression sur tous les cours"
          icon={CheckCircle2}
          isLoading={isLoading}
        />
        <StatCard
          title="Progression moyenne"
          value={`${stats?.averageProgress || 0}%`}
          description="Sur les cours commencés"
          icon={TrendingUp}
          isLoading={isLoading}
        />
      </div>

      {selectedView === "courses" && (
        <CourseDetailView onClose={() => setSelectedView(null)} />
      )}

      <ActivityTimeline />
    </div>
  );
};