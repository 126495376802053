interface PlanContentProps {
  content: string;
  sections?: string[];
}

export const PlanContent = ({ content, sections = [] }: PlanContentProps) => {
  // Si des sections sont fournies, les utiliser, sinon diviser le contenu
  const contentSections = sections.length > 0 ? sections : content.split('\n\n');

  return (
    <div className="prose prose-sm max-w-none">
      {contentSections.map((section, index) => (
        <div key={index} className="mb-4">
          {section.split('\n').map((line, lineIndex) => {
            // Détecter les titres de sections
            if (line.match(/^[0-9]+\./)) {
              return <h3 key={lineIndex} className="font-semibold mt-4 mb-2">{line}</h3>;
            }
            // Détecter les sous-sections
            if (line.match(/^[-•]/)) {
              return <p key={lineIndex} className="ml-4 mb-1">• {line.replace(/^[-•]\s*/, '')}</p>;
            }
            // Texte normal
            return <p key={lineIndex} className="mb-1">{line}</p>;
          })}
        </div>
      ))}
    </div>
  );
};