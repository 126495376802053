import { Badge } from "@/components/ui/badge";
import { Clock, BookOpen } from "lucide-react";
import { Chapter } from "@/types/course";
import { AdSpace } from "../chapter/AdSpace";

interface PublicCourseContentProps {
  content: string;
  chapters: Chapter[];
  imageUrl?: string;
}

export const PublicCourseContent = ({ content, chapters, imageUrl }: PublicCourseContentProps) => {
  const totalDuration = chapters?.reduce((acc, chapter) => acc + (chapter.duration || 15), 0) || 0;

  return (
    <article className="bg-white rounded-lg shadow-lg overflow-hidden">
      {imageUrl && (
        <div className="w-full h-[400px] relative">
          <img
            src={imageUrl}
            alt="Image du cours"
            className="w-full h-full object-cover"
          />
        </div>
      )}
      
      <div className="p-8">
        <div className="flex flex-wrap gap-3 mb-8">
          <Badge variant="secondary" className="px-3 py-1">
            <BookOpen className="w-3.5 h-3.5 mr-1.5" />
            {chapters?.length || 0} chapitre{chapters?.length !== 1 ? 's' : ''}
          </Badge>
          <Badge variant="outline" className="px-3 py-1">
            <Clock className="w-3.5 h-3.5 mr-1.5" />
            {totalDuration} min
          </Badge>
        </div>

        {/* Publicité horizontale */}
        <div className="mb-8">
          <AdSpace type="content-start" />
        </div>

        {/* Contenu principal */}
        <div 
          className="prose prose-lg max-w-none prose-headings:text-gray-900 prose-p:text-gray-700 prose-a:text-primary hover:prose-a:text-primary/80 prose-strong:text-gray-900 prose-img:rounded-lg"
          dangerouslySetInnerHTML={{ __html: content || "" }}
        />
      </div>
    </article>
  );
};