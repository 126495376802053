export const pricingPlans = [
  {
    name: "BEKANE hebdomadaire",
    description: "Pour débuter votre apprentissage",
    price: "5",
    period: "semaine",
    features: [
      "Accès aux cours de base",
      "Tests d'entraînement limités",
      "Support par email",
      "Validité 1 semaine"
    ],
    planType: "weekly",
    featured: false
  },
  {
    name: "BEKANE mensuel",
    description: "La formule la plus populaire",
    price: "10",
    period: "mois",
    features: [
      "Tous les cours disponibles",
      "Tests d'entraînement illimités",
      "Support prioritaire",
      "Suivi personnalisé",
      "Validité 1 mois"
    ],
    planType: "monthly",
    featured: true
  },
  {
    name: "BEKANE annuel",
    description: "Pour une préparation intensive",
    price: "50",
    period: "an",
    features: [
      "Tout le contenu Premium",
      "Support 24/7",
      "Garantie de réussite",
      "Validité 12 mois"
    ],
    planType: "yearly",
    featured: false
  }
];