import { useQuery } from "@tanstack/react-query";
import { supabase } from "@/integrations/supabase/client";
import { toast } from "sonner";

export const useAdminStats = () => {
  return useQuery({
    queryKey: ["admin-stats"],
    queryFn: async () => {
      try {
        // Nombre total d'utilisateurs
        const { count: usersCount, error: usersError } = await supabase
          .from("profiles")
          .select("*", { count: "exact", head: true });

        if (usersError) throw usersError;

        // Nombre de cours publiés
        const { count: publishedCoursesCount, error: coursesError } = await supabase
          .from("courses")
          .select("*", { count: "exact", head: true })
          .eq("status", "published");

        if (coursesError) throw coursesError;

        // Nombre total de chapitres
        const { count: chaptersCount, error: chaptersError } = await supabase
          .from("chapters")
          .select("*", { count: "exact", head: true });

        if (chaptersError) throw chaptersError;

        // Nombre d'articles de blog
        const { count: blogPostsCount, error: blogError } = await supabase
          .from("blog_posts")
          .select("*", { count: "exact", head: true });

        if (blogError) throw blogError;

        // Récupérer toutes les progressions des cours
        const { data: progressData, error: progressError } = await supabase
          .from("course_progress")
          .select(`
            progress_percentage,
            user_id,
            course_id,
            completed_at
          `);

        if (progressError) throw progressError;

        // Calculer le taux de complétion moyen
        const completedCourses = progressData.filter(p => p.progress_percentage === 100);
        const totalEnrollments = progressData.length;
        const completionRate = totalEnrollments > 0
          ? Math.round((completedCourses.length / totalEnrollments) * 100)
          : 0;

        // Utilisateurs actifs (avec au moins une progression)
        const activeUsers = new Set(progressData.map(p => p.user_id)).size;

        // Tendances d'inscription (derniers 6 mois)
        const sixMonthsAgo = new Date();
        sixMonthsAgo.setMonth(sixMonthsAgo.getMonth() - 6);

        const { data: enrollmentTrends, error: trendsError } = await supabase
          .from("profiles")
          .select("created_at")
          .gte("created_at", sixMonthsAgo.toISOString())
          .order("created_at", { ascending: true });

        if (trendsError) throw trendsError;

        // Grouper les inscriptions par mois
        const trends = enrollmentTrends.reduce((acc: Record<string, number>, curr) => {
          const month = new Date(curr.created_at).toLocaleString('fr-FR', { month: 'long' });
          acc[month] = (acc[month] || 0) + 1;
          return acc;
        }, {});

        // Statistiques détaillées sur la progression
        const progressStats = {
          totalEnrollments,
          completedCourses: completedCourses.length,
          inProgressCourses: progressData.filter(p => p.progress_percentage > 0 && p.progress_percentage < 100).length,
          notStartedCourses: progressData.filter(p => p.progress_percentage === 0).length,
        };

        return {
          usersCount: usersCount || 0,
          activeUsersCount: activeUsers,
          publishedCoursesCount: publishedCoursesCount || 0,
          chaptersCount: chaptersCount || 0,
          blogPostsCount: blogPostsCount || 0,
          completionRate,
          progressStats,
          enrollmentTrends: Object.entries(trends).map(([name, value]) => ({
            name,
            value
          }))
        };
      } catch (error) {
        console.error("Error fetching admin stats:", error);
        toast.error("Erreur lors de la récupération des statistiques");
        throw error;
      }
    },
  });
};