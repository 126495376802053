import { Button } from "@/components/ui/button";
import { ArrowLeft } from "lucide-react";
import { useNavigate } from "react-router-dom";

export const PublicCourseHeader = ({ title }: { title: string }) => {
  const navigate = useNavigate();

  return (
    <>
      <Button
        variant="ghost"
        className="mb-6 hover:bg-white/50"
        onClick={() => navigate("/code")}
      >
        <ArrowLeft className="h-4 w-4 mr-2" />
        Retour aux cours
      </Button>
      <h1 className="text-4xl font-bold mb-6 text-gray-900">{title}</h1>
    </>
  );
};