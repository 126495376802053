import { useParams } from "react-router-dom";
import { DashboardLayout } from "@/components/layout/DashboardLayout";
import { useQuery } from "@tanstack/react-query";
import { supabase } from "@/integrations/supabase/client";
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";
import { AlertCircle } from "lucide-react";
import { Skeleton } from "@/components/ui/skeleton";
import { QuizQuestion } from "@/types/quiz";

const Quiz = () => {
  const { id: chapterId } = useParams<{ id: string }>();

  const { data: questions, isLoading } = useQuery({
    queryKey: ["chapter-questions", chapterId],
    queryFn: async () => {
      const { data, error } = await supabase
        .from("chapter_quiz_questions")
        .select(`
          *,
          answers:chapter_quiz_answers(*)
        `)
        .eq("chapter_id", chapterId)
        .order("order_index");

      if (error) throw error;
      return data as QuizQuestion[];
    },
  });

  if (isLoading) {
    return (
      <DashboardLayout type="student">
        <div className="container mx-auto px-4 py-8">
          <Skeleton className="h-8 w-1/3 mb-4" />
          <Skeleton className="h-[400px]" />
        </div>
      </DashboardLayout>
    );
  }

  if (!questions?.length) {
    return (
      <DashboardLayout type="student">
        <div className="container mx-auto px-4 py-8">
          <Alert variant="destructive">
            <AlertCircle className="h-4 w-4" />
            <AlertTitle>Quiz introuvable</AlertTitle>
            <AlertDescription>
              Le quiz que vous recherchez n'existe pas ou n'est pas accessible.
            </AlertDescription>
          </Alert>
        </div>
      </DashboardLayout>
    );
  }

  return (
    <DashboardLayout type="student">
      <div className="container mx-auto px-4 py-8">
        <h1 className="text-2xl font-bold mb-6">Quiz du chapitre</h1>
        
        <div className="bg-white rounded-lg shadow p-6">
          <div className="space-y-8">
            {questions.map((question, index) => (
              <div key={question.id} className="space-y-4">
                <h3 className="text-lg font-medium">
                  Question {index + 1}: {question.question}
                </h3>
                {question.explanation && (
                  <p className="text-muted-foreground">{question.explanation}</p>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default Quiz;