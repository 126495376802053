import { useState } from "react";
import { Card, CardContent } from "@/components/ui/card";
import { toast } from "sonner";
import { Course, CourseFormData, CourseStatus } from "@/types/course";
import { CourseFormHeader } from "./CourseFormHeader";
import { CourseBasicInfo } from "./CourseBasicInfo";
import { CourseFormActions } from "./form/CourseFormActions";
import { CourseFormTabs } from "./form/CourseFormTabs";
import { supabase } from "@/integrations/supabase/client";

interface CourseFormProps {
  initialData?: Course;
  onSubmit: (data: CourseFormData) => void;
  onDelete?: () => void;
  refetchCourses?: () => void;
}

export const CourseForm = ({
  initialData,
  onSubmit,
  onDelete,
  refetchCourses,
}: CourseFormProps) => {
  const [title, setTitle] = useState(initialData?.title || "");
  const [content, setContent] = useState(initialData?.content || "");
  const [imageUrl, setImageUrl] = useState(initialData?.image_url || "");
  const [status, setStatus] = useState<CourseStatus>(
    initialData?.status || "draft"
  );
  const [slug, setSlug] = useState(initialData?.slug || "");
  const [chapters, setChapters] = useState(initialData?.chapters || []);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [metaDescription, setMetaDescription] = useState(initialData?.meta_description || "");
  const [metaKeywords, setMetaKeywords] = useState(initialData?.meta_keywords || "");
  const [seoTitle, setSeoTitle] = useState(initialData?.seo_title || "");
  const [isIndexable, setIsIndexable] = useState(initialData?.is_indexable ?? true);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      await onSubmit({
        title,
        content,
        image_url: imageUrl,
        status,
        slug,
        chapters,
        meta_description: metaDescription,
        meta_keywords: metaKeywords,
        seo_title: seoTitle,
        is_indexable: isIndexable,
        order_index: initialData?.order_index
      });
      toast.success(initialData ? "Cours modifié" : "Cours créé avec succès!");
    } catch (error) {
      console.error("Erreur lors de la soumission du cours:", error);
      toast.error("Une erreur est survenue lors de l'enregistrement du cours");
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleDelete = async () => {
    if (!initialData?.id) return;
    
    setIsDeleting(true);
    try {
      const { error: chaptersError } = await supabase
        .from('chapters')
        .delete()
        .eq('course_id', initialData.id);

      if (chaptersError) throw chaptersError;

      const { error: courseError } = await supabase
        .from('courses')
        .delete()
        .eq('id', initialData.id);

      if (courseError) throw courseError;

      toast.success("Cours supprimé avec succès");
      refetchCourses?.();
      onDelete?.();
    } catch (error) {
      console.error('Error deleting course:', error);
      toast.error("Erreur lors de la suppression du cours");
    } finally {
      setIsDeleting(false);
    }
  };

  const previewData: Course = {
    id: initialData?.id || "",
    title,
    content,
    image_url: imageUrl,
    status,
    slug,
    chapters,
    order_index: initialData?.order_index || 0,
    created_at: initialData?.created_at || new Date().toISOString(),
    updated_at: initialData?.updated_at || new Date().toISOString(),
    type: initialData?.type || "course",
    meta_description: metaDescription,
    meta_keywords: metaKeywords,
    seo_title: seoTitle,
    is_indexable: isIndexable,
  };

  return (
    <div className="space-y-8">
      <form onSubmit={handleSubmit} className="space-y-8">
        <Card>
          <CardContent className="pt-6 space-y-6">
            <CourseFormHeader
              isEditing={!!initialData}
              previewData={previewData}
            />

            <CourseBasicInfo
              title={title}
              setTitle={setTitle}
              content={content}
              setContent={setContent}
              imageUrl={imageUrl}
              setImageUrl={setImageUrl}
              status={status}
              setStatus={setStatus}
              slug={slug}
              setSlug={setSlug}
              courseId={initialData?.id}
              metaDescription={metaDescription}
              setMetaDescription={setMetaDescription}
              metaKeywords={metaKeywords}
              setMetaKeywords={setMetaKeywords}
              seoTitle={seoTitle}
              setSeoTitle={setSeoTitle}
              isIndexable={isIndexable}
              setIsIndexable={setIsIndexable}
            />

            <CourseFormActions
              isSubmitting={isSubmitting}
              isDeleting={isDeleting}
              isEditing={!!initialData}
              onDelete={handleDelete}
            />
          </CardContent>
        </Card>
      </form>

      {initialData?.id && (
        <CourseFormTabs
          courseId={initialData.id}
          onChaptersChange={setChapters}
        />
      )}
    </div>
  );
};