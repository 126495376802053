import { Button } from "@/components/ui/button";
import { ChevronLeft, ChevronRight, ArrowRight } from "lucide-react";

interface NavigationButtonsProps {
  onPrevious: () => void;
  onNext: () => void;
  isFirstChapter: boolean;
  isLastChapter: boolean;
}

export const NavigationButtons = ({
  onPrevious,
  onNext,
  isFirstChapter,
  isLastChapter,
}: NavigationButtonsProps) => {
  return (
    <div className="p-4 border-t mt-auto bg-background/95 backdrop-blur supports-[backdrop-filter]:bg-background/60">
      <div className="flex justify-between gap-2">
        <Button
          variant="outline"
          onClick={onPrevious}
          disabled={isFirstChapter}
          className="w-full"
        >
          <ChevronLeft className="w-4 h-4 mr-2" />
          Précédent
        </Button>
        <Button
          onClick={onNext}
          disabled={false}
          className="w-full group"
        >
          {isLastChapter ? (
            <>
              Cours suivant
              <ArrowRight className="w-4 h-4 ml-2 transition-transform group-hover:translate-x-1" />
            </>
          ) : (
            <>
              Suivant
              <ChevronRight className="w-4 h-4 ml-2" />
            </>
          )}
        </Button>
      </div>
    </div>
  );
};