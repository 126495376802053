import { ScrollArea } from "@/components/ui/scroll-area";

interface TableItem {
  id: string;
  title: string;
  level: number;
}

interface TableOfContentsProps {
  items: TableItem[];
  activeSection: string;
}

export const TableOfContents = ({ items, activeSection }: TableOfContentsProps) => {
  return (
    <div className="hidden lg:block w-64 flex-shrink-0">
      <div className="sticky top-8">
        <h4 className="font-semibold mb-4 text-gray-900">Table des matières</h4>
        <ScrollArea className="h-[calc(100vh-200px)]">
          <nav className="space-y-1">
            {items.map((item) => (
              <a
                key={item.id}
                href={`#${item.id}`}
                className={`
                  block py-1 text-sm transition-colors
                  ${item.level === 1 ? 'pl-0' : item.level === 2 ? 'pl-4' : 'pl-8'}
                  ${activeSection === item.id ? 'text-primary font-medium' : 'text-gray-600 hover:text-primary'}
                `}
              >
                {item.title}
              </a>
            ))}
          </nav>
        </ScrollArea>
      </div>
    </div>
  );
};