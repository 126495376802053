import { Keyword } from "./types";

export const parseSecondaryKeywords = (content: string): Keyword[] => {
  console.log("🔍 Début du parsing des mots-clés secondaires");
  console.log("Contenu reçu:", content);

  const keywords: Keyword[] = [];
  const lines = content.split('\n');
  let currentKeyword: Partial<Keyword> = {};

  for (const line of lines) {
    const trimmedLine = line.trim();
    
    // Début d'un nouveau mot-clé
    const keywordMatch = trimmedLine.match(/^\d+\.\s+\*{1,2}Mot-clé\*{1,2}\s*:\s*(.+?)(?:\*{1,2})?$/);
    if (keywordMatch) {
      if (Object.keys(currentKeyword).length > 0) {
        keywords.push(currentKeyword as Keyword);
      }
      currentKeyword = {
        keyword: keywordMatch[1].trim()
      };
      continue;
    }

    // Volume
    const volumeMatch = trimmedLine.match(/\*{1,2}Volume\*{1,2}\s*:\s*(\d+)/i);
    if (volumeMatch) {
      currentKeyword.volume = parseInt(volumeMatch[1]);
      continue;
    }

    // Difficulté
    const difficultyMatch = trimmedLine.match(/\*{1,2}Difficulté\*{1,2}\s*:\s*(\d+)/i);
    if (difficultyMatch) {
      currentKeyword.difficulty = parseInt(difficultyMatch[1]);
      continue;
    }

    // Intention
    const intentMatch = trimmedLine.match(/\*{1,2}Intention\*{1,2}\s*:\s*(\w+)/i);
    if (intentMatch) {
      currentKeyword.intent = intentMatch[1].toLowerCase();
    }
  }

  // Ajouter le dernier mot-clé
  if (Object.keys(currentKeyword).length > 0 && currentKeyword.keyword) {
    keywords.push(currentKeyword as Keyword);
  }

  // Filtrer les mots-clés incomplets
  const validKeywords = keywords.filter(kw => 
    kw.keyword && 
    typeof kw.volume === 'number' && 
    typeof kw.difficulty === 'number' && 
    kw.intent
  ).map(kw => ({
    ...kw,
    recommended: kw.volume > 30 && kw.difficulty < 50
  }));

  console.log("✅ Mots-clés secondaires parsés:", validKeywords);
  return validKeywords;
};