import { Button } from "@/components/ui/button";
import { Plus } from "lucide-react";

interface QuestionHeaderProps {
  onAddQuestion: () => void;
}

export const QuestionHeader = ({ onAddQuestion }: QuestionHeaderProps) => {
  return (
    <div className="flex justify-between items-center">
      <h3 className="text-lg font-semibold">Questions</h3>
      <Button onClick={onAddQuestion} size="sm">
        <Plus className="h-4 w-4 mr-2" />
        Ajouter une question
      </Button>
    </div>
  );
};