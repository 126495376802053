import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Skeleton } from "@/components/ui/skeleton";
import { LineChart, Line, XAxis, YAxis, ResponsiveContainer } from "recharts";
import { ChartContainer, ChartTooltip } from "@/components/ui/chart";

interface EnrollmentTrend {
  name: string;
  value: number;
}

interface EnrollmentTrendsProps {
  isLoading: boolean;
  trends: EnrollmentTrend[];
}

export const EnrollmentTrends = ({ isLoading, trends }: EnrollmentTrendsProps) => (
  <Card>
    <CardHeader>
      <CardTitle>Tendance des inscriptions</CardTitle>
    </CardHeader>
    <CardContent>
      <div className="h-[300px]">
        {isLoading ? (
          <Skeleton className="h-full w-full" />
        ) : (
          <ResponsiveContainer width="100%" height="100%">
            <LineChart data={trends}>
              <XAxis dataKey="name" />
              <YAxis />
              <Line
                type="monotone"
                dataKey="value"
                stroke="hsl(var(--primary))"
                strokeWidth={2}
              />
              <ChartTooltip />
            </LineChart>
          </ResponsiveContainer>
        )}
      </div>
    </CardContent>
  </Card>
);