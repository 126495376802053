import { useState } from "react";
import { QuizForm } from "./QuizForm";
import { QuizList } from "./QuizList";
import { QuizQuestion } from "@/types/quiz";

interface QuizManagerProps {
  chapterId: string;
}

export const QuizManager = ({ chapterId }: QuizManagerProps) => {
  const [showQuizForm, setShowQuizForm] = useState(false);
  const [editingQuestion, setEditingQuestion] = useState<QuizQuestion | null>(null);

  const handleAdd = () => {
    setEditingQuestion(null);
    setShowQuizForm(true);
  };

  const handleEdit = (question: QuizQuestion) => {
    setEditingQuestion(question);
    setShowQuizForm(true);
  };

  const handleSuccess = () => {
    setShowQuizForm(false);
    setEditingQuestion(null);
  };

  return (
    <div className="space-y-8">
      <QuizList
        chapterId={chapterId}
        onEdit={handleEdit}
        onAdd={handleAdd}
      />
      
      {showQuizForm && (
        <QuizForm
          chapterId={chapterId}
          initialData={editingQuestion}
          onSuccess={handleSuccess}
          onCancel={() => {
            setShowQuizForm(false);
            setEditingQuestion(null);
          }}
        />
      )}
    </div>
  );
};