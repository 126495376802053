export const getQuizGenerationPrompt = (chapterContent: string) => {
  return `En tant qu'expert en création de quiz pédagogiques, analyse le contenu suivant et génère un quiz pertinent.

Contenu du chapitre :
${chapterContent}

Instructions pour la génération du quiz :
1. Crée 5 questions variées qui testent la compréhension des concepts clés
2. Pour chaque question :
   - Fournis 4 réponses possibles
   - Indique clairement la bonne réponse
   - Ajoute une explication pour la réponse correcte
3. Varie les types de questions :
   - Questions de compréhension
   - Questions d'application
   - Questions d'analyse
   - Questions de synthèse

Format de réponse attendu :
### Question 1
**Question :** [La question]
**Réponses :**
1. [Réponse A]
2. [Réponse B]
3. [Réponse C]
4. [Réponse D]
**Réponse correcte :** [Numéro de la réponse correcte]
**Explication :** [Explication détaillée de la réponse correcte]

[Répéter le format pour chaque question]`;
};