import { useState } from "react";
import { toast } from "sonner";
import { supabase } from "@/integrations/supabase/client";
import { validateQuestion } from "../validation/QuestionValidation";
import { Answer, QuizQuestion } from "@/types/quiz";

export const useQuestionForm = (
  chapterId: string,
  initialData?: QuizQuestion,
  onSuccess?: () => void
) => {
  const [question, setQuestion] = useState(initialData?.question || "");
  const [richText, setRichText] = useState("");
  const [answers, setAnswers] = useState<Answer[]>(
    initialData?.answers?.map(a => ({
      id: a.id,
      answer: a.answer,
      is_correct: a.is_correct,
      explanation: a.explanation || undefined
    })) || [{ answer: "", is_correct: false }]
  );
  const [questionType, setQuestionType] = useState("single_choice");
  const [fontSize, setFontSize] = useState("normal");
  const [textAlign, setTextAlign] = useState("left");
  const [backgroundColor, setBackgroundColor] = useState("transparent");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [orderIndex, setOrderIndex] = useState(initialData?.order_index || 0);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!question.trim()) {
      toast.error("La question est requise");
      return;
    }

    setIsSubmitting(true);

    try {
      validateQuestion(question, questionType, answers);

      if (initialData?.id) {
        await updateQuestion(initialData.id);
      } else {
        await createQuestion();
      }

      toast.success(initialData ? "Question mise à jour" : "Question ajoutée avec succès");
      resetForm();
      onSuccess?.();
    } catch (error: any) {
      console.error("Error submitting question:", error);
      toast.error(error.message || "Une erreur est survenue");
    } finally {
      setIsSubmitting(false);
    }
  };

  const updateQuestion = async (questionId: string) => {
    const { error: questionError } = await supabase
      .from("chapter_quiz_questions")
      .update({
        question,
        order_index: orderIndex,
        explanation: richText || null
      })
      .eq("id", questionId);

    if (questionError) throw questionError;

    for (const answer of answers) {
      if (answer.id) {
        const { error: updateError } = await supabase
          .from("chapter_quiz_answers")
          .update({
            answer: answer.answer,
            is_correct: answer.is_correct,
            explanation: answer.explanation
          })
          .eq("id", answer.id);

        if (updateError) throw updateError;
      } else {
        const { error: insertError } = await supabase
          .from("chapter_quiz_answers")
          .insert({
            question_id: questionId,
            answer: answer.answer,
            is_correct: answer.is_correct,
            explanation: answer.explanation
          });

        if (insertError) throw insertError;
      }
    }
  };

  const createQuestion = async () => {
    const { data: questionData, error: questionError } = await supabase
      .from("chapter_quiz_questions")
      .insert({
        chapter_id: chapterId,
        question,
        order_index: orderIndex,
        explanation: richText || null
      })
      .select()
      .single();

    if (questionError) throw questionError;

    if (questionType !== "text_input") {
      const answersToInsert = answers.map((answer) => ({
        question_id: questionData.id,
        answer: answer.answer,
        is_correct: answer.is_correct,
        explanation: answer.explanation,
      }));

      const { error: answersError } = await supabase
        .from("chapter_quiz_answers")
        .insert(answersToInsert);

      if (answersError) throw answersError;
    }
  };

  const resetForm = () => {
    setQuestion("");
    setRichText("");
    setAnswers([{ answer: "", is_correct: false }]);
    setQuestionType("single_choice");
    setFontSize("normal");
    setTextAlign("left");
    setBackgroundColor("transparent");
    setOrderIndex(0);
  };

  return {
    question,
    richText,
    answers,
    questionType,
    fontSize,
    textAlign,
    backgroundColor,
    isSubmitting,
    setQuestion,
    setRichText,
    setAnswers,
    setQuestionType,
    setFontSize,
    setTextAlign,
    setBackgroundColor,
    handleSubmit,
    resetForm
  };
};