import { ScrollArea } from "@/components/ui/scroll-area";
import { SourceItem } from "./SourceItem";
import { Source } from "../../types";

interface SourceListProps {
  sources: Source[];
  selectedSources: string[];
  onSourceSelect: (title: string) => void;
}

export const SourceList = ({
  sources,
  selectedSources,
  onSourceSelect,
}: SourceListProps) => {
  if (sources.length === 0) return null;

  return (
    <ScrollArea className="h-[400px] rounded-md border p-4">
      <div className="space-y-4">
        {sources.map((source) => (
          <SourceItem
            key={source.title}
            {...source}
            isSelected={selectedSources.includes(source.title)}
            onSelect={onSourceSelect}
          />
        ))}
      </div>
    </ScrollArea>
  );
};