import { Card } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { Edit, Eye, Trash2 } from "lucide-react";
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "@/components/ui/sheet";
import { CoursePreview } from "./CoursePreview";
import { Course } from "@/types/course";
import { CourseStatusBadge } from "./CourseStatusBadge";
import { CourseStatusSelect } from "./CourseStatusSelect";
import { useCourseProgress } from "@/hooks/useCourseProgress";

interface CourseCardProps {
  course: Course;
  onEdit: (course: Course) => void;
  onStatusChange: (courseId: string, status: "draft" | "pending_validation" | "published") => void;
  onDelete?: (course: Course) => void;
}

export const CourseCard = ({ course, onEdit, onStatusChange, onDelete }: CourseCardProps) => {
  const { progress } = useCourseProgress(course.id);

  return (
    <Card className="flex flex-col h-full">
      {course.image_url && (
        <div className="aspect-video relative">
          <img
            src={course.image_url}
            alt={course.title}
            className="object-cover w-full h-full"
          />
        </div>
      )}
      <div className="p-6 space-y-4 flex-1 flex flex-col">
        <div className="flex justify-between items-start gap-4">
          <div className="min-w-0 flex-1">
            <h3 className="text-lg font-semibold break-words">{course.title}</h3>
            <div
              className="text-sm text-muted-foreground mt-2 break-words overflow-hidden"
              dangerouslySetInnerHTML={{
                __html: course.content || "",
              }}
            />
          </div>
          <CourseStatusBadge status={course.status} className="flex-shrink-0" />
        </div>
        
        {course.chapters && course.chapters.length > 0 && (
          <p className="text-sm text-muted-foreground">
            {course.chapters.length} chapitre{course.chapters.length > 1 ? "s" : ""}
          </p>
        )}

        <div className="space-y-4 mt-auto">
          <CourseStatusSelect
            status={course.status}
            onStatusChange={(newStatus) => onStatusChange(course.id, newStatus)}
          />
          
          <div className="flex gap-2">
            <Button
              variant="outline"
              size="sm"
              className="flex-1 whitespace-nowrap"
              onClick={() => onEdit(course)}
            >
              <Edit className="h-4 w-4 mr-2" />
              Modifier
            </Button>
            <Sheet>
              <SheetTrigger asChild>
                <Button variant="outline" size="sm" className="flex-1 whitespace-nowrap">
                  <Eye className="h-4 w-4 mr-2" />
                  Aperçu
                </Button>
              </SheetTrigger>
              <SheetContent
                side="right"
                className="w-[90vw] sm:w-[45vw] overflow-y-auto"
              >
                <SheetHeader>
                  <SheetTitle>Aperçu du cours</SheetTitle>
                  <SheetDescription>
                    Visualisez le cours et ses chapitres
                  </SheetDescription>
                </SheetHeader>
                <div className="mt-4">
                  <CoursePreview course={course} />
                </div>
              </SheetContent>
            </Sheet>
            {onDelete && (
              <Button
                variant="outline"
                size="sm"
                className="flex-1 whitespace-nowrap hover:bg-destructive hover:text-destructive-foreground"
                onClick={() => onDelete(course)}
              >
                <Trash2 className="h-4 w-4 mr-2" />
                Supprimer
              </Button>
            )}
          </div>
        </div>
      </div>
    </Card>
  );
};