import { useState } from "react";
import { Button } from "@/components/ui/button";
import { ArticleData } from "../types";
import { TitleGenerator } from "./title-generation/TitleGenerator";
import { TitleList } from "./title-generation/TitleList";
import { GeneratedTitle } from "./title-generation/types";

interface TitleGenerationProps {
  data: ArticleData;
  onComplete: (data: Partial<ArticleData>) => void;
}

export const TitleGeneration = ({ data, onComplete }: TitleGenerationProps) => {
  const [titles, setTitles] = useState<GeneratedTitle[]>([]);
  const [selectedTitle, setSelectedTitle] = useState<string>("");

  const handleContinue = () => {
    onComplete({ selectedTitle });
  };

  return (
    <div className="space-y-6">
      <TitleGenerator
        selectedKeywords={data.selectedKeywords || []}
        onTitlesGenerated={setTitles}
      />

      {titles.length > 0 && (
        <div className="space-y-4">
          <TitleList
            titles={titles}
            selectedTitle={selectedTitle}
            onTitleSelect={setSelectedTitle}
          />

          <Button
            onClick={handleContinue}
            disabled={!selectedTitle}
            className="w-full"
          >
            Continuer avec le titre sélectionné
          </Button>
        </div>
      )}
    </div>
  );
};