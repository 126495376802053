import { Button } from "@/components/ui/button";
import { PricingDialog } from "./PricingDialog";
import { toast } from "sonner";

interface SubscriptionActionsProps {
  onSubscribe: (planType: string) => void;
  onCancel: () => void;
  subscription: {
    status: string;
    plan_type: string;
  } | null;
}

export const SubscriptionActions = ({ onSubscribe, onCancel, subscription }: SubscriptionActionsProps) => {
  const handleCancel = async () => {
    try {
      await onCancel();
      toast.success("Votre abonnement a été annulé avec succès");
    } catch (error) {
      console.error("Error cancelling subscription:", error);
      toast.error("Une erreur est survenue lors de l'annulation");
    }
  };

  return (
    <div className="pt-4 space-y-4">
      <PricingDialog 
        onSubscribe={onSubscribe} 
        currentPlan={subscription?.plan_type}
      />
      {subscription?.status === 'active' && (
        <Button 
          variant="destructive" 
          className="w-full hover:bg-red-600 transition-colors"
          onClick={handleCancel}
        >
          Annuler l'abonnement
        </Button>
      )}
    </div>
  );
};