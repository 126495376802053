import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Card, CardHeader, CardTitle, CardContent } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { Edit, Trash2, GripVertical } from "lucide-react";
import { QuizAnswer } from "@/types/quiz";

interface SortableQuestionCardProps {
  id: string;
  index: number;
  question: string;
  answers: QuizAnswer[];
  onDelete: (id: string) => void;
}

export const SortableQuestionCard = ({
  id,
  index,
  question,
  answers,
  onDelete,
}: SortableQuestionCardProps) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <Card
      ref={setNodeRef}
      style={style}
      className="relative hover:shadow-md transition-shadow"
    >
      <div
        className="absolute left-2 top-1/2 -translate-y-1/2 cursor-move"
        {...attributes}
        {...listeners}
      >
        <GripVertical className="h-5 w-5 text-muted-foreground" />
      </div>

      <CardHeader className="pb-4 pl-10">
        <div className="flex justify-between items-start">
          <div className="space-y-2">
            <CardTitle className="text-lg flex items-center gap-2">
              <span className="text-muted-foreground">Q{index}.</span>
              {question || "Nouvelle question"}
            </CardTitle>
          </div>
          <div className="flex gap-2">
            <Button variant="outline" size="sm">
              <Edit className="h-4 w-4" />
            </Button>
            <Button
              variant="destructive"
              size="sm"
              onClick={() => onDelete(id)}
            >
              <Trash2 className="h-4 w-4" />
            </Button>
          </div>
        </div>
      </CardHeader>

      <CardContent>
        <div className="space-y-2">
          {answers.map((answer, index) => (
            <div
              key={answer.id}
              className={`p-2 rounded-md ${
                answer.is_correct
                  ? "bg-green-100 dark:bg-green-900/20"
                  : "bg-gray-100 dark:bg-gray-800"
              }`}
            >
              <span className="mr-2">{index + 1}.</span>
              {answer.answer || "Nouvelle réponse"}
              {answer.is_correct && (
                <span className="ml-2 text-green-600 dark:text-green-400">
                  (Correcte)
                </span>
              )}
            </div>
          ))}
        </div>
      </CardContent>
    </Card>
  );
};