import { Card } from "@/components/ui/card";
import { ChevronRight } from "lucide-react";
import { useMemo } from "react";

interface ArticleBodyProps {
  content: string;
  onHeadingsGenerated: (headings: Array<{ id: string; title: string; level: number }>) => void;
}

export const ArticleBody = ({ content, onHeadingsGenerated }: ArticleBodyProps) => {
  const generateId = (text: string) => {
    return text
      .toLowerCase()
      .replace(/[^a-z0-9]+/g, '-')
      .replace(/(^-|-$)/g, '');
  };

  const formattedContent = useMemo(() => {
    const sections = content.split(/(?=\n#{1,3}\s)/);
    const toc: Array<{ id: string; title: string; level: number }> = [];
    
    const formattedSections = sections.map((section, sectionIndex) => {
      const lines = section.trim().split('\n');
      const formattedLines = lines.map((line, lineIndex) => {
        if (line.match(/^#\s/)) {
          const title = line.replace(/^#\s/, '');
          const id = generateId(title);
          toc.push({ id, title, level: 1 });
          return (
            <h1 
              key={`${sectionIndex}-${lineIndex}`} 
              id={id}
              className="text-4xl font-bold mb-8 mt-12 text-gray-900"
            >
              {title}
            </h1>
          );
        }
        if (line.match(/^##\s/)) {
          const title = line.replace(/^##\s/, '');
          const id = generateId(title);
          toc.push({ id, title, level: 2 });
          return (
            <h2 
              key={`${sectionIndex}-${lineIndex}`} 
              id={id}
              className="text-3xl font-semibold mb-6 mt-10 text-gray-800"
            >
              {title}
            </h2>
          );
        }
        if (line.match(/^###\s/)) {
          const title = line.replace(/^###\s/, '');
          const id = generateId(title);
          toc.push({ id, title, level: 3 });
          return (
            <h3 
              key={`${sectionIndex}-${lineIndex}`} 
              id={id}
              className="text-2xl font-medium mb-4 mt-8 text-gray-700"
            >
              {title}
            </h3>
          );
        }
        if (line.match(/^[-•*]\s/)) {
          return (
            <li key={`${sectionIndex}-${lineIndex}`} className="ml-6 mb-3 flex items-start">
              <ChevronRight className="h-5 w-5 mr-2 mt-1 text-primary flex-shrink-0" />
              <span className="text-gray-700 leading-relaxed">
                {line.replace(/^[-•*]\s/, '')}
              </span>
            </li>
          );
        }
        if (line.match(/^>\s/)) {
          return (
            <blockquote 
              key={`${sectionIndex}-${lineIndex}`} 
              className="border-l-4 border-primary pl-4 my-6 italic text-gray-700 bg-gray-50 p-4 rounded"
            >
              {line.replace(/^>\s/, '')}
            </blockquote>
          );
        }
        if (line.trim()) {
          return (
            <p key={`${sectionIndex}-${lineIndex}`} className="mb-4 leading-relaxed text-gray-700">
              {line}
            </p>
          );
        }
        return null;
      });

      return (
        <div key={sectionIndex} className="mb-6">
          {formattedLines}
        </div>
      );
    });

    onHeadingsGenerated(toc);
    return formattedSections;
  }, [content, onHeadingsGenerated]);

  return (
    <Card className="flex-grow max-w-3xl">
      <div className="p-8 prose prose-lg max-w-none">
        {formattedContent}
      </div>
    </Card>
  );
};