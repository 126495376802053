import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Textarea } from "@/components/ui/textarea";
import { Wand2, Loader2 } from "lucide-react";
import { SEOSectionProps } from "./types";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";

export const SEOSection = ({
  seoTitle,
  metaDescription,
  metaKeywords,
  onSeoTitleChange,
  onMetaDescriptionChange,
  onMetaKeywordsChange,
  onAnalyzeSEO,
  isAnalyzing,
}: SEOSectionProps) => {
  return (
    <Card>
      <CardHeader>
        <CardTitle>Optimisation SEO</CardTitle>
      </CardHeader>
      <CardContent className="space-y-6">
        <div className="flex items-center justify-between">
          <h3 className="font-medium">SEO</h3>
          <Button 
            type="button" 
            variant="outline" 
            onClick={onAnalyzeSEO}
            disabled={isAnalyzing}
          >
            {isAnalyzing ? (
              <>
                <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                Analyse en cours...
              </>
            ) : (
              <>
                <Wand2 className="mr-2 h-4 w-4" />
                Analyser avec l'IA
              </>
            )}
          </Button>
        </div>

        <div className="space-y-2">
          <Label htmlFor="seoTitle">Titre SEO</Label>
          <Input
            id="seoTitle"
            value={seoTitle}
            onChange={(e) => onSeoTitleChange(e.target.value)}
            placeholder="Titre optimisé pour les moteurs de recherche"
          />
        </div>

        <div className="space-y-2">
          <Label htmlFor="metaDescription">Description Meta</Label>
          <Textarea
            id="metaDescription"
            value={metaDescription}
            onChange={(e) => onMetaDescriptionChange(e.target.value)}
            placeholder="Description qui apparaîtra dans les résultats de recherche"
          />
        </div>

        <div className="space-y-2">
          <Label htmlFor="metaKeywords">Mots-clés Meta</Label>
          <Input
            id="metaKeywords"
            value={metaKeywords}
            onChange={(e) => onMetaKeywordsChange(e.target.value)}
            placeholder="Mots-clés séparés par des virgules"
          />
        </div>
      </CardContent>
    </Card>
  );
};