import { formatDate } from "@/lib/utils";

interface SubscriptionDetailsProps {
  subscription: {
    plan_type: string;
    start_date?: string;
    end_date?: string;
  } | null;
}

export const SubscriptionDetails = ({ subscription }: SubscriptionDetailsProps) => {
  const getPlanLabel = (planType: string) => {
    const planLabels = {
      weekly: 'Hebdomadaire',
      monthly: 'Mensuel',
      yearly: 'Annuel'
    };
    return planLabels[planType as keyof typeof planLabels] || planType;
  };

  return (
    <>
      <div className="grid gap-2">
        <div className="font-medium">Plan actuel</div>
        <div className="text-2xl font-bold">
          {subscription?.plan_type ? (
            `BEKANE ${getPlanLabel(subscription.plan_type)}`
          ) : (
            "Gratuit"
          )}
        </div>
      </div>

      {subscription?.start_date && (
        <div className="grid gap-2">
          <div className="font-medium">Date de début</div>
          <div>{formatDate(subscription.start_date)}</div>
        </div>
      )}

      {subscription?.end_date && (
        <div className="grid gap-2">
          <div className="font-medium">Date de fin</div>
          <div>{formatDate(subscription.end_date)}</div>
        </div>
      )}
    </>
  );
};