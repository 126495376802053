export const getPlanGenerationPrompt = (title: string, keywords: string, configuration: any) => {
  // Calcul dynamique du nombre de parties principales en fonction de la longueur de l'article
  const wordCount = parseInt(configuration.wordCount || "1500");
  const minSections = Math.max(3, Math.floor(wordCount / 300)); // Au moins 3 sections
  const maxSections = Math.max(5, Math.floor(wordCount / 200)); // Au moins 5 sections
  
  // Calcul du nombre de sous-parties en fonction de la longueur
  const minSubSections = Math.max(2, Math.floor(wordCount / 500));
  const maxSubSections = Math.max(3, Math.floor(wordCount / 400));

  return `Générez 3 propositions de plans détaillés pour l'article "${title}".

Paramètres de l'article :
- Mots-clés principaux : ${keywords}
- Style d'écriture : ${configuration.writingStyle === 'professionnel' ? 'Professionnel et technique' : 
  configuration.writingStyle === 'conversationnel' ? 'Accessible et engageant' : 
  configuration.writingStyle === 'academique' ? 'Académique et approfondi' : 
  'Journalistique et informatif'}
- Perspective : ${configuration.perspective === 'expert' ? 'Expert pour professionnels' : 
  configuration.perspective === 'debutant' ? 'Adapté aux débutants' : 
  configuration.perspective === 'pratique' ? 'Orienté solutions pratiques' : 
  'Neutre et objectif'}
- Longueur visée : ${configuration.wordCount || '1500'} mots
- Nombre de titres à inclure dans l'article : ${configuration.titleCount || "3"}

Instructions de structure :
1. Proposez exactement 3 plans distincts avec :
   - Introduction captivante et contextuelle (${Math.floor(wordCount * 0.1)} mots)
   - ${minSections}-${maxSections} parties principales équilibrées
   - ${minSubSections}-${maxSubSections} sous-parties détaillées par section
   - Conclusion synthétique avec ouverture (${Math.floor(wordCount * 0.1)} mots)

2. Chaque plan doit :
   - Intégrer naturellement les mots-clés
   - Suivre une progression logique
   - Inclure des exemples concrets
   - Prévoir des données chiffrées
   - Anticiper les questions du lecteur
   - Prévoir ${configuration.titleCount || "3"} titres ou sous-titres accrocheurs

3. Types de plans suggérés selon le style :
   ${configuration.writingStyle === 'academique' ? `
   - Plan dialectique (thèse/antithèse/synthèse)
   - Plan analytique (définition/analyse/discussion)
   - Plan démonstratif (hypothèse/preuves/conclusion)` :
   configuration.writingStyle === 'professionnel' ? `
   - Plan problème/solution
   - Plan chronologique
   - Plan comparatif` :
   configuration.writingStyle === 'conversationnel' ? `
   - Plan questions/réponses
   - Plan par points clés
   - Plan pratique et concret` : `
   - Plan chronologique
   - Plan thématique
   - Plan analytique`}

Format détaillé pour chaque plan :

Introduction (${Math.floor(wordCount * 0.1)} mots) :
- Accroche adaptée au style ${configuration.writingStyle}
- Contexte du sujet
- Problématique claire
- Annonce du plan

Corps de l'article (${Math.floor(wordCount * 0.8)} mots) :
1. [Titre explicite de la première partie]
   1.1 [Sous-partie détaillée]
   1.2 [Sous-partie détaillée]
   1.3 [Points clés à aborder]

2. [Titre explicite de la deuxième partie]
   2.1 [Sous-partie détaillée]
   2.2 [Sous-partie détaillée]
   2.3 [Points clés à aborder]

3. [Titre explicite de la troisième partie]
   3.1 [Sous-partie détaillée]
   3.2 [Sous-partie détaillée]
   3.3 [Points clés à aborder]

Conclusion (${Math.floor(wordCount * 0.1)} mots) :
- Synthèse des points essentiels
- Réponse à la problématique
- ${configuration.perspective === 'pratique' ? 'Conseils pratiques et actions concrètes' :
   configuration.perspective === 'expert' ? 'Perspectives professionnelles et recommandations' :
   configuration.perspective === 'debutant' ? 'Résumé simplifié et prochaines étapes' :
   'Ouverture et réflexion générale'}

Transitions :
${configuration.writingStyle === 'academique' ? `
- Transitions logiques et argumentées
- Connecteurs logiques sophistiqués
- Liens conceptuels entre les parties` :
configuration.writingStyle === 'professionnel' ? `
- Transitions concises et efficaces
- Liens pragmatiques entre les sections
- Progression structurée` :
configuration.writingStyle === 'conversationnel' ? `
- Transitions naturelles et fluides
- Questions rhétoriques
- Exemples de liaison` : `
- Transitions classiques
- Liens thématiques
- Progression chronologique`}`;
};