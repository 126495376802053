import { Chapter } from "@/types/course";
import { Button } from "@/components/ui/button";
import { Card } from "@/components/ui/card";
import { Pencil, Trash2, Plus, Download, GripVertical } from "lucide-react";
import { useState } from "react";
import { ImportChapterDialog } from "./ImportChapterDialog";
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  DragEndEvent,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { SortableChapterItem } from "./SortableChapterItem";

interface ChapterListProps {
  chapters: Chapter[];
  onEdit: (chapter: Chapter) => void;
  onAdd: () => void;
  onDelete: (chapterId: string) => void;
  onReorder: (chapters: Chapter[]) => void;
}

export const ChapterList = ({
  chapters,
  onEdit,
  onAdd,
  onDelete,
  onReorder,
}: ChapterListProps) => {
  const [isImportDialogOpen, setIsImportDialogOpen] = useState(false);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const handleImport = (content: { title: string; content: string }) => {
    const newChapter = {
      title: content.title,
      content: content.content,
      order_index: chapters.length,
    };
    onEdit(newChapter as Chapter);
  };

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (over && active.id !== over.id) {
      const oldIndex = chapters.findIndex((chapter) => chapter.id === active.id);
      const newIndex = chapters.findIndex((chapter) => chapter.id === over.id);

      const newChapters = arrayMove(chapters, oldIndex, newIndex).map(
        (chapter, index) => ({
          ...chapter,
          order_index: index,
        })
      );

      onReorder(newChapters);
    }
  };

  return (
    <div className="space-y-4">
      <div className="flex justify-between items-center">
        <h2 className="text-2xl font-bold">Chapitres</h2>
        <div className="space-x-2">
          <Button
            variant="outline"
            onClick={() => setIsImportDialogOpen(true)}
          >
            <Download className="h-4 w-4 mr-2" />
            Importer
          </Button>
          <Button onClick={onAdd}>
            <Plus className="h-4 w-4 mr-2" />
            Ajouter un chapitre
          </Button>
        </div>
      </div>

      {chapters.length === 0 ? (
        <Card className="p-8 text-center text-muted-foreground">
          Aucun chapitre n'a été créé pour ce cours.
        </Card>
      ) : (
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragEnd={handleDragEnd}
        >
          <SortableContext
            items={chapters.map((chapter) => chapter.id)}
            strategy={verticalListSortingStrategy}
          >
            <div className="space-y-2">
              {chapters
                .sort((a, b) => a.order_index - b.order_index)
                .map((chapter) => (
                  <SortableChapterItem
                    key={chapter.id}
                    chapter={chapter}
                    onEdit={onEdit}
                    onDelete={onDelete}
                  />
                ))}
            </div>
          </SortableContext>
        </DndContext>
      )}

      <ImportChapterDialog
        isOpen={isImportDialogOpen}
        onOpenChange={setIsImportDialogOpen}
        onImport={handleImport}
      />
    </div>
  );
};