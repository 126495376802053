import { Card } from "@/components/ui/card";
import { Checkbox } from "@/components/ui/checkbox";
import { GeneratedTitle } from "./types";

interface TitleListProps {
  titles: GeneratedTitle[];
  selectedTitle: string;
  onTitleSelect: (title: string) => void;
}

export const TitleList = ({
  titles,
  selectedTitle,
  onTitleSelect
}: TitleListProps) => {
  return (
    <div className="space-y-4">
      {titles.map((title, index) => (
        <Card key={index} className="p-4">
          <div className="flex items-start gap-4">
            <Checkbox
              checked={selectedTitle === title.title}
              onCheckedChange={() => onTitleSelect(title.title)}
            />
            <div className="flex-1">
              <h3 className="font-medium">{title.title}</h3>
              <p className="mt-2 text-sm text-muted-foreground">
                {title.description}
              </p>
            </div>
          </div>
        </Card>
      ))}
    </div>
  );
};