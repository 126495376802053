import { DashboardLayout } from "@/components/layout/DashboardLayout";
import { useQuery } from "@tanstack/react-query";
import { supabase } from "@/integrations/supabase/client";
import { StudentCoursesHeader } from "@/components/student/courses/StudentCoursesHeader";
import { StudentCourseGrid } from "@/components/student/courses/StudentCourseGrid";
import { StudentCourseView } from "@/components/courses/StudentCourseView";
import { useState } from "react";
import { Course } from "@/types/course";
import { Helmet } from "react-helmet";

const StudentCourses = () => {
  const [selectedCourse, setSelectedCourse] = useState<Course | null>(null);
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);

  const { data: courses, isLoading } = useQuery({
    queryKey: ["student-courses"],
    queryFn: async () => {
      const { data, error } = await supabase
        .from("courses")
        .select(`
          *,
          chapters (
            id,
            title,
            content,
            duration,
            order_index
          )
        `)
        .eq("status", "published")
        .order("order_index", { ascending: true });

      if (error) throw error;
      return data as Course[];
    },
  });

  const handlePreviewCourse = (course: Course) => {
    setSelectedCourse(course);
    setIsPreviewOpen(true);
  };

  const handleClosePreview = () => {
    setIsPreviewOpen(false);
    setSelectedCourse(null);
  };

  return (
    <DashboardLayout type="student">
      <Helmet>
        <title>Mes cours | Formation</title>
        <script>
          {`
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','GTM-WNTHDK2Z');
          `}
        </script>
      </Helmet>

      <div className="container mx-auto px-4 py-8">
        {/* Google Tag Manager (noscript) */}
        <noscript>
          <iframe 
            src="https://www.googletagmanager.com/ns.html?id=GTM-WNTHDK2Z"
            height="0" 
            width="0" 
            style={{ display: 'none', visibility: 'hidden' }}
          />
        </noscript>
        
        <StudentCoursesHeader />

        {isLoading ? (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {[1, 2, 3].map((n) => (
              <div key={n} className="animate-pulse">
                <div className="bg-gray-200 h-48 rounded-lg mb-4" />
                <div className="h-4 bg-gray-200 rounded w-3/4 mb-2" />
                <div className="h-4 bg-gray-200 rounded w-1/2" />
              </div>
            ))}
          </div>
        ) : (
          <StudentCourseGrid courses={courses || []} onPreview={handlePreviewCourse} />
        )}

        <StudentCourseView
          course={selectedCourse}
          isOpen={isPreviewOpen}
          onClose={handleClosePreview}
        />
      </div>
    </DashboardLayout>
  );
};

export default StudentCourses;