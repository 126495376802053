import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
} from "@/components/ui/sheet";
import { Course } from "@/types/course";
import { CoursePreview } from "./CoursePreview";

interface StudentCourseViewProps {
  course: Course | null;
  isOpen: boolean;
  onClose: () => void;
}

export const StudentCourseView = ({ course, isOpen, onClose }: StudentCourseViewProps) => {
  if (!course) return null;

  return (
    <Sheet open={isOpen} onOpenChange={onClose}>
      <SheetContent
        side="right"
        className="w-[90vw] sm:w-[60vw] overflow-y-auto"
      >
        <SheetHeader>
          <SheetTitle>Contenu du cours</SheetTitle>
          <SheetDescription>
            Consultez le contenu détaillé du cours et ses chapitres
          </SheetDescription>
        </SheetHeader>
        <div className="mt-6">
          <CoursePreview course={course} />
        </div>
      </SheetContent>
    </Sheet>
  );
};