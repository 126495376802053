import { PricingPlan } from "./pricing/PricingPlan";
import { pricingPlans } from "./pricing/pricingPlans";
import { useSubscription } from "./pricing/useSubscription";

export const PricingSection = () => {
  const { handleSubscribe } = useSubscription();

  return (
    <section className="py-16 md:py-24">
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-12">
          <h2 className="text-3xl font-bold tracking-tight sm:text-4xl">
            Nos formules d'apprentissage
          </h2>
          <p className="mt-4 text-lg text-[#000000]">
            Choisissez la formule qui correspond le mieux à vos besoins
          </p>
        </div>

        <div className="grid grid-cols-1 gap-8 md:grid-cols-3">
          {pricingPlans.map((plan, index) => (
            <div 
              key={plan.name}
              style={{ animationDelay: `${index * 100}ms` }}
            >
              <PricingPlan
                {...plan}
                onSubscribe={handleSubscribe}
              />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};