import { useState, useEffect } from "react";
import { Card } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { ScrollArea } from "@/components/ui/scroll-area";
import { MinimizeIcon, MaximizeIcon, TrashIcon } from "lucide-react";
import { Badge } from "@/components/ui/badge";

interface LogEntry {
  timestamp: string;
  message: string;
  type: "info" | "error" | "success";
  data?: any;
}

interface AIMonitoringPanelProps {
  className?: string;
}

export const AIMonitoringPanel = ({ className = "" }: AIMonitoringPanelProps) => {
  const [isMinimized, setIsMinimized] = useState(false);
  const [logs, setLogs] = useState<LogEntry[]>([]);

  // Listen to console logs
  useEffect(() => {
    const originalConsoleLog = console.log;
    const originalConsoleError = console.error;

    console.log = (...args) => {
      originalConsoleLog.apply(console, args);
      addLog(args.map(arg => 
        typeof arg === 'object' ? JSON.stringify(arg, null, 2) : String(arg)
      ).join(' '), "info");
    };

    console.error = (...args) => {
      originalConsoleError.apply(console, args);
      addLog(args.map(arg => 
        typeof arg === 'object' ? JSON.stringify(arg, null, 2) : String(arg)
      ).join(' '), "error");
    };

    return () => {
      console.log = originalConsoleLog;
      console.error = originalConsoleError;
    };
  }, []);

  const addLog = (message: string, type: LogEntry["type"] = "info", data?: any) => {
    setLogs(prev => [
      {
        timestamp: new Date().toISOString(),
        message,
        type,
        data
      },
      ...prev
    ]);
  };

  const clearLogs = () => {
    setLogs([]);
  };

  const getLogColor = (type: LogEntry["type"]) => {
    switch (type) {
      case "error":
        return "text-red-500";
      case "success":
        return "text-green-500";
      default:
        return "text-foreground";
    }
  };

  return (
    <Card className={`${className} fixed bottom-4 right-4 w-[600px] transition-all ${
      isMinimized ? 'h-12' : 'h-[500px]'
    }`}>
      <div className="p-2 border-b flex items-center justify-between bg-muted">
        <div className="flex items-center gap-2">
          <h3 className="font-medium">Monitoring IA</h3>
          <Badge variant="outline" className="text-xs">
            {logs.length} logs
          </Badge>
        </div>
        <div className="flex items-center gap-2">
          <Button
            variant="ghost"
            size="sm"
            onClick={clearLogs}
          >
            <TrashIcon size={16} />
          </Button>
          <Button
            variant="ghost"
            size="sm"
            onClick={() => setIsMinimized(!isMinimized)}
          >
            {isMinimized ? <MaximizeIcon size={16} /> : <MinimizeIcon size={16} />}
          </Button>
        </div>
      </div>

      {!isMinimized && (
        <ScrollArea className="h-[calc(100%-48px)] rounded-b-lg">
          <div className="p-4 space-y-4">
            {logs.map((log, index) => (
              <div
                key={index}
                className={`space-y-1 ${getLogColor(log.type)}`}
              >
                <div className="flex items-center gap-2">
                  <span className="text-xs text-muted-foreground">
                    {new Date(log.timestamp).toLocaleTimeString()}
                  </span>
                  <Badge variant={log.type === "error" ? "destructive" : "outline"} className="text-xs">
                    {log.type.toUpperCase()}
                  </Badge>
                </div>
                <div className="text-sm font-mono whitespace-pre-wrap">
                  {log.message}
                </div>
                {log.data && (
                  <pre className="mt-1 text-xs bg-muted p-2 rounded overflow-x-auto">
                    {JSON.stringify(log.data, null, 2)}
                  </pre>
                )}
              </div>
            ))}
          </div>
        </ScrollArea>
      )}
    </Card>
  );
};