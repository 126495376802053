import { BookOpen, Settings, Home } from "lucide-react";
import { SidebarLink } from "./SidebarLink";

interface SidebarNavProps {
  type: "admin" | "student";
}

export const SidebarNav = ({ type }: SidebarNavProps) => {
  const baseRoute = type === "admin" ? "/admin" : "/student";
  
  const links = [
    ...(type === "admin"
      ? [
          { icon: Home, label: "Accueil", path: `${baseRoute}/dashboard` },
          { icon: BookOpen, label: "Cours", path: `${baseRoute}/courses` },
        ]
      : [
          { icon: Home, label: "Tableau de bord", path: `${baseRoute}/dashboard` },
          { icon: BookOpen, label: "Cours", path: `${baseRoute}/courses` },
        ]),
    { icon: Settings, label: "Paramètres", path: `${baseRoute}/settings` },
  ];

  return (
    <nav className="flex-1 px-4 pt-6 lg:pt-8 overflow-y-auto">
      <ul className="space-y-2.5 flex flex-col">
        {links.map((link) => (
          <SidebarLink
            key={link.path}
            path={link.path}
            label={link.label}
            Icon={link.icon}
          />
        ))}
      </ul>
    </nav>
  );
};