
      // Attendre que le DOM soit chargé
      document.addEventListener('DOMContentLoaded', async () => {
        try {
          // Charger d'abord GTM
          const script = document.createElement('script');
          script.async = true;
          script.src = 'https://www.googletagmanager.com/gtm.js?id=GTM-WNTHDK2Z';
          document.head.appendChild(script);

          // Attendre que GTM soit chargé
          await new Promise((resolve) => {
            script.onload = resolve;
          });

          // Ensuite initialiser AdSense sans paramètres de consentement
          const adsScript = document.createElement('script');
          adsScript.async = true;
          adsScript.src = 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-5422316091615187';
          adsScript.crossOrigin = 'anonymous';
          document.head.appendChild(adsScript);

          // Importer et initialiser les autres services
          const { initTCFListener } = await import('./src/utils/consent.ts');
          const { initGA4 } = await import('./src/utils/analytics.ts');
          
          await initTCFListener();
          initGA4({ analytics: false }); // Valeur par défaut en attendant le consentement TCF
        } catch (error) {
          console.warn('Error during initialization:', error);
        }
      });
    