import { useState } from "react";
import { Button } from "@/components/ui/button";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { Input } from "@/components/ui/input";
import { ArticleData } from "../../types";

interface ArticleConfigurationFormProps {
  data: ArticleData;
  onComplete: (data: Partial<ArticleData>) => void;
}

export const ArticleConfigurationForm = ({ data, onComplete }: ArticleConfigurationFormProps) => {
  const [formData, setFormData] = useState({
    writingStyle: data.configuration?.writingStyle || "professionnel",
    perspective: data.configuration?.perspective || "expert",
    titleCount: data.configuration?.titleCount || "5",
    wordCount: data.configuration?.wordCount || "1500",
  });

  const handleSubmit = () => {
    // Validation des valeurs numériques
    const titleCount = Math.max(1, Math.min(10, parseInt(formData.titleCount) || 5));
    const wordCount = Math.max(500, Math.min(5000, parseInt(formData.wordCount) || 1500));

    onComplete({
      configuration: {
        ...formData,
        titleCount: titleCount.toString(),
        wordCount: wordCount.toString(),
      },
    });
  };

  return (
    <div className="space-y-6">
      <div className="space-y-4">
        <div>
          <label className="text-sm font-medium mb-1 block">
            Style d'écriture
          </label>
          <Select
            value={formData.writingStyle}
            onValueChange={(value) => setFormData(prev => ({ ...prev, writingStyle: value }))}
          >
            <SelectTrigger>
              <SelectValue placeholder="Choisir un style d'écriture" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="professionnel">Professionnel</SelectItem>
              <SelectItem value="conversationnel">Conversationnel</SelectItem>
              <SelectItem value="academique">Académique</SelectItem>
              <SelectItem value="journalistique">Journalistique</SelectItem>
            </SelectContent>
          </Select>
        </div>

        <div>
          <label className="text-sm font-medium mb-1 block">
            Perspective
          </label>
          <Select
            value={formData.perspective}
            onValueChange={(value) => setFormData(prev => ({ ...prev, perspective: value }))}
          >
            <SelectTrigger>
              <SelectValue placeholder="Choisir une perspective" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="expert">Expert du domaine</SelectItem>
              <SelectItem value="debutant">Débutant</SelectItem>
              <SelectItem value="neutre">Neutre</SelectItem>
              <SelectItem value="pratique">Orienté pratique</SelectItem>
            </SelectContent>
          </Select>
        </div>

        <div>
          <label className="text-sm font-medium mb-1 block">
            Nombre de titres à générer
          </label>
          <Input
            type="number"
            min="1"
            max="10"
            value={formData.titleCount}
            onChange={(e) => setFormData(prev => ({ ...prev, titleCount: e.target.value }))}
            placeholder="Nombre de titres (1-10)"
            className="w-full"
          />
          <p className="text-sm text-muted-foreground mt-1">
            Entrez un nombre entre 1 et 10
          </p>
        </div>

        <div>
          <label className="text-sm font-medium mb-1 block">
            Longueur approximative de l'article (en mots)
          </label>
          <Input
            type="number"
            min="500"
            max="5000"
            step="100"
            value={formData.wordCount}
            onChange={(e) => setFormData(prev => ({ ...prev, wordCount: e.target.value }))}
            placeholder="Nombre de mots (500-5000)"
            className="w-full"
          />
          <p className="text-sm text-muted-foreground mt-1">
            Entrez un nombre entre 500 et 5000
          </p>
        </div>
      </div>

      <Button
        onClick={handleSubmit}
        className="w-full"
      >
        Continuer
      </Button>
    </div>
  );
};