import { useState } from "react";
import { Card } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { toast } from "sonner";
import { Chapter, ChapterStatus } from "@/types/course";
import { supabase } from "@/integrations/supabase/client";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { ContentSection } from "./form/sections/ContentSection";
import { SEOSection } from "./form/sections/SEOSection";
import { QuizSection } from "./form/sections/QuizSection";
import { generateSlug } from "@/utils/slugUtils";

interface ChapterFormProps {
  courseId: string;
  initialData?: Chapter;
  onSuccess: () => void;
  onCancel: () => void;
}

export const ChapterForm = ({
  courseId,
  initialData,
  onSuccess,
  onCancel,
}: ChapterFormProps) => {
  const [title, setTitle] = useState(initialData?.title || "");
  const [content, setContent] = useState(initialData?.content || "");
  const [imageUrl, setImageUrl] = useState(initialData?.image_url || "");
  const [orderIndex, setOrderIndex] = useState(initialData?.order_index || 0);
  const [duration, setDuration] = useState(initialData?.duration || 15);
  const [status, setStatus] = useState<ChapterStatus>(initialData?.status || "draft");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isAnalyzing, setIsAnalyzing] = useState(false);
  const [slug, setSlug] = useState(initialData?.slug || "");
  const [seoTitle, setSeoTitle] = useState(initialData?.seo_title || "");
  const [metaDescription, setMetaDescription] = useState(initialData?.meta_description || "");
  const [metaKeywords, setMetaKeywords] = useState(initialData?.meta_keywords || "");

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      const chapterData = {
        title,
        content,
        image_url: imageUrl,
        order_index: orderIndex,
        duration,
        course_id: courseId,
        status,
        slug: slug || generateSlug(title, orderIndex),
        seo_title: seoTitle,
        meta_description: metaDescription,
        meta_keywords: metaKeywords
      };

      if (initialData?.id) {
        const { error } = await supabase
          .from("chapters")
          .update(chapterData)
          .eq("id", initialData.id);

        if (error) throw error;
        toast.success("Chapitre mis à jour avec succès");
      } else {
        const { error } = await supabase
          .from("chapters")
          .insert(chapterData);

        if (error) throw error;
        toast.success("Chapitre créé avec succès");
      }

      onSuccess();
    } catch (error) {
      console.error("Error submitting chapter:", error);
      toast.error("Une erreur est survenue lors de l'enregistrement du chapitre");
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newTitle = e.target.value;
    setTitle(newTitle);
    if (!initialData?.slug && !slug) {
      setSlug(generateSlug(newTitle, orderIndex));
    }
  };

  const analyzeSEO = async () => {
    if (!content || !title) {
      toast.error("Le titre et le contenu sont requis pour l'analyse SEO");
      return;
    }

    setIsAnalyzing(true);
    try {
      const { data, error } = await supabase.functions.invoke('analyze-seo', {
        body: { title, content }
      });

      if (error) throw error;

      setSeoTitle(data.seoTitle);
      setMetaDescription(data.metaDescription);
      setMetaKeywords(data.metaKeywords);
      
      toast.success("Analyse SEO terminée avec succès");
    } catch (error) {
      console.error("Error analyzing SEO:", error);
      toast.error("Erreur lors de l'analyse SEO");
    } finally {
      setIsAnalyzing(false);
    }
  };

  return (
    <div className="space-y-8">
      <form onSubmit={handleSubmit}>
        <Card className="p-6">
          <Tabs defaultValue="content" className="w-full">
            <TabsList className="grid w-full grid-cols-3">
              <TabsTrigger value="content">Contenu</TabsTrigger>
              <TabsTrigger value="seo">SEO</TabsTrigger>
              <TabsTrigger value="quiz" disabled={!initialData?.id}>
                Quiz
              </TabsTrigger>
            </TabsList>

            <TabsContent value="content">
              <ContentSection
                title={title}
                content={content}
                imageUrl={imageUrl}
                orderIndex={orderIndex}
                duration={duration}
                status={status}
                slug={slug}
                onTitleChange={handleTitleChange}
                onContentChange={setContent}
                onImageUrlChange={setImageUrl}
                onOrderIndexChange={(e) => setOrderIndex(parseInt(e.target.value))}
                onDurationChange={(e) => setDuration(parseInt(e.target.value))}
                onStatusChange={setStatus}
                onSlugChange={setSlug}
              />
            </TabsContent>

            <TabsContent value="seo">
              <SEOSection
                seoTitle={seoTitle}
                metaDescription={metaDescription}
                metaKeywords={metaKeywords}
                onSeoTitleChange={setSeoTitle}
                onMetaDescriptionChange={setMetaDescription}
                onMetaKeywordsChange={setMetaKeywords}
                onAnalyzeSEO={analyzeSEO}
                isAnalyzing={isAnalyzing}
              />
            </TabsContent>

            <TabsContent value="quiz">
              <QuizSection chapterId={initialData?.id} />
            </TabsContent>
          </Tabs>

          <div className="flex justify-end space-x-2 mt-6">
            <Button type="button" variant="outline" onClick={onCancel}>
              Annuler
            </Button>
            <Button type="submit" disabled={isSubmitting}>
              {isSubmitting
                ? "Enregistrement..."
                : initialData
                ? "Mettre à jour"
                : "Créer"}
            </Button>
          </div>
        </Card>
      </form>
    </div>
  );
};