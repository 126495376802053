import { useState } from "react";
import { QuestionForm } from "./QuestionForm";
import { QuestionList } from "./QuestionList";
import { QuizQuestion } from "@/types/quiz";

interface QuizFormProps {
  chapterId: string;
  onSuccess: () => void;
  onCancel: () => void;
  initialData?: QuizQuestion | null;
}

export const QuizForm = ({
  chapterId,
  onSuccess,
  onCancel,
  initialData,
}: QuizFormProps) => {
  const [showQuestionForm, setShowQuestionForm] = useState(false);
  const [questions, setQuestions] = useState<QuizQuestion[]>([]);

  return (
    <div className="space-y-8">
      <QuestionList
        chapterId={chapterId}
        showQuestionForm={showQuestionForm}
        onShowQuestionForm={setShowQuestionForm}
      />
      
      {showQuestionForm && (
        <QuestionForm
          chapterId={chapterId}
          open={showQuestionForm}
          onOpenChange={setShowQuestionForm}
          onSuccess={() => {
            setShowQuestionForm(false);
            onSuccess();
          }}
          initialData={initialData}
        />
      )}
    </div>
  );
};