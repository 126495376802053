import { Header } from "@/components/layout/Header";
import { Footer } from "@/components/layout/Footer";
import { useNavigate } from "react-router-dom";
import { Button } from "@/components/ui/button";
import { Card, CardContent } from "@/components/ui/card";
import { useQuery } from "@tanstack/react-query";
import { supabase } from "@/integrations/supabase/client";
import { Helmet } from "react-helmet";
import { format } from "date-fns";
import { fr } from "date-fns/locale";
import { Clock, ArrowRight } from "lucide-react";
import { lazy, Suspense } from "react";

const Blog = () => {
  const navigate = useNavigate();

  const { data: posts, isLoading } = useQuery({
    queryKey: ["blog-posts"],
    queryFn: async () => {
      const { data, error } = await supabase
        .from("blog_posts")
        .select("*")
        .eq("status", "published")
        .order("created_at", { ascending: false });

      if (error) throw error;
      return data;
    },
  });

  return (
    <div className="min-h-screen flex flex-col">
      <Helmet>
        <title>Bekane | Le blog des futurs motards</title>
        <meta name="description" content="Retrouvez des contenus et des conseils pour réussir l'apprentissage de la moto." />
        <meta name="keywords" content="blog, bekane, blog moto, blog motard débutant, motard débutant" />
        <meta name="author" content="Bekane" />
        <link rel="canonical" href="https://www.bekane.fr/blog" />
      </Helmet>

      <Header />
      <main className="flex-grow bg-gray-50">
        <div className="container mx-auto px-4 py-12">
          <div className="max-w-3xl mx-auto text-center mb-12">
            <h1 className="text-4xl font-bold mb-4 animate-fadeIn">Blog Bekane</h1>
            <p className="text-lg text-gray-600 mb-8">
              Découvrez nos articles sur le code de la route moto, des conseils pratiques 
              et restez informé des dernières actualités de la sécurité routière.
            </p>
          </div>

          {isLoading ? (
            <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
              {[1, 2, 3].map((n) => (
                <Card key={n} className="animate-pulse">
                  <div className="w-full h-48 bg-gray-200" />
                  <CardContent className="p-6">
                    <div className="h-6 bg-gray-200 rounded w-3/4 mb-4" />
                    <div className="h-4 bg-gray-200 rounded w-full mb-2" />
                    <div className="h-4 bg-gray-200 rounded w-2/3" />
                  </CardContent>
                </Card>
              ))}
            </div>
          ) : (
            <>
              {posts && posts.length > 0 ? (
                <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
                  {posts.map((post) => (
                    <Card 
                      key={post.id} 
                      className="overflow-hidden hover:shadow-lg transition-shadow duration-300 animate-fadeIn bg-white"
                    >
                      {post.image_url && (
                        <div className="relative">
                          <img 
                            src={post.image_url} 
                            alt={post.title}
                            loading="lazy"
                            className="w-full h-48 object-cover"
                          />
                          <div className="absolute bottom-0 left-0 right-0 px-4 py-2 bg-gradient-to-t from-black/60 to-transparent">
                            <div className="flex items-center text-white text-sm">
                              <Clock className="h-4 w-4 mr-2" />
                              <time dateTime={post.created_at}>
                                {format(new Date(post.created_at), "d MMMM yyyy", { locale: fr })}
                              </time>
                            </div>
                          </div>
                        </div>
                      )}
                      <CardContent className="p-6">
                        <h2 className="text-xl font-semibold mb-3 line-clamp-2 hover:text-primary transition-colors">
                          {post.title}
                        </h2>
                        <p className="text-gray-600 mb-4 line-clamp-3">
                          {post.content?.replace(/<[^>]*>/g, '').substring(0, 150)}...
                        </p>
                        <Button 
                          onClick={() => navigate(`/blog/${post.slug}`)}
                          className="w-full group"
                        >
                          Lire la suite
                          <ArrowRight className="ml-2 h-4 w-4 transition-transform group-hover:translate-x-1" />
                        </Button>
                      </CardContent>
                    </Card>
                  ))}
                </div>
              ) : (
                <div className="text-center py-12">
                  <p className="text-lg text-gray-600">
                    Aucun article n'est disponible pour le moment.
                    Revenez bientôt pour découvrir nos premiers contenus !
                  </p>
                </div>
              )}
            </>
          )}
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default Blog;
