import { Auth } from "@supabase/auth-ui-react";
import { ThemeSupa } from "@supabase/auth-ui-shared";
import { supabase } from "@/integrations/supabase/client";
import { Link } from "react-router-dom";

export const LoginForm = () => {
  return (
    <div className="bg-white rounded-lg">
      <Auth
        supabaseClient={supabase}
        appearance={{
          theme: ThemeSupa,
          variables: {
            default: {
              colors: {
                brand: '#000000',
                brandAccent: '#333333',
              },
            },
          },
          className: {
            container: 'space-y-6',
            button: 'w-full bg-black hover:bg-black/90 text-white',
            input: 'rounded-md border-gray-200 focus:border-black focus:ring-black',
            label: 'text-sm font-medium text-black',
            message: 'text-sm text-red-600',
            anchor: 'text-sm text-black hover:text-black/80 underline',
          },
        }}
        localization={{
          variables: {
            sign_in: {
              email_label: "Adresse email",
              password_label: "Mot de passe",
              button_label: "Se connecter",
              loading_button_label: "Connexion en cours...",
              email_input_placeholder: "Votre adresse email",
              password_input_placeholder: "Votre mot de passe",
              link_text: "Déjà inscrit ? Connectez-vous",
            },
            sign_up: {
              email_label: "Adresse email",
              password_label: "Mot de passe",
              button_label: "S'inscrire",
              loading_button_label: "Inscription en cours...",
              email_input_placeholder: "Votre adresse email",
              password_input_placeholder: "Choisissez un mot de passe",
              link_text: "Pas encore de compte ? Inscrivez-vous",
            },
            forgotten_password: {
              button_label: "Réinitialiser le mot de passe",
              email_label: "Adresse email",
              email_input_placeholder: "Votre adresse email",
              link_text: "Mot de passe oublié ?",
              confirmation_text: "Vérifiez vos emails pour réinitialiser votre mot de passe",
            },
          },
        }}
        theme="light"
        providers={[]}
      />
      <div className="text-center text-sm mt-6">
        <span className="text-black">Pas encore de compte ?</span>{" "}
        <Link
          to="/auth/register"
          className="font-medium text-black hover:text-black/80 underline"
        >
          Créer un compte
        </Link>
      </div>
    </div>
  );
};