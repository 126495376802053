import { DashboardLayout } from "@/components/layout/DashboardLayout";
import { Skeleton } from "@/components/ui/skeleton";

export const CourseViewSkeleton = () => {
  return (
    <DashboardLayout type="student">
      <div className="min-h-screen flex">
        <div className="w-80 hidden lg:block">
          <Skeleton className="h-full" />
        </div>
        <div className="flex-1 p-4 lg:p-6">
          <Skeleton className="h-8 w-1/3 mb-4" />
          <Skeleton className="h-[600px]" />
        </div>
      </div>
    </DashboardLayout>
  );
};