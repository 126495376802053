import { useState } from "react";
import { BlogHeader } from "./BlogHeader";
import { BlogCard } from "./BlogCard";
import { DeleteDialog } from "./DeleteDialog";
import { BlogListProps } from "./types";

export const BlogList = ({ posts, onEdit, onAdd, onStatusChange, onDelete }: BlogListProps) => {
  const [viewMode, setViewMode] = useState<"list" | "grid">("list");
  const [postToDelete, setPostToDelete] = useState<string | null>(null);

  const handleDelete = async (postId: string) => {
    try {
      if (onDelete) {
        await onDelete(postId);
      }
      setPostToDelete(null);
    } catch (error) {
      console.error("Error deleting post:", error);
    }
  };

  return (
    <div className="space-y-4">
      <BlogHeader
        viewMode={viewMode}
        setViewMode={setViewMode}
        onAdd={onAdd}
      />

      {posts.length > 0 ? (
        <div className={`grid gap-4 ${viewMode === "grid" ? "grid-cols-1 md:grid-cols-2 lg:grid-cols-3" : ""}`}>
          {posts.map((post) => (
            <BlogCard
              key={post.id}
              post={post}
              viewMode={viewMode}
              onEdit={onEdit}
              onStatusChange={onStatusChange}
              onDeleteClick={(postId) => setPostToDelete(postId)}
            />
          ))}
        </div>
      ) : (
        <p className="text-muted-foreground text-center py-8">
          Aucun article de blog pour le moment
        </p>
      )}

      <DeleteDialog
        isOpen={!!postToDelete}
        onClose={() => setPostToDelete(null)}
        onConfirm={() => postToDelete && handleDelete(postToDelete)}
      />
    </div>
  );
};