import { Card, CardContent, CardHeader } from "@/components/ui/card";
import { CourseHeader } from "./CourseHeader";
import { ChaptersList } from "./ChaptersList";
import { useNavigate } from "react-router-dom";
import { useToast } from "@/hooks/use-toast";
import { useCourseProgress } from "@/hooks/useCourseProgress";
import { CourseImagePreview } from "./preview/CourseImagePreview";
import { CourseActionButton } from "./preview/CourseActionButton";
import { Course } from "@/types/course";
import { useQuery } from "@tanstack/react-query";
import { supabase } from "@/integrations/supabase/client";
import { Badge } from "@/components/ui/badge";
import { CheckCircle2, Clock, PlayCircle } from "lucide-react";
import { useEffect, useState } from "react";

interface CoursePreviewProps {
  course: Course;
}

// Type pour les données de progression reçues en temps réel
interface CourseProgressPayload {
  progress_percentage: number;
  completed_at: string | null;
}

export const CoursePreview = ({ course }: CoursePreviewProps) => {
  const navigate = useNavigate();
  const { toast } = useToast();
  const { progress, isCompleted, completedChapters } = useCourseProgress(course.id);
  const [realTimeProgress, setRealTimeProgress] = useState(progress);
  const [realTimeIsCompleted, setRealTimeIsCompleted] = useState(isCompleted);

  useEffect(() => {
    const channel = supabase
      .channel('course-progress-changes')
      .on(
        'postgres_changes',
        {
          event: '*',
          schema: 'public',
          table: 'course_progress',
          filter: `course_id=eq.${course.id}`,
        },
        (payload) => {
          const newData = payload.new as CourseProgressPayload;
          if (newData) {
            setRealTimeProgress(newData.progress_percentage || 0);
            setRealTimeIsCompleted(!!newData.completed_at);
          }
        }
      )
      .subscribe();

    return () => {
      supabase.removeChannel(channel);
    };
  }, [course.id]);

  // Synchroniser l'état local avec les données de progression
  useEffect(() => {
    setRealTimeProgress(progress);
    setRealTimeIsCompleted(isCompleted);
  }, [progress, isCompleted]);

  const { data: lastVisitedChapter } = useQuery({
    queryKey: ["last-visited-chapter", course.id],
    queryFn: async () => {
      const { data: { session } } = await supabase.auth.getSession();
      if (!session?.user?.id) return null;

      const { data, error } = await supabase
        .from("chapter_progress")
        .select("chapter_id")
        .eq("course_id", course.id)
        .eq("user_id", session.user.id)
        .order("last_visited_at", { ascending: false })
        .limit(1)
        .maybeSingle();

      if (error) {
        console.error("Error fetching last visited chapter:", error);
        return null;
      }

      return data?.chapter_id;
    },
  });

  const getCourseStatus = () => {
    if (realTimeIsCompleted) {
      return {
        label: "Terminé",
        icon: <CheckCircle2 className="w-4 h-4" />,
        color: "bg-green-500"
      };
    }
    if (completedChapters > 0) {
      return {
        label: "En cours",
        icon: <Clock className="w-4 h-4" />,
        color: "bg-blue-500"
      };
    }
    return {
      label: "Non commencé",
      icon: <PlayCircle className="w-4 h-4" />,
      color: "bg-gray-500"
    };
  };

  const status = getCourseStatus();

  const handleCourseAccess = () => {
    navigate(`/student/courses/${course.slug}`);
    
    const message = realTimeIsCompleted 
      ? "Révision du cours" 
      : completedChapters > 0
        ? "Reprise du cours" 
        : "Commencez votre apprentissage";
        
    const description = realTimeIsCompleted 
      ? "Parfait pour consolider vos connaissances !" 
      : completedChapters > 0
        ? lastVisitedChapter
          ? "Reprise là où vous vous étiez arrêté"
          : `Vous avez complété ${completedChapters} chapitre${completedChapters > 1 ? 's' : ''} sur ${course.chapters.length}`
        : "Bon apprentissage !";

    toast({
      title: message,
      description: description,
      duration: 3000,
    });
  };

  return (
    <Card className={`h-full flex flex-col transition-all duration-300 hover:shadow-lg animate-fadeIn group ${realTimeIsCompleted ? 'order-last' : ''}`}>
      <CourseImagePreview 
        imageUrl={course.image_url}
        title={course.title}
        progress={realTimeProgress}
        isCompleted={realTimeIsCompleted}
      />

      <CardHeader className="pb-2">
        <div className="flex items-center justify-between gap-4">
          <CourseHeader
            title={course.title}
            chapters={course.chapters}
          />
          <Badge 
            className={`${status.color} text-white flex items-center gap-2 px-3 py-1.5`}
          >
            {status.icon}
            {status.label}
          </Badge>
        </div>
      </CardHeader>

      <CardContent className="flex flex-col flex-1 space-y-4">
        <div className="prose prose-sm max-w-none break-words">
          <div
            dangerouslySetInnerHTML={{
              __html: course.content || "",
            }}
          />
        </div>

        {course.chapters.length > 0 && (
          <div className="flex-1">
            <ChaptersList 
              chapters={course.chapters} 
              courseId={course.id}
            />
          </div>
        )}

        <CourseActionButton 
          isCompleted={realTimeIsCompleted}
          progress={realTimeProgress}
          onClick={handleCourseAccess}
        />
      </CardContent>
    </Card>
  );
};