import { ChapterManager } from "../ChapterManager";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "@/components/ui/tabs";
import { Chapter } from "@/types/course";

interface CourseFormTabsProps {
  courseId: string;
  onChaptersChange: (chapters: Chapter[]) => void;
}

export const CourseFormTabs = ({
  courseId,
  onChaptersChange,
}: CourseFormTabsProps) => {
  return (
    <Tabs defaultValue="chapters">
      <TabsList>
        <TabsTrigger value="chapters">Chapitres</TabsTrigger>
      </TabsList>
      <TabsContent value="chapters">
        <ChapterManager courseId={courseId} onChaptersChange={onChaptersChange} />
      </TabsContent>
    </Tabs>
  );
};