import { CourseStatus } from "@/types/course";
import { GeneralInfoSection } from "./form/sections/GeneralInfoSection";
import { ContentSection } from "./form/sections/ContentSection";
import { SEOSection } from "./form/sections/SEOSection";

interface CourseBasicInfoProps {
  title: string;
  setTitle: (title: string) => void;
  content: string;
  setContent: (content: string) => void;
  imageUrl: string;
  setImageUrl: (url: string) => void;
  status: CourseStatus;
  setStatus: (status: CourseStatus) => void;
  slug: string;
  setSlug: (slug: string) => void;
  courseId?: string;
  metaDescription: string;
  setMetaDescription: (desc: string) => void;
  metaKeywords: string;
  setMetaKeywords: (keywords: string) => void;
  seoTitle: string;
  setSeoTitle: (title: string) => void;
  isIndexable: boolean;
  setIsIndexable: (indexable: boolean) => void;
}

export const CourseBasicInfo = ({
  title,
  setTitle,
  content,
  setContent,
  imageUrl,
  setImageUrl,
  status,
  setStatus,
  slug,
  setSlug,
  courseId,
  metaDescription,
  setMetaDescription,
  metaKeywords,
  setMetaKeywords,
  seoTitle,
  setSeoTitle,
  isIndexable,
  setIsIndexable,
}: CourseBasicInfoProps) => {
  return (
    <div className="space-y-8">
      <GeneralInfoSection
        title={title}
        setTitle={setTitle}
        slug={slug}
        setSlug={setSlug}
        imageUrl={imageUrl}
        setImageUrl={setImageUrl}
        status={status}
        setStatus={setStatus}
        courseId={courseId}
      />
      
      <ContentSection 
        content={content}
        onContentChange={setContent}
        imageUrl={imageUrl}
        onImageUrlChange={setImageUrl}
        status={status}
        onStatusChange={setStatus}
      />

      <SEOSection
        metaDescription={metaDescription}
        onMetaDescriptionChange={setMetaDescription}
        metaKeywords={metaKeywords}
        onMetaKeywordsChange={setMetaKeywords}
        seoTitle={seoTitle}
        onSeoTitleChange={setSeoTitle}
        isIndexable={isIndexable}
        onIsIndexableChange={setIsIndexable}
      />
    </div>
  );
};