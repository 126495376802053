import { Chapter } from "@/types/course";
import { AdSpace } from "./AdSpace";

interface ChapterContentProps {
  chapter: Chapter;
  courseId: string;
  currentIndex: number;
  totalChapters: number;
  onChapterComplete: (chapterId: string) => void;
  chapters: Chapter[];
}

export const ChapterContent = ({ 
  chapter,
  courseId,
  currentIndex,
  totalChapters,
  onChapterComplete,
  chapters 
}: ChapterContentProps) => {
  const content = chapter.content || "";
  const paragraphs = content.split('</p>');
  const midPoint = Math.floor(paragraphs.length / 2);

  return (
    <article className="prose prose-lg max-w-none bg-white rounded-lg p-8 shadow-sm">
      <h1 className="text-3xl font-bold mb-6">{chapter.title}</h1>
      
      {/* Annonce en début de contenu */}
      <div className="my-8">
        <AdSpace type="content-start" />
      </div>
      
      {chapter.image_url && (
        <img
          src={chapter.image_url}
          alt={chapter.title}
          className="w-full rounded-lg mb-6"
        />
      )}
      
      <div className="prose prose-lg max-w-none space-y-6">
        {/* Première moitié du contenu */}
        <div dangerouslySetInnerHTML={{ 
          __html: paragraphs.slice(0, midPoint).join('</p>') + (midPoint > 0 ? '</p>' : '') 
        }} />

        {/* Annonce au milieu du contenu */}
        <div className="my-8">
          <AdSpace type="content-middle" />
        </div>

        {/* Seconde moitié du contenu */}
        <div dangerouslySetInnerHTML={{ 
          __html: paragraphs.slice(midPoint).join('</p>') 
        }} />
      </div>

      {/* Annonce en fin de contenu */}
      <div className="my-8">
        <AdSpace type="content-end" />
      </div>
    </article>
  );
};