import { useState } from "react";
import { ArticleData } from "./types";
import { ArticleGeneratorSteps } from "./ArticleGeneratorSteps";
import { AIMonitoringPanel } from "./AIMonitoringPanel";

export const ArticleGeneratorContainer = () => {
  const [currentStep, setCurrentStep] = useState("1");
  const [articleData, setArticleData] = useState<ArticleData>({
    subject: "",
    language: "fr",
    model: "gpt-4o",
    selectedSources: [],
    configuration: {
      titleCount: "5",
      writingStyle: "professionnel",
      perspective: "expert",
      wordCount: "1500"
    }
  });

  const handleStepComplete = async (step: string, data: Partial<ArticleData>) => {
    const updatedData = { ...articleData, ...data };
    setArticleData(updatedData);
    
    const nextStep = {
      "1": "2",
      "2": "3",
      "3": "4",
      "4": "5",
      "5": "6",
      "6": "7",
      "7": "8",
      "8": "9"
    }[step];

    if (nextStep) {
      setCurrentStep(nextStep);
    }
  };

  return (
    <div className="relative">
      <ArticleGeneratorSteps 
        currentStep={currentStep}
        articleData={articleData}
        onStepComplete={handleStepComplete}
      />
      <AIMonitoringPanel className="fixed bottom-4 right-4 w-96" />
    </div>
  );
};