import { useState } from "react";
import { Button } from "@/components/ui/button";
import { toast } from "sonner";
import { useAIGeneration } from "@/hooks/useAIGeneration";
import { getQuizGenerationPrompt } from "./prompts/quizGenerationPrompt";
import { parseQuizFromText } from "./prompts/quizParser";
import { Card } from "@/components/ui/card";
import { Checkbox } from "@/components/ui/checkbox";

interface QuizGeneratorProps {
  chapterContent: string;
  onQuizGenerated: (questions: any[]) => void;
}

export const QuizGenerator = ({ chapterContent, onQuizGenerated }: QuizGeneratorProps) => {
  const [generatedQuestions, setGeneratedQuestions] = useState<any[]>([]);
  const [selectedQuestions, setSelectedQuestions] = useState<string[]>([]);
  const { generateContent, isLoading } = useAIGeneration();

  const generateQuiz = async () => {
    if (!chapterContent) {
      toast.error("Le contenu du chapitre est requis pour générer un quiz");
      return;
    }

    const prompt = getQuizGenerationPrompt(chapterContent);
    const content = await generateContent(prompt);

    if (content) {
      const parsedQuestions = parseQuizFromText(content);
      console.log("Questions générées:", parsedQuestions);
      setGeneratedQuestions(parsedQuestions);
    }
  };

  const handleQuestionSelect = (question: string) => {
    setSelectedQuestions(prev => {
      if (prev.includes(question)) {
        return prev.filter(q => q !== question);
      }
      return [...prev, question];
    });
  };

  const handleSaveQuestions = () => {
    const selectedQuestionData = generatedQuestions.filter(q => 
      selectedQuestions.includes(q.question)
    );
    onQuizGenerated(selectedQuestionData);
    toast.success("Questions sélectionnées ajoutées au quiz");
  };

  return (
    <div className="space-y-6">
      <Button
        onClick={generateQuiz}
        disabled={isLoading}
        className="w-full"
      >
        {isLoading ? "Génération du quiz en cours..." : "Générer un quiz avec l'IA"}
      </Button>

      {generatedQuestions.length > 0 && (
        <div className="space-y-4">
          <div className="grid gap-4">
            {generatedQuestions.map((q, index) => (
              <Card key={index} className="p-4">
                <div className="flex items-start gap-4">
                  <Checkbox
                    checked={selectedQuestions.includes(q.question)}
                    onCheckedChange={() => handleQuestionSelect(q.question)}
                  />
                  <div className="flex-1 space-y-2">
                    <h3 className="font-medium">{q.question}</h3>
                    <div className="space-y-1">
                      {q.answers.map((answer: any, ansIndex: number) => (
                        <div
                          key={ansIndex}
                          className={`p-2 rounded ${
                            answer.isCorrect
                              ? "bg-green-100 dark:bg-green-900/20"
                              : "bg-gray-100 dark:bg-gray-800"
                          }`}
                        >
                          {answer.text}
                          {answer.isCorrect && (
                            <div className="text-sm text-green-600 dark:text-green-400 mt-1">
                              Explication: {answer.explanation}
                            </div>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </Card>
            ))}
          </div>

          <Button
            onClick={handleSaveQuestions}
            disabled={selectedQuestions.length === 0}
            className="w-full"
          >
            Ajouter les questions sélectionnées
          </Button>
        </div>
      )}
    </div>
  );
};