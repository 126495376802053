import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { Button } from "@/components/ui/button";
import { ArrowUpDown } from "lucide-react";
import { Sheet, SheetContent, SheetDescription, SheetHeader, SheetTitle, SheetTrigger } from "@/components/ui/sheet";
import { CourseOrderManager } from "@/components/admin/courses/CourseOrderManager";

interface CourseFiltersProps {
  searchQuery: string;
  onSearchChange: (value: string) => void;
  statusFilter?: string;
  onStatusFilterChange?: (value: string) => void;
  onOrderChange?: () => void;
}

export const CourseFilters = ({
  searchQuery,
  onSearchChange,
  statusFilter,
  onStatusFilterChange,
  onOrderChange,
}: CourseFiltersProps) => {
  return (
    <div className="flex flex-col sm:flex-row gap-4">
      <div className="flex-1">
        <Label htmlFor="search" className="sr-only">
          Rechercher
        </Label>
        <Input
          id="search"
          placeholder="Rechercher un cours..."
          value={searchQuery}
          onChange={(e) => onSearchChange(e.target.value)}
        />
      </div>

      <div className="flex gap-2">
        {statusFilter !== undefined && onStatusFilterChange && (
          <>
            <Sheet>
              <SheetTrigger asChild>
                <Button 
                  variant="outline" 
                  size="icon" 
                  title="Réorganiser les cours"
                  onClick={onOrderChange}
                >
                  <ArrowUpDown className="h-4 w-4" />
                </Button>
              </SheetTrigger>
              <SheetContent side="right" className="w-[90vw] sm:w-[600px]">
                <SheetHeader>
                  <SheetTitle>Réorganiser les cours</SheetTitle>
                  <SheetDescription>
                    Modifiez l'ordre d'affichage des cours en utilisant les flèches
                  </SheetDescription>
                </SheetHeader>
                <div className="mt-4">
                  <CourseOrderManager />
                </div>
              </SheetContent>
            </Sheet>

            <Select value={statusFilter} onValueChange={onStatusFilterChange}>
              <SelectTrigger className="w-[180px]">
                <SelectValue placeholder="Filtrer par statut" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="all">Tous les statuts</SelectItem>
                <SelectItem value="draft">Brouillon</SelectItem>
                <SelectItem value="pending_validation">En attente</SelectItem>
                <SelectItem value="published">Publié</SelectItem>
              </SelectContent>
            </Select>
          </>
        )}
      </div>
    </div>
  );
};