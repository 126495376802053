import { TabsContent } from "@/components/ui/tabs";
import { ArticleData } from "../../types";
import { BasicParameters } from "../BasicParameters";
import { ArticleType } from "../ArticleType";
import { SourceAnalysis } from "../SourceAnalysis";
import { KeywordSelection } from "../KeywordSelection";
import { TitleGeneration } from "../TitleGeneration";
import { SecondaryKeywords } from "../SecondaryKeywords";
import { ArticleConfiguration } from "../ArticleConfiguration";
import { PlanSelection } from "../PlanSelection";
import { ArticleFinalization } from "../ArticleFinalization";

interface StepsContentProps {
  currentStep: string;
  articleData: ArticleData;
  onStepComplete: (step: string, data: Partial<ArticleData>) => void;
}

export const StepsContent = ({
  currentStep,
  articleData,
  onStepComplete,
}: StepsContentProps) => {
  return (
    <>
      <TabsContent value="1">
        <BasicParameters
          data={articleData}
          onComplete={(data) => onStepComplete("1", data)}
        />
      </TabsContent>

      <TabsContent value="2">
        <ArticleType
          data={articleData}
          onComplete={(data) => onStepComplete("2", data)}
        />
      </TabsContent>

      <TabsContent value="3">
        <SourceAnalysis
          data={articleData}
          onComplete={(data) => onStepComplete("3", data)}
        />
      </TabsContent>

      <TabsContent value="4">
        <KeywordSelection
          data={articleData}
          onComplete={(data) => onStepComplete("4", data)}
        />
      </TabsContent>

      <TabsContent value="5">
        <TitleGeneration
          data={articleData}
          onComplete={(data) => onStepComplete("5", data)}
        />
      </TabsContent>

      <TabsContent value="6">
        <SecondaryKeywords
          data={articleData}
          onComplete={(data) => onStepComplete("6", data)}
        />
      </TabsContent>

      <TabsContent value="7">
        <ArticleConfiguration
          data={articleData}
          onComplete={(data) => onStepComplete("7", data)}
        />
      </TabsContent>

      <TabsContent value="8">
        <PlanSelection
          data={articleData}
          onComplete={(data) => onStepComplete("8", data)}
        />
      </TabsContent>

      <TabsContent value="9">
        <ArticleFinalization
          data={articleData}
          onComplete={(data) => onStepComplete("9", data)}
        />
      </TabsContent>
    </>
  );
};