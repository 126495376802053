import { cn } from "@/lib/utils";
import { Award } from "lucide-react";

interface CourseImagePreviewProps {
  imageUrl?: string;
  title: string;
  progress?: number;
  isCompleted?: boolean;
}

export const CourseImagePreview = ({ 
  imageUrl, 
  title, 
  isCompleted = false 
}: CourseImagePreviewProps) => {
  return (
    <div className="aspect-video relative overflow-hidden rounded-t-lg">
      {imageUrl && (
        <img
          src={imageUrl}
          alt={title}
          className="object-cover w-full h-full transform transition-transform duration-300 group-hover:scale-105"
        />
      )}
      <div className="absolute inset-0 bg-gradient-to-t from-black/50 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
      {isCompleted && (
        <div className="absolute top-4 right-4 bg-green-500 text-white p-2 rounded-full shadow-lg animate-bounce">
          <Award className="w-6 h-6" />
        </div>
      )}
    </div>
  );
};