import { Card } from "@/components/ui/card";
import { Tabs } from "@/components/ui/tabs";
import { ArticleData } from "./types";
import { StepsNavigation } from "./steps/navigation/StepsNavigation";
import { StepsContent } from "./steps/navigation/StepsContent";

interface ArticleGeneratorStepsProps {
  currentStep: string;
  articleData: ArticleData;
  onStepComplete: (step: string, data: Partial<ArticleData>) => void;
}

export const ArticleGeneratorSteps = ({
  currentStep,
  articleData,
  onStepComplete
}: ArticleGeneratorStepsProps) => {
  return (
    <Card className="p-6">
      <Tabs value={currentStep} className="w-full">
        <StepsNavigation />
        <StepsContent
          currentStep={currentStep}
          articleData={articleData}
          onStepComplete={onStepComplete}
        />
      </Tabs>
    </Card>
  );
};