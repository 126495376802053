import { Course } from "@/types/course";
import { StudentCourseCard } from "./StudentCourseCard";

interface StudentCourseGridProps {
  courses: Course[];
  onPreview: (course: Course) => void;
}

export const StudentCourseGrid = ({ courses, onPreview }: StudentCourseGridProps) => {
  const sortedCourses = [...courses].sort((a, b) => 
    a.title.localeCompare(b.title, undefined, { sensitivity: 'base' })
  );

  if (!courses?.length) {
    return (
      <div className="text-center py-12">
        <p className="text-muted-foreground">Aucun cours disponible pour le moment.</p>
      </div>
    );
  }

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
      {sortedCourses.map((course) => (
        <StudentCourseCard 
          key={course.id} 
          course={course} 
          onPreview={() => onPreview(course)}
          onClick={() => onPreview(course)}
        />
      ))}
    </div>
  );
};