import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Tiptap } from "@/components/editor/Tiptap";
import { QuestionTypeSelect } from "../question-types/QuestionTypeSelect";
import { QuestionFormatting } from "../formatting/QuestionFormatting";

interface BaseQuestionFormProps {
  question: string;
  richText: string;
  questionType: string;
  fontSize: string;
  textAlign: string;
  backgroundColor: string;
  isSubmitting: boolean;
  onQuestionChange: (value: string) => void;
  onRichTextChange: (value: string) => void;
  onQuestionTypeChange: (value: string) => void;
  onFontSizeChange: (value: string) => void;
  onTextAlignChange: (value: string) => void;
  onBackgroundColorChange: (value: string) => void;
  onCancel: () => void;
  onSubmit: (e: React.FormEvent) => void;
}

export const BaseQuestionForm = ({
  question,
  richText,
  questionType,
  fontSize,
  textAlign,
  backgroundColor,
  isSubmitting,
  onQuestionChange,
  onRichTextChange,
  onQuestionTypeChange,
  onFontSizeChange,
  onTextAlignChange,
  onBackgroundColorChange,
  onCancel,
  onSubmit,
}: BaseQuestionFormProps) => {
  return (
    <form onSubmit={onSubmit} className="space-y-6">
      <QuestionTypeSelect value={questionType} onChange={onQuestionTypeChange} />

      <div className="space-y-2">
        <Label htmlFor="question">Question</Label>
        <Input
          id="question"
          value={question}
          onChange={(e) => onQuestionChange(e.target.value)}
          placeholder="Entrez la question"
          required
        />
      </div>

      <div className="space-y-2">
        <Label>Contenu enrichi (optionnel)</Label>
        <Tiptap content={richText} onChange={onRichTextChange} />
      </div>

      <QuestionFormatting
        fontSize={fontSize}
        textAlign={textAlign}
        backgroundColor={backgroundColor}
        onFontSizeChange={onFontSizeChange}
        onTextAlignChange={onTextAlignChange}
        onBackgroundColorChange={onBackgroundColorChange}
      />

      <div className="flex justify-end gap-2">
        <Button type="button" variant="outline" onClick={onCancel}>
          Annuler
        </Button>
        <Button type="submit" disabled={isSubmitting}>
          {isSubmitting ? "Enregistrement..." : "Ajouter la question"}
        </Button>
      </div>
    </form>
  );
};