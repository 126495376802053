import { KeywordCard } from "./KeywordCard";
import { Keyword } from "./types";

interface KeywordListProps {
  keywords: Keyword[];
  selectedKeywords: string[];
  onKeywordSelect: (keyword: string) => void;
}

export const KeywordList = ({
  keywords,
  selectedKeywords,
  onKeywordSelect
}: KeywordListProps) => {
  return (
    <div className="grid gap-4 grid-cols-1 md:grid-cols-2">
      {keywords.map((kw) => (
        <KeywordCard
          key={kw.keyword}
          keyword={kw}
          isSelected={selectedKeywords.includes(kw.keyword)}
          onSelect={onKeywordSelect}
        />
      ))}
    </div>
  );
};