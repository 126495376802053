import { Button } from "@/components/ui/button";
import { Facebook, Twitter, Linkedin, BookmarkPlus, MessageSquare, Share2 } from "lucide-react";

export const SharingButtons = () => {
  return (
    <>
      {/* Desktop sharing buttons */}
      <div className="hidden lg:block w-16 flex-shrink-0">
        <div className="sticky top-8 space-y-4">
          <Button variant="ghost" size="icon" className="w-10 h-10">
            <Share2 className="h-5 w-5" />
          </Button>
          <Button variant="ghost" size="icon" className="w-10 h-10">
            <Facebook className="h-5 w-5" />
          </Button>
          <Button variant="ghost" size="icon" className="w-10 h-10">
            <Twitter className="h-5 w-5" />
          </Button>
          <Button variant="ghost" size="icon" className="w-10 h-10">
            <Linkedin className="h-5 w-5" />
          </Button>
          <Button variant="ghost" size="icon" className="w-10 h-10">
            <BookmarkPlus className="h-5 w-5" />
          </Button>
          <Button variant="ghost" size="icon" className="w-10 h-10">
            <MessageSquare className="h-5 w-5" />
          </Button>
        </div>
      </div>

      {/* Mobile sharing buttons */}
      <div className="lg:hidden fixed bottom-0 left-0 right-0 bg-white border-t p-4 flex justify-around items-center">
        <Button variant="ghost" size="icon">
          <Share2 className="h-5 w-5" />
        </Button>
        <Button variant="ghost" size="icon">
          <BookmarkPlus className="h-5 w-5" />
        </Button>
        <Button variant="ghost" size="icon">
          <MessageSquare className="h-5 w-5" />
        </Button>
      </div>
    </>
  );
};