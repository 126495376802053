import { Button } from "@/components/ui/button";
import { Grid, List, Plus } from "lucide-react";

interface BlogHeaderProps {
  viewMode: "list" | "grid";
  setViewMode: (mode: "list" | "grid") => void;
  onAdd: () => void;
}

export const BlogHeader = ({ viewMode, setViewMode, onAdd }: BlogHeaderProps) => {
  return (
    <div className="flex justify-between items-center">
      <div className="flex items-center gap-4">
        <h3 className="text-lg font-semibold">Articles de blog</h3>
        <div className="flex gap-2">
          <Button
            variant={viewMode === "list" ? "default" : "outline"}
            size="sm"
            onClick={() => setViewMode("list")}
          >
            <List className="h-4 w-4" />
          </Button>
          <Button
            variant={viewMode === "grid" ? "default" : "outline"}
            size="sm"
            onClick={() => setViewMode("grid")}
          >
            <Grid className="h-4 w-4" />
          </Button>
        </div>
      </div>
      <Button onClick={onAdd} size="sm">
        <Plus className="h-4 w-4 mr-2" />
        Nouvel article
      </Button>
    </div>
  );
};